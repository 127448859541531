<div>
    <i nz-icon nzType="folder" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Payrolls</b>
</div>
<ds-loading *ngIf="settings.loader"></ds-loading>
<div class="m-20">
    <div class="fltr_crd">
        <form nz-form [formGroup]="searchForm">
            <div nz-row style="margin-top: 5px;">
                <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                    <div class="filter-box">
                        <div class="form-label">Payroll Year </div>
                        <nz-select nzShowSearch formControlName="yr_id" style="width : 90%;margin-bottom: 30px;">
                            <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                    <div class="filter-box" >
                        <div class="form-label">District</div>
                        <nz-select nzShowSearch formControlName="dstrt_id" style="width : 90%;margin-bottom: 30px;">
                            <nz-option [nzValue]="zeroVal" [nzLabel]="'All'">
                            </nz-option>
                            <nz-option *ngFor="let o of data.dstrct_lst" [nzValue]="o.lctn_id"
                                [nzLabel]="o.lctn_nm">
                            </nz-option>
                        </nz-select>
                    </div>
                    <div class="filter-box" *ngIf="data.dstrct_lst.length == 1">
                        <div class="form-label">District</div>
                        <div>{{data.dstrct_lst[0].lctn_nm}}</div>
                    </div>
                </div>
                <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                    <div class="filter-box">
                        <div class="form-label">Employee Type</div>
                        <nz-select nzShowSearch formControlName="emple_type_id"
                            style="width : 90%;margin-bottom: 30px;">
                            <nz-option [nzValue]="zeroVal" nzLabel="All">
                            </nz-option>
                            <nz-option *ngFor="let o of data.empleTypeLst" [nzValue]="o.emple_type_id"
                                [nzLabel]="o.emple_type_nm">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                    <div class="filter-box">
                        <div class="form-label">Employee Designation</div>
                        <nz-select nzShowSearch formControlName="dsgns_id"
                            style="width : 90%;margin-bottom: 30px;margin-left: 10px;">
                            <nz-option [nzValue]="zeroVal" nzLabel="All">
                            </nz-option>
                            <nz-option *ngFor="let o of data.dsgnsLst" [nzValue]="o.dsgns_id" [nzLabel]="o.dsgns_nm">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzXs="12" nzSm="3" nzMd="3" nzLg="3" nzXl="3">
                    <button nz-button nzType="primary" (click)="getPayrolls()"
                        style="margin-left:20px;margin-top:20px">Get
                        Payrolls</button>
                </div>
                <div nz-col nzXs="12" nzSm="3" nzMd="3" nzLg="3" nzXl="3"
                    *ngIf="data.hyrchy_id != 2 && data.hyrchy_id != 1" style="margin-top:20px;">
                    <button nz-button nzType="primary" (click)="gotogenerate()">
                        Generate Payroll
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div id="data-grid-demo" *ngIf="settings.shwGrid">
        <dx-data-grid id="gridContainer" [dataSource]="data.pyrlsLst" [allowColumnReordering]="true"
            [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true" [allowColumnResizing]="true"
            [wordWrapEnabled]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-search-panel [visible]="true">
            </dxo-search-panel>
            <dxo-editing mode="cell" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false">
            </dxo-editing>
            <dxi-column dataField="sno" caption="S.No" width="80" alignment="center">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                type="buttons" [width]="200" caption="View" cellTemplate="viewTemplate">
                <div *dxTemplate="let data of 'viewTemplate'">
                    <div class="tblEdtBtn" (click)="viewDetails(data)">
                        View Details
                    </div>
                </div>
            </dxi-column>
            <dxi-column *ngFor="let f of data.columnDefs" [visible]="f.visible" [dataField]="f.field"
                [caption]="f.headerName" [width]="f.width" [alignment]="f.alignment" [allowResizing]="true"
                [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter">
            </dxi-column>

            <dxo-summary>
                <dxi-total-item column="sno" alignment="right" displayFormat="Total:">
                </dxi-total-item>
                <dxi-total-item column="ttl_empls_ct" summaryType="sum" alignment="center" displayFormat=" {0}">
                </dxi-total-item>
                <dxi-total-item column="ttl_nt_slry_at" summaryType="sum"
                    [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                    displayFormat=" {0}"></dxi-total-item>
                <dxi-total-item column="ttl_cntrct_emple_ct" summaryType="sum" alignment="center"
                    displayFormat=" {0}"></dxi-total-item>
                <dxi-total-item column="ttl_cntrct_emple_at" summaryType="sum"
                    [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                    displayFormat=" {0}"></dxi-total-item>
                <dxi-total-item column="ttl_otsrcd_emple_ct" summaryType="sum" alignment="center"
                    displayFormat=" {0}"></dxi-total-item>
                <dxi-total-item column="ttl_otsrcd_emple_at" summaryType="sum"
                    [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                    displayFormat=" {0}"></dxi-total-item>
            </dxo-summary>
        </dx-data-grid>
    </div>
</div>

<nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="1500" [nzVisible]="settings.shwDetails" [nzTitle]="data.sidebarHeading"
    (nzOnClose)="hideForm()">
    <ng-container *nzDrawerContent>




        <div nz-row style="margin-bottom: 10px;">
            <div nz-col nzSpan="20">
                <b>Total Payroll Runs In {{data.mnth_nm}}</b>
            </div>
            <div *ngIf="!data.allEmpleLst.length" nz-col nzSpan="3" style="float:right">
                <button nz-button nzType="primary" (click)="changeEmpleType(0)">Show All Employees</button>
            </div>
        </div>

        <div id="data-grid-demo">
            <dx-data-grid id="gridContainer" [dataSource]="data.payrlRuns" [allowColumnReordering]="true"
                [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
                [allowColumnResizing]="true" [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                </dxo-pager>
                <dxo-search-panel [visible]="true">
                </dxo-search-panel>
                <dxo-editing mode="cell" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false">
                </dxo-editing>
                <dxi-column dataField="sno" caption="S.No" width="80" alignment="center">
                </dxi-column>


              
                <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                    type="buttons" [width]="180" caption="View" cellTemplate="viewTemplate">
                    <div *dxTemplate="let data of 'viewTemplate'">
                        <div class="tblEdtBtn" (click)="viewEmplyeDetails(data)">
                            View Employee Details
                        </div>
                    </div>
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                    [width]="150" caption="Status" cellTemplate="addTemplate">
                    <div *dxTemplate="let data of 'addTemplate'">
                        <div [ngClass]="data.data.btn_in == 1 ? 'tblApproveBtn' : ''"
                            (click)="data.data.btn_in == 1 ? approve(data) : ''">
                            {{data.data.sts_nm}}
                        </div>
                    </div>
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="data.hyrchy_id != 8 && settings.shwRjctClmn"
                    [width]="150" caption="Reject" cellTemplate="removeTemplate">
                    <div *dxTemplate="let data of 'removeTemplate'">
                        <div *ngIf="data.data.btn_in == 1" class="tblDelBtn" (click)="reject(data)">
                            Reject
                        </div>
                    </div>
                </dxi-column>
                <dxi-column [showInColumnChooser]="false" [visible]="data.hyrchy_id == 8 && settings.shwCnclClmn"
                    [width]="150" caption="Cancel Payroll" cellTemplate="cancelTemplate">
                    <div *dxTemplate="let data of 'cancelTemplate'">
                        <div *ngIf="data.data.btn_in == 1" class="tblDelBtn" (click)="cancel(data)">
                            Cancel Payroll
                        </div>
                    </div>
                </dxi-column>
                <dxi-column *ngIf="settings.shwRjctRsnClmn" [visible]="(data.permissions.updt_in==1)?true:false"
                    [width]="150" caption="Payroll Reject Reason" dataField="rjct_rsn_txt" alignment="left">
                </dxi-column>
                <dxi-column *ngFor="let f of data.columnDefs1" [visible]="f.visible" [dataField]="f.field"
                    [caption]="f.headerName" [width]="f.width" [alignment]="f.alignment" [allowResizing]="true"
                    [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter">
                </dxi-column>
                <dxo-summary>
                    <!-- <div *ngFor="let f of data.columnDefs1">
                    <dxi-total-item *ngIf="f.summaryType" [column]="f.field" [summaryType]="f.summaryType"
                        [displayFormat]="f.displayFormat"
                        [valueFormat]="f.smryValueFormat == 'true' ? {type:'currency',currency:'INR', precision:'2'} : ''"
                        [alignment]="f.algnmnt">
                    </dxi-total-item>
                </div> -->
                    <dxi-total-item column="sno" summaryType="count"  displayFormat="Total: " alignment="center"></dxi-total-item>
                    <dxi-total-item column="ttl_empls_ct" summaryType="sum" alignment="center"
                        displayFormat="{0}">
                    </dxi-total-item>
                    <dxi-total-item column="ttl_nt_slry_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="{0}"></dxi-total-item>
                    <dxi-total-item column="ttl_cntrct_emple_ct" summaryType="sum" alignment="center"
                        displayFormat="{0}"></dxi-total-item>
                    <dxi-total-item column="ttl_cntrct_emple_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="{0}"></dxi-total-item>
                    <dxi-total-item column="ttl_otsrcd_emple_ct" summaryType="sum" alignment="center"
                        displayFormat="{0}"></dxi-total-item>
                    <dxi-total-item column="ttl_otsrcd_emple_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="{0}"></dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
        </div>

        <div *ngIf="data.allEmpleLst.length">
            <div style="margin-bottom: -4px;margin-top: 50px;"><b>Payroll Generated For Below Employees In
                    {{data.mnth_nm}}</b></div>

            <div nz-row style="margin-top: 15px;margin-bottom: -20px;">
                <div nz-col nzSpan="4">
                    <div class="form-label">Employee Type</div>
                    <nz-select nzShowSearch [(ngModel)]="data.emple_type_id" style="width : 90%;margin-bottom: 30px;"
                        (ngModelChange)="changeEmpleType($event)">
                        <nz-option [nzValue]="zeroVal" nzLabel="All"></nz-option>
                        <nz-option *ngFor="let o of data.empleTypeLst" [nzValue]="o.emple_type_id"
                            [nzLabel]="o.emple_type_nm">
                        </nz-option>
                    </nz-select>
                </div>
            </div>

            <div id="data-grid-demo">
                <dx-data-grid id="gridContainer" [dataSource]="data.allEmpleLst" [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
                    [allowColumnResizing]="true" [wordWrapEnabled]="true">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                    </dxo-pager>
                    <dxo-search-panel [visible]="true">
                    </dxo-search-panel>
                    <dxo-editing mode="cell" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false">
                    </dxo-editing>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="true" [fileName]="data.excelName">
                    </dxo-export>
                    <!-- <div *ngFor="let f of data.empleColumnDefs">
                    <dxi-column [visible]="f.visible" [dataField]="f.field" [caption]="f.headerName" [width]="f.width"
                        [alignment]="f.alignment" [allowResizing]="true" [allowFiltering]="f.filter"
                        [allowHeaderFiltering]="f.filter">
                    </dxi-column>
                    <dxi-column *ngFor="let d of f.sub_hdngs" [visible]="d.visible" [dataField]="d.field"
                        [caption]="d.headerName" [width]="d.width" [alignment]="d.alignment" [allowResizing]="true"
                        [allowFiltering]="d.filter" [allowHeaderFiltering]="d.filter">
                    </dxi-column>
                </div> -->
                    <dxi-column dataField="sno" caption="S.No" width="80" alignment="center"></dxi-column>
                    <dxi-column dataField="dstrt_nm" caption="District" width="200" alignment="left"></dxi-column>
                    <!-- <dxi-column dataField="lctn_nm" caption="" width="200" alignment="left"></dxi-column> -->
                    <dxi-column dataField="emple_nm" caption="Employee Name" width="200" alignment="left"></dxi-column>
                    <dxi-column dataField="emple_nu" caption="Employee Number" width="150"
                        alignment="left"></dxi-column>
                    <dxi-column dataField="dsgns_nm" caption="Designation" width="200" alignment="left"></dxi-column>
                    <dxi-column dataField="emple_type_nm" caption="Employee Type" width="200" alignment="left">
                    </dxi-column>
                    <dxi-column dataField="wk_dy_ct" caption="Working Days" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="pyrl_dy_ct" caption="Pay Days" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="tl_dy_ct" caption="Total Days" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="bsc_slry_at" caption="Remuneration" width="150"
                        alignment="center"></dxi-column>
                    <dxi-column caption="Employer Contribution">
                        <dxi-column dataField="emplr_epf_pct_at" caption="EPF" width="150"
                            alignment="center"></dxi-column>
                        <dxi-column dataField="emplr_esi_pct_at" caption="ESI" width="150"
                            alignment="center"></dxi-column>
                    </dxi-column>
                    <dxi-column caption="Employee Contribution">
                        <dxi-column dataField="emple_epf_pct_at" caption="EPF" width="150"
                            alignment="center"></dxi-column>
                        <dxi-column dataField="emple_esi_pct_at" caption="ESI" width="150"
                            alignment="center"></dxi-column>
                    </dxi-column>
                    <dxi-column dataField="it_pct_at" caption="IT Amount" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="pt_at" caption="PT" width="50" alignment="center"></dxi-column>
                    <dxi-column dataField="gst_pct_at" caption="GST" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="nt_at" caption="Total to be claimed" width="150"
                        alignment="center"></dxi-column>
                    <dxi-column dataField="bnk_nm" caption="Bank Name" width="200" alignment="center"></dxi-column>
                    <dxi-column dataField="bnk_acnt_nu" caption="Bank Amount Number" width="200" alignment="center">
                    </dxi-column>
                    <dxi-column dataField="ifsc_cd" caption="Bank IFSC Code" width="200"
                        alignment="center"></dxi-column>
                    <dxi-column dataField="bnk_brnch_nm" caption="Bank Branch Name" width="200" alignment="center">
                    </dxi-column>
                    <dxo-summary>
                        <dxi-total-item column="sno" summaryType="sum" alignment="center"
                         displayFormat="Total:">
                    </dxi-total-item>
                        <dxi-total-item column="bsc_slry_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="emplr_epf_pct_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="emplr_esi_pct_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat=" {0}">
                        </dxi-total-item>
                        <dxi-total-item column="emple_epf_pct_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="emple_esi_pct_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="it_pct_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="pt_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="gst_pct_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="{0}">
                        </dxi-total-item>
                        <dxi-total-item column="nt_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="{0}">
                        </dxi-total-item>
                    </dxo-summary>
                </dx-data-grid>
            </div>
        </div>

        <div *ngIf="!data.allEmpleLst.length">
            <div style="margin-bottom: -4px;margin-top: 50px;"><b>Employees List</b></div>

            <div nz-row style="margin-top: 15px;margin-bottom: -20px;">
                <div nz-col nzSpan="4">
                    <div class="form-label">Employee Type</div>
                    <nz-select nzShowSearch [(ngModel)]="data.emple_type_id" style="width : 90%;margin-bottom: 30px;"
                        (ngModelChange)="filterByEmpleType()">
                        <nz-option [nzValue]="zeroVal" nzLabel="All"></nz-option>
                        <nz-option *ngFor="let o of data.empleTypeLst" [nzValue]="o.emple_type_id"
                            [nzLabel]="o.emple_type_nm">
                        </nz-option>
                    </nz-select>
                </div>
            </div>

            <div id="data-grid-demo">
                <dx-data-grid id="gridContainer" [dataSource]="data.empleLst" [allowColumnReordering]="true"
                    [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
                    [allowColumnResizing]="true" [wordWrapEnabled]="true">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                    </dxo-pager>
                    <dxo-search-panel [visible]="true">
                    </dxo-search-panel>
                    <dxo-editing mode="cell" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false">
                    </dxo-editing>
                    <dxo-export [enabled]="true" [allowExportSelectedData]="true" [fileName]="data.excelName">
                    </dxo-export>
                    <dxi-column dataField="sno" caption="S.No" width="50" alignment="center"></dxi-column>
                    <dxi-column dataField="dstrt_nm" caption="District" width="200" alignment="left"></dxi-column>
                    <!-- <dxi-column dataField="lctn_nm" caption="Rythu Bazar" width="200" alignment="left"></dxi-column> -->
                    <dxi-column dataField="emple_nm" caption="Employee Name" width="200" alignment="left"></dxi-column>
                    <dxi-column dataField="emple_nu" caption="Employee Number" width="150"
                        alignment="left"></dxi-column>
                    <dxi-column dataField="dsgns_nm" caption="Designation" width="200" alignment="left"></dxi-column>
                    <dxi-column dataField="emple_type_nm" caption="Employee Type" width="200" alignment="left">
                    </dxi-column>
                    <dxi-column dataField="wk_dy_ct" caption="Working Days" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="pyrl_dy_ct" caption="Pay Days" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="tl_dy_ct" caption="Total Days" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="bsc_slry_at" caption="Remuneration" width="150"
                        alignment="center"></dxi-column>
                    <dxi-column caption="Employer Contribution">
                        <dxi-column dataField="emplr_epf_pct_at" caption="EPF" width="150"
                            alignment="center"></dxi-column>
                        <dxi-column dataField="emplr_esi_pct_at" caption="ESI" width="150"
                            alignment="center"></dxi-column>
                    </dxi-column>
                    <dxi-column caption="Employee Contribution">
                        <dxi-column dataField="emple_epf_pct_at" caption="EPF" width="150"
                            alignment="center"></dxi-column>
                        <dxi-column dataField="emple_esi_pct_at" caption="ESI" width="150"
                            alignment="center"></dxi-column>
                    </dxi-column>
                    <dxi-column dataField="it_pct_at" caption="IT Amount" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="pt_at" caption="PT" width="50" alignment="center"></dxi-column>
                    <dxi-column dataField="gst_pct_at" caption="GST" width="100" alignment="center"></dxi-column>
                    <dxi-column dataField="nt_at" caption="Total to be claimed" width="150"
                        alignment="center"></dxi-column>
                    <dxi-column dataField="bnk_nm" caption="Bank Name" width="200" alignment="center"></dxi-column>
                    <dxi-column dataField="bnk_acnt_nu" caption="Bank Amount Number" width="200" alignment="center">
                    </dxi-column>
                    <dxi-column dataField="ifsc_cd" caption="Bank IFSC Code" width="200"
                        alignment="center"></dxi-column>
                    <dxi-column dataField="bnk_brnch_nm" caption="Bank Branch Name" width="200" alignment="center">
                    </dxi-column>
                    <dxo-summary>
                        <dxi-total-item column="bsc_slry_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="emplr_epf_pct_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="emplr_esi_pct_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="emple_epf_pct_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="emple_esi_pct_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="it_pct_at" summaryType="sum" alignment="right"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="pt_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="gst_pct_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="Total: {0}">
                        </dxi-total-item>
                        <dxi-total-item column="nt_at" summaryType="sum"
                            [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                            displayFormat="Total: {0}">
                        </dxi-total-item>
                    </dxo-summary>
                </dx-data-grid>
            </div>
        </div>

    </ng-container>


</nz-drawer>
<nz-modal [(nzVisible)]="settings.isVisible" [nzContent]="modalContent"  nzTitle="Are you sure want to reject this payroll ?"
    (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <ng-template #modalContent>
        <div>
            <nz-form-label nzRequired>Enter Reason For Rejection</nz-form-label>
            <div class="filter-box">
                <textarea rows="3" nz-input [(ngModel)]="data.rjctn_rsn_tx"></textarea>
            </div>
        </div>
    </ng-template>
   
</nz-modal>

<!-- <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
(nzOnCancel)="previewVisible = false">
<ng-template #modalContent>
  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
</ng-template>
</nz-modal> -->