import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
// import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-payroll-approvals',
  templateUrl: './payroll-approvals.component.html',
  styleUrls: ['./payroll-approvals.component.css']
})

export class PayrollApprovalsComponent implements OnInit {

  settings = {
    loader: false,
    shwDetails: false,
    shwGrid: false,
    isVisible: false,
    shwRjctClmn: false,
    shwCnclClmn: false,
    shwRjctRsnClmn: false
  }

  data = {
    permissions: { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 },
    years: [],
    empleLst: [],
    pyrlsLst: [],
    columnDefs: [],
    dsgnsLst: [],
    empleTypeLst: [],
    pyrle_rn_in: false,

    hyrchy_id: null,
    columnDefs1: [],
    columnDefs2: [],
    mnth_nm: null,
    sidebarHeading: 'Employees List',
    mnths: [{ mnth_id: 1, name: 'Jan' }, { mnth_id: 2, name: 'Feb' }, { mnth_id: 3, name: 'Mar' }, { mnth_id: 4, name: 'Apr' }, { mnth_id: 5, name: 'May' },
    { mnth_id: 6, name: 'Jun' }, { mnth_id: 7, name: 'July' }, { mnth_id: 8, name: 'Aug' }, { mnth_id: 9, name: 'Sep' }, { mnth_id: 10, name: 'Oct' }, { mnth_id: 11, name: 'Nov' },
    { mnth_id: 12, name: 'Dec' }],
    pyrle_mnth_rn_id: null,
    emple_type_id: 0,
    excelName: '',
    rjctn_rsn_tx: null
  }
  searchForm: FormGroup;
  zeroVal = 0;
  constructor(private apisrvc: AppsService, private fb: FormBuilder, private modal: NzModalService, public router: Router, private message: NzMessageService) {
    let usrData = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(usrData);
    // this.data.hyrchy_id = usrData.hyrchy_id;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      yr_id: ['', [Validators.required]],
      mnth_id: ['', [Validators.required]],
    });
    this.getYears();
    // this.getDesignations();
    this.getEmployeeTypes();
    // this.getApprovals();
  }




  getYears() {
    this.settings.loader = true;
    const rte = `payroll/years`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.years = res['data'];
      setTimeout(() => {
        this.searchForm.controls['yr_id'].setValue(parseInt(moment(new Date()).format('YYYY')));
        this.searchForm.controls['mnth_id'].setValue(parseInt(moment(new Date()).format('M')));
        this.getApprovals();
      }, 50)
    });
  }


  getDesignations() {
    this.settings.loader = true;
    const rte = `payroll/designations`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.dsgnsLst = res['data'];
    });
  }

  getEmployeeTypes() {
    this.settings.loader = true;
    const rte = `payroll/employee/types`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.empleTypeLst = res['data'];
    });
  }

  getApprovals() {
    this.data.rjctn_rsn_tx = null;
    this.settings.loader = true;
    const rte = `payroll/payroll/approvals/${this.searchForm.value.mnth_id}/${this.searchForm.value.yr_id}`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.settings.shwGrid = true;
        this.data.pyrlsLst = res['data'];
        if (this.data.pyrlsLst && this.data.pyrlsLst.length) {
          this.data.excelName = 'Payroll_' + this.data.pyrlsLst[0].mnth_nm + '_' + this.data.pyrlsLst[0].dstrt_nm;
          this.settings.shwRjctClmn = false;
          this.settings.shwCnclClmn = false;
          this.settings.shwRjctRsnClmn = false;
          this.data.pyrlsLst.filter((l) => {
            if (l.btn_in == 1) {
              this.settings.shwRjctClmn = true;
              this.settings.shwCnclClmn = true;
            }
            if (l.rjct_in == 1) {
              this.settings.shwRjctRsnClmn = true;
            }
          })
        }
        this.data.columnDefs = [
          { headerName: 'Month/Year', field: 'mnth_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
          { headerName: 'District', field: 'lctn_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', width: '200' },

          { headerName: 'Payroll Generation Date', field: 'pyrle_rn_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '200', filter: true },
          { headerName: 'Payroll generated for employees', field: 'ttl_empls_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '200', filter: true },
          { headerName: 'Payroll Amount', field: 'ttl_nt_slry_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true, width: '200', filter: true },
          { headerName: 'Contractors Employees Count', field: 'ttl_cntrct_emple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '200', sortable: true, filter: true },
          { headerName: 'Contractors Employees Payroll Amount', field: 'ttl_cntrct_emple_at', alignment: 'right', cellClass: 'pm-grid-number-cell', width: '200', sortable: true, filter: true },
          { headerName: 'Outsourced Employees Count', field: 'ttl_otsrcd_emple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 'auto' },
          { headerName: 'Outsourced Employees Payroll Amount', field: 'ttl_otsrcd_emple_at', alignment: 'right', cellClass: 'pm-grid-number-cell', width: '200', sortable: true, filter: true },
        ];
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
      }
    });
  }

  approve(d) {
    console.log(d.data);
    this.data.pyrle_mnth_rn_id = d.data.pyrle_mnth_rn_id;
    // if (d.data.snd_aprvl_in == 0) {
    //   this.modal.confirm({
    //     nzTitle: 'Are you sure want to send this payroll to approval ?',
    //     nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
    //     nzOkText: 'Yes',
    //     nzOkType: 'primary',
    //     nzOnOk: () => {
    //       console.log('Send to approval');
    //       this.settings.loader = true;
    //       let data = {
    //         pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id
    //       }
    //       const rte = `payroll/send-to-approval`;
    //       this.apisrvc.post(data, rte).subscribe(res => {
    //         this.settings.loader = false;
    //         if (res['status'] === 200) {
    //           // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //           // this.getApprovals();
    //         } else {
    //           // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //         }
    //       });
    //     },
    //     nzCancelText: 'No',
    //     nzOnCancel: () => console.log('Cancel')
    //   });
    // }
      if (d.data.snd_aprvl_in == 1) {
      this.modal.confirm({
        nzTitle: 'Are you sure want to approve this payroll?',
        nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOnOk: () => {
         
          this.settings.loader = true;
          let data = {
            pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id
          }
          const rte = `payroll/level2-approval`;
      
          this.apisrvc.post(data, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
              // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
              this.createMessage('success', res["message"])

              this.getApprovals();
            } else {
              // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
              this.createMessage('warning', res["message"])

            }
          });
        },
        nzCancelText: 'No',
        nzOnCancel: () => console.log('Cancel')
      });
    }



    // else if (d.data.lvl_2_aprvl_in == 0) {
    //   this.modal.confirm({
    //     nzTitle: 'Are you sure want to approve this payroll?',
    //     nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
    //     nzOkText: 'Yes',
    //     nzOkType: 'primary',
    //     nzOnOk: () => {
    //       console.log('level 2 Approval');
    //       this.settings.loader = true;
    //       let data = {
    //         pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id
    //       }
    //       const rte = `payroll/level2-approval`;
    //       this.apisrvc.post(data, rte).subscribe(res => {
    //         this.settings.loader = false;
    //         if (res['status'] === 200) {
    //           // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //     this.createMessage('success', res["message"] )

    //           this.getApprovals();
    //         } else {
    //           this.createMessage('warning', res["message"] )

    //         }
    //       });
    //     },
    //     nzCancelText: 'No',
    //     nzOnCancel: () => console.log('Cancel')
    //   });
    // }
  }

  viewEmplyeDetails(d) {
    console.log(d.data);
    this.settings.loader = true;
    this.data.pyrle_mnth_rn_id = d.data.pyrle_mnth_rn_id;
    const rte = `payroll/employees/details/${d.data.pyrle_mnth_rn_id}/${this.data.emple_type_id}`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.settings.shwDetails = true;
        this.data.empleLst = res['data'];
        // this.data.columnDefs2 = [
        //   { headerName: 'S.No', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 'auto' },
        //   { headerName: 'Employee Name', field: 'emple_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'District', field: 'dstrt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Rythu Bazar', field: 'lctn_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employee Name', field: 'emple_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employee Number', field: 'emple_nu', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Designation', field: 'dsgns_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employee Type', field: 'emple_type_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Working Days', field: 'wk_dy_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Pay Days', field: 'py_dy_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Total Days', field: 'tl_dy_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Remuration', field: 'bsc_at', alignment: 'right', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employee EPF', field: 'emple_epf_pct_at', alignment: 'right', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employer EPF', field: 'emplr_epf_pct_at', alignment: 'right', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employee ESI', field: 'emple_esi_pct_at', alignment: 'right', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'Employer ESI', field: 'emplr_esi_pct_at', alignment: 'right', cellClass: 'pm-grid-number-cell', filter: true, width: '200' },
        //   { headerName: 'IT Amount', field: 'it_pct_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'PT', field: 'pt_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'GST', field: 'gst_pct_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'Net Amount', field: 'nt_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'Bank Name', field: 'bnk_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'Bank Amount Number', field: 'bnk_acnt_nu', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'Bank IFSC Code', field: 'ifsc_cd', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        //   { headerName: 'Bank Branch Name', field: 'bnk_brnch_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true, width: '200' },
        // ];
      } else {


        this.createMessage('warning', res["message"])


      }
    });
  }

  hideForm() {
    this.settings.shwDetails = false;
    this.data.empleLst = [];
  }

  filterByEmpleType() {
    this.settings.loader = true;
    const rte = `payroll/employees/details/${this.data.pyrle_mnth_rn_id}/${this.data.emple_type_id}`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.settings.shwDetails = true;
        this.data.empleLst = res['data'];
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
      }
    });
  }

  reject(d) {
    // this.modal.confirm({
    //   nzTitle: 'Are you sure want to reject this payroll ?',
    //   nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
    //   nzOkText: 'Yes',
    //   nzOkType: 'primary',
    //   nzOnOk: () => {
    //     this.settings.loader = true;
    //     let data = {
    //       pyrle_mnth_rn_id: d.data.pyrle_mnth_rn_id
    //     }
    //     const rte = `payroll/reject`;
    //     this.apisrvc.post(data, rte).subscribe(res => {
    //       this.settings.loader = false;
    //       if (res['status'] === 200) {
    //         this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //         this.getApprovals();
    //       } else {
    //         this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });

    //       }
    //     });
    //   },
    //   nzCancelText: 'No',
    //   nzOnCancel: () => console.log('Cancel')
    // });
    this.data.pyrle_mnth_rn_id = d.data.pyrle_mnth_rn_id;
    this.settings.isVisible = true;
  }

  cancel(d) {
    this.modal.confirm({
      nzTitle: 'Are you sure want to cancel this payroll ?',
      nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.settings.loader = true;
        let data = {
          pyrle_mnth_rn_id: d.data.pyrle_mnth_rn_id
        }
        const rte = `payroll/cancel`;
        this.apisrvc.post(data, rte).subscribe(res => {
          this.settings.loader = false;
          if (res['status'] === 200) {
            // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
            this.createMessage("Success", res["message"]);

            this.getApprovals();
          } else {
            // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
            this.createMessage('warning', res["message"])



          }
        });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  handleCancel() {
    this.settings.isVisible = false;
    this.data.rjctn_rsn_tx = null;
  }

  handleOk() {
    this.settings.loader = true;
    let data = {
      pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id,
      rjctn_rsn_tx: this.data.rjctn_rsn_tx
    }
    const rte = `payroll/reject`;
    this.apisrvc.post(data, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage("Success", res["message"]);


        this.settings.isVisible = false;
        this.getApprovals();
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage("Success", res["message"]);

      }
    });
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
}
