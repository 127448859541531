import { Component, OnInit, OnDestroy } from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AppsService } from 'src/app/shared/services/apps.service';
import * as _ from 'lodash'
import { Console, error } from 'console';
import { center } from '@turf/turf';

@Component({
  selector: 'app-attndce-dashboard',
  templateUrl: './attndce-dashboard.component.html',
  styleUrls: ['./attndce-dashboard.component.scss']
})
export class AttndceDashboardComponent implements OnInit, OnDestroy {
  clntDtls: any;
  tntLst: any;
  header_label = {
    name: `Attendence Dashboard`,
    dtepkrlbl: false
  };
  selectedtenant: any;
  userDtls: any;
  isVisible: boolean;
  slctdDt = new Date();
  curDate: string;
  dateFormat = 'dd/MM/yyyy';
  monthFormat = 'yyyy/MM';
  today = new Date();
  filters: boolean;
  showLdr = false;
  emply_lst = []
  private root: am5.Root;  // Store reference to the chart root element
  columnDefs: any;
  header: 'Attendence Dashboard'
  peChrtDta: any[];
  prsnt_cnt: any;
  absnt_cnt: any;
  emply_cnt: any;
  grd_lvl = 0
  slctdTnt: any;
  summary
  svnDyDta: any;
  pieRoot: am5.Root;
  pieChart: any;
  stackedRoot: am5.Root;
  stackedChart: any;
  assetCardsData: any[];
  pstAbstnc: any;
  ttlEmplys: any;
  tnt_nm: any;
  dsgnAtndnce: any;

  constructor(public apiServ: AppsService,) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = 0
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));

  }

  // Initialize the chart when the component is created
  ngOnInit() {
    this.getEmplyAtndce();
    this.getLst7daysAtndce();
    this.getPastAbsnt();
    this.getDsgnCtgryDta()
  }

  // Clean up the chart when the component is destroyed to avoid memory leaks
  ngOnDestroy() {
    this.cleChrtRoot()
  }

  initializeStackedChart() {
    // Create root element for stacked chart
    this.stackedRoot = am5.Root.new("stackedChart");

    this.stackedRoot.setThemes([am5themes_Animated.new(this.stackedRoot)]);

    this.stackedChart = this.stackedRoot.container.children.push(am5xy.XYChart.new(this.stackedRoot, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      paddingLeft: 0,
      layout: this.stackedRoot.verticalLayout
    }));

    this.stackedChart.set("scrollbarX", am5.Scrollbar.new(this.stackedRoot, {
      orientation: "horizontal"
    }));

    let data = this.svnDyDta;

    let xRenderer = am5xy.AxisRendererX.new(this.stackedRoot, {
      minorGridEnabled: true
    });

    let xAxis = this.stackedChart.xAxes.push(am5xy.CategoryAxis.new(this.stackedRoot, {
      categoryField: "frmtd_dt",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(this.stackedRoot, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(data);

    let yAxis = this.stackedChart.yAxes.push(am5xy.ValueAxis.new(this.stackedRoot, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(this.stackedRoot, {
        strokeOpacity: 0.1
      })
    }));

    let legend = this.stackedChart.children.push(am5.Legend.new(this.stackedRoot, {
      centerX: am5.p50,
      x: am5.p50
    }));

    function makeSeries(name, fieldName) {
      let series = this.stackedChart.series.push(am5xy.ColumnSeries.new(this.stackedRoot, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "frmtd_dt"
      }));

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });

      series.data.setAll(data);

      series.appear();

      series.bullets.push(() => {
        return am5.Bullet.new(this.stackedRoot, {
          sprite: am5.Label.new(this.stackedRoot, {
            text: "{valueY}",
            fill: this.stackedRoot.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      legend.data.push(series);
    }

    makeSeries.call(this, "Present", "prsnt_cnt");
    makeSeries.call(this, "Absent", "absnt_cnt");

    this.stackedChart.appear(1000, 100);
  }

  initializeChart() {
    // Create root element for pie chart
    this.pieRoot = am5.Root.new("chartdiv");

    this.pieRoot.setThemes([am5themes_Animated.new(this.pieRoot)]);

    this.pieChart = this.pieRoot.container.children.push(
      am5percent.PieChart.new(this.pieRoot, {
        endAngle: 270
      })
    );

    let series = this.pieChart.series.push(
      am5percent.PieSeries.new(this.pieRoot, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270
      })
    );

    series.states.create("hidden", {
      endAngle: -90
    });

    series.data.setAll(this.peChrtDta);
    series.appear(1000, 100);
  }

  getEmplyDta(e) {
    console.log(e)
    this.tnt_nm = e.value
    if (e.column.caption === 'District Name' && e.rowType == 'data') {
      this.cleChrtRoot()
      console.log('hhhhh')
      this.grd_lvl = 1;
      this.selectedtenant = e.data.tnt_id
      this.getEmplyAtndce();
      this.getLst7daysAtndce();
      this.getPastAbsnt();
      this.getDsgnCtgryDta()
    }
  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) > 0;
  };

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  ontntSelect(value) {
    if (value == 0) {
      this.grd_lvl = 0
      this.selectedtenant = 0;
    }
    else {
      this.grd_lvl = 1
      this.selectedtenant = value;
      this.tnt_nm = this.tntLst.find((tn) => tn.tnt_id == value)?.tnt_nm
    }
    this.cleChrtRoot()
    this.getLst7daysAtndce();
    this.getEmplyAtndce();
    this.getPastAbsnt();this.getDsgnCtgryDta()
  }

  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.cleChrtRoot()
    this.getLst7daysAtndce();
    this.getEmplyAtndce()
  }

  fltrs() {
    this.filters = !this.filters;
  }

  getEmplyAtndce() {
    var postDta
    if (this.grd_lvl == 0) {
      postDta = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
      }

    }
    else {
      postDta = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
        slctd_tnt: this.selectedtenant
      }
    }
    console.log(postDta)
    this.apiServ.post(postDta, `apsfl/employe/attendence`).subscribe((res) => {
      if (res['status'] == 200) {
        this.emply_lst = res['data'];
        console.log(this.emply_lst);
        this.summary = this.emply_lst.reduce((acc, cur) => {
          acc.absent += cur.absnt_cnt;
          acc.present += cur.prsnt_cnt
          return acc;
        }, { absent: 0, present: 0 })
        console.log(this.summary)

        let categories = ['absent', 'present'];
        this.peChrtDta = categories.map(category => ({
          category: category,
          value: this.summary[category]
        }));
        console.log(this.peChrtDta);
        this.ttlEmplys = _.sumBy(this.peChrtDta, 'value')
        console.log(this.ttlEmplys)
        if (this.grd_lvl == 0) {
          this.columnDefs = this.columnDefs = [
            { headerName: 'S.No', field: 'sno', width: 80, alignment: 'center' },
            { headerName: 'District Name', field: 'tnt_nm', hide: false },
            { headerName: 'Employes', field: 'ttl_emplys', hide: false, alignment: 'center', displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
            { headerName: 'No.of Employes Present', field: 'prsnt_cnt', hide: false, alignment: 'center', displayFormat: "{0}", summaryType: "sum", smryValueFormat: false, },
            { headerName: 'Total Activities', field: 'ttl_wrks', hide: false, alignment: 'center', displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
            { headerName: 'Employes on leave', field: 'absnt_cnt', hide: false, alignment: 'center', displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
            { headerName: 'Authorized Leaves', field: 'aurthorized_lvs', hide: false, alignment: 'center', displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
            { headerName: 'Un-Authoriaed Leaves', field: 'un_aurthorized_lvs', hide: false, alignment: 'center', displayFormat: "Sum: {0}", summaryType: "sum", smryValueFormat: false, },
            { headerName: 'Employe present percentage(%)', field: 'prsnt_prcnt', hide: false, alignment: 'center', displayFormat: "Sum: {0}", summaryType: "avg", smryValueFormat: false, },
          ]
        }
        else {
          this.columnDefs = this.columnDefs = [
            { headerName: 'S.No', field: 'sno', width: 80, alignment: 'center' },
            { headerName: 'Employee Name', field: 'emple_nm', hide: false },
            { headerName: 'Total Activities', field: 'ttl_wrks', hide: false },
            { headerName: 'Total Leaves', field: 'ttl_lvs_ct', hide: false },
            { headerName: 'Available Leaves', field: 'blnc_lvs_ct', hide: false },
            { headerName: 'Check-in status', field: 'lv_txt', hide: false },
            // { headerName: 'Leave Type', field: 'lve_hndlr', hide: false },
          ]
        }
        this.initializeChart();
      }
    }
      , (err) => {
        console.log(err)
      });
  }
  onCellPrepared(e) {
    if (e.column.caption == 'District Name') {
      e.cellElement.style.color = "rgb(39, 153, 234)";
      e.cellElement.style.cursor = `pointer`;
      e.cellElement.style.fontWeight = `500`;
    }
  }
  getLst7daysAtndce() {
    console.log('this function called')
    var post
    if (this.grd_lvl == 0) {
      post = {
        grd_lvl: this.grd_lvl,
      }

    }
    else {
      post = {
        grd_lvl: this.grd_lvl,
        slctd_tnt: this.selectedtenant
      }
    }
    console.log(post)
    this.apiServ.post(post, `apsfl/employe/lst7dayAtndnce`).subscribe((res) => {
      if (res['status'] == 200) {
        this.svnDyDta = res['data'];
        this.initializeStackedChart();
        console.log(this.svnDyDta)
      }
      else {
        throw new Error('Something Went Wrong, please try again');
      }
    },
      (error) => {
        console.error('Fetch error:', error);

      }
    )
  }
  cleChrtRoot() {
    if (this.stackedChart) {
      this.stackedRoot.dispose();
      this.stackedChart = null; // Clear the reference
    }

    if (this.pieChart) {
      this.pieRoot.dispose();
      this.pieChart = null; // Clear the reference
    }
  }
  formatter2 = new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
  });
  SumINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };
  formatter1 = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  getPastAbsnt() {
    var postData = {
      slctd_tnt: this.selectedtenant
    }
    console.log(postData)
    this.apiServ.post(postData, `apsfl/get/pastAbsntnc`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.pstAbstnc = res['data'];
        console.log(this.pstAbstnc)
      }
    })
  }
 
  goBack() {
    if (this.grd_lvl == 1) {
      this.grd_lvl = 0;
      this.selectedtenant = 0
      this.cleChrtRoot()
      this.getLst7daysAtndce();
      this.getEmplyAtndce();
      this.getPastAbsnt();
      this.getDsgnCtgryDta()
    }
  }
  getDsgnCtgryDta(){
    var postData ={
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        slctd_tnt: this.selectedtenant
    }
    this.apiServ.post(postData,`apsfl/employe/designation/Attendence`).subscribe((res) =>{
      if(res['status'] == 200){
        this.dsgnAtndnce = res['data'];
        console.log(this.dsgnAtndnce)
      }
      else{

      }
    })
  }
}
