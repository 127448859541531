import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PayrollService } from "../../payroll.service";
// import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
// import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
declare const $: any;

@Component({
  selector: 'app-hikes-list',
  templateUrl: './hikes-list.component.html',
  styleUrls: ['./hikes-list.component.css']
})
export class HikesListComponent implements OnInit {
  // @ViewChild(DataTableDirective, { static: false })
  // public dtElement: DataTableDirective;
  // public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  public lstSubDepartment: any[];
  public tempId: any;
  public editId: any;
  loader: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private payrollSrvc: PayrollService,
    // private toastr: ToastrService, 
  ) { }

  ngOnInit() {
    // this.dtOptions = {
    //   // ... skipped ...
    //   pageLength: 10,
    //   dom: "lrtip",
    // };
    this.LoadHikes();
  }


  // Get department list  Api Call
  LoadHikes() {
    this.loader = true;
    this.payrollSrvc.getHikesLst().subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        this.lstSubDepartment = res['data'];
        this.dtTrigger.next();
      } else {
        // this.toastr.error(res['message'], "Error");
      }
    });
  }



  // editDepartment() {
  //   if (this.editDepartmentForm.valid) {
  //     let obj = {
  //       lve_prfle_nm: this.editDepartmentForm.value.DepartmentName,
  //       dscn_tx: this.editDepartmentForm.value.DepartmentDscn,
  //       lop_dy_ct: this.editDepartmentForm.value.lop_dy_ct
  //     };
  //     this.payrollSrvc.updateLeavePolicy(obj, this.editId).subscribe((res) => {
  //       if (res['status'] == 200) {
  //         this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //           dtInstance.destroy();
  //         });
  //         this.LoadSubDepartment();
  //         $("#edit_subdepartment").modal("hide");
  //         this.toastr.success("Leave Policy Updated Sucessfully", "Success");
  //       } else {
  //         this.toastr.error(res['message'], "Error");
  //       }
  //     });

  //   }
  // }

  // // To Get The department Edit Id And Set Values To Edit Modal Form
  // edit(value) {
  //   this.editId = value;
  //   const index = this.lstSubDepartment.findIndex((item) => {
  //     return item.lve_prfle_id === value;
  //   });
  //   let toSetValues = this.lstSubDepartment[index];
  //   this.editDepartmentForm.setValue({
  //     DepartmentName: toSetValues.lve_prfle_nm,
  //     DepartmentDscn: toSetValues.dscn_tx,
  //     lop_dy_ct: toSetValues.lop_dy_ct
  //   });
  // }


  deleteDepartment() {
    // this.payrollSrvc.deleteLeavePolicy(this.tempId).subscribe((res) => {
    //   if (res['status'] == 200) {
    //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //       dtInstance.destroy();
    //     });
    //     this.LoadSubDepartment();
    //     $("#delete_department").modal("hide");
    //     this.toastr.success("Leave Policy Deleted Sucessfully", "Success");
    //   } else {
    //     this.toastr.error(res['message'], "Error");
    //   }
    // });
  }


  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
