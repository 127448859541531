import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as L from 'leaflet';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'leaflet.heat/dist/leaflet-heat.js';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';


@Component({
  selector: 'app-svm-trips-cvrge-dashboard',
  templateUrl: './svm-trips-cvrge-dashboard.component.html',
  styleUrls: ['./svm-trips-cvrge-dashboard.component.scss']
})
export class SvmTripsCvrgeDashboardComponent implements OnInit {
  clntDtls: any; 
  rdrctSvmDtls;
  tntLst: any = [];
  selectedtenant: any;
  selectedtenantNm: any;
  ulb_id: any;
  userDtls: any;
  tdyCoveredTrips: any[];
  showLdr: boolean;
  curDate;
  slctdDt = new Date();
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  dbrd_ky = 'svm'
  tripsData: any[];
  columnDefs: any = [];
  rowselected: any;
  selectedSvm: any;
  svm_id;
  usrDtls: any;
  map: any;
  schdPolyline: any;
  rteDta: any;
  trvld_Polyline: any;
  eachTrpData: any;
  @ViewChild('pdfContent') pdfContent: ElementRef;
  heatData: any = [];
  heatmapLayer: any;
  circleMarker: any;
  crclArry: any = [];
  shwCvrge: boolean = true;
  shwplyback: boolean = false;
  vehclesLst: any=[];
  svm_apfs_cd: any;
  selectedVehicle: any;
  slctdVehNm: any;
  cvrge_prcnt: number;
  avg_cvrg: string;
  schdPolylineArry: any = [];
  schdStartMarker: any;
  schdEndMarker: any;
  dstCnt: number;

  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router, public datepipe: DatePipe) {
    this.rdrctSvmDtls = [];
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.rdrctSvmDtls = this.TransfereService.getLoclData('svmDta');
    this.clntDtls[0].tnts.forEach(element => {
      this.tntLst.push(element);
    });
    if (this.rdrctSvmDtls) {
      this.ontntSelect(this.rdrctSvmDtls.tnt_id);
      this.selectedSvm = this.rdrctSvmDtls.svm_nm;
      this.svm_id = this.rdrctSvmDtls.svm_id;
      this.slctdDt = this.rdrctSvmDtls.slctdDt;
      this.svm_apfs_cd = this.rdrctSvmDtls.svm_apfs_cd;
    } else {
      this.selectedtenant = this.tntLst[0].tnt_id;
      this.selectedtenantNm = this.tntLst[0].tnt_dsply_nm;
    }
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ngOnInit(): void {
    this.getCoveredTripsCount();
    this.getTripsData();
    this.initializeMap();
    this.getVehicleBySvm();
  }
  ontntSelect(value) {
    console.log(value);
    if (value == 0) {
      this.selectedtenant = 0;
      this.selectedtenantNm = "All ULB's Selected";
      this.ulb_id = 0;
    }
    for (let i = 0; i < this.tntLst.length; i++) {
      console.log('in');
      if (this.tntLst[i].tnt_id == value) {
        this.selectedtenant = this.tntLst[i].tnt_id;
        this.selectedtenantNm = this.tntLst[i].tnt_dsply_nm;

      }
    }

    this.getCoveredTripsCount();
    this.getTripsData();

  }
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.getCoveredTripsCount();
    this.getTripsData();

  }
  getCoveredTripsCount(): any {
    this.tdyCoveredTrips = [];
    this.showLdr = true;
    this.svm_id = this.rdrctSvmDtls.svm_id;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      svm_id: this.svm_id,
      
    }
    let rte = `dashboard/enterprise/getCoveredTripsCount/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tdyCoveredTrips = res['data'];
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  getTripsData() {
    this.tripsData = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      svm_id: this.svm_id
    }
    let rte = `dashboard/enterprise/svm/getCoveredTripsDetails`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tripsData = res['data'];
        console.log(this.tripsData);
        this.dstCnt=0;
        this.tripsData.forEach(t => {
          this.dstCnt+=t.actl_trvl_dstne_ct;
        });
        this.columnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'ULB', field: 'ZONE', alignment: 'left' },
          { headerName: 'Sachivalayam', field: 'svm_nm', alignment: 'left' },
          { headerName: 'Vehicle', field: 'VEHICLE', alignment: 'Center' },
          { headerName: 'Trip Number', field: 'TRIP_NUM', alignment: 'Center' },
          { headerName: 'Source', field: 'SOURCE', alignment: 'left' },
          { headerName: 'Destination', field: 'DESTINATION', alignment: 'left' },
          { headerName: 'Start Time', field: 'ACTUAL_START', alignment: 'Center' },
          { headerName: 'End Time', field: 'ACTUAL_END', alignment: 'Center' },
          { headerName: 'Schedule Distance (In KM)', field: 'km', alignment: 'Center' },
          { headerName: 'Travelled Distance (In KM)', field: 'actl_trvl_dstne_ct', alignment: 'Center' },
          { headerName: 'Trip Status', field: 'TripStatus', alignment: 'left' },
          // { headerName: 'Coverage %', field: 'cvrg_prcntg_ct', alignment: 'Center' },
          { headerName: 'Route Deviated', field: 'Deviation', alignment: 'Center' },
        ]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  goBack() {
    this.TransfereService.ClearLocalData('svmDta');
    this.route.navigate([`internal/dashboard/ulbtripCvrge-dashboard`]);
  }
  // *************** MAP ************************//
  initializeMap() {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {
      if (!this.map) {
        this.map = L.map('map', {
          center: [this.usrDtls[0].lat, this.usrDtls[0].lng],
          zoom: 3,
        });
        this.map.setView([this.usrDtls[0].lat, this.usrDtls[0].lng], 13);
        const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          minZoom: 13,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          attribution: ''
        });
        tiles.addTo(this.map);
      }
    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
  }

  // for getting  Schedule lines
  getPlayback() {
    // this.eachTrpData = data.data;
    
    this.shwCvrge = false;
    this.shwplyback = true;
    if (this.schdPolylineArry) {
      for (let s = 0; s < this.schdPolylineArry.length; s++) {
        this.map.removeLayer(this.schdPolylineArry[s].plylns);

      }
    }
    if (this.trvld_Polyline) {
      this.map.removeLayer(this.trvld_Polyline);
    }
    if (this.crclArry) {
      for (let c = 0; c < this.crclArry.length; c++) {
        this.map.removeLayer(this.crclArry[c].crcls);
      }
    }
    if(this.schdStartMarker){
        this.map.removeLayer(this.schdStartMarker); 
    }
    if(this.schdEndMarker){
      this.map.removeLayer(this.schdEndMarker); 
  }
    // plotting schedule polyline on map
    for (let r = 0; r < this.tripsData.length; r++) {
      
      this.schdPolyline = L.polyline([JSON.parse(this.tripsData[r].rte_arc_lne_tx)], {
        color: '#008000',
        weight: 5,
        lineCap: 'butt',
        opacity: 0.6,
        smoothFactor: 10.0,
        lineJoin: 'round'
      }).addTo(this.map);
      this.schdPolylineArry.push({ plylns: this.schdPolyline });
      this.map.fitBounds(this.schdPolyline.getBounds());
      var startIcon = new L.Icon({
        iconUrl: '/assets/images/markers/mapIcons/m/location.png',
        iconSize: [33, 33],
        iconAnchor: [16, 28],
      });

      this.schdStartMarker = L.marker([JSON.parse(this.tripsData[0].rte_arc_lne_tx)[0][0], JSON.parse(this.tripsData[0].rte_arc_lne_tx)[0][1]], {
        icon: startIcon
      }).addTo(this.map).bindTooltip(this.tripsData[0].SOURCE, { permanent: true, opacity: 0.7 }).openTooltip();

      // ----------Fence END Area (flag position) -- Leaflet OS ----------//
      var endIcon = new L.Icon({
        iconUrl: '/assets/images/markers/mapIcons/m/location.png',
        iconSize: [33, 33],
        iconAnchor: [16, 28],
      });
      let k=JSON.parse(this.tripsData[this.tripsData.length-1].rte_arc_lne_tx).length-1;
      // if (c.end_fnce_lat && c.end_fnce_lng) {
        

        this.schdEndMarker = L.marker([JSON.parse(this.tripsData[this.tripsData.length-1].rte_arc_lne_tx)[k][0], JSON.parse(this.tripsData[this.tripsData.length-1].rte_arc_lne_tx)[k][1]], {
          icon: endIcon
        }).addTo(this.map).bindTooltip(this.tripsData[this.tripsData.length-1].DESTINATION, { permanent: true, opacity: 0.7 }).openTooltip();

      // }

    }

    // if (this.eachTrpData.actl_strt_ts == null) {
    let strt_ts = this.curDate + ' 00:00:00';
    // } else {
    //   this.eachTrpData.strt_ts = data.data.actl_strt_ts
    // }
    // if (this.eachTrpData.actl_end_ts == null) {

    let end_ts = this.curDate + ' 23:59:59';
    // } else {
    //   this.eachTrpData.end_ts = data.data.actl_end_ts
    // }
    // console.log(strt_ts,end_ts)
    // this.eachTrpData.plyBk_strt_ts = this.datepipe.transform(strt_ts, 'yyyy-MM-dd HH:mm');
    // this.eachTrpData.plyBk_end_ts = this.datepipe.transform(end_ts, 'yyyy-MM-dd HH:mm');
    //  plotting playback route for trip
    var postslctdDt = {
      dt: this.curDate,
      data: {
        dev_imei_nu: this.tripsData[0].dev_imei_nu,
        start_tm: this.datepipe.transform(strt_ts, 'yyyy-MM-dd HH:mm'),
        end_tm: this.datepipe.transform(end_ts, 'yyyy-MM-dd HH:mm')
      }
    }
    // console.log(postslctdDt)
    // Getting travelled route line arc
    this.showLdr = true;
    const rte = `dashboard/enterprise/get_tripTrvlRoute`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        // var res_data = res['data'];
        // if (res_data && res_data.length > 0) {
        this.rteDta = res['data'];
        console.log(this.rteDta)
        var bnd_strng = [];
        for (let l = 0; l < this.rteDta.length; l++) {
          bnd_strng.push([this.rteDta[l].lat, this.rteDta[l].lng]);
        }
        this.showLdr = false;
        this.trvld_Polyline = L.polyline(([bnd_strng]), {
          color: 'red',
          weight: 4,
          lineCap: 'butt',
          opacity: 0.5,
          smoothFactor: 10.0,
          lineJoin: 'round',
        }).addTo(this.map);
        this.map.fitBounds(this.trvld_Polyline.getBounds());
        // }
      } else {
        this.showLdr = false;
      }
    });
  }
  getHeatMap() {
    this.shwCvrge = true;
    this.shwplyback = false;
    if (this.schdPolylineArry) {
      for (let s = 0; s < this.schdPolylineArry.length; s++) {
        this.map.removeLayer(this.schdPolylineArry[s].plylns);
      }
    }
    if (this.trvld_Polyline) {
      this.map.removeLayer(this.trvld_Polyline);
    }
    this.heatData = [];
    if (this.crclArry) {
      for (let c = 0; c < this.crclArry.length; c++) {
        this.map.removeLayer(this.crclArry[c].crcls);
      }
    }
    // getting heat map data
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postData = {
      asrt_id: this.selectedVehicle,
      dt: this.curDate,
    };
    this.showLdr = true;
    const rte = `dashboard/enterprise/cvrgeHeatMap`;
    this.apiServ.post(postData, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.heatData = res['data'];
        // for (let i = 0; i < respData.length; i++) {
        //   this.heatData.push({
        //     "lat": respData[i].lat,
        //     "lng": respData[i].lng,
        //     "count":1
        //   });
        // }
        this.showLdr = false;
        // console.log(this.heatData);

        // if (this.heatmapLayer) {
        //   this.map.removeLayer(this.heatmapLayer);
        // }
        // this.heatmapLayer = L.heatLayer(this.heatData, {
        //   maxZoom: 10,
        //   radius: 12,
        //   maxOpacity: .8,
        // })
        //   .addTo(this.map);
        // this.crclArry.push({ crcls: this.circleMarker });
        // this.map.setZoom(18);
        var minLat = Infinity, minLng = Infinity, maxLat = -Infinity, maxLng = -Infinity;

            for (let h = 0; h < this.heatData.length; h++) {
              if (this.heatData[h].cvrg_ct == 0) {
                this.heatData[h].clr = '#e30505';
                this.heatData[h].fillColor = '#e36868';
              } else {
                this.heatData[h].clr = '#04c92b';
                this.heatData[h].fillColor = '#99eaa9';
              }

              // Create circle marker
              this.circleMarker = L.circle([this.heatData[h].lat, this.heatData[h].lng], { 
                stroke: false, 
                radius: 55, 
                weight: 0, 
                fillOpacity: 0.7, 
                fillColor: this.heatData[h].fillColor 
              }).addTo(this.map);
             

              // Update bounds
              if (this.heatData[h].lat < minLat) minLat = this.heatData[h].lat;
              if (this.heatData[h].lng < minLng) minLng = this.heatData[h].lng;
              if (this.heatData[h].lat > maxLat) maxLat = this.heatData[h].lat;
              if (this.heatData[h].lng > maxLng) maxLng = this.heatData[h].lng;

              this.crclArry.push({ crcls: this.circleMarker });
              this.map.fitBounds(this.circleMarker.getBounds());
              // Calculate the bounds
                var bounds = [[minLat, minLng], [maxLat, maxLng]];

                this.map.fitBounds(bounds, {
                  animate: true,
                  duration: 0.5,
                  maxZoom:23
                  });   
            }
            this.map.setZoom(14);
            
          this.showLdr = false;
         
          // this.circleMarker[i].setStyle({this.heatData[h].fillColor,stroke: false,fillOpacity: 0.07})
        }
    })
  }
  ngOnDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
  downloadPDF() {
    const element = this.pdfContent.nativeElement;

    // Apply custom styling to fix the content size
    element.style.width = '100%';
    element.style.height = 'auto';
    const options = {
      scale: 2, // Adjust the scale factor as needed
      useCORS: true, // Enable cross-origin resource sharing
      logging: true // Enable logging for debugging (optional)
    };

    html2canvas(element, options).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      console.log(pdfWidth, pdfHeight)

      pdf.addImage(imgData, 'PNG', 0, 0, 300, 200);
      pdf.save('your-file-name.pdf');
    });
  }
  onChkCoverage(evt) {
    this.shwCvrge = evt;
    if (this.shwCvrge == true) {
      this.shwplyback = false;
      this.eachTrpData = null;
      this.getHeatMap();
    } else {
      if (this.crclArry) {
        for (let c = 0; c < this.crclArry.length; c++) {
          this.map.removeLayer(this.crclArry[c].crcls);
        }
      }
      this.shwplyback = true;
      this.getPlayback();
    }
  }
  onChkPlyback(evt) {
    this.shwplyback = evt;
    if (this.shwplyback == true) {
      this.shwCvrge = false;
      this.getPlayback();
    } else {
      this.eachTrpData = null;
      this.shwCvrge = true;
      this.getHeatMap();
    }
  }
  getVehicleBySvm() {
    this.apiServ.get(`drivers/vehicles/bysvm/${this.svm_apfs_cd}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.vehclesLst = res['data'];
        if (this.vehclesLst.length > 0) {
          this.selectedVehicle = this.vehclesLst[0].asrt_id;
          this.slctdVehNm = this.vehclesLst[0].asrt_nm;
          this.onChkCoverage(true);
        } else {
          this.selectedVehicle = 0;
          this.slctdVehNm = '';
        }
      }
    });
  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Ward Secretariat wise Trip Coverage Status.xlsx');
      });
    });
  }

  onasrtgetSelected(asrt) {
    this.selectedVehicle = asrt;
    this.vehclesLst.forEach(e => {
      if (e.asrt_id == asrt) {
        this.slctdVehNm = e.asrt_nm;
      } else {
        this.selectedVehicle = 0;
        this.slctdVehNm = '';
      }

    });
    this.onChkCoverage(true);
  }
}
