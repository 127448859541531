<ds-loading *ngIf="showLdr"></ds-loading>

<!-- <app-common-header [header_name] = "header_label"></app-common-header> -->
<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-8 col-md-8 mg-a">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;" >
                
                <div *ngIf="filters == true" (click) = "fltrs()" class="filter_btn">
                    <span nz-icon nzType="filter" nzTheme="outline"></span> Hide Filters
                </div>

                <div *ngIf="filters == false" (click) = "fltrs()" class="filter_btn">
                    <span nz-icon nzType="filter" nzTheme="outline"></span> Show Filters
                </div>

            </div>

        </div>

    </div>
</div>

<div class="main">
<div class="fltrsCrd" *ngIf="filters">
    <div class="row">
        <div class="col-lg-3 col-md-3 customWidth">
            <div class="drpLbl">Select Date</div>
            <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                (ngModelChange)="onChange($event)"></nz-date-picker>
        </div>
        <div class="col-lg-3 col-md-3 customWidth" *ngIf="tntLst.length>1">
            <div class="drpLbl">Select ULB</div>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant" (ngModelChange)="ontntSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <!-- <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option> -->
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
            </nz-select>
        </div>
        <div class="col-lg-3 col-md-3 customWidth" *ngIf="tntLst.length==1">
            <div class="drpLbl">ULB</div>
            <div class="slctBx disbledtxt">{{tntLst[0].tnt_nm}}</div>
        </div>

        <div *ngIf="svmLst && svmLst.length > 0" class="col-xl-3 col-lg-3 col-md-3 customWidth">
            <div class="drpLbl" style="float: left;">Select Sachivalayam</div>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Sachivalayam" [(ngModel)]="slctdSvm"
                (ngModelChange)="selectSachvalayam($event)">
                <nz-option nzLabel='All' nzValue='0' *ngIf="userDtls.rle_id!=37"></nz-option>
                <nz-option *ngFor="let c of svmLst" [nzLabel]=c.svm_nm [nzValue]=c.svm_id>
                </nz-option>
            </nz-select>
        </div>



        <div class="col-lg-3 col-md-3 crdCl customWidth" *ngIf="ShowClap">
            <!-- <p class="slctlbl">Vehicle Vendor</p> -->
            <div class="drpLbl">Vehicle Vendor</div>
            <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
                <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
            </nz-select>
        </div>

        <div class="col-lg-3 col-md-3 customWidth">
            <button nz-button nzType="primary" class="fltrSbmitBtn">Submit</button>
        </div>
    </div>
</div>

    <div class="row">
        <ul class="cards" *ngIf="rdCnts && rdCnts.length>0">
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #1f694b 0%,#257175 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Trips</h1>
                        </div>
                        <div class="row ">
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'trips')">{{rdCnts[0].ttl_trps === null ?
                                    "0" :
                                    rdCnts[0].ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'trips')">{{rdCnts[0].prmry_trps === null ?
                                    "0" :
                                    rdCnts[0].prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'trips')">{{rdCnts[0].scndry_trps === null ?
                                    "0" :
                                    rdCnts[0].scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{rdCnts[0].ttl_rd_trps === null ? 0 : rdCnts[0].ttl_rd_trps}}</h1>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>

            <!-- <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #557025 0%,#275513 100%);">
                    <div class="card_content" >
                        <div class="row">
                            <h1 class="crdHdr">Primary Route Deviated Trips</h1>
                        </div>
                        <div class="row crdHdr_sb">
                            <div class="col-lg-6 col-md-6">
                                <h1>
                                    <i nz-icon nzType="node-index" nzTheme="outline"></i>
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h1 class="card_sub_title">{{rdCnts[0].prmry_rd_trps  === null ? 0 : rdCnts[0].prmry_rd_trps }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li> -->
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #557025 0%,#275513 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">travelled Trips</h1>
                        </div>
                        <div class="row ">

                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'trvl')">{{rdCnts[0].trvl_ttl_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'trvl')">{{rdCnts[0].trvl_prmry_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'trvl')">{{rdCnts[0].trvl_scndry_trps ===
                                    null ? "0" :
                                    rdCnts[0].trvl_scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #803334 0%,#771c4a 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Route Deviated Trips</h1>
                        </div>
                        <div class="row ">

                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'rd')">{{rdCnts[0].ttl_rd_trps === null ?
                                    "0" :
                                    rdCnts[0].ttl_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'rd')">{{rdCnts[0].prmry_rd_trps === null ?
                                    "0" :
                                    rdCnts[0].prmry_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'rd')">{{rdCnts[0].scndry_rd_trps === null ?
                                    "0" :
                                    rdCnts[0].scndry_rd_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="cards_item">
                <div class="card_g" style="background:linear-gradient(135deg, #335b80 0%,#251c77 100%);">
                    <div class="card_content">
                        <div class="row">
                            <h1 class="crdHdr">Not Travelled Trips</h1>
                        </div>
                        <div class="row ">

                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(null,'nt')">{{rdCnts[0].nt_trvl_ttl_trps ===
                                    null ? "0" :
                                    rdCnts[0].nt_trvl_ttl_trps}}
                                </h1>
                                <h1 class="card_sub_title">Total</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(1,'nt')">{{rdCnts[0].nt_trvl_prmry_trps ===
                                    null ? "0" :
                                    rdCnts[0].nt_trvl_prmry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Primary</h1>
                            </div>
                            <div class="col card_prt">
                                <h1 class="crdHdr_sb_sm" (click)="getsort(0,'nt')">{{rdCnts[0].nt_trvl_scndry_trps ===
                                    null ? "0" :
                                    rdCnts[0].nt_trvl_scndry_trps}}
                                </h1>
                                <h1 class="card_sub_title">Secondary</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

    </div>

    <div class="bg-wp">
        <div class="row" style="padding:0px 10px">
            <div class="col-xl-3 col-lg-3 col-md-3 customWidth">
                <div class="drpLbl" style="float: left;">Search Vehicle</div>
                <nz-input-group>
                    <input type="text"
                        style="height: 32px;border-radius: 3px !important; background: linear-gradient(135deg, #ffffff 0%, #e7eaec 100%);border: 1px solid #d9d9d9;"
                        nz-input [(ngModel)]="searchText" placeholder="Search here" (ngModelChange)="onInput($event)"
                        [ngModelOptions]="{standalone: true}" />
                </nz-input-group>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 customWidth">
                <div class="drpLbl" style="float: left;">Select Category</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Category" [(ngModel)]="selectedCtgry"
                    (ngModelChange)="selectCategory($event)">
                    <nz-option nzLabel='All' nzValue='0'></nz-option>
                    <nz-option *ngFor="let c of vehCtgrys" [nzLabel]=c.asrt_ctgry_nm [nzValue]=c.asrt_ctgry_id>
                    </nz-option>
                </nz-select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 customWidth">
                <div class="drpLbl" style="float: left;">Select Start Location</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Start Location" [(ngModel)]="strt_loc"
                    (ngModelChange)="onselectStrtLctn($event)">
                    <nz-option nzLabel='All' nzValue='0'></nz-option>
                    <nz-option *ngFor="let l of tripLoctns" [nzLabel]=l.strt_fnce_nm [nzValue]=l.strt_fnce_id>
                    </nz-option>
                </nz-select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 customWidth">
                <div class="drpLbl" style="float: left;">Select End Location</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="End Location" [(ngModel)]="end_loc"
                    (ngModelChange)="onselectEndLctn($event)">
                    <nz-option nzLabel='All' nzValue='0'></nz-option>
                    <nz-option *ngFor="let l of tripEndLoctns" [nzLabel]=l.end_fnce_nm [nzValue]=l.end_fnce_id>
                    </nz-option>
                </nz-select>
            </div>
            <div *ngIf="wrds && wrds.length > 0 && clntDtls[0].clnt_id!=7" class="col-xl-3 col-lg-3 col-md-3 customWidth">
                <div class="drpLbl" style="float: left;">Select Ward</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Ward" [(ngModel)]="slctdWrd"
                    (ngModelChange)="selectWard($event)">
                    <nz-option nzLabel='All' nzValue='0'></nz-option>
                    <nz-option *ngFor="let c of wrds" [nzLabel]=c.wrd_nm [nzValue]=c.wrd_id>
                    </nz-option>
                </nz-select>
            </div>
            <!-- <div *ngIf="svmLst && svmLst.length > 0" class="col-xl-3 col-lg-3 col-md-3">
                <div class="drpLbl" style="float: left;">Select Sachivalayam</div>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Sachivalayam" [(ngModel)]="slctdSvm"
                    (ngModelChange)="selectSachvalayam($event)">
                    <nz-option nzLabel='All' nzValue='0' *ngIf="userDtls.rle_id!=37"></nz-option>
                    <nz-option *ngFor="let c of svmLst" [nzLabel]=c.svm_nm [nzValue]=c.svm_id>
                    </nz-option>
                </nz-select>
            </div> -->
            <div *ngIf="shwRdCks==true" class="col-xl-3 col-lg-3 col-md-3 customWidth"
                style="padding: 8px;margin-top: 10px;padding: 24px;font-size:12px;">
                <label nz-checkbox [(ngModel)]="rdchked" (ngModelChange)="rdChkd($event)">Show Deviation
                    Reported</label>
            </div>
            <div  class="col-xl-4 col-lg-4 col-md-4 customWidth" style="display: flex;justify-content: space-between;">
            <div  *ngIf="shwChckbox==true"
                style="margin-top: 34px;">
                <label nz-checkbox [(ngModel)]="checked" (ngModelChange)="deSelectChk($event)">Plot all Routes by selecting each route</label> 
            </div>
            <div *ngIf="shwChckbox==true"
                style="margin-top: 16px;">
                <button nz-button nzType="primary" class="sbmtbtn" (click)="clearRouteMapLayers()">
                    Clear Map
                </button>
            </div>
        </div>

        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4">

            </div>
            <div class="col-xl-8 col-lg-8 col-md-8">
                <div class="inptCls">
                    <span>Scheduled Route<input class="prmry" disabled="true"></span>
                    <span>Travelled Route<input class="scndry" disabled="true"></span>
                    <span *ngFor="let v of SlctdWrdPolyData">{{v.asrt_nm}} Trip:{{v.trp_sqnc_nu}}<input class="inpt_cls"
                            [ngStyle]=" {'background-color': v.clr_obj }" disabled="true"></span>
                    <!-- <span *ngFor="let v of SlctdSvmPolyData">{{v.asrt_nm}}<input class="inpt_cls"
                                [ngStyle]=" {'background-color': v.clr_obj }" disabled="true"></span> -->
                </div>
                <!-- <button *ngIf="showsetRte==true" nz-button nzType="primary"  class="sbmtbtn" (click)="addRoute();">
                    Set as master Route
                </button> -->
            </div>
        </div>

        <div class="row" style="width: 100%;height: 600px;overflow: hidden;margin: 0px;">
            <div class="col-lg-4 col-md-4 htscrl colforecstmwdth" *ngIf="!shwNodata" style="padding: 0px;">
                <div *ngFor="let c of vehTrpLst" [ngClass]="c.rn_sts==1 ? 'rungRtDvtdCls':''">
                    <div class="serchCard" [ngClass]="  c.isSelected == true ?  'card_Selected' : 'card_not_selected'">
                        <div class="row" (click)="getTripMap(c,slctdDt)">
                            <div class="col-lg-12 col-md-12">
                                <div class="row serchCardPdng" style="padding-top:5px ;">

                                    <div class="col-lg-9 col-md-9 col">
                                        <p class="serchCardLeftTxt srchCrdHdr">{{c.asrt_nm}} </p>

                                        <p class="serchCardLeftTxt srchCrdHdr sbtxt" style="   
                                    font-weight: 500;
                                    line-height: 14px;
                                    ">{{c.asrt_ctgry_nm}}<span *ngIf="ShowClap"> | {{c.vndr_nm}}</span>
                                            <span *ngIf="c.wrd_nm"> | {{c.wrd_nm}}</span>
                                        </p>
                                    </div>

                                    
                                    <div class="col-lg-3 col-md-3 col mg-1" style="margin: auto;">
                                        <div class="Stng_cls"
                                            style="    display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: flex-end;">
                                            <i nz-icon nzType="setting" nzTheme="outline" (click)="openOprtsSldr(c)"
                                                style="margin-right:10px;cursor: pointer;"></i>

                                            <!-- <span nz-icon nzType="car" nzTheme="outline" ></span> -->
                                            <!-- <img src="../../../../../assets/images/prkng.png"
                                                style="cursor: pointer;width: 22px;margin-top: -2px;"
                                                (click)="openStoppage(c,slctdDt)"> -->
                                                <img src="../../../../../assets/images/prkng.png"
                                                style="cursor: pointer;width: 22px;margin-top: -2px;"
                                                (click)="dnldPrkngDta(c)" *ngIf="c.trp_sqnc_nu == 1">
                                        </div>
                                    </div>



                                    <div class="col-lg-5 col-md-5 col">
                                        <p class="serchCardLeftTxt svmNm srchCrdHdr" *ngIf="c.schivalayam != null">
                                            {{c.schivalayam}} </p>
                                            <p class="serchCardLeftTxt svmNm" *ngIf="c.schivalayam == null" >
                                                -- </p>

                                        <p class="serchCardLeftTxt srchCrdHdr sbtxt" style="   
                                    font-weight: 500;
                                    line-height: 14px;
                                    " >Sachivalayam

                                        </p>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col">
                                       
                                        <p class="serchCardLeftTxt srchCrdHdr" >Trip -
                                            {{c.trp_sqnc_nu}}  <span *ngIf="c.rd_in" class="serchCardLeftTxt srchCrdHdr rutdvtntxt" style="
                                   
                                            font-weight: 600;
                                            color: #f55;">[ Route Deviated ]</span></p>
                                        <p class="serchCardLeftTxt srchCrdHdr sbtxt" style="  
                                    font-weight: 500;
                                    line-height: 14px;
                                   ">{{c.trip_sts}}</p>
                                        <p *ngIf="c.trp_stats_in==1" class="serchCardLeftTxt srchCrdHdr sbtxt" style="  
                                     font-weight: 500;
                                     line-height: 22px;
                                     ">{{c.cvrg_prcntg_ct}}% Covered</p>
                                    </div>




                                    <div class="col-lg-1 col-md-1" style="margin: auto;padding: 0px;cursor: pointer;">
                                        <p class="serchCardLeftTxt" style="     float: right;
                                        margin-right: 12px;
                                        color: #858585;padding-top:24px"
                                            >
                                          
                                            <i nz-icon nzType="right" nzTheme="outline"></i>
                                        </p>
                                    </div>





                                    <!-- <div class="col-lg-1 col-md-1 col mg-1">
                                       <img src="../../../../../assets/images/parking.png">
                                    </div> -->
                                </div>
                                <!-- <div class="row" style="padding-top:10px ;">
                                    <div (click)="dnldPrkngDta(c)">
                                        Click Here To See Parking Points
                                    </div>
                                </div> -->
                                <div class="row serchCardPdng" >
                                    <!-- <div class="col-lg-5 col-md-5 col">
                                        <p class="serchCardLeftTxt srchCrdHdr" style="    color: #3F51B5;"><span nz-icon nzType="environment" nzTheme="outline"></span> From Location</p>
                                        <p class="serchCardRightTxt">{{c.strt_fnce_nm}}</p>
                                        <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Schedule Start
                                            Time:{{c.sch_strt_tm}}</p>


                                        <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Start Time:{{c.actl_strt_ts}}</p>
                                    </div>

                                    <div class="col-lg-5 col-md-5 col">
                                        <p class="serchCardLeftTxt srchCrdHdr" style="    color: #3F51B5;"> <span nz-icon nzType="environment" nzTheme="outline"></span> To Location </p>
                                        <p class="serchCardRightTxt">{{c.end_fnce_nm}}</p>
                                        <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Schedule End Time:{{c.sch_end_tm}} </p>
                                       
                                        <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">End Time:{{c.actl_end_ts}}</p>
                                    </div> -->
<div class="col-lg-12 col-md-12">

    <!-- <div class="containers">
        <ul>
            <li class="boxone">
                <span><span nz-icon nzType="environment" nzTheme="outline" class="lctnicon"></span></span>
                <div class="tmngs">{{c.strt_fnce_nm}} </div>
                <div class="tmngs">Schedule Start Time{{c.sch_strt_tm}} </div>
                <div class="tmngs">Actual Start Time{{c.actl_strt_ts}} </div>
                <div class="time">
                    <span>From</span>
                    <span></span>
                </div>
            </li>
            <li class="boxone">
                <span><span nz-icon nzType="environment" nzTheme="outline" class="lctnicon"></span></span>
                <div class="tmngs"> {{c.end_fnce_nm}}</div>
                <div class="tmngs">Schedule End Time{{c.sch_end_tm}} </div>
                <div class="tmngs">Actual End Time{{c.actl_end_ts}} </div>
                <div class="time">
                    <span>To</span>
                    <span></span>
                </div>
            </li>
        </ul>
    </div> -->


    <div class="row">
        <div class="col-md-6 rmvepdng">
            <div class="fromlctn">
                <div class="lctnIcn"><span nz-icon nzType="environment" nzTheme="outline" class="lctnicon"></span></div>
               <div> From Location</div>
            </div>
            <div class="tmngs">{{c.strt_fnce_nm}} </div>
                <div class="tmngs tmesltFnt">Schedule Start Time : <span class="tmeslts">{{c.sch_strt_tm}}</span> </div>
                <div class="tmngs tmesltFnt">Actual Start Time : <span class="tmeslts">{{c.actl_strt_ts}}</span> </div>
        </div>
        <div class="col-md-6 rmvepdng">
            <div class="fromlctn">
                <div class="lctnIcn"><span nz-icon nzType="environment" nzTheme="outline" class="lctnicon"></span></div>
               <div> To Location</div>
            </div>
            <div class="tmngs"> {{c.end_fnce_nm}}</div>
                <div class="tmngs tmesltFnt">Schedule End Time : <span class="tmeslts"> {{c.sch_end_tm}} </span></div>
                <div class="tmngs tmesltFnt">Actual End Time : <span class="tmeslts">{{c.actl_end_ts}}</span> </div>
        </div>
    </div>

</div>


                                    <!-- <div class="col-lg-2 col-md-2" style="margin: auto;padding: 0px;cursor: pointer;">
                                        <p class="serchCardLeftTxt" style=" font-size: 15px;text-align: right; margin: auto;padding-top: 24px;"
                                            >
                                          
                                            <i nz-icon nzType="right" nzTheme="outline"></i>
                                        </p>
                                    </div> -->


                                    <!-- sssssssssssssssssssssssssssssssssss -->

                                </div>

                                <!-- <div class="row serchCardPdng" style="padding-top:10px ;">

                                    <div class="col-lg-2 col-md-2 col">
                                        <p class="serchCardLeftTxt">To</p>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col">
                                        <p class="serchCardRightTxt" style="text-align: center;">:</p>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col">
                                        <p class="serchCardRightTxt">{{c.end_fnce_nm}}</p>
                                        <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">Schedule End Time:{{c.sch_end_tm}}</p>
                                      
                                        <p class="serchCardLeftTxt" style="text-align: left;
                                    font-size: 12px;
                                    line-height: 14px;">End Time:{{c.actl_end_ts}}</p>
                                    </div>

                                </div> -->

                                <div class="row serchCardPdng" style="padding-top:5px ;">

                                    <!-- <div class="col-lg-2 col-md-2 col">
                                        <div class="tmngs" style="font-weight: 700;">Distance</div>
                                    </div>
                                    <div class="col-lg-1 col-md-1 col">
                                        <div class="tmngs" style="text-align: center;">:</div>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col">
                                        <div class="tmngs" style="text-align: left;">Schedule Distance:{{c.km}}&nbsp;KM
                                        </div>
                                    </div> -->

                                    <div class="col-md-6 rmvepdng">
                                        <div class="tmngs" style="text-align: left;">Schedule Distance : {{c.km}}&nbsp;KM
                                        </div>
                                    </div>
                                    <div class="col-md-6 rmvepdng">
                                        <div class="tmngs" style="text-align: left;">Schedule Travelled Time : --
                                        </div>
                                    </div>
                                    <div class="col-md-6 rmvepdng">
                                        <div class="tmngs" style="text-align: left;">Actual Distance : {{c.km}}&nbsp;KM
                                        </div>
                                    </div>
                                    <div class="col-md-6 rmvepdng">
                                        <div class="tmngs" style="text-align: left;">Actual Travelled Time : --
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- <div class="col-lg-1 col-md-1" style="margin: auto;padding: 0px;cursor: pointer;">
                                <p class="serchCardLeftTxt" style=" font-size: 15px;text-align: center; margin: auto;"
                                    (click)="getTripMap(c,slctdDt)">
                                    <i nz-icon nzType="right" nzTheme="outline"></i>
                                </p>
                            </div> -->
                        </div>

                        <div class="" style="display: flex; justify-content: center;">
                            <div>
                            <div class="" *ngIf="c.rd_in==1 && c.drvr_cntctd_in==1" class="infotxt"
                                (click)="deviateSlider(c);">Check Deviation Info</div>
                            
                                <button nz-button nzType="primary" nzSize="small" class="sbmtbtnone" (click)="deviateSlider(c);"
                                    *ngIf="c.rd_in==1 && c.drvr_cntctd_in!=1">
                                    Report Deviation
                                </button>
                           </div>

                            <div  *ngIf="userDtls.rle_id!=37">
                               
                                   
                                        <button nz-button nzType="primary" nzSize="small" class="sbmtbtnone"
                                            (click)="editRoute(c,'nw');"
                                            *ngIf="c.shwEdtBtn && c.rte_arc_lne_tx==undefined"
                                            style="margin-left: 10px; margin-right: 10px;">
                                            Add
                                        </button>
                                        <button nz-button nzType="primary" nzSize="small" class="sbmtbtnone"
                                            (click)="editRoute(c,'edt');"
                                            *ngIf="c.shwEdtBtn && c.rte_arc_lne_tx!=undefined"
                                            style="margin-left: 10px; margin-right: 10px;">
                                            Edit
                                        </button>
                                   
                                   
                                   

                                        <button nz-button nzType="primary" nzSize="small" class="sbmtbtnone"
                                            (click)="setMasterRoute();" style="background: #298f9d;
                            border-color: #256e40;" *ngIf="c.shwAddBtn">
                                            Set as master Route
                                        </button>
                                    
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 htscrl colforecstmwdth" style="padding: 49px;
            font-weight: 600;" *ngIf="shwNodata">
                No Trips Found for Selected Option(s)
            </div>
            <div class="col-lg-8 col-md-8 htscrl coleghtcstmwdth">
                <div id="routeDevtmap"></div>
            </div>
        </div>
        <nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" nzWidth="1000" [nzTitle]="">
            <ng-container *nzModalContent>
                <div style="margin-bottom: 10px;
                padding-bottom: 10px;">

                    <div class="row">
                        <div class="col-lg-8 col-md-8">
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <nz-date-picker nzShowTime (ngModelChange)="onselectPbStrtTime($event)"
                                        [(ngModel)]="Pbstrt_tm"></nz-date-picker>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <nz-date-picker nzShowTime (ngModelChange)="onselectPbEndTime($event)"
                                        [(ngModel)]="Pbend_tm"></nz-date-picker>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <button nz-button nzType="primary" class="sbmtbtn2" (click)="getPlayBack()">
                                        Get Playback
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 vwBtns" *ngFor="let l of tileLayers"
                                    (click)="chklyrSlct(l)">
                                    <div [ngClass]="l.lyrSlct == true ? 'layrSlctd' : 'layrnotSlctd'"> {{l.lyr_nm}} View
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-1 col-md-1">
                            <button nz-button nzType="primary" class="sbmtbtn2" (click)="clearEditablePolyline()">
                                Clear
                            </button>
                        </div>
                        <div class="col-lg-1 col-md-1">
                            <button nz-button nzType="primary" class="sbmtbtn2" (click)="downloadPdf()">
                                <span nz-icon nzType="download" nzTheme="outline"></span>
                            </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-4" *ngIf="selectedtenant==1000033">
                            <label nz-checkbox [(ngModel)]="svmChkd" (ngModelChange)="onSvmChk($event)">Show
                                Sachivalayam</label>
                        </div>
                    </div>
                </div>
                <!-- <div id="map_pdf_section"> -->
                    <div class="row" style="margin-bottom: 10px;
                    padding-bottom: 10px;">
                        {{rteSaveData.asrt_nm}} - Trip:{{rteSaveData.trp_sqnc_nu}}
                    </div>
                    <div
                        [ngClass]="{'map-no-sidebar col-md-8': map_stng.show_build_sidebar == true, 'map-with-sidebar col-md-12': map_stng.show_build_sidebar != true }">
                        <div leaflet style="height: 1200px; width:140%; margin: 0; padding: 0;" leafletDraw
                            (leafletMapReady)="onMapReady($event)" [leafletOptions]="leafletOptions"
                            [leafletLayers]="layers" [leafletDrawOptions]="drawOptions" [leafletDrawLocal]="drawLocal"
                            (leafletDrawCreated)="onDrawCreated($event)" (leafletDrawStart)="onDrawStart($event)"
                            (leafletDrawEditStart)="onDrawStart($event)" (leafletDrawEditStop)="onDrawEditStop($event)"
                            (leafletDrawDeleteStart)="onDrawStart($event)"
                            (leafletDrawDeleteStop)="onDrawStart($event)">

                            <!-- Add the drawnItems featureGroup to the map -->
                            <!-- <div [leafletLayer]="drawnItems"></div> -->
                            <!-- <div *ngFor="let m of markers" [leafletLayer]="m"></div> -->
                        </div>

                    </div>
                <!-- </div> -->
            </ng-container>
        </nz-modal>
    </div>
</div>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="400" [nzVisible]="openSlider"
    class="sldrCls" nzTitle="Route Deviation Feedback" (nzOnClose)="closeSideBar()">
    <ng-container *nzDrawerContent>
    <div class="row">
        <table class="drvrDtls">
            <tr>
                <td>Vehicle Number</td>
                <td>:</td>
                <td>{{slctdDtls.asrt_nm}}</td>
            </tr>
            <tr *ngIf="slctdDtls.svm_nm">
                <td>Sachivalayam</td>
                <td>:</td>
                <td>{{slctdDtls.svm_nm}}</td>
            </tr>
            <tr *ngIf="slctdDtls.crw_nm">
                <td>Driver Name</td>
                <td>:</td>
                <td>{{slctdDtls.crw_nm}}</td>
            </tr>
            <tr *ngIf="slctdDtls.crw_ph">
                <td>Driver Contact</td>
                <td>:</td>
                <td>{{slctdDtls.crw_ph}}</td>
            </tr>
            <tr>
                <td>Driver Contacted</td>
                <td>:</td>
                <td *ngIf="slctdDtls.drvr_cntctd_in==undefined">Yet To Be Contacted</td>
                <td *ngIf="slctdDtls.drvr_cntctd_in==1">Yes</td>
                <td *ngIf="slctdDtls.drvr_cntctd_in==0">No</td>
            </tr>
            <tr *ngIf="slctdDtls.drvr_cntctd_in">
                <td>Deviation Accepted</td>
                <td>:</td>
                <td *ngIf="slctdDtls.rte_dvtn_acpt_in==undefined">Not Known</td>
                <td *ngIf="slctdDtls.rte_dvtn_acpt_in==1">Yes</td>
                <td *ngIf="slctdDtls.rte_dvtn_acpt_in==0">No</td>
            </tr>
            <tr *ngIf="slctdDtls.dvtn_rsn_tx">
                <td>Reason For Deviation</td>
                <td>:</td>
                <td>{{slctdDtls.dvtn_rsn_tx}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="slctdDtls.drvr_cntctd_in!=1">
        <div class="row fulwdth">
            <div class="col-md-12">
                <div class="dvtnFormTitle">
                    Feedback on Route Deviation
                </div>
            </div>
        </div>
        <div class="row">
            <form nz-form [formGroup]="dvtnForm" class="dvtnDtlsfrm">
                <div nz-row [nzGutter]="8">
                    <div nz-col nzSpan="24">
                        <nz-form-label>Driver Contacted</nz-form-label>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-radio-group formControlName="drvr_cntctd_in">
                                    <label nz-radio nzValue="1">Yes</label>
                                    <label nz-radio nzValue="0">No</label>
                                </nz-radio-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="24">
                        <nz-form-label>Route Deviation Accepted</nz-form-label>
                        <nz-form-item>
                            <nz-form-control>
                                <nz-radio-group formControlName="rte_dvtn_acpt_in">
                                    <label nz-radio nzValue="1">Yes</label>
                                    <label nz-radio nzValue="0">No</label>
                                </nz-radio-group>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="24">
                        <nz-form-label>Reason</nz-form-label>
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="dvtn_rsn_tx" placeholder="Reason" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
            <div class="btngrp">
                <button nz-button style="margin-right: 8px;" (click)="closeSideBar()" nzType="primary"
                    nzDanger>Cancel</button>
                <button nz-button (click)="addDvtnRsn()" class="adBtn"
                    *ngIf="dvtnForm.status == 'VALID'">Submit</button>
            </div>
        </div>
    </div>
    <div *ngIf="slctdDtls.drvr_cntctd_in==1">
        Details Already Updated
    </div>
    <!-- <div *ngIf="slctdDtls.drvr_cntctd_in!=1 && slctdDtls.trp_cls_in!=0">
            Details Not Updated
            </div> -->
            </ng-container>
</nz-drawer>
<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="400" [nzVisible]="openOprtnSlider"
    class="sldrCls" nzTitle="Trip Details" (nzOnClose)="closeOprtnSideBar()">
    <ng-container *nzDrawerContent>
    <div class="row sprtn_brdr" *ngIf="oprtnsData">
        <table class="drvrDtls">
            <tr>
                <td>Vehicle Number</td>
                <td>:</td>
                <td>{{oprtnsData.asrt_nm}}</td>
            </tr>
            <tr>
                <td>Vehicle Category</td>
                <td>:</td>
                <td>{{oprtnsData.asrt_ctgry_nm}}</td>
            </tr>

            <tr>
                <td>Trip No:</td>
                <td>:</td>
                <td>{{oprtnsData.trp_sqnc_nu}}</td>
            </tr>
            <tr>
                <td>Trip Status:</td>
                <td>:</td>
                <td [ngClass]="oprtnsData.rd_in==1 ? 'clr_red':' '">{{oprtnsData.trip_sts}}</td>
            </tr>
            <tr *ngIf="oprtnsData.svm_nm">
                <td>Sachivalayam</td>
                <td>:</td>
                <td>{{oprtnsData.svm_nm}}</td>
            </tr>
            <tr *ngIf="oprtnsData.crw_nm">
                <td>Driver Name</td>
                <td>:</td>
                <td>{{oprtnsData.crw_nm}}</td>
            </tr>
            <tr *ngIf="oprtnsData.crw_ph">
                <td>Driver Contact</td>
                <td>:</td>
                <td>{{oprtnsData.crw_ph}}</td>
            </tr>
            <tr>
                <td>From</td>
                <td>:</td>
                <td>
                    <div>{{oprtnsData.strt_fnce_nm}}</div>
                    <div>Schedule Start Time:{{oprtnsData.sch_strt_tm}}</div>
                    <div>Start Time:{{oprtnsData.actl_strt_ts}}</div>
                </td>
            </tr>
            <tr>
                <td>To</td>
                <td>:</td>
                <td>
                    <div>{{oprtnsData.end_fnce_nm}}</div>
                    <div>Schedule End Time:{{oprtnsData.sch_end_tm}}</div>
                    <div>End Time:{{oprtnsData.actl_end_ts}}</div>
                </td>
            </tr>
            <!-- <tr *ngIf="oprtnsData.crw_ph">
                <td>Driver Contact</td>
                <td>:</td>
                <td>{{oprtnsData.crw_ph}}</td>
            </tr>
            <tr *ngIf="oprtnsData.crw_ph">
                <td>Driver Contact</td>
                <td>:</td>
                <td>{{oprtnsData.crw_ph}}</td>
            </tr>
            <tr *ngIf="oprtnsData.crw_ph">
                <td>Driver Contact</td>
                <td>:</td>
                <td>{{oprtnsData.crw_ph}}</td>
            </tr> -->
        </table>
        <!-- <div *ngFor="let c of oprtnsData">
            <div class="sectdCard">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="row serchCardPdng" style="padding-top:5px ;">

                            <div class="col-lg-7 col-md-7 col">
                                <p class="serchCardLeftTxt srchCrdHdr">{{c.asrt_nm}} </p>
                                <p class="serchCardLeftTxt srchCrdHdr" style="    font-size: 11px;
                            font-weight: 500;
                            line-height: 14px;
                            color: #3f51b5;">{{c.asrt_ctgry_nm}}<span *ngIf="ShowClap"> | {{c.vndr_nm}}</span>
                                 <span *ngIf="c.wrd_nm" >  | {{c.wrd_nm}}</span></p>
                            </div>
                            <div class="col-lg-5 col-md-5 col mg-a">
                                <p *ngIf="c.rd_in" class="serchCardLeftTxt srchCrdHdr" style="text-align: right;
                            font-size: 13px;
                            font-weight: 600;
                            line-height: 10px;
                            color: #f55;">Route Deviated</p>
                                <p class="serchCardLeftTxt srchCrdHdr" style="text-align: right;">Trip -
                                    {{c.trp_sqnc_nu}}</p>
                                <p class="serchCardLeftTxt srchCrdHdr" style="text-align: right;    font-size: 12px;
                            font-weight: 500;
                            line-height: 14px;
                            color: #3f51b5;">{{c.trip_sts}}</p>
                            </div>
                           
                        </div>

                        <div class="row serchCardPdng" style="padding-top:10px ;">

                            <div class="col-lg-2 col-md-2 col">
                                <p class="serchCardLeftTxt">From</p>
                            </div>
                            <div class="col-lg-1 col-md-1 col">
                                <p class="serchCardRightTxt" style="text-align: center;">:</p>
                            </div>
                            <div class="col-lg-9 col-md-9 col">
                                <p class="serchCardRightTxt">{{c.strt_fnce_nm}}</p>
                                <p class="serchCardLeftTxt" style="text-align: left;
                            font-size: 12px;
                            line-height: 14px;" *ngIf="!c.shwStrtTmEdt">Schedule Start
                                    Time:{{c.sch_strt_tm}}<span class="tm_edt" (click)="onStrtTmEdt(c);"><i
                                            nz-icon nzType="edit" nzTheme="outline"></i></span></p>


                                <p class="serchCardLeftTxt" style="text-align: left;
                             font-size: 12px;
                             line-height: 14px;" *ngIf="c.shwStrtTmEdt">Schedule Start Time:
                                    <nz-time-picker nzShowTime (ngModelChange)="onselectStrtTime($event,c)"
                                        [(ngModel)]="slctdstrt_tm" [nzUse12Hours]="true"
                                        [nzFormat]="timeFormat">
                                    </nz-time-picker><span class="tm_edt" (click)="closeSlct(c);"><i nz-icon
                                            nzType="close" nzTheme="outline"></i></span>
                                </p>
                                <p class="serchCardRightTxt" style="text-align: left;
                            font-size: 12px;
                            line-height: 14px;">Start Time:{{c.actl_strt_ts}}</p>
                            </div>

                        </div>

                        <div class="row serchCardPdng" style="padding-top:10px ;">

                            <div class="col-lg-2 col-md-2 col">
                                <p class="serchCardLeftTxt">To</p>
                            </div>
                            <div class="col-lg-1 col-md-1 col">
                                <p class="serchCardRightTxt" style="text-align: center;">:</p>
                            </div>
                            <div class="col-lg-9 col-md-9 col">
                                <p class="serchCardRightTxt">{{c.end_fnce_nm}}</p>
                                <p class="serchCardLeftTxt" style="text-align: left;
                            font-size: 12px;
                            line-height: 14px;" *ngIf="!c.shwEndTmEdt">Schedule End Time:{{c.sch_end_tm}}<span
                                        class="tm_edt" (click)="onEndTmEdt(c);"><i nz-icon nzType="edit"
                                            nzTheme="outline"></i></span></p>
                                <p class="serchCardLeftTxt" style="text-align: left;
                             font-size: 12px;
                             line-height: 14px;" *ngIf="c.shwEndTmEdt">
                                    Schedule End Time:<nz-time-picker nzShowTime
                                        (ngModelChange)="onselectEndTime($event,c)" [(ngModel)]="slctdend_tm"
                                        [nzUse12Hours]="true" [nzFormat]="timeFormat">
                                    </nz-time-picker><span class="tm_edt" (click)="closeSlct(c);"><i nz-icon
                                            nzType="close" nzTheme="outline"></i></span>
                                </p>
                                <p class="serchCardLeftTxt" style="text-align: left;
                            font-size: 12px;
                            line-height: 14px;">End Time:{{c.actl_end_ts}}</p>
                            </div>

                        </div>
                    </div>
                    
                </div>

               
            </div>
        </div> -->
    </div>
    <div class="sprtn_brdr">
        <div class="row fulwdth">
            <div class="col-md-12">
                <div class="dvtnFormTitle">
                    Trip Operations
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <button nz-button nzType="primary" style="background: #ff9017;
                border-color: #FF9800;" class="sbmtbtn" (click)="clearTrip()">
                    Clear Trip
                </button>
            </div>
            <div class="col-lg-4 col-md-4">
                <button nz-button nzType="primary" class="sbmtbtn" (click)="StartTrip()">
                    Start Trip
                </button>

            </div>
            <div class="col-lg-4 col-md-4">
                <button nz-button nzType="primary" style="background: #ff4d4f;
                border-color: #ff4d4f;" class="sbmtbtn" (click)="cancelTrip()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
    <div class="sprtn_brdr">
        <div class="row fulwdth">
            <div class="col-md-12">
                <div class="dvtnFormTitle">
                    Change Vehicle
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="drpLbl">Select Vehicle</div>
                <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedVehicle"
                    (ngModelChange)="onvehcleSelect($event)" nzPlaceHolder="Select Vehicle to change Trip"
                    [ngModelOptions]="{standalone: true}" style="width:100%;">
                    <nz-option *ngFor="let v of vhcleslst" [nzLabel]=v.asrt_nm [nzValue]="v.asrt_id"></nz-option>
                </nz-select>
            </div>
            <div class="col-lg-2 col-md-2"></div>
            <div class="col-lg-4 col-md-4">
                <div class="drpLbl"></div>
                <div class="drpLbl"></div>

                <button nz-button nzType="primary" class="sbmtbtn" (click)="submitVehicle()">
                    Submit
                </button>
            </div>
        </div>
    </div>
    <div>
        <div class="row fulwdth">
            <div class="col-md-12">
                <div class="dvtnFormTitle">
                    Change Timings
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="drpLbl">Schedule Start time</div>
                <nz-time-picker nzShowTime (ngModelChange)="onselectStrtTime($event,oprtnsData)"
                    [(ngModel)]="slctdstrt_tm" [nzUse12Hours]="true" [nzFormat]="timeFormat">
                </nz-time-picker>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="drpLbl">Schedule End time</div>
                <nz-time-picker nzShowTime (ngModelChange)="onselectEndTime($event,oprtnsData)"
                    [(ngModel)]="slctdend_tm" [nzUse12Hours]="true" [nzFormat]="timeFormat">
                </nz-time-picker>
            </div>

        </div>

    </div>
    </ng-container>
</nz-drawer>
<nz-modal [(nzVisible)]="stpgDtls" nzTitle="Trip Stoppage Details" (nzOnCancel)="handlestoppage()" (nzOnOk)="handleOk()"
    nzWidth="800px" nzClassName="prkngdtls">
    <ng-container *nzModalContent>
        <dx-data-grid id="grid" class="gridHeight" [dataSource]="stpgPnts" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="false" [showColumnLines]="true"
            [showRowLines]="true" [showBorders]="true" style="width:100%;    max-width: 100%!important;"
            [allowColumnResizing]="true" [columnWidth]="auto" [wordWrapEnabled]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Parking points List">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <!-- [width]="f.field == 'sno' ?80:200" -->
            <dxi-column *ngFor="let f of columnDefs" [visible]="f.visible" [dataField]="f.field"
                [sortOrder]="f.sortOrder" [caption]="f.headerName" [width]="f.width" [alignment]="f.alignment"
                [format]="this.currencyINRFormater" [allowResizing]="true" [allowFiltering]="f.filter"
                [allowHeaderFiltering]="f.filter" [showInColumnChooser]="f.Chooser">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons_edit" [width]="100" caption="Location"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="cursor-pointer tblEdtBtn" (click)="vewonMap(data.data)">
                        Map View
                    </div>
                </div>
            </dxi-column>
        </dx-data-grid>
    </ng-container>
</nz-modal>
