<ds-loading *ngIf="showLdr"></ds-loading>
<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-12 col-md-12 mg-a">
                <h1 *ngIf="clntDtls && clntDtls.length > 0">
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
        </div>
    </div>
</div>
<div class="page-body">
    <div class="fltr">
        <form nz-form [formGroup]="validateForm" class="form">
            <div class="row" style="padding :10px">
                <div class="col-md-3">
                    <nz-form-item style="display: block;">
                        <div class="fltrlabel">Select District</div>
                        <div>
                            <nz-form-control>
                                <nz-select nzPlaceHolder="Select Type" formControlName="dstrt_id"
                                    (ngModelChange)="getdistrictid($event)">
                                    <nz-option nzValue="0" nzLabel="All">
                                    </nz-option>
                                    <nz-option *ngFor="let v of districts" [nzValue]="v.tnt_id" [nzLabel]="v.tnt_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </div>
                    </nz-form-item>
                </div>
                <div class="col-md-3">
                    <nz-form-item style="display: block;">
                        <div class="fltrlabel">Select Mandal</div>
                        <div>
                            <nz-form-control>
                                <nz-select nzPlaceHolder="Select Type" formControlName="mndl_id"
                                    (ngModelChange)="getsbstn($event)">
                                    <nz-option [nzValue]="0" nzLabel="All">
                                    </nz-option>
                                    <nz-option *ngFor="let d of mandals" [nzValue]="d.mndl_id" [nzLabel]="d.mndl_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </div>
                    </nz-form-item>
                </div>
                <div class="col-md-3">
                    <nz-form-item style="display: block;">
                        <div class="fltrlabel">Select Substation</div>
                        <div>
                            <nz-form-control>
                                <nz-select nzPlaceHolder="Select Type" formControlName="oms_pop_id">
                                    <nz-option [nzValue]="0" nzLabel="All">
                                    </nz-option>
                                    <nz-option *ngFor="let d of sbstns" [nzValue]="d.oms_pop_id" [nzLabel]="d.pop_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </div>
                    </nz-form-item>
                </div>
                <div class="col-md-3">
                    <div class="row rptBtnRw">
                        <button type="primary" class="btn-sm" (click)="getDtls()">Get
                            Data</button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="fltrbg " >

        <!-- <div class="" ><span>Substation Details</span></div> -->


        <div>
            <!-- <div> -->


            <div *ngIf="sbstndtls">

                <div class="crd_style" style="  background-color: #fffaf0; /* White background */
                
                padding: 15px;
                border-radius: 5px;    height: 47%;">
                    <h5 style="    color: #e54848;
    font-size: 18px;
                  "> Substation Location and APSFL Details</h5>

                    <div class="row m-0">
                        <div class="col-4 hdr">Address</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.adrs_tx || 'NA'}}</span> 
                            <!-- /{{sbstndtls.pin_cd || 'NA'}}-->
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Gram Panchayat</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue">{{sbstndtls.gp_nm || 'NA' }}</span>
                    </div>

                    <div class="row m-0">
                        <div class="col-4 hdr">Substation Type</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pop_typ || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Substation Code</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue">{{sbstndtls.pop_cd || 'NA' }}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Location Type</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pin_cd || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Substation I & C Year</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <!-- <div class="row m-0">
                        <div class="col-4 hdr">PIN Code</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pin_cd || 'NA'}}</span>
                    </div> -->
                    <!-- <div class="row m-0">
                        <div class="col-4 hdr">Latitude</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Longitude</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lng || 'NA'}}</span>
                    </div> -->
                </div>

                <div class="col-md-12 crd_style " style=" background-color: #f0faff; /* White background */
                
                padding: 15px;
                border-radius: 5px;    height: 51%;">
                    <h5 style="    color: #e54848;
    font-size: 18px;"> Substation Details</h5>
                    <div class="row m-0">
                        <div class="col-4 hdr">Connectivity Type</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pop_typ || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Equipment Installed in </div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue">{{sbstndtls.pin_cd || 'NA' }}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Subscribe Type</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pin_cd || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Subscribers</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">EB Connected Load in KW</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">EB Service Number</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Present Site Load in Amps</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">No of Earth Pits</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">BBNL Router Availability</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                </div>
                <!-- <div class="crd_style" style="      background-color: #f0fff3; /* White background */
                
                padding: 15px;
                border-radius: 5px;    height:47%;">



                    <h5 style="    color: #e54848;
    font-size: 18px;
                   "> Substation APSFL INFO</h5>

                    <div class="row m-0">
                        <div class="col-4 hdr">Substation Type</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pop_typ || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Substation Code</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue">{{sbstndtls.pop_cd || 'NA' }}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Location Type</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.pin_cd || 'NA'}}</span>
                    </div>
                    <div class="row m-0">
                        <div class="col-4 hdr">Substation I & C Year</div>
                        <span class="col-1">:</span>
                        <span class="col-auto vlue"> {{sbstndtls.lat || 'NA'}}</span>
                    </div>
                    <div>



                    </div>




                </div> -->
            </div>

            <div>
                <div id="livemap" style="    margin-top: 2%;
                position: relative;
                height: 101%;border: 8px solid white;
                border-radius: 10px;"></div>
          

            </div>

        </div>

    </div>

    <div class="col-md-12 mt-20" style="padding:0px;padding: 0px;
    margin-top: 30px;">
        <div class="row">
            <div class="col-md-4">
                <div class="crd_style" (click)="selctedcard('WORKS', '#544795')"
                    style="margin-top:5px;    background-image: none;">
                    <div class="row" style="align-items: baseline;">
                        <div class="col-md-5 cstmcol">
                            <div class="actvts">Activities <span
                                    style="color: #ffdaa1;">({{WorksCrdData?.ttl_wrks}})</span>
                            </div>
                        </div>
                        <div class="col-md-7" style="text-align: right;">
                            <!-- <div class="col-md-6" style="text-align: center;"> -->
                            <div class="tabs-data" *ngFor="let t of tabsActdata" (click)="slectdActTab(t)"
                                [ngClass]="t.tab_id == slctdActdt ? 'slct_dt' : 'dflt_dt' ">
                                {{t.tab_nm}}
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="row rwpdng">
                        <div class="col-md-3">
                            <div class="cnt">{{WorksCrdData?.in_prgrs}}</div>
                            <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;"> In
                                    Progress</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="cnt">{{WorksCrdData?.cmpltd_wrks}}</div>
                            <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;">
                                    Completed</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="cnt">{{ INRFormater(WorksCrdData?.cstmr_efctd_ct || 0) }} </div>
                            <div class="crd_title">
                                <span class="cstmfnt" style="    color: #3F51B5;"> CAF's Effected(In
                                    Progress)
                                </span>
                            </div>
                        </div>
                    </div>

                    <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                        [stroke]="chartOptions?.stroke" [fill]="chartOptions?.fill" [yaxis]="chartOptions?.yaxis"
                        [xaxis]="chartOptions?.xaxis" [colors]="chartOptions?.colors">
                    </apx-chart>
                    <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
            left: 50%;
            font-size: 30px;    color: #544795;" *ngIf="slectdCrd == 'WORKS'"></span>
                </div>
            </div>
            <div class="col-md-8">
                <div class="crd_style" (click)="selctedcard('ASSETS', '#3e8fb3')"
                    style="height: 90%; margin-bottom: 0px; margin-top:5px;    background-image: none;">
                    <div class="row">
                        <div class="col-md-12 cstmcol">
                            <div class="actvts" style="      background: #3e8fb3;">Assets <span
                                    style="color: #ffdaa1;">({{astcnts && astcnts.length ? astcnts.length :
                                    0}})</span>
                            </div>
                        </div>
                        <div class="row rwpdng w-100" style="padding-top: 20px;">
                            <div *ngFor="let a of tpAstType" class="col-2">
                                <div (click)="getAstTypWs($event)">
                                    <div class="row">
                                        <!-- <div class="col-md-3">
                                            <span> <i nz-icon nzType="eye" nzTheme="outline" style="font-size: 30px;"></i></span>

                                        </div> -->

                                        <div class="col-md-12">
                                            <!-- <span> <i nz-icon [nzType]="a?.icn_tx" nzTheme="outline" style="font-size: 40px;    color: #eb9e9e"></i></span> -->
                                            <img [src]="a?.icn_tx" height="50" width="50">
                                            <!-- <img src="assets/images/apsfl/ACs.jpg" height="100" width="100"> -->
                                            <!-- <img src="assets/images/apsfl/truck.gif" alt=""> -->

                                            <div class="cnt">{{a?.count}} </div>
                                            <div class="crd_title">
                                                <span class="cstmfnt" style="color: #3F51B5;;">{{a?.ast_typ_nm}}</span>
                                                <!-- <span> <i nz-icon nzType="eye" nzTheme="outline"></i></span>  -->
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-1">
                                        
                                        </div> -->

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span nz-icon nzType="caret-down" nzTheme="outline"
                    style="position: absolute; left: 50%;  font-size: 30px; color: #3e8fb3;"
                    *ngIf="slectdCrd == 'ASSETS'"></span>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="   cstmTb px-4 pt-2 crd_style"
                style="margin-top: 0px;     margin-top: 5px;    background-image: none;">
                <div class="card_ttl">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="card_hdr_ttl slctd-crd" *ngIf="slectdCrd == 'WORKS'"
                                style="position: relative;">
                                Activities</div>
                            <div class="card_hdr_ttl slctd-crd" *ngIf="slectdCrd == 'ASSETS'"
                                style="position: relative;">
                                Assets
                            </div>
                        </div>
                        <div class="col-md-10" *ngIf="slectdCrd == 'WORKS'">
                            <div class="row pstnCls ">
                                <div class="col-md-12" style="text-align:left; padding-left: 20px;">
                                    <nz-range-picker *ngIf="lvl_id!=3" [(ngModel)]="dateRange" [nzFormat]="'dd-MM-yyyy'"
                                        (ngModelChange)="onChangeDate()"></nz-range-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"> </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
                    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [allowColumnResizing]="true" (onCellClick)="onCellClick($event)"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                        (onCellPrepared)="onCellPrepared($event)" (onContentReady)="onContentReady($event)"
                        (onExporting)="onExporting($event)">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager visible="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 25, 50]"
                            [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <dxo-group-panel [visible]="false"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                        <dxo-column-chooser [enabled]="false" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName="Substation"></dxo-export>
                        <!-- <dxo-export [enabled]="true"
                    [allowExportSelectedData]="true" fileName="Substation"></dxo-export>    -->
                        <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [visible]="d.visible"
                            [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                            [groupIndex]="d.groupIndex" [format]="d.format">
                        </dxi-column>

                        <dxi-column [visible]="tb_id==1" [showInColumnChooser]="false" type="buttons" [width]="100"
                            caption="View" cellTemplate="editTemplate">
                            <div *dxTemplate="let data of 'editTemplate'">
                                <div *ngIf="data.rowType=='data'">
                                    <div *ngIf="tb_id==1" class="tblEdtBtn" (click)="gotoProfile(data,'work')">
                                        <i nz-icon nzType="eye" nzTheme="outline"></i>
                                        <p>View Profile</p>
                                    </div>
                                </div>
                            </div>
                        </dxi-column>

                        <dxo-summary>
                            <dxi-total-item column="cstmr_efctd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="no_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="asgn_emps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="wrk_incld_expns" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="amt" summaryType="sum" [displayFormat]="sumcurrencyINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="ttl" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="inprg" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="cmpltd" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="ttl_wrks" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="emp_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <!-- <dxi-group-item column="cstmr_efctd_ct" summaryType="sum" alignByColumn="true">
                        </dxi-group-item>
                        <dxi-group-item column="asgn_emps" summaryType="sum" alignByColumn="true">
                        </dxi-group-item> -->
                            <dxi-group-item column="cstmr_efctd_ct" summaryType="sum" alignByColumn="false"
                                [displayFormat]="SumINRFormater">
                            </dxi-group-item>
                            <dxi-group-item column="asgn_emps" summaryType="sum" alignByColumn="false"
                                [displayFormat]="SumINRFormater">
                            </dxi-group-item>
                            <!-- <dxi-group-item column="emp_ct" summaryType="sum" alignByColumn="false"  [displayFormat]="SumINRFormater">
                        </dxi-group-item> -->
                            <!-- 
                        <dxi-group-item *ngIf="tb_id=='2'" column="ttl_wrks" summaryType="sum" alignByColumn="false"
                            [displayFormat]="SumINRFormater">
                        </dxi-group-item> -->
                        </dxo-summary>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="dwrWdth" [nzVisible]="sidenav"
    [nzTitle]="drwerTitle" (nzOnClose)="closeSideBar()" nzWrapClassName="wrkPrfleDrawer">
    <ng-container *nzDrawerContent>
        <div *ngIf="appCond==1">
            <app-wrok-profile [wrkData]="wrkData"></app-wrok-profile>
        </div>


        <div class="apsfl_dshbrd" *ngIf="appCond==2">
            <app-emplye-prfle [wrkData]="wrkData"></app-emplye-prfle>
        </div>

        <div class="apsfl_dshbrd" *ngIf="appCond==3">
            <app-vehicle-slider [vechile]="selectedVehicle"></app-vehicle-slider>
        </div>

        <div class="apsfl_dshbrd" *ngIf="appCond==4">
            <app-trip-timeline [tripId]="slctd_trp"></app-trip-timeline>
        </div>


    </ng-container>
</nz-drawer>