import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
// import {
//     MatSnackBar,
//     MatSnackBarHorizontalPosition,
//     MatSnackBarVerticalPosition,
// } from '@angular/material/snack-bar';
// import SecureLS from 'secure-ls';
import { environment } from 'src/environments/environment';
// var ls = new SecureLS({ encodingType: 'base64' });

@Injectable({
    providedIn: 'root'
})
export class HolidayService {
    public _header: BehaviorSubject<any> = new BehaviorSubject<any>({ parentTitle: null, title: null });
    private _baseURL: string = environment.origin + '/api/';
    // horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    // verticalPosition: MatSnackBarVerticalPosition = 'top';
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        this._header.next({ title: null, parentTitle: null });
    }
    get(rte): Observable<any> {
        return this._httpClient.get(this._baseURL + rte);
    }
    post(rte, data): Observable<any> {
        return this._httpClient.post(this._baseURL + rte, { data: data });
    }
    put(rte, data): Observable<any> {
        return this._httpClient.put(this._baseURL + rte, { data: data });
    }
    delete(rte): Observable<any> {
        return this._httpClient.delete(this._baseURL + rte);
    }
    getHolidays(yr) {
        return this._httpClient.get(this._baseURL + `payroll/holidays/${yr}`);
    }
    addHoliday(data) {return this._httpClient.post(this._baseURL + 'payroll/add_holiday', { data: data }); }    
    updtHoliday(data) {return this._httpClient.put(this._baseURL + 'payroll/updt_holiday', { data: data }); }
    delHoliday(data) {return this._httpClient.put(this._baseURL + 'payroll/del_holiday', { data: data }); }
    getWeekDys() {return this._httpClient.get(this._baseURL + `payroll/getWeeks`);}
    updtWeek(data) {return this._httpClient.put(this._baseURL + 'payroll/updt_week', { data: data }); }
}