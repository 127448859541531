<!-- Page Content -->
<div class="content container-fluid">

    <!-- Page Header -->
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Payroll Items</h3>
                <ul class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/layout/dashboard/admin">Dashboard</a></li>
                    <li class="breadcrumb-item active">Payroll Items</li>
                </ul>
            </div>
        </div>
    </div>
    <!-- /Page Header -->

    <!-- Page Tab -->
    <div class="page-menu">
        <div class="row">
            <div class="col-sm-12">
                <ul class="nav nav-tabs nav-tabs-bottom">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#tab_additions">Additions</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#tab_overtime">Overtime</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#tab_deductions">Deductions</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- /Page Tab -->

    <!-- Tab Content -->
    <div class="tab-content">

        <!-- Additions Tab -->
        <div class="tab-pane show active" id="tab_additions">

            <!-- Add Addition Button -->
            <div class="text-end mb-4 clearfix">
                <button class="btn btn-primary add-btn" type="button" data-bs-toggle="modal" data-bs-target="#add_addition"><i
                        class="fa fa-plus"></i> Add Addition</button>
            </div>
            <!-- /Add Addition Button -->

            <!-- Payroll Additions Table -->
            <div class="payroll-table card">
                <div class="table-responsive">
                    <table class="table table-hover table-radius">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Default/Unit Amount</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let addPayroll of allAddPayroll;let i = index">
                                <th>{{addPayroll.name}}</th>
                                <td>{{addPayroll.category}}</td>
                                <td>{{addPayroll.unitCost}}</td>
                                <td class="text-end">
                                    <div class="dropdown dropdown-action">
                                        <a  class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item"  data-bs-toggle="modal"
                                                data-bs-target="#edit_addition" (click)="editAdd(addPayroll.id)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                            <a class="dropdown-item"  data-bs-toggle="modal"
                                                data-bs-target="#delete_addition" (click)="tempAddId = addPayroll.id"><i class="fa fa-trash-o m-r-5"></i>
                                                Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="allAddPayroll.length === 0">
                                <td colspan="10">
                                    <h5 style="text-align: center;">No data found</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- /Payroll Additions Table -->

        </div>
        <!-- Additions Tab -->

        <!-- Overtime Tab -->
        <div class="tab-pane" id="tab_overtime">

            <!-- Add Overtime Button -->
            <div class="text-end mb-4 clearfix">
                <button class="btn btn-primary add-btn" type="button" data-bs-toggle="modal" data-bs-target="#add_overtime"><i
                        class="fa fa-plus"></i> Add Overtime</button>
            </div>
            <!-- /Add Overtime Button -->

            <!-- Payroll Overtime Table -->
            <div class="payroll-table card">
                <div class="table-responsive">
                    <table class="table table-hover table-radius">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Rate</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let overPayroll of allOverPayroll;let i = index">
                                <th>{{overPayroll.name}}</th>
                                <td>{{overPayroll.rate}}</td>
                                <td class="text-end">
                                    <div class="dropdown dropdown-action">
                                        <a  class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item"  data-bs-toggle="modal"
                                                data-bs-target="#edit_overtime" (click)="editOver(overPayroll.id)"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                                            <a class="dropdown-item"  data-bs-toggle="modal"
                                                data-bs-target="#delete_overtime" (click)="tempOverId = overPayroll.id"><i class="fa fa-trash-o m-r-5"></i>
                                                Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="allOverPayroll.length === 0">
                                <td colspan="10">
                                    <h5 style="text-align: center;">No data found</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- /Payroll Overtime Table -->

        </div>
        <!-- /Overtime Tab -->

        <!-- Deductions Tab -->
        <div class="tab-pane" id="tab_deductions">

            <!-- Add Deductions Button -->
            <div class="text-end mb-4 clearfix">
                <button class="btn btn-primary add-btn" type="button" data-bs-toggle="modal"
                    data-bs-target="#add_deduction"><i class="fa fa-plus"></i> Add Deduction</button>
            </div>
            <!-- /Add Deductions Button -->

            <!-- Payroll Deduction Table -->
            <div class="payroll-table card">
                <div class="table-responsive">
                    <table class="table table-hover table-radius">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Default/Unit Amount</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let deductPayroll of allDeductPayroll;let i = index">
                                <th>{{deductPayroll.name}}</th>
                                <td>{{deductPayroll.unitCost}}</td>
                                <td class="text-end">
                                    <div class="dropdown dropdown-action">
                                        <a  class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false"><i class="material-icons">more_vert</i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item"  data-bs-toggle="modal"
                                                data-bs-target="#edit_deduction" (click)="editDeduct(deductPayroll.id)"><i class="fa fa-pencil m-r-5"></i>
                                                Edit</a>
                                            <a class="dropdown-item"  data-bs-toggle="modal"
                                                data-bs-target="#delete_deduction" (click)="tempDeductId = deductPayroll.id"><i class="fa fa-trash-o m-r-5"></i>
                                                Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="allDeductPayroll.length === 0">
                                <td colspan="10">
                                    <h5 style="text-align: center;">No data found</h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- /Payroll Deduction Table -->

        </div>
        <!-- /Deductions Tab -->

    </div>
    <!-- Tab Content -->

</div>
<!-- /Page Content -->

<!-- Add Addition Modal -->
<div id="add_addition" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Addition</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (ngSubmit)="addPayroll()" [formGroup]="addPayrollForm">
                    <div class="form-group">
                        <label>Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [class.invalid]="addPayrollForm.get('addPayrollName').invalid && addPayrollForm.get('addPayrollName').touched"
                        formControlName="addPayrollName">
                        <div
                                    *ngIf="addPayrollForm.get('addPayrollName').invalid && addPayrollForm.get('addPayrollName').touched">
                                    <small
                                        *ngIf="addPayrollForm.get('addPayrollName').invalid && addPayrollForm.get('addPayrollName').touched"
                                        class="text-danger"> *Name is required</small>
                                </div>
                    </div>
                    <div class="form-group">
                        <label>Category <span class="text-danger">*</span></label>
                        <select class="form-select form-control" [class.invalid]="addPayrollForm.get('addPayrollCategory').invalid && addPayrollForm.get('addPayrollCategory').touched"
                        formControlName="addPayrollCategory">
                            <option>Select a category</option>
                            <option>Monthly remuneration</option>
                            <option>Additional remuneration</option>
                        </select>
                        <div
                        *ngIf="addPayrollForm.get('addPayrollCategory').invalid && addPayrollForm.get('addPayrollCategory').touched">
                        <small
                            *ngIf="addPayrollForm.get('addPayrollCategory').invalid && addPayrollForm.get('addPayrollCategory').touched"
                            class="text-danger"> *Category is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Unit calculation</label>
                        <div class="status-toggle">
                            <input type="checkbox" id="unit_calculation" class="check">
                            <label for="unit_calculation" class="checktoggle">checkbox</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Unit Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" [class.invalid]="addPayrollForm.get('addPayrollUnit').invalid && addPayrollForm.get('addPayrollUnit').touched"
                            formControlName="addPayrollUnit">
                            <span class="input-group-text">.00</span>
                        </div>
                        <div
                            *ngIf="addPayrollForm.get('addPayrollUnit').invalid && addPayrollForm.get('addPayrollUnit').touched">
                            <small
                                *ngIf="addPayrollForm.get('addPayrollUnit').invalid && addPayrollForm.get('addPayrollUnit').touched"
                                class="text-danger"> *Category is required</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Assignee</label>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="addition_assignee" id="addition_no_emp"
                                value="option1" checked>
                            <label class="form-check-label" for="addition_no_emp">
                                No assignee
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="addition_assignee" id="addition_all_emp"
                                value="option2">
                            <label class="form-check-label" for="addition_all_emp">
                                All employees
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="addition_assignee"
                                id="addition_single_emp" value="option3">
                            <label class="form-check-label" for="addition_single_emp">
                                Select Employee
                            </label>
                        </div>
                        <div class="form-group">
                            <select class="form-select form-control">
                                <option>-</option>
                                <option>Select All</option>
                                <option>John Doe</option>
                                <option>Richard Miles</option>
                            </select>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Add Addition Modal -->

<!-- Edit Addition Modal -->
<div id="edit_addition" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Addition</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (ngSubmit)="editPayroll()" [formGroup]="editPayrollForm">
                    <div class="form-group">
                        <label>Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text"  [class.invalid]="editPayrollForm.get('editPayrollName').invalid && editPayrollForm.get('editPayrollName').touched"
                        formControlName="editPayrollName">
                        <div
                        *ngIf="editPayrollForm.get('editPayrollName').invalid && editPayrollForm.get('editPayrollName').touched">
                        <small
                            *ngIf="editPayrollForm.get('editPayrollName').invalid && editPayrollForm.get('editPayrollName').touched"
                            class="text-danger"> *Name is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label>Category <span class="text-danger">*</span></label>
                        <select class="form-select form-control" [class.invalid]="editPayrollForm.get('editPayrollCategory').invalid && editPayrollForm.get('editPayrollCategory').touched"
                        formControlName="editPayrollCategory">
                            <option>Select a category</option>
                            <option>Monthly remuneration</option>
                            <option>Additional remuneration</option>
                        </select>
                        <div
                        *ngIf="editPayrollForm.get('editPayrollCategory').invalid && editPayrollForm.get('editPayrollCategory').touched">
                        <small
                            *ngIf="editPayrollForm.get('editPayrollCategory').invalid && editPayrollForm.get('editPayrollCategory').touched"
                            class="text-danger"> *Category is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Unit calculation</label>
                        <div class="status-toggle">
                            <input type="checkbox" id="edit_unit_calculation" class="check">
                            <label for="edit_unit_calculation" class="checktoggle">checkbox</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Unit Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" [class.invalid]="editPayrollForm.get('editPayrollUnit').invalid && editPayrollForm.get('editPayrollUnit').touched"
                            formControlName="editPayrollUnit">
                            <span class="input-group-text">.00</span>
                        </div>
                        <div
                            *ngIf="editPayrollForm.get('editPayrollUnit').invalid && editPayrollForm.get('editPayrollUnit').touched">
                            <small
                                *ngIf="editPayrollForm.get('editPayrollUnit').invalid && editPayrollForm.get('editPayrollUnit').touched"
                                class="text-danger"> *Unit is required</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Assignee</label>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="edit_addition_assignee"
                                id="edit_addition_no_emp" value="option1" checked>
                            <label class="form-check-label" for="edit_addition_no_emp">
                                No assignee
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="edit_addition_assignee"
                                id="edit_addition_all_emp" value="option2">
                            <label class="form-check-label" for="edit_addition_all_emp">
                                All employees
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="edit_addition_assignee"
                                id="edit_addition_single_emp" value="option3">
                            <label class="form-check-label" for="edit_addition_single_emp">
                                Select Employee
                            </label>
                        </div>
                        <div class="form-group">
                            <select class="form-select form-control">
                                <option>-</option>
                                <option>Select All</option>
                                <option>John Doe</option>
                                <option>Richard Miles</option>
                            </select>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Edit Addition Modal -->

<!-- Delete Addition Modal -->
<div class="modal custom-modal fade" id="delete_addition" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-header">
                    <h3>Delete Addition</h3>
                    <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                    <div class="row">
                        <div class="col-6">
                            <a  class="btn btn-primary continue-btn" (click)="deletePayroll()">Delete</a>
                        </div>
                        <div class="col-6">
                            <a  data-bs-dismiss="modal"
                                class="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Addition Modal -->

<!-- Add Overtime Modal -->
<div id="add_overtime" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Overtime</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (ngSubmit)="addOver()" [formGroup]="addOverForm">
                    <div class="form-group">
                        <label>Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [class.invalid]="addOverForm.get('addOverName').invalid && addOverForm.get('addOverName').touched"
                        formControlName="addOverName">
                        <div
                                    *ngIf="addOverForm.get('addOverName').invalid && addOverForm.get('addOverName').touched">
                                    <small
                                        *ngIf="addOverForm.get('addOverName').invalid && addOverForm.get('addOverName').touched"
                                        class="text-danger"> *Name is required</small>
                                </div>
                    </div>
                    <div class="form-group">
                        <label>Rate Type <span class="text-danger">*</span></label>
                        <select class="form-select form-control">
                            <option>-</option>
                            <option>Daily Rate</option>
                            <option>Hourly Rate</option>
                        </select>
                       
                    </div>
                    <div class="form-group">
                        <label>Rate <span class="text-danger">*</span></label>
                        <input class="form-control" type="text"  [class.invalid]="addOverForm.get('addOverRate').invalid && addOverForm.get('addOverRate').touched"
                        formControlName="addOverRate">
                        <div
                        *ngIf="addOverForm.get('addOverRate').invalid && addOverForm.get('addOverRate').touched">
                        <small
                            *ngIf="addOverForm.get('addOverRate').invalid && addOverForm.get('addOverRate').touched"
                            class="text-danger"> *Rate is required</small>
                    </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Add Overtime Modal -->

<!-- Edit Overtime Modal -->
<div id="edit_overtime" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Overtime</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (ngSubmit)="editOverSubmit()" [formGroup]="editOverForm">
                    <div class="form-group">
                        <label>Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [class.invalid]="editOverForm.get('editOverName').invalid && editOverForm.get('editOverName').touched"
                        formControlName="editOverName">
                        <div
                        *ngIf="editOverForm.get('editOverName').invalid && editOverForm.get('editOverName').touched">
                        <small
                            *ngIf="editOverForm.get('editOverName').invalid && editOverForm.get('editOverName').touched"
                            class="text-danger"> *Name is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label>Rate Type <span class="text-danger">*</span></label>
                        <select class="form-select form-control">
                            <option>-</option>
                            <option>Daily Rate</option>
                            <option>Hourly Rate</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Rate <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [class.invalid]="editOverForm.get('editOverRate').invalid && editOverForm.get('editOverRate').touched"
                        formControlName="editOverRate">
                        <div
                        *ngIf="editOverForm.get('editOverRate').invalid && editOverForm.get('editOverRate').touched">
                        <small
                            *ngIf="editOverForm.get('editOverRate').invalid && editOverForm.get('editOverRate').touched"
                            class="text-danger"> *Rate is required</small>
                    </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Edit Overtime Modal -->

<!-- Delete Overtime Modal -->
<div class="modal custom-modal fade" id="delete_overtime" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-header">
                    <h3>Delete Overtime</h3>
                    <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                    <div class="row">
                        <div class="col-6">
                            <a  class="btn btn-primary continue-btn" (click)="deleteOver()">Delete</a>
                        </div>
                        <div class="col-6">
                            <a  data-bs-dismiss="modal"
                                class="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Overtime Modal -->

<!-- Add Deduction Modal -->
<div id="add_deduction" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Deduction</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (ngSubmit)="addDeducts()" [formGroup]="addDeductForm">
                    <div class="form-group">
                        <label>Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [class.invalid]="addDeductForm.get('addDeductName').invalid && addDeductForm.get('addDeductName').touched"
                        formControlName="addDeductName">
                        <div
                        *ngIf="addDeductForm.get('addDeductName').invalid && addDeductForm.get('addDeductName').touched">
                        <small
                            *ngIf="addDeductForm.get('addDeductName').invalid && addDeductForm.get('addDeductName').touched"
                            class="text-danger"> *Name is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Unit calculation</label>
                        <div class="status-toggle">
                            <input type="checkbox" id="unit_calculation_deduction" class="check">
                            <label for="unit_calculation_deduction" class="checktoggle">checkbox</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Unit Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" [class.invalid]="addDeductForm.get('addDeductUnit').invalid && addDeductForm.get('addDeductUnit').touched"
                            formControlName="addDeductUnit">
                            <span class="input-group-text">.00</span>
                        </div>
                        <div
                        *ngIf="addDeductForm.get('addDeductUnit').invalid && addDeductForm.get('addDeductUnit').touched">
                        <small
                            *ngIf="addDeductForm.get('addDeductUnit').invalid && addDeductForm.get('addDeductUnit').touched"
                            class="text-danger"> *Unit is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Assignee</label>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="deduction_assignee" id="deduction_no_emp"
                                value="option1" checked>
                            <label class="form-check-label" for="deduction_no_emp">
                                No assignee
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="deduction_assignee"
                                id="deduction_all_emp" value="option2">
                            <label class="form-check-label" for="deduction_all_emp">
                                All employees
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="deduction_assignee"
                                id="deduction_single_emp" value="option3">
                            <label class="form-check-label" for="deduction_single_emp">
                                Select Employee
                            </label>
                        </div>
                        <div class="form-group">
                            <select class="form-select form-control">
                                <option>-</option>
                                <option>Select All</option>
                                <option>John Doe</option>
                                <option>Richard Miles</option>
                            </select>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Add Deduction Modal -->

<!-- Edit Deduction Modal -->
<div id="edit_deduction" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Deduction</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form nz-form (ngSubmit)="editDeductSubmit()" [formGroup]="editDeductForm">
                    <div class="form-group">
                        <label>Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [class.invalid]="editDeductForm.get('editDeductName').invalid && editDeductForm.get('editDeductName').touched"
                        formControlName="editDeductName">
                        <div
                        *ngIf="editDeductForm.get('editDeductName').invalid && editDeductForm.get('editDeductName').touched">
                        <small
                            *ngIf="editDeductForm.get('editDeductName').invalid && editDeductForm.get('editDeductName').touched"
                            class="text-danger"> *Name is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Unit calculation</label>
                        <div class="status-toggle">
                            <input type="checkbox" id="edit_unit_calculation_deduction" class="check">
                            <label for="edit_unit_calculation_deduction" class="checktoggle">checkbox</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Unit Amount</label>
                        <div class="input-group">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" [class.invalid]="editDeductForm.get('editDeductunit').invalid && editDeductForm.get('editDeductunit').touched"
                            formControlName="editDeductunit">
                            <span class="input-group-text">.00</span>
                        </div>
                        <div
                        *ngIf="editDeductForm.get('editDeductunit').invalid && editDeductForm.get('editDeductunit').touched">
                        <small
                            *ngIf="editDeductForm.get('editDeductunit').invalid && editDeductForm.get('editDeductunit').touched"
                            class="text-danger"> *Unit is required</small>
                    </div>
                    </div>
                    <div class="form-group">
                        <label class="d-block">Assignee</label>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="edit_deduction_assignee"
                                id="edit_deduction_no_emp" value="option1" checked>
                            <label class="form-check-label" for="edit_deduction_no_emp">
                                No assignee
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="edit_deduction_assignee"
                                id="edit_deduction_all_emp" value="option2">
                            <label class="form-check-label" for="edit_deduction_all_emp">
                                All employees
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="edit_deduction_assignee"
                                id="edit_deduction_single_emp" value="option3">
                            <label class="form-check-label" for="edit_deduction_single_emp">
                                Select Employee
                            </label>
                        </div>
                        <div class="form-group">
                            <select class="form-select form-control">
                                <option>-</option>
                                <option>Select All</option>
                                <option>John Doe</option>
                                <option>Richard Miles</option>
                            </select>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /Edit Addition Modal -->

<!-- Delete Deduction Modal -->
<div class="modal custom-modal fade" id="delete_deduction" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-header">
                    <h3>Delete Deduction</h3>
                    <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                    <div class="row">
                        <div class="col-6">
                            <a  class="btn btn-primary continue-btn" (click)="deleteDeduct()">Delete</a>
                        </div>
                        <div class="col-6">
                            <a  data-bs-dismiss="modal"
                                class="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Deduction Modal -->