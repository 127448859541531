<div style="margin: -25px -25px -25px -15px;">
    <nz-layout>
        <div style="flex-grow: 1;">
            <div id="livemap" style="height:88vh;"></div>
        </div>
        <nz-sider nzWidth="500px" nzCollapsible="true" nzCollapsedWidth="0" [nzCollapsed]="sidebarcollapsed">
            <div style="display:flex; width:100%;">
                <div class="sidebar-filters">
                    <div class="sidebar-container">
                        <h2>APSFL Network</h2>
                        <div>
                            <form nz-form>
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="11" nzFor="district" nzRequired>District </nz-form-label>
                                    <nz-form-control [nzSpan]="12" nzErrorTip="Please select district!">
                                        <nz-select id="district" formControlName="FilterValues"
                                            nzPlaceHolder="select district" (ngModelChange)="distChange($event)">
                                            <nz-option nzValue="male" nzLabel="male"></nz-option>
                                            <nz-option nzValue="female" nzLabel="female"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="11" nzFor="vehicle-group" nzRequired>Select Vehicle Group
                                    </nz-form-label>
                                    <nz-form-control [nzSpan]="12" nzErrorTip="Please select vehicle group!">
                                        <nz-select id="vehicle-group" formControlName="FilterValues"
                                            nzPlaceHolder="select vehicle group"
                                            (ngModelChange)="vcleGrpChange($event)">
                                            <nz-option nzValue="male" nzLabel="male"></nz-option>
                                            <nz-option nzValue="female" nzLabel="female"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="11" nzFor="location-group" nzRequired>Select Location Group
                                    </nz-form-label>
                                    <nz-form-control [nzSpan]="12" nzErrorTip="Please select your location group!">
                                        <nz-select id="location-group" formControlName="FilterValues"
                                            nzPlaceHolder="select location group"
                                            (ngModelChange)="lctnGrpChange($event)">
                                            <nz-option nzValue="male" nzLabel="male"></nz-option>
                                            <nz-option nzValue="female" nzLabel="female"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="11" nzFor="location" nzRequired>Select Locations
                                    </nz-form-label>
                                    <nz-form-control [nzSpan]="12" nzErrorTip="Please select your locations!">
                                        <nz-select id="location" formControlName="FilterValues"
                                            nzPlaceHolder="select locations" (ngModelChange)="lctnsChange($event)">
                                            <nz-option nzValue="male" nzLabel="male"></nz-option>
                                            <nz-option nzValue="female" nzLabel="female"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                <nz-form-item>
                                    <nz-form-label [nzSpan]="11" nzFor="vehicle-category" nzRequired>Select Vehicle
                                        Category
                                    </nz-form-label>
                                    <nz-form-control [nzSpan]="12" nzErrorTip="Please select your vehiclecategory!">
                                        <nz-select id="vehicle-category" formControlName="FilterValues"
                                            nzPlaceHolder="Select vehicle category"
                                            (ngModelChange)="vhcleCtgryChange($event)">
                                            <nz-option nzValue="male" nzLabel="male"></nz-option>
                                            <nz-option nzValue="female" nzLabel="female"></nz-option>
                                        </nz-select>
                                    </nz-form-control>
                                </nz-form-item>
                                <!-- <nz-form-item>
                            <nz-form-control [nzSpan]="12" [nzOffset]="5">
                                <button nz-button nzType="primary">Submit</button>
                            </nz-form-control>
                        </nz-form-item> -->
                            </form>
                        </div>
                        <div class="counts-section">
                            <div>
                                <span>12</span>
                                <div> <span><span nz-icon nzType="node-index" nzTheme="outline"></span> Online</span>
                                </div>
                            </div>
                            <div>
                                <span>4</span>
                                <div> <span><span nz-icon nzType="warning" nzTheme="outline"></span> Offline</span>
                                </div>
                            </div>
                            <div>
                                <span>2</span>
                                <div> <span><span nz-icon nzType="tool" nzTheme="outline"></span> Repair</span></div>
                            </div>
                            <div>
                                <span>1</span>
                                <div> <span><span nz-icon nzType="question-circle" nzTheme="outline"></span>
                                        Others</span>
                                </div>
                            </div>
                        </div>
                        <div>                        
                            <dx-data-grid id="gridContainer" [dataSource]="customers" keyExpr="sno" [showBorders]="true">
                                <dxi-column *ngFor="let column of columns" [dataField]="column.dataField" [caption]="column.caption"></dxi-column>
                              </dx-data-grid>
                        </div>
                    </div>
                    <button class="toggle-btn" style="margin-left: auto;" (click)="toggle()">
                        {{!sidebarcollapsed? 'Close Filters': 'Open Filters'}} </button>
                </div>
            </div>
        </nz-sider>
    </nz-layout>
</div>