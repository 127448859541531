<div class="relative w-full">
    <nz-card class="m-4 p-2">
        <div class="text-center m-2">
            <div class="hdr-ttl grd-hdr">{{title}}</div>
        </div>
        <div>
            <div>
                <div class="text-right" *ngIf="data.anlytcs_crd_json.length">
                    <span class="hd-anlt" (click)="settings.shw_anlytc = !settings.shw_anlytc">
                        <span *ngIf="!settings.shw_anlytc">Show</span> <span
                            *ngIf="settings.shw_anlytc">Hide</span>analytics
                    </span>
                </div>

                <div *ngIf="settings.shw_anlytc">
                    <nz-card class="example-card" appearance="outlined">

                        <div>
                            <div class="grid grid-flow-col auto-cols-max gap-2">
                                <div *ngFor="let a of data.anlytcs_crd_json">

                                    <ng-container>
                                        <div>
                                            <div class="flex-auto">
                                                <div class="w-full h-full"> <span class="hdr-ttl">
                                                        {{a.crd_hdr_nm}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>

                                            <div class="grid grid-flow-col auto-cols-max gap-2">

                                                <div *ngFor="let c of a.crd_val"
                                                    class="crd-dv flex items-center justify-between rounded-xl px-1 py-8  dark:bg-white dark:bg-opacity-5 dark:text-indigo-400 fst_bgrnd sqft_1_bfre">
                                                    <div class="cnt-crd">
                                                        <div
                                                            class="text-2xl font-semibold leading-none tracking-tight sm:text-4xl pl-4">
                                                            <span
                                                                [ngClass]="c.fltr_typ == 'group_count' ? 'crsr-alwd' : 'crsr-nt-alwd crd-cnt-nrml-clr'"
                                                                (click)="c.fltr_typ == 'group_count' ? fltrDataGrd(c, null): ''">

                                                                <span
                                                                    [ngClass]="c.crd_hghlt_in == true ? 'crd-cnt-hglt-clr' : 'crd-cnt-nrml-clr'">
                                                                    {{c.cnt_val}} </span>


                                                            </span>

                                                        </div>
                                                        <div class="mt-1 font-medium pl-4 cnt-ttl"> {{c.title}}</div>
                                                    </div>
                                                    <ng-container *ngIf="c.sb_anltc_cnts && c.sb_anltc_cnts.length">

                                                        <div *ngFor="let sc of c.sb_anltc_cnts" class="cnt-crd">

                                                            <div
                                                                class="text-2xl font-semibold leading-none tracking-tight sm:text-4xl pl-4">
                                                                <span
                                                                    [ngClass]="sc.fltr_typ == 'group_count' ? 'crsr-alwd' : 'crsr-nt-alwd'"
                                                                    (click)="sc.fltr_typ == 'group_count' ? fltrDataGrd(c, sc) : ''">
                                                                    <span
                                                                        [ngClass]="sc.crd_hghlt_in == true ? 'crd-cnt-hglt-clr' : 'crd-cnt-nrml-clr'">
                                                                        {{sc.cnt_val}}</span>
                                                                </span>
                                                            </div>
                                                            <div class="mt-1 font-medium pl-4 cnt-ttl"> {{sc.title}}
                                                            </div>

                                                        </div>

                                                    </ng-container>


                                                    <div>
                                                        <div class="crdIcn2">
                                                            <!-- <mat-icon svgIcon="equalizer"></mat-icon> -->
                                                            <span nz-icon nzType="bar-chart" nzTheme="outline"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </ng-container>
                                </div>

                                <div *ngFor="let g of data.anlytcs_grph_json" class="flex">
                                    <div class="flex">
                                        <div>
                                            <div class="cstm_chart">
                                                <div>
                                                    <span class="hdr-ttl"> {{g.chartOptions.title}}</span>

                                                </div>
                                                <apx-chart [series]="g.chartOptions.series"
                                                    [chart]="g.chartOptions.chart" [labels]="g.chartOptions.labels"
                                                    [responsive]="g.chartOptions.responsive"></apx-chart>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </nz-card>



                </div>

            </div>

            <div>
                <!-- records counts -->

                <div class="mt-4 flex">
                    <div>
                        <!-- <span *ngIf="settings.fltr_apld_rst_btn_in" matTooltip="Reset data" class="cursor-pointer"
                            (click)="clrGrdFltrs()"> <mat-icon svgIcon="restart_alt"></mat-icon> </span> -->

                        <span *ngIf="settings.fltr_apld_rst_btn_in" class="cursor-pointer" (click)="clrGrdFltrs()"
                            nz-icon nzType="reload" nzTheme="outline"></span>
                    </div>
                    <div>

                        <span class="dv-ttl-rc-lbl">Total Records :</span> <span class="dv-ttl-rc-vl">
                            {{dataSource && dataSource.length}}</span>
                    </div>
                    <div *ngIf="enableMultiSelect" class="pl-8">
                        <span class="dv-ttl-rc-lbl">Selected Records :</span> <span class="dv-ttl-rc-vl">
                            {{data.grdSlctdRws.length}}</span>
                    </div>
                </div>

                <!-- grid -->
                <!-- class="ds-card grid" -->
                <!-- (onExporting)="onExporting($event)" -->
                <div>
                    <dx-data-grid #DxDataGridComponent id="DxDataGridComponent" [dataSource]="dataSource"
                        [showBorders]="true" [repaintChangesOnly]="true" (onCellClick)="onCellClick($event)"
                        [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
                        [allowColumnResizing]="true" (onContentReady)="onContentReady($event)"
                        (onToolbarPreparing)=" onToolbarPreparing($event)"
                        (onSelectionChanged)="selectionChanged($event)" (onInitialized)="saveGridInstance($event)">

                        <dxo-paging [enabled]="settings.shw_pg_sz" [pageSize]="settings.pg_sz"></dxo-paging>
                        <dxo-pager [visible]="settings.shw_pg_sz" [showPageSizeSelector]="true"
                            [allowedPageSizes]="settings.pg_sz_arry" [showInfo]="true">
                        </dxo-pager>
                        <dxo-scrolling columnRenderingMode="virtual" mode="standard"></dxo-scrolling>
                        <dxo-selection mode="multiple" [showCheckBoxesMode]="'always'"
                            *ngIf="enableMultiSelect"></dxo-selection>
                        <dxo-column-chooser [enabled]="settings.fltr_grp_pnl_enbl" mode="select"
                            showColumnChooser="true"></dxo-column-chooser>
                        <dxo-export [enabled]="settings.fltr_grp_pnl_enbl" [allowExportSelectedData]="true"
                            [fileName]="settings.exprt_fl_nm" [formats]="exportFormats"></dxo-export>
                        <dxo-search-panel [visible]="settings.fltr_grp_pnl_enbl"
                            [highlightCaseSensitive]="true"></dxo-search-panel>
                        <dxo-filter-panel [visible]="settings.fltr_grp_pnl_enbl"></dxo-filter-panel>
                        <dxo-group-panel [visible]="settings.fltr_grp_pnl_enbl"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="settings.fltr_grp_pnl_enbl"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
                        <dxo-filter-row [visible]="settings.fltr_grp_pnl_enbl"></dxo-filter-row>
                        <dxo-header-filter [visible]="settings.fltr_grp_pnl_enbl"></dxo-header-filter>

                        <dxi-column *ngFor="let b of settings.row_buttons" [showInColumnChooser]="false"
                            [visible]="true" [type]="b.btn_id"
                            [width]="b.width ? b.width : b.btn_type == 'button' ? 130 : 70"
                            [cellTemplate]="b.cellTemplate" [alignment]="'center'"
                            [caption]="b.btn_type == 'icon' ? b.label  : ''">
                            <div *dxTemplate="let data of b.cellTemplate">
                                <!-- <button *ngIf="b.btn_type == 'button'" mat-stroked-button [color]="b.color"
                                class="dv-cstm-btn" (click)="onRowBtnClkEvnt(data.data, b)"><span
                                    *ngIf="b.icon"><mat-icon class="btn-icn">{{b.icon}}</mat-icon></span>
                                {{b.label}}</button> -->

                                <button *ngIf="b.btn_type == 'button'" nz-button nzType="default" [ngClass]="b.color"
                                    class="dv-cstm-btn" (click)="onRowBtnClkEvnt(data.data, b)">
                                    <i *ngIf="b.icon" nz-icon [nzType]="b.icon" class="btn-icn"></i>
                                    {{b.label}}
                                </button>


                                <button *ngIf="b.btn_type == 'icon'" class="eyeicnbtn"
                                    (click)="onRowBtnClkEvnt(data.data, b)">
                                    <!-- <mat-icon [svgIcon]="b.icon"></mat-icon> -->
                                    <i nz-icon [nzType]="b.icon"></i>
                                </button>

                            </div>
                        </dxi-column>



                        <dxi-column [visible]="c.visible" [dataField]="c.field" [caption]="c.headerName"
                            [width]="c.width" [alignment]="c.alignment" [allowResizing]="true"
                            [allowFiltering]="c.filter" [allowSearch]="c.filter" [allowHiding]="true"
                            [allowGrouping]="c.allowGrouping"
                            [format]="c.crncy_in == true ? { style: 'currency', currency: 'INR', useGrouping: true, minimumSignificantDigits: 4 } : ''"
                            [allowHeaderFiltering]="c.allowHeaderFiltering" *ngFor="let c of colDef">

                            <ng-container *ngIf="c.subClmns">

                                <dxi-column *ngFor="let l2 of c.subClmns" [visible]="l2.visible" [dataField]="l2.field"
                                    [caption]="l2.headerName" [width]="l2.width" [alignment]="l2.alignment"
                                    [allowResizing]="true" [allowFiltering]="l2.filter"
                                    [allowHeaderFiltering]="l2.allowHeaderFiltering" [allowGrouping]="l2.allowGrouping">

                                    <ng-container *ngIf="l2.subClmns">
                                        <dxi-column *ngFor="let l3 of l2.subClmns" [visible]="l3.visible"
                                            [dataField]="l3.field" [caption]="l3.headerName" [width]="l3.width"
                                            [alignment]="l3.alignment" [allowResizing]="true"
                                            [allowFiltering]="l3.filter"
                                            [allowHeaderFiltering]="l3.allowHeaderFiltering"
                                            [allowGrouping]="l3.allowGrouping">


                                            <ng-container *ngIf="l3.subClmns">
                                                <dxi-column *ngFor="let l4 of l3.subClmns" [visible]="l4.visible"
                                                    [dataField]="l4.field" [caption]="l4.headerName" [width]="l4.width"
                                                    [alignment]="l4.alignment" [allowResizing]="true"
                                                    [allowFiltering]="l4.filter"
                                                    [allowHeaderFiltering]="l4.allowHeaderFiltering"
                                                    [allowGrouping]="l4.allowGrouping">


                                                    <ng-container *ngIf="l4.subClmns">
                                                        <dxi-column *ngFor="let l5 of l4.subClmns"
                                                            [visible]="l5.visible" [dataField]="l5.field"
                                                            [caption]="l5.headerName" [width]="l5.width"
                                                            [alignment]="l5.alignment" [allowResizing]="true"
                                                            [allowFiltering]="l5.filter"
                                                            [allowHeaderFiltering]="l5.allowHeaderFiltering"
                                                            [allowGrouping]="l5.allowGrouping">



                                                            <ng-container *ngIf="l5.subClmns">
                                                                <dxi-column *ngFor="let l6 of l5.subClmns"
                                                                    [visible]="l6.visible" [dataField]="l6.field"
                                                                    [caption]="l6.headerName" [width]="l6.width"
                                                                    [alignment]="l6.alignment" [allowResizing]="true"
                                                                    [allowFiltering]="l6.filter"
                                                                    [allowHeaderFiltering]="l6.allowHeaderFiltering"
                                                                    [allowGrouping]="l6.allowGrouping">

                                                                </dxi-column>
                                                            </ng-container>



                                                        </dxi-column>
                                                    </ng-container>



                                                </dxi-column>
                                            </ng-container>


                                        </dxi-column>
                                    </ng-container>


                                </dxi-column>
                            </ng-container>
                        </dxi-column>

                        <dxo-summary *ngIf="settings.shw_smry">
                            <dxi-total-item *ngFor="let s of settings.smry_arry" [column]="s.column"
                                [valueFormat]="s.crncy_in == true ? s.format : ''"
                                [showInColumn]="s.showInSmryColumnTxt" [summaryType]="s.summaryType"
                                [displayFormat]="s.summaryDisplayFormat"></dxi-total-item>

                            <dxi-total-item column="created" summaryType="sum" displayFormat="Total: {0}">
                            </dxi-total-item>
                        </dxo-summary>

                    </dx-data-grid>

                </div>
            </div>
        </div>
    </nz-card>


    <!-- dailouge box -->
    <!-- <ng-template #gridDialogContent>
        <h2 mat-dialog-title>Alert!</h2>
        <mat-dialog-content>
            <div>
                <div>
                    {{settings.dlg_stngs.alrt_bdy_msg}}
                </div>

            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button *ngFor="let b of settings.dlg_stngs.alrt_bx_btns" mat-flat-button [color]="b.color" class="m-4"
                style="color: rgb(255, 255, 255);" (click)="onBtnClick(b.cb_res_cd)">{{b.label}}</button>
        </mat-dialog-actions>
    </ng-template> -->

    <!-- <nz-modal [(nzVisible)]="isVisible" nzTitle="Custom Modal Title" (nzOnCancel)="handleCancel()">
        <div *nzModalContent>
            <p>{{settings.dlg_stngs.alrt_bdy_msg}}</p>
        </div>
        <div *nzModalFooter>
            <span>Modal Footer:</span>
            <button *ngFor="let b of settings.dlg_stngs.alrt_bx_btns" [ngStyle]="{ 'color': b.color }" class="m-4"
                style="color: rgb(255, 255, 255);" (click)="onBtnClick(b.cb_res_cd)">{{b.label}}</button>
        </div>
    </nz-modal> -->

</div>

<div *ngIf="data.dmyChrt" class="dmy-chrt">
    <apx-chart [series]="data.dmyChrt.series" [chart]="data.dmyChrt.chart" [labels]="data.dmyChrt.labels"
        [responsive]="data.dmyChrt.responsive"></apx-chart>

</div>