import { Component, OnInit ,ViewChild } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
@Component({
  selector: 'app-oms-asset-dashboard',
  templateUrl: './oms-asset-dashboard.component.html',
  styleUrls: ['./oms-asset-dashboard.component.scss']
})
export class OmsAssetDashboardComponent implements OnInit{
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  dstrtdata: any;
  header_label = {
    name: `OMS Assets Dashboard`,
    dtepkrlbl: false
  };
  slctd_dstrt=0
  slectdCrd;
  assetCardsData: any[];
  slectdCrd_nm: any;
  columnDefs;
  assetGridData: any[];
  columnDefs2: ({ headerName: string; field: string; alignment: string; width: number; visible: boolean; groupIndex?: undefined; } | { headerName: string; field: string; width: string; alignment: string; visible?: undefined; groupIndex?: undefined; } | { headerName: string; field: string; alignment: string; groupIndex: number; width?: undefined; visible?: undefined; } | { headerName: string; field: string; alignment: string; width?: undefined; visible?: undefined; groupIndex?: undefined; })[];
  columnDefs1: ({ headerName: string; field: string; alignment: string; width: number; visible: boolean; } | { headerName: string; field: string; width: string; alignment: string; visible?: undefined; } | { headerName: string; field: string; alignment: string; width?: undefined; visible?: undefined; })[];
  slct_index= 0;
  userDtls: any;
  constructor(public apiServ: AppsService, private route: ActivatedRoute, private router: Router,) {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.userDtls)
    this.slctd_dstrt=this.userDtls[0].tnts.length==1 ? this.userDtls[0].tnts[0].tnt_id:0;

  }
  
  ngOnInit(): void {

    // this.columnDefs = [
    //   { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, visible: true },
    //   { headerName: 'Ditrict', field: 'tnt_nm', width: 'auto', alignment: 'left' },
    //   { headerName: 'Asset Name', field: 'ast_nm', width: 'auto', alignment: 'left' },
    //   { headerName: 'Asset type', field: 'ast_typ_nm', alignment: 'left', },
    //   { headerName: 'Asset Model', field: 'ast_mdl_nm', alignment: 'left' },
    //   { headerName: 'Asset make', field: 'ast_mke_nm', alignment: 'left' },
    //   { headerName: 'Asset Status', field: 'ast_sts_nm', alignment: 'left' },
    // ]

    this.getDstrtData();
    this.getAssetCardsData();
  }

  colorChanges = (clr_cd) => {
    console.log("test case")
    document.documentElement.style.setProperty('--colorcode', clr_cd);

  }
  
  getDstrtData() {

    this.apiServ.get(`apsfl/data/districts`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.dstrtdata = res['data'];
console.log(this.slctd_dstrt)
        if(this.slctd_dstrt > 0){
          this.dstrtdata = res['data'].filter((w)=> w.tnt_id==this.slctd_dstrt)
          console.log(this.dstrtdata)
        }else{
          this.dstrtdata=res['data']
        }
      }

    })

  }

  getAssetCardsData() {
    this.assetCardsData = []
    this.apiServ.get(`apsfl/assets/dashboard/cards/${this.slctd_dstrt}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.assetCardsData = res['data'];
        console.log(this.slct_index)
        this.selctedcard(this.assetCardsData[this.slct_index],this.slct_index)
      }
    })
  }


  getAssetGridData() {
    this.assetGridData = []
    this.columnDefs=[]
    this.columnDefs1=[]
    this.columnDefs2=[]
    this.apiServ.get(`apsfl/assets/dashboard/griddata/${this.slectdCrd}/${this.slctd_dstrt}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.assetGridData = res['data'];

        var count = 1
        this.assetGridData.forEach(e => {
          e['sno'] = count++
        });
        console.log(this.slectdCrd)
      // Component TypeScript Code
if (this.slectdCrd === 0) {
  console.log("Selected Card is 0");
  this.columnDefs = [
    { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, visible: false },
    { headerName: 'District', field: 'tnt_nm',  alignment: 'left' },
    { headerName: 'Asset Name', field: 'ast_nm',  alignment: 'left' },
    { 
      headerName: 'Asset Type', 
      field: 'ast_typ_nm', 
      alignment: 'left', 
      groupIndex: 1, // Set groupIndex here
      visible : false
    },
    { headerName: 'Asset Make', field: 'ast_mke_nm', alignment: 'left' },
    { headerName: 'Asset Model', field: 'ast_mdl_nm', alignment: 'left' },
    { headerName: 'Asset Status', field: 'ast_sts_nm', alignment: 'center' },
  ];
} else {
  console.log("Selected Card is not 0");
  this.columnDefs = [
    { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, visible: true },
    { headerName: 'District', field: 'tnt_nm',  alignment: 'left' },
    { headerName: 'Asset Name', field: 'ast_nm',  alignment: 'left' },
    { 
      headerName: 'Asset Type',  
      field: 'ast_typ_nm', 
      alignment: 'left', 
      groupIndex: null,      visible : false // Reset groupIndex if needed
    },
    { headerName: 'Asset Make', field: 'ast_mke_nm', alignment: 'left' },
    { headerName: 'Asset Model', field: 'ast_mdl_nm', alignment: 'left' },
    { headerName: 'Asset Status', field: 'ast_sts_nm', alignment: 'center' },
  ];
}
this.dataGrid.instance.refresh();
       
      }
    })
  }


  groupCellTemplate = (cellElement, cellInfo) => {
    // cellInfo provides information about the group
    const groupField = cellInfo.column.caption;
    const groupCount = cellInfo.data.items.length;

    // Customize the group header with the field name and group count
    cellElement.innerHTML = `${groupField}: ${cellInfo.key} (${groupCount} items)`;
};

  getDstrwiseData(e){
    this.getAssetCardsData()
  }

  selctedcard(e,index){
    this.dataGrid.instance.clearGrouping();
    this.columnDefs=[]
    this.assetGridData=[]
    console.log(e,index)
    this.slct_index=index
    this.slectdCrd_nm=e.ast_typ_nm;
    this.slectdCrd=e.ast_typ_id
    this.colorChanges(e.crd_clr)
    console.log(this.slectdCrd)
    this.getAssetGridData()
  }
  

}
