import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/providers/api.service';
// import {
//     MatSnackBar,
//     MatSnackBarHorizontalPosition,
//     MatSnackBarVerticalPosition,
// } from '@angular/material/snack-bar';
// import { MatDialog } from '@angular/material/dialog';
// import { CustomDialougeBox } from 'app/shared/dailog_box/custom_dialog';
// import { AuthService } from 'app/core/auth/auth.service';
@Injectable({
    providedIn: 'root'
})
export class DsMapService {
    public _header: BehaviorSubject<any> = new BehaviorSubject<any>({ parentTitle: null, title: null });
    private _baseURL: string = environment.origin + 'apiv1/';
    // horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    // verticalPosition: MatSnackBarVerticalPosition = 'top';
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,  private apiSrv: ApiService,
    ) {
        this._header.next({ title: null, parentTitle: null });
    }


    get(rte): Observable<any> {
        return this._httpClient.get(this._baseURL + rte);
    }
    post(rte, data): Observable<any> {
        return this._httpClient.post(this._baseURL + rte, { data: data });
    }
    put(rte, data): Observable<any> {
        return this._httpClient.put(this._baseURL + rte, { data: data });
    }
    delete(rte): Observable<any> {
        return this._httpClient.delete(this._baseURL + rte);
    }

    /*** Specializations ***/
    getMapElements(data): Observable<any> {
        console.log(this._baseURL)
        return this._httpClient.post(this._baseURL + 'maps/mapelmnts/hyrchy', { data: data });
    }
    // getSpecialization(tnt_id): Observable<any> {
    //     return this._httpClient.get(this._baseURL + 'hospital/tenants/' + tnt_id + '/specialization/master');
    // }
    // addSpecialization(data): Observable<any> {
    //     return this._httpClient.post(this._baseURL + 'hospital/specialization/master/add', { data: data });
    // }
    // deleteSpecialization(data): Observable<any> {
    //     return this._httpClient.post(this._baseURL + 'hospital/specialization/master/delete', { data: data });
    // }

    getLayouts(data): Observable<any> {
        return this._httpClient.post(this._baseURL + 'maps/mapelmnts/getLayerBounds', { data: data });
    }

    // showDailogeBox(data, width, clbk) {
    //     const dialogRef = this._matDialog.open(CustomDialougeBox, {
    //         disableClose: true,
    //         width: width,
    //         data: data
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         clbk(result);
    //     });
    // }

}
