<div class="flex position-relative" style="width:100%">
    <!-- <div>{{in_map_data |json}}</div> -->
    <div class="row hdrCls">{{in_map_data.hdr_settings.title}}
        <span *ngIf="in_map_data.keysShared && in_map_data.keysShared.length==0"> - {{in_map_data.hdr_settings.sb_title}}</span>
    </div>
    <div class="w-full">

        <div id={{mapId}} style="width:100%;height: 60vh;z-index: 0">
            
            <div *ngIf="in_map_data.dbrd_lvl==0"  class="segments-container">
                <div *ngIf="in_map_data.keysShared && in_map_data.keysShared.length>0" class="w-100 p-1 ">
                    <div class="segments-div overflow-y-auto">
                        <div  *ngFor="let k of in_map_data.keysShared" >
                            <div (click)="plotSlctdHeatKey(k)" class="sgmnt-cls" >
                                <!--   [ngClass]="{'actv_btn': k.slctd==true,'inActv_btn': k.slctd==false}" -->
                                <span nz-icon nzType="check" nzTheme="outline" *ngIf="k.slctd==true">Selected:</span>
                                {{k.dsply_nm}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="pop-card" *ngIf="shwPopCrd==true">
                <div class="dst_nmCls"> {{data.popCrdDtls.dstrt_nm}}</div>
                <div class="infoTbl">
                    <table>
                        <tr>
                            <th *ngFor="let s of tableData">{{s.header}}</th>
                            <!-- <th>Operational Plants</th>
                            <th>Under Construction</th>
                            <th>Work not Initiated</th> -->
                        </tr>
                        <tr>
                            <td *ngFor="let s of tableData">{{s.value}}</td>
                            <!-- <td>{{slctdData.cmpltd_ct}}</td>
                            <td>{{slctdData.pnd_ct}}</td>
                            <td>{{slctdData.ntstrtd_ct}}</td> -->
                        </tr>
                    </table>
                </div>
            </div>
            <div class="scale-crd slider-container" *ngIf="in_map_data.dbrd_lvl==0 && slctdKeydata && slctdKeydata.length>0">
              <div class="slider-line-container">
                <div class="slider-line"
                     [ngStyle]="{'background': gradientBackground}"
                     (mousemove)="onHover($event)"
                     (mouseleave)="onLeave()">
                </div>
              
                <div class="tooltip"
                     *ngIf="hoveredValue !== null"
                     [ngStyle]="{'left': tooltipPosition + 'px'}">
                     <!--  -->
                     {{hoverdTitle}}: {{hoveredValue}}
                </div>
              </div>
            </div>
            <div class="dst-nm-card" *ngIf="in_map_data.dbrd_lvl<=1">
                <table *ngIf="data.dstElmtns && data.dstElmtns.length>0">
                    <tr>
                        <td>
                            <div *ngFor="let d of data.dstElmtns.slice(0, 6); let i = index">
                                <div
                                    [ngClass]="{'Active_dstrct': d.shwSlctdDstrt==true,'Active_hvr_dstrct': d.shwHvr==true}">
                                    {{d.sqnce_id}}.{{d.elmnt_nm}}</div>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let d of data.dstElmtns.slice(6,12); let i = index">
                                <div
                                    [ngClass]="{'Active_dstrct': d.shwSlctdDstrt==true,'Active_hvr_dstrct': d.shwHvr==true}">
                                    {{d.sqnce_id}}.{{d.elmnt_nm}}</div>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let d of data.dstElmtns.slice(12,18); let i = index">
                                <div
                                    [ngClass]="{'Active_dstrct': d.shwSlctdDstrt==true,'Active_hvr_dstrct': d.shwHvr==true}">
                                    {{d.sqnce_id}}.{{d.elmnt_nm}}</div>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let d of data.dstElmtns.slice(18,24); let i = index">
                                <div
                                    [ngClass]="{'Active_dstrct': d.shwSlctdDstrt==true,'Active_hvr_dstrct': d.shwHvr==true}">
                                    {{d.sqnce_id}}.{{d.elmnt_nm}}</div>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let d of data.dstElmtns.slice(24); let i = index">
                                <div
                                    [ngClass]="{'Active_dstrct': d.shwSlctdDstrt==true,'Active_hvr_dstrct': d.shwHvr==true}">
                                    {{d.sqnce_id}}.{{d.elmnt_nm}}</div>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
            <div class="dst-nm-card" *ngIf="in_map_data.dbrd_lvl==2">
                <table *ngIf="data.ulbElmnts && data.ulbElmnts.length>0">
                    <tr>
                        <td>
                            <div class="dstNm_cls" *ngFor="let d of data.ulbElmnts.slice(0,5); let i = index;"
                                [ngClass]="{'Active_dstrct': d.shwSlctdULB==true}">
                                {{i+1}}.{{d.elmnt_nm}}
                            </div>
                        </td>
                        <td>
                            <div class="dstNm_cls" *ngFor="let d of data.ulbElmnts.slice(5); let i = index;"
                                [ngClass]="{'Active_dstrct': d.shwSlctdULB==true}">
                                {{i+6}}.{{d.elmnt_nm}}
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>
</div>
<ds-loading *ngIf="settings.shwLdg === true"></ds-loading>