<app-loading *ngIf="loader == true"></app-loading>
<div class="hldyPg">
    <!-- Page Content -->
    <div class="content container-fluid">



        <!-- Page Header -->
        <div class="page-header">
            <div class="row ">
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2">
                    <h3 class="page-title">Holidays</h3>

                </div>
                <div class="col-xl-5 col-lg-5 col-md-4 col-sm-4">

                    <p *ngIf="!shwSaveBtn" style="font-size: 12px;  margin-bottom: 3px;color: #5e5e5e; letter-spacing: .4px; font-weight: 400;text-align: right;
                padding-right: 10px;">Update week holidays by click on week cell&nbsp;&nbsp;<b class="legendTxt"><input
                                disabled readonly class="fIpt">Selected</b>&nbsp;&nbsp;<b class="legendTxt"><input
                                disabled readonly class="sIpt">De-Selected</b>
                    </p>
                    <p *ngIf="shwSaveBtn" (click)="submitWeekChng()"><button style="font-size: 12px;
                    margin-bottom: 3px;
                    color: #ffffff;
                    letter-spacing: .4px;
                    font-weight: 400;
                    background-color: #55ce63;
                    padding: 1px 18px;
                    float: right;
                    margin-top: -10px;
                    border: none;
                    line-height: 20px;
                    border-radius: 32px;">Save</button>
                    </p>

                    <table class="bld_grp_tble" style="float: right;">
                        <tr>
                            <th scope="col">Week</th>
                            <th scope="col" class="bldbrp_nms">W1</th>
                            <th scope="col" class="bldbrp_nms">W2</th>
                            <th scope="col" class="bldbrp_nms">W3</th>
                            <th scope="col" class="bldbrp_nms">W4</th>
                            <th scope="col" class="bldbrp_nms">W5</th>
                        </tr>
                        <tr *ngFor="let w of weeks">
                            <th scope="row">{{w.week}}</th>
                            <td (click)="editWeek(w.wk_dy_id,'w1',w.w1_in)"
                                [ngClass]="w.w1_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w2',w.w2_in)"
                                [ngClass]="w.w2_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w3',w.w3_in)"
                                [ngClass]="w.w3_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w4',w.w4_in)"
                                [ngClass]="w.w4_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w5',w.w5_in)"
                                [ngClass]="w.w5_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                        </tr>
                    </table>
                </div>
            </div>


            <div class="row ">


                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">

                    <div style="margin-top: 5px;">






                        <div class="form-label">Select Year </div>
                        <nz-select nzShowSearch [(ngModel)]="slctdYr" (ngModelChange)="getHolidays(slctdYr)"
                            style="width : 90%;margin-bottom: 30px;">
                            <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
                            </nz-option>
                        </nz-select>


                    </div>



                </div>



                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">

                    <div style="margin-top: 5px;">

                        <div class="form-label">Select Holiday Profiles </div>
                        <nz-select nzShowSearch [(ngModel)]="hldy_prfle_id" style="width : 90%;margin-bottom: 30px;" (ngModelChange)="getHolidays(slctdYr);this.getWeeks();" >
                            <nz-option *ngFor="let o of hldyprflst" [nzValue]="o.hldy_prfle_id"
                                [nzLabel]="o.hldy_prfle_nm">
                            </nz-option>
                        </nz-select>

                    </div>



                </div>

<!-- 
                <div nz-col nzXs="12" nzSm="3" nzMd="3" nzLg="3" nzXl="3" style="    margin-top: 7px;
">

                    <button nz-button nzType="primary" (click)="getHolidays(slctdYr);this.getWeeks();"
                        style="margin-left:20px;margin-top:20px">Get
                    </button>
                </div> -->



                <!-- <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                <a  class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_holiday"><i  class="fa fa-plus"></i> Add Holiday</a>
            </div> -->

            </div>
        </div>
        <!-- /Page Header -->




        <dx-data-grid id="gridContainer" class="p-8" [dataSource]="hldys" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
            (onToolbarPreparing)="(data.permissions.insrt_in==1)?onToolbarPreparing($event):false"
            [columnAutoWidth]="true" [wordWrapEnabled]="true">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-pager [visible]="hldys && hldys.length>10" [showPageSizeSelector]="true"
                [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="false"> </dxo-filter-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="true" [allowExportSelectedData]="false" fileName="Zone wise Trip Coverage Status">
            </dxo-export>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxi-column *ngFor="let f of hldyscolumnDefs" [visible]="f.visible" [dataField]="f.field"
                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Edit"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="editHoliday(data.data)">Edit Holiday</div>
                </div>
            </dxi-column>


            <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="Delete"
                cellTemplate="deleteTemplate">
                <div *dxTemplate="let data of 'deleteTemplate'">
                    <div class="tblDelBtn" (click)="onDeleteHoliday(data)">
                        Delete
                    </div>
                </div>
            </dxi-column>



        </dx-data-grid>

    </div>
    <!-- /Page Content -->





    <nz-drawer [nzBodyStyle]="{
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        'padding-bottom': '53px'
      }" [nzMaskClosable]="false" [nzWidth]="450" [nzVisible]="shwSidebar == true" [nzTitle]="sidebarHeading"
        (nzOnClose)="handleCancel()">
        <ng-container *nzDrawerContent>
            <div *ngIf="addHolidayVisible == true">
                <form nz-form [formGroup]="addHolidayForm" (ngSubmit)="addholidays()">
                    <nz-form-item style="display:block">
                        <nz-form-label [nzSpan]="6" nzFor="HolidayName">Holiday Name</nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzValidateStatus]="addHolidayForm.get('HolidayName')">
                            <input nz-input formControlName="HolidayName" placeholder="Enter holiday name" />

                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item  style="display:block">
                        <nz-form-label [nzSpan]="6" nzFor="Holidaydate">Holiday Date</nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzValidateStatus]="addHolidayForm.get('Holidaydate')">
                            <nz-date-picker formControlName="Holidaydate" nzFormat="yyyy-MM-dd"
                                [ngModelOptions]="{ standalone: true }" style="width:100%"></nz-date-picker>

                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item  style="display:block">
                        <nz-form-label [nzSpan]="6" nzFor="Description">Description</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <textarea nz-input formControlName="Description" rows="3"
                                placeholder="Enter description (optional)"></textarea>
                        </nz-form-control>
                    </nz-form-item>






                    <nz-form-item>
                        <nz-form-control [nzOffset]="7" [nzSpan]="12">

                            <div class="row">

                                <!-- <button nz-button nzType="primary" class="col m-5" (click)="updateMaster()" *ngIf="
                    settings.shwMasterEditDiv == true &&
                    settings.shwMasterDelDiv == false" [nzLoading]="isBtnLoading">
                                    Update
                                </button> -->
                                <!-- <button nz-button nzType="primary" class="col m-5" *ngIf=" settings.shwMasterEditDiv == false &&
                    settings.shwMasterDelDiv == true" nz-popconfirm nzPopconfirmTitle="Are you sure delete this ?"
                                    nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteMaster()"
                                    (nzOnCancel)="cancel()" nzDanger [nzLoading]="settings.isBtnLoading"
                                    [disabled]="settings.isBtnLoading">
                                    Delete
                                </button> -->
                                <button nz-button nzType="default" class="col m-5" (click)="handleCancel()">
                                    Cancel
                                </button>
                                <button nz-button nzType="primary" class="col m-5" (click)="addholidays()"
                                    [nzLoading]="isBtnLoading">
                                    Submit
                                </button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>




                </form>
            </div>

            <div *ngIf="editHolidayVisible == true">
                <form nz-form [formGroup]="editHolidayForm">
                    <!-- Holiday Name Field -->
                    <nz-form-item style="display:block">
                        <nz-form-label [nzSpan]="6" nzFor="editHolidayName">Holiday Name</nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzValidateStatus]="editHolidayForm.get('editHolidayName')">
                            <input nz-input formControlName="editHolidayName" placeholder="Enter holiday name" />
                            <nz-form-explain
                                *ngIf="editHolidayForm.get('editHolidayName').invalid && editHolidayForm.get('editHolidayName').touched">
                                * Holiday name is required.
                            </nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>

                    <!-- Holiday Date Field -->
                    <nz-form-item style="display:block">
                        <nz-form-label [nzSpan]="6" nzFor="editHolidayDate">Holiday Date</nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzValidateStatus]="editHolidayForm.get('editHolidayDate')">
                            <nz-date-picker formControlName="editHolidayDate" [nzFormat]="'dd-MM-YYYY'"
                                [ngModelOptions]="{ standalone: true }"></nz-date-picker>
                            <nz-form-explain
                                *ngIf="editHolidayForm.get('editHolidayDate').invalid && editHolidayForm.get('editHolidayDate').touched">
                                * Holiday date is required.
                            </nz-form-explain>
                        </nz-form-control>
                    </nz-form-item>

                    <!-- Description Field -->
                    <nz-form-item style="display:block">
                        <nz-form-label [nzSpan]="6" nzFor="editDescription">Description</nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <textarea nz-input formControlName="editDescription" rows="3"
                                placeholder="Enter description (optional)"></textarea>
                        </nz-form-control>
                    </nz-form-item>


                    <nz-form-item>
                        <nz-form-control [nzOffset]="7" [nzSpan]="12">

                            <div class="row">
                                <!-- <button nz-button nzType="primary" class="col m-5" (click)="addNewMaster()" [nzLoading]="isBtnLoading">
                                    Submit
                                </button> -->
                                <button nz-button nzType="primary" class="col m-5" (click)="updateHolidays()"
                                    [nzLoading]="isBtnLoading">
                                    Update
                                </button>
                                <!-- <button nz-button nzType="primary" class="col m-5" *ngIf=" settings.shwMasterEditDiv == false &&
                    settings.shwMasterDelDiv == true" nz-popconfirm nzPopconfirmTitle="Are you sure delete this ?"
                                    nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteMaster()"
                                    (nzOnCancel)="cancel()" nzDanger [nzLoading]="settings.isBtnLoading"
                                    [disabled]="settings.isBtnLoading">
                                    Delete
                                </button> -->
                                <button nz-button nzType="default" class="col m-5" (click)="handleCancel()">
                                    Cancel
                                </button>
                            </div>
                        </nz-form-control>
                    </nz-form-item>



                </form>
            </div>
            <div *ngIf="shwDeleteCnfrm == true
">
                <nz-alert nzType="warning" nzMessage="Warning"
                    nzDescription="You are about to delete the following record." nzShowIcon></nz-alert>
                <div>



                    <div>
                        <table class="del-confirm-table">
                            <tr class="row">
                                <td class="col">Holiday</td>
                                <td class="col">{{ delObj.hldy_nm }}</td>
                            </tr>
                            <tr class="row">
                                <td class="col">Description</td>
                                <td class="col">{{ delObj.dscn_tx }}</td>
                            </tr>

                        </table>
                    </div>


                    <div></div>
                </div>
                <div class="row">
                    <button nz-button nzType="default" class="col m-5" (click)="handleCancel()">Cancel</button>
                    <button nz-button nzType="primary" class=" col m-5" nz-popconfirm
                        nzPopconfirmTitle="Are you sure to delete holiday ?" nzPopconfirmPlacement="bottom"
                        (nzOnConfirm)="deleteHolidays()" (nzOnCancel)="cancel()" [nzLoading]="isBtnLoading"
                        [disabled]="isBtnLoading">Delete</button>


                </div>
            </div>


        </ng-container>
    </nz-drawer>
















</div>