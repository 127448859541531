import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-emplye-prfle',
  templateUrl: './emplye-prfle.component.html',
  styleUrls: ['./emplye-prfle.component.scss']
})
export class EmplyePrfleComponent {
  @Input() wrkData
  slctdActdt = 3

  slctdAtnd = 1
  EmployData = []
  date = new Date();

  slctdAtndhndlr = 'crntmnt'
  to_dt = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  frm_dt = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  // to_dt  
  // frm_dt

  dateFormat = [ this.frm_dt,this.to_dt]



  empdtls: any;
  columnEmplyDefs = []
  grddtls: any;
  hndlr = 'crntdt';
  empdtlsr: any;
  wrkdata: any;
  sidenav: boolean;
  drwerTitle: string;
  wrksdata: any;
  ngOnInit() {


    this.getEmplyCrdDtls()
    this.getEmplygridDtls()

  }


  constructor(public apiServ: AppsService, private rout: ActivatedRoute, public datepipe: DatePipe,) {


  }
  onChange(e) {
    console.log(e)
    this.to_dt = this.datepipe.transform(e[1], 'yyyy-MM-dd');
    this.frm_dt = this.datepipe.transform(e[0], 'yyyy-MM-dd');

    this.getEmplygridDtls()


  }

  getEmplyCrdDtls() {



    this.empdtls = []
    this.grddtls = []

    let data = {
      hndlr: this.hndlr,
      slctdAtndhndlr: this.slctdAtndhndlr,

    }
    const rte = `/apsfl/employee/profile/${this.wrkData.crw_id}`;

    this.apiServ.post(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        console.log(res['data'])


        this.empdtls = res['data'].crddtls
        this.empdtlsr = res['data'].crddtlsr

      }
    })


  }
  getEmplygridDtls() {



    this.empdtls = []
    this.grddtls = []

    let data = {

      frm_dt: this.datepipe.transform(this.frm_dt, 'yyyy-MM-dd'),
      to_dt: this.datepipe.transform(this.to_dt, 'yyyy-MM-dd')
    }
    const rte = `/apsfl/employee/grid/${this.wrkData.crw_id}`;


    console.log(data, 'gggggggggggg')

    this.apiServ.post(data, rte).subscribe((res) => {
      if (res['status'] == 200) {
        console.log(res['data'])


        this.grddtls = res['data']



        this.columnEmplyDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
          { headerName: 'Mandal ', field: 'mndl_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Work Description ', field: 'oms_wrm_dsc_tx', width: 'auto', alignment: 'left' },
          { headerName: 'Work type', field: 'wrk_typ_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Work Group', field: 'wrk_grp_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Issue Type', field: 'isue_typ_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Work Status', field: 'wrk_sts_nm', width: 'auto', alignment: 'left' },
          { headerName: 'No of Customers Effected', field: 'cstmr_efctd_ct', width: 'auto', alignment: 'center' },
          // { headerName: 'No of Employees Assigned', field: 'asgn_emps', alignment: 'center' },
        ]



        // { headerName: 'Date Of Birth', field: 'DOB', alignment: 'left' },




      }
    })


  }

  ngOnChanges() {

  }
  tabsActdata = [
    {
      'tab_id': 1,
      'tab_nm': 'Overall',
      'tab_hndlr': 'overall'
    },
    {
      'tab_id': 2,
      'tab_nm': 'This Month',
      'tab_hndlr': 'crntmnt'

    },
    {
      'tab_id': 3,
      'tab_nm': 'Today',
      'tab_hndlr': 'crntdt'

    },

  ]

  tabsAtnddata = [

    {
      'tab_id': 1,
      'tab_nm': 'This Month',
      'tab_hndlr': 'crntmnt'

    }

  ]
  slectdActTab(t) {
    console.group(t, 'ttttttttttttttttttt')

    this.slctdActdt = t.tab_id
    this.hndlr = t.tab_hndlr
    this.getEmplyCrdDtls()

    if (t.tab_hndlr === 'crntmnt') {
      const currentDate = new Date();

      this.frm_dt = this.datepipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
      this.to_dt = this.datepipe.transform( currentDate,'yyyy-MM-dd')
      this.dateFormat =[   this.frm_dt,this.to_dt] 

      this.getEmplygridDtls()

    }
    if (t.tab_hndlr === 'crntdt') {
      const currentDate = new Date();

      // Use today’s date for frm_dt
      this.frm_dt = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
      this.to_dt = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
      this.dateFormat =[   this.frm_dt,this.to_dt] 

      // Get the last day of the current month for to_dt
      this.getEmplygridDtls()
   

    }
    if (t.tab_hndlr === 'overall') {
      const currentDate = new Date();
    

      // Use today’s date for frm_dt
      const date=new Date(2024,9,1)
      this.frm_dt = this.datepipe.transform(date, 'yyyy-MM-dd');
      this.to_dt = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
      this.dateFormat =[   this.frm_dt,this.to_dt] 

      // Get the last day of the current month for to_dt
      this.getEmplygridDtls()
   

    }
  }

  slectdAtnTab(t) {
    console.group(t, 'ttttttttttttttttttt')

    this.slctdAtnd = t.tab_id
    this.slctdAtndhndlr = t.tab_hndlr
    this.getEmplyCrdDtls()
  }

  gotoProfile(data, employ) {
    this.drwerTitle = `${data.data.wrk_typ_nm}`
    console.log(data)
    this.wrksdata = data.data



    console.log(this.wrkdata, 'gggggggggggggggggg')
    this.sidenav = true
  }
  closeSideBar() {
    this.sidenav = false

  }
}
