import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiveTrackingComponent } from './live-tracking.component';
import { ActivityTrackComponent } from './activity-track/activity-track.component';
import { ApsflLiveTrackingComponent } from './apsfl-live-tracking/apsfl-live-tracking.component';


const routes: Routes = [
  { path: 'list', component: LiveTrackingComponent,  pathMatch : 'full' },
  { path: 'activity', component: ActivityTrackComponent,  pathMatch : 'full' },
  {path:'apsfl-network', component:ApsflLiveTrackingComponent,pathMatch:'full'},
  { path: '**', redirectTo : 'list' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiveTrackingRoutingModule { }
