import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from 'rxjs';
// import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PayrollService {
    private _baseURL: string = environment.origin + '/api/'
    // horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    // verticalPosition: MatSnackBarVerticalPosition = 'top';
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getPayrollsList(data): Observable<any> {
        return this._httpClient.post(this._baseURL + 'payroll/payrolls/list', { data: data });
    }
    getData(rte): Observable<any> {
        return this._httpClient.get(this._baseURL + rte);
    }

    // openSnackBar(msg, cls) {
    //     this._snackBar.open(msg, '', {
    //         duration: 3000,
    //         horizontalPosition: this.horizontalPosition,
    //         verticalPosition: this.verticalPosition,
    //         panelClass: ['mat-toolbar', cls]
    //     });
    // }

    getDepartments() { return this._httpClient.get(this._baseURL + `payroll/department`); }
    getDesignations() { return this._httpClient.get(this._baseURL + `payroll/designations/list`); }
    getEmplyCat() { return this._httpClient.get(this._baseURL + `payroll/employees/category`); }
    getEmplyStatus() { return this._httpClient.get(this._baseURL + `payroll/employees/status`); }
    getSubDprt(id) { return this._httpClient.get(this._baseURL + `payroll/department/${id}/sub-department`); }
    getOrgnts() { return this._httpClient.get(this._baseURL + `payroll/organization/list`); }



    getEmployeesMaster(postData) { return this._httpClient.post(this._baseURL + `payroll/employees/payroll`, { data: postData }); }

    generateTempPayroll(postData) { return this._httpClient.post(this._baseURL + `payroll/generate/temp`, { data: postData }); }

    addEmployeesToPayroll(postData) { return this._httpClient.post(this._baseURL + `payroll/employees/components/payroll`, { data: postData }); }

    generatePayroll(postData) { return this._httpClient.post(this._baseURL + `payroll/generate`, { data: postData }); }

    getCounts(postData) { return this._httpClient.post(this._baseURL + `payroll/activity/counts`, { data: postData }); }

    getEmployeeCounts() { return this._httpClient.get(this._baseURL + `payroll/employees/counts`); }

    getEmployeePublishCounts(postData) { return this._httpClient.post(this._baseURL + `payroll/employees/publish/counts`, { data: postData }); }

    getEmployeesMnthlyList(postData): Observable<any> { return this._httpClient.post(this._baseURL + `payroll/employees/monthly/list`, { data: postData }); }

    publishEmplePyrle(postData): Observable<any> { return this._httpClient.post(this._baseURL + `payroll/publish/employee/payroll`, { data: postData }); }

    publishPyrle(postData): Observable<any> { return this._httpClient.post(this._baseURL + `payroll/publish/payroll`, { data: postData }); }

    getEmplysLvsmryLst(postData) { return this._httpClient.post(this._baseURL + `payroll/employees/lvesmryDtls`, { data: postData }); }
    updEmplysLvsmryLst(postData) { return this._httpClient.post(this._baseURL + `payroll/employees/updLvesmryDtls`, { data: postData }); }

    dwnldPyrl(data) { return this._httpClient.post(this._baseURL + 'payroll/payrollPdfs', { data: data }); }

    // Adjustments

    getAdjstmntsRsns() { return this._httpClient.get(this._baseURL + `payroll/adjustments/reasons`); }
    getEmployeesForAdjustments(postData) { return this._httpClient.post(this._baseURL + `payroll/adjustments/employees`, { data: postData }); }
    getCompForAdjustments() { return this._httpClient.get(this._baseURL + `payroll/adjustments/components`); }
    getYearsForAdjustments() { return this._httpClient.get(this._baseURL + `payroll/adjustments/years`); }
    getPayrollComponents(postData) { return this._httpClient.post(this._baseURL + `payroll/adjustments/payroll/components`, { data: postData }); }
    addPayrollAdjustments(postData) { return this._httpClient.post(this._baseURL + `payroll/adjustments/payroll`, { data: postData }); }


    getAdjustedLst() { return this._httpClient.get(this._baseURL + `payroll/adjustments/list`); }
    getUnpaidLst() { return this._httpClient.get(this._baseURL + `payroll/adjustments/unpaid/list`); }
    getPendingAdjCounts() { return this._httpClient.get(this._baseURL + `payroll/adjustments/pending/counts`); }
    deleteAdjustment(adjsd_emple_prl_id, orgnl_emple_prl_id) { return this._httpClient.delete(this._baseURL + `payroll/adjustments/unpaid/list/${adjsd_emple_prl_id}/${orgnl_emple_prl_id}`); }

    getPendingAdjForEmployees(postData) { return this._httpClient.post(this._baseURL + `payroll/adjustments/pending/list`, { data: postData }); }


    // Overrides
    addOverrideToTemp(postData) { return this._httpClient.post(this._baseURL + `payroll/overrides/temp`, { data: postData }); }
    overrideOverridesToTemp(postData) { return this._httpClient.post(this._baseURL + `payroll/override/overrides/temp`, { data: postData }); }
    deleteOverrides(pyrle_ovrde_ky, emple_prl_cmpnt_ky) { return this._httpClient.delete(this._baseURL + `payroll/overrides/temp/${pyrle_ovrde_ky}/${emple_prl_cmpnt_ky}`); }
    applyOverrides(postData) { return this._httpClient.post(this._baseURL + `payroll/apply/overrides/temp`, { data: postData }); }


    // Hikes
    getHikesLst() { return this._httpClient.get(this._baseURL + `payroll/hikes`); }
    getHikesCategories() { return this._httpClient.get(this._baseURL + `payroll/hikes/category`); }
    addHike(postData) { return this._httpClient.post(this._baseURL + `payroll/hikes`, { data: postData }); }
    getHikesComponents(postData) { return this._httpClient.post(this._baseURL + `payroll/hikes/employee/components`, { data: postData }); }

}
