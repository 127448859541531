<div class="header">
    <div class="logo logo-dark" *ngIf="isFolded == false">
        <a href="">
            <img src="assets/images/wetrackon-03.png" alt="Logo" class="main-header-logo" *ngIf="isFolded == false && hostname != 'APSFL'" style="width:100%">
            <img src="assets/images/apsfl/sidemenu_logo.png" alt="Logo" class="main-header-logo" *ngIf="isFolded == false && hostname == 'APSFL' " style="width:100%">
           
            <!-- <p class="main-header-logo main-header-logo-txt d-n">Track your wagon</p> -->
            <!-- <img class="logo-fold" [src]="clntDtls[0].dsply_lgo_url_tx" alt="Logo" style="    width: 55px;
            height: 65px;
            margin: 5px;"> -->
            <!-- <img class="logo-fold" src="assets/images/logo/icon.png" alt="Logo" *ngIf="isFolded == true" style="width: 60px;"> -->
        </a>
    </div>
    <!-- <div class="logo logo-white">
        <a href="">
            <img src="assets/images/logo/logo-white.png" alt="Logo" >
            <img class="logo-fold" src="assets/images/logo/logo-fold-white.png" alt="Logo">
        </a>
    </div> -->
    <div class="nav-wrap">
        <ul class="nav-left" [ngClass]="isFolded == true ? 'w-75' : ''">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
            <!-- <li>
                <a (click)="searchToggle()">
                    <i nz-icon nzType="search" theme="outline"></i>
                </a>
                <nz-drawer [nzWidth]="380" nzTitle="Search" [nzVisible]="searchVisible" nzPlacement="left" (nzOnClose)="searchToggle()">
                    <app-search></app-search>
                </nz-drawer>
            </li> -->
        </ul>

        <ul  class="nav-center "*ngIf="lgnPgData.app_hdr_nm == 'CLAP'"  >
          <div  class="bgimgsHdr" *ngIf="clntDtls[0].tnts[0].tnt_banr_img_url_tx && usrDtls.clap_in != 1"  [ngStyle]="{'background-image':img }"></div>
            <!-- Commented out the following section as it is not required
            <img *ngIf="lgnPgData.app_hdr_nm == 'CLAP' && clntDtls[0].spr_admn_in != 1" 
                 [src]="lgnPgData.app_hdr_img_url_tx" 
                 class="kndukurLogoImg">
            -->
            <div>
                <!-- <div class=" hdrimgndTxt" *ngIf="clntDtls[0].tnts[0].tnt_banr_img_url_tx && usrDtls.clap_in != 1">
                    <div class="">
                        <img [src]="clntDtls[0].tnts[0].tnt_banr_img_url_tx" alt="" style="margin-top: 6px;">
                    </div>
                </div> -->
                <div class=" hdrimgndTxt" *ngIf="usrDtls.clap_in == 1">
                    <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length >= 1 && clntDtls[0].clnt_banr_img_url_tx">
                        <div  class="bgimgsHdr"  [ngStyle]="{'background-image':clntheaderstrip }"></div>
                    </div>
                    <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length == 1 && clntDtls[0].tnts[0].tnt_banr_img_url_tx ">
                        <div  class="bgimgsHdr"  [ngStyle]="{'background-image':tntheaderstrip }"></div>
                    </div>
                    <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length == 1 && !clntDtls[0].tnts[0].tnt_banr_img_url_tx">    
                        <div class="col-lg-3 col-md-3">
                            <!-- Optional image binding can be uncommented if needed -->
                            <!-- <img [src]="clntDtls[0].tnts[0].tnt_lgo_url_tx"> -->
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h1 class="header-logo-text">{{ clntDtls[0].tnts[0].tnt_dsply_nm }} Municipal Corporation</h1>
                            <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                        </div>
                        <div class="col-lg-3 col-md-3"></div>
                    </div>
                    <div class="row hdrimgndTxt" *ngIf="clntDtls[0].spr_admn_in == 1 && clntDtls[0].clnt_id == 2000002">
                        <div class="col-lg-3 col-md-3"></div>
                        <div class="col-lg-6 col-md-6">
                            <h1 class="header-logo-text">Nagar Nigam {{ clntDtls[0].tnts[0].tnt_dsply_nm }}</h1>
                            <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                        </div>
                        <div class="col-lg-3 col-md-3"></div>
                    </div>
                    <div class="row hdrimgndTxt" *ngIf="clntDtls[0].clnt_id == 24">
                        <div class="col-lg-3 col-md-3"></div>
                        <div class="col-lg-6 col-md-6">
                            <h1 class="header-logo-text">{{ clntDtls[0].tnts[0].tnt_dsply_nm }}</h1>
                            <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                        </div>
                        <div class="col-lg-3 col-md-3"></div>
                    </div>
                </div>
            </div>
           
       
        </ul> 
        
        <ul  class="nav-center bgimgsHdr" *ngIf="lgnPgData.app_hdr_nm == 'WETRACKON'">
            <div  class="bgimgsHdr" *ngIf="clntDtls[0].tnts[0].tnt_banr_img_url_tx && usrDtls.clap_in != 1"  [ngStyle]="{'background-image':img }"></div>
        <div class="row" >
            <!-- <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts[0].tnt_banr_img_url_tx && usrDtls.clap_in != 1">
                <div class="row hdrimgndTxt">
                    <img [src]="clntDtls[0].tnts[0].tnt_banr_img_url_tx" alt="" style="margin-top: 6px;">
                </div>
            </div> -->
            <div class=" hdrimgndTxt" *ngIf="usrDtls.clap_in == 1">
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length >= 1 && clntDtls[0].clnt_banr_img_url_tx">
                    <div  class="bgimgsHdr"   [ngStyle]="{'background-image':clntheaderstrip }"></div>
                </div>
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length == 1 && clntDtls[0].tnts[0].tnt_banr_img_url_tx ">
                    <div  class="bgimgsHdr" [ngStyle]="{'background-image':tntheaderstrip }"></div>
                </div>

              
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length == 1 && !clntDtls[0].tnts[0].tnt_banr_img_url_tx">
                    <div class="col-lg-3 col-md-3"></div> 
                    <div class="col-lg-6 col-md-6">
                        <h1 class="header-logo-text">{{ clntDtls[0].tnts[0].tnt_dsply_nm }} Municipal Corporation</h1>
                        <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                    </div> 
                    <div class="col-lg-3 col-md-3"></div>
                </div>
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].spr_admn_in == 1 && clntDtls[0].clnt_id == 2000002">
                    <div class="col-lg-3 col-md-3"></div>
                    <div class="col-lg-6 col-md-6">
                        <h1 class="header-logo-text">Nagar Nigam {{ clntDtls[0].tnts[0].tnt_dsply_nm }}</h1>
                        <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                    </div>
                    <div class="col-lg-3 col-md-3"></div>
                </div>
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].clnt_id == 24">
                    <div class="col-lg-3 col-md-3"></div>
                    <div class="col-lg-6 col-md-6">
                        <h1 class="header-logo-text">{{ clntDtls[0].tnts[0].tnt_dsply_nm }}</h1>
                        <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                    </div>
                    <div class="col-lg-3 col-md-3"></div>
                </div>
            </div>
        </div>
        </ul>

<!-- {{lgnPgData.app_hdr_nm}} -->
        <ul  class="nav-center bgimgsHdr" *ngIf="lgnPgData?.app_hdr_nm == 'APSFL'">
            <div  class="bgimgsHdr" *ngIf="clntDtls[0].clnt_id == 3000000"  style="background:url('assets/images/apsfl_hdr.png');background-position: center !important;    background-repeat: no-repeat !important;"></div>
        <!-- <div class="row" >
          
            <div class=" hdrimgndTxt" *ngIf="usrDtls.clap_in == 1">
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length >= 1 && clntDtls[0].clnt_banr_img_url_tx">
                    <div  class="bgimgsHdr"   [ngStyle]="{'background-image':clntheaderstrip }"></div>
                </div>
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length == 1 && clntDtls[0].tnts[0].tnt_banr_img_url_tx ">
                    <div  class="bgimgsHdr" [ngStyle]="{'background-image':tntheaderstrip }"></div>
                </div>

              
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].tnts.length == 1 && !clntDtls[0].tnts[0].tnt_banr_img_url_tx">
                    <div class="col-lg-3 col-md-3"></div> 
                    <div class="col-lg-6 col-md-6">
                        <h1 class="header-logo-text">{{ clntDtls[0].tnts[0].tnt_dsply_nm }} Municipal Corporation</h1>
                        <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                    </div> 
                    <div class="col-lg-3 col-md-3"></div>
                </div>
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].spr_admn_in == 1 && clntDtls[0].clnt_id == 2000002">
                    <div class="col-lg-3 col-md-3"></div>
                    <div class="col-lg-6 col-md-6">
                        <h1 class="header-logo-text">Nagar Nigam {{ clntDtls[0].tnts[0].tnt_dsply_nm }}</h1>
                        <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                    </div>
                    <div class="col-lg-3 col-md-3"></div>
                </div>
                <div class="row hdrimgndTxt" *ngIf="clntDtls[0].clnt_id == 24">
                    <div class="col-lg-3 col-md-3"></div>
                    <div class="col-lg-6 col-md-6">
                        <h1 class="header-logo-text">{{ clntDtls[0].tnts[0].tnt_dsply_nm }}</h1>
                        <h2 class="header-logo-sb-text">{{ clntDtls[0].clnt_loc }}</h2>
                    </div>
                    <div class="col-lg-3 col-md-3"></div>
                </div>
            </div>
        </div> -->
        </ul>




        
        <!-- <ul class="nav-right">
            <li>
                <span class=" pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
                    [nzPlacement]="'bottomRight'">
                    <ul style="    list-style-type: none;
                    padding-left: 0px;
                    margin-bottom: 0px;
                    align-items: center;
                    display: flex;
                ">
                        <li> <nz-avatar [nzIcon]="'user'" nzSrc="assets/images/avatars/profile.jpg" class="hdrUsrImg">
                        </nz-avatar></li>
                        <li> <p class="m-b-8 text-dark font-weight-semibold ml-2 shwNmDiv">{{usrDtls.fst_nm}}
                            {{usrDtls.lst_nm}}
                            <i class="font-size-10 dwnarw" nz-icon nzType="down" theme="outline"></i>
                        </p>
                        <p class="m-b-8 text-dark font-weight-semibold ml-2 shwNmDiv">{{usrDtls.lst_lgn_ts}}
                        </p></li>
                    </ul>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" nzSrc="assets/images/avatars/profile.jpg"></nz-avatar>
                                <div class="m-l-10">
                                  
                                </div>
                            </div>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="editprfl()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                                    <span class="m-l-10">Edit Profile</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </li>
        </ul> -->
    </div>
</div>
<style>
    .ant-select {
        background: #ffffff;
        border-radius: 2px;
        border-radius: 3px;
        transition: border 0.3s, box-shadow 0.3s;
        width: 100%;
    }

    .ant-select-multiple .ant-select-selection-item-remove>* {
        line-height: 1;
        color: #737373 !important;
    }
</style>