import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { ApiService } from 'src/app/providers/api.service';
import * as turf from "@turf/turf";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-trip-timeline',
  templateUrl: './trip-timeline.component.html',
  styleUrls: ['./trip-timeline.component.scss']
})
export class TripTimelineComponent implements OnInit {
  userDtls: any;
  trpPnts: any;
  startMarker: any;
  startIcon: any;
  @Input() tripId
  map: any;
  startPBMarker: any;

  constructor(private apiServ: ApiService, public datepipe: DatePipe, private message: NzMessageService) {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
  }
  ngOnInit(): void {
    this.openTimeline() 
    // this.initialize()
  }


 openTimeline() {
    //  this.asrt = v.asrt_nm
    // const modal = document.getElementById('custom-modal') as HTMLIonModalElement;
    // if (modal) {
    //   await modal.present(); // Open the modal
    //   console.log(v);
      var postDta ={
        trp_rn_id:this.tripId
      }
    this.apiServ.post(postDta,`apsfl/Timeline/Trip`).subscribe((res) => {
      if (res['status'] == 200) {
        this.trpPnts = res['data'];
        console.log(this.trpPnts,this.trpPnts.length-1,this.trpPnts[this.trpPnts.length-1].trp_sts_id);
        this.initialize()
        var startIcon = new L.Icon({
          iconUrl: `/assets/images/markers/mapIcons/m/${this.trpPnts[this.trpPnts.length-1].img_upld_txt=='start' ? 'vehicle_intrp.png':'vehicle_trpCpltd.png'}`,
          iconSize: [42, 45],
          iconAnchor: [22, 45],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        });
        this.startPBMarker = L.marker([this.trpPnts[0].img_lat, this.trpPnts[0].img_lng], {
          icon: startIcon
        }).addTo(this.map);
   

      }
      
    })

  }
 



  initialize() {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.map = L.map('map', {
        center: [this.trpPnts[0].img_lat, this.trpPnts[0].img_lng],
        zoom: 13
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      tiles.addTo(this.map);
    } catch (e) {

    }

  }


}
