import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomGridComponent } from './custom-grid.component';
import { DxoSummaryModule } from 'devextreme-angular/ui/nested';
import {
    DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
    DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule, DxDiagramModule,
    DevExtremeModule
} from 'devextreme-angular';

import {
    NgApexchartsModule
} from "ng-apexcharts";
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzModalModule } from 'ng-zorro-antd/modal';



@NgModule({
    declarations: [CustomGridComponent],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DxDataGridModule,
        DxBulletModule,
        DxTemplateModule,
        DxButtonModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxTabPanelModule,
        DxChartModule,
        DxPieChartModule,
        DxTabsModule,
        DxLoadPanelModule,
        DxDiagramModule,
        DevExtremeModule,
        DxoSummaryModule,
        NgApexchartsModule, NzCardModule, NzModalModule,
    ],

    exports: [
        CustomGridComponent
    ]

})

export class CustomGridModule {


}