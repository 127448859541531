import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import { animate } from '@angular/animations';
import { Location, DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormGroup, FormControl, Validator, Validators } from "@angular/forms";
import * as moment from 'moment';
import 'leaflet.heat/dist/leaflet-heat.js';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements OnInit {
  isCollapsed = false;
  opnSdebar: boolean;
  heatform: any;
  assrtGroupList: any = [];
  asrtctry: any = [];
  heatmap;
  date = new Date();
  sdate: Date = new Date();
  edate: Date = new Date();
  strtTm = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  endTm = this.datepipe.transform(this.date, 'yyyy-MM-dd');
  heatmapLayer: any;
  asrtGrps: any = [];
  asrtctgrs: any = [];
  asrtCtgryList: any = [];
  asrt_grp_id;
  asrt_ctgry_id;
  clntDtls: any = [];
  tntLst: any = [];
  selectedtenants: any;
  weeklyHeatMapData: any = [];
  vehicleData: any;
  tdata: any;
  testData: any=[];
  showAll:boolean=false;
  slctdDfltTnt: any;
  usrDtls: any;
  clap_in: any;
  title
  ShowClap: boolean;
  _tntBndLyr;
  tntPlygons;
  constructor(private apiS: ApiService, private router: Router, private ref: ChangeDetectorRef, public datepipe: DatePipe, private message: NzMessageService) {

    this.heatform = new FormGroup({
      ulbs: new FormControl(''),
      lctn: new FormControl(''),
      vhcl: new FormControl(''),
      strtTm: new FormControl(''),
      endTm: new FormControl(''),
      dtrng: new FormControl(''),

    })
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls)
    this.tntLst = this.clntDtls[0].tnts;
    if(this.tntLst.length<=8 && this.tntLst.length>1){
      this.title="Zone"
    }else{
      this.title="ULB"
    }
    if(this.tntLst.length == 1){
      this.selectedtenants =this.tntLst[0].tnt_id;
      this.slctdDfltTnt  = this.selectedtenants;
      console.log(this.selectedtenants);
      this.showAll=false;
    }else{
      this.selectedtenants=0;
      this.slctdDfltTnt  = this.selectedtenants;
      console.log(this.selectedtenants);
      this.showAll=true;
    }
    this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.usrDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
  }

  ngOnInit(): void {
    this.initialize();
    this.opnSdebar = true;
  }
  closeSidebar() {
    this.isCollapsed = true;
    this.opnSdebar = false;
  }
  openSidebar() {
    this.isCollapsed = false;
    this.opnSdebar = true;
  }
  initialize = function () {
    this.usrDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.usrDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.heatmap = L.map('heatmap', {doubleClickZoom: false,maxZoom:18,minZoom:7})
    
      // L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: ''})
      // .addTo(this.heatmap);
      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: ''     
       }).addTo(this.heatmap);

     
    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
    if(this.slctdDfltTnt){
      this.ontntSelects(this.slctdDfltTnt);
    }
    this._tntBndLyr = L.layerGroup();
  };


  disabledDate = (current: Date): boolean => {
    // Can not select days after today
    return current && current > this.date ;
  };

  onChange(result: Date[]): void {
    console.log(this.datepipe.transform(result[0], 'yyyy-MM-dd'));
    let strtDt = this.datepipe.transform(result[0], 'yyyy-MM-dd');
    let endDt = this.datepipe.transform(result[1], 'yyyy-MM-dd');
    console.log(strtDt,endDt);
    this.heatform.get('strtTm').setValue(strtDt);
    this.heatform.get('endTm').setValue(endDt);
  }

  generatemap() {
    var data = {
      "sdate": this.heatform.value.strtTm,
      "edate": this.heatform.value.endTm,
      "asrt_grp_id": this.asrtGrps,
      "asrt_ctgry_id": this.asrtctry
    }
    console.log(data);
    this.genHeatMapLayer(data);
  }

  genHeatMapLayer(data) {
    this.vehicleData = [];

    if(this.heatmapLayer){
      this.heatmap.removeLayer(this.heatmapLayer);
    }
    var min = 1;
    var max = 3910;
    this.apiS.post(data, 'playback/dashboard/heatMap')
      .subscribe((res) => {
        this.weeklyHeatMapData = res['data'];
        console.log(this.weeklyHeatMapData,"this.weeklyHeatMapData")
        for (var i = 0; i < this.weeklyHeatMapData.length; i++) {
          this.vehicleData.push({
            "lat": this.weeklyHeatMapData[i].lat,
            "lng": this.weeklyHeatMapData[i].lng,
            "count":1
          });
        }
        console.log(this.vehicleData,"this.vehicleData")
        this.heatmapLayer = L.heatLayer(this.vehicleData, {
          maxZoom: 15,
          minZoom:8,
          radius: 12,
          maxOpacity: .8,
        })
        .addTo(this.heatmap);


      }, (err) => {
        console.log("In errorr");
      });
  }

  ontntSelects(value) {
    console.log(value);
    this.selectedtenants = value;
    if (value == 0) {
      this.heatmap.setView([this.clntDtls[0].lat, this.clntDtls[0].lng], 8);
      this.heatmap.setMinZoom(7);

    } else {
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == this.selectedtenants) {
          this.heatmap.setView([this.tntLst[i].lat, this.tntLst[i].lng], 13);
          this.heatmap.setMinZoom(9);
          this.plotTntBounds();


        }
      }
    }
    this.apiS.get(`playback/vehicle/groups/${this.selectedtenants}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.assrtGroupList = res['data'];
        console.log(this.assrtGroupList)
      }
    }, (err) => {
      console.log("In errorr");
    })
  }
  getCatLists(grpId) {

    // console.log("gettttttttttttttttttttttttttttt");
    console.log(grpId)

    this.asrt_grp_id = grpId;
    this.asrtGrps = grpId;
    this.asrtCtgryList = [];
    this.asrt_ctgry_id = 0;
    console.log(this.asrtGrps);
    console.log(grpId);

    if (this.asrtGrps == 'select') {
      this.asrtGrps = 0;
      this.asrt_grp_id = 0;
      var asrt_data = {
        "asrt_grp": this.asrtGrps
      };
      // console.log(asrt_data);
      this.apiS.post(asrt_data, 'playback/categories/assets')
        .subscribe((res) => {
          this.asrtCtgryList = res['data'];
          console.log(this.asrtCtgryList);
        }, (err) => {
          console.log("In errorr");
        });
    }
    else if (this.asrtGrps == 'deselect') {
      this.asrtGrps = [];
      return;
    } else if (this.clap_in == 1) {
      var asrt_data = {
        "asrt_grp": this.asrtGrps
      };
      // console.log(asrt_data);
      this.apiS.post(asrt_data, 'playback/categories/assets')
        .subscribe((res) => {
          this.asrtCtgryList = res['data'];
          console.log(this.asrtCtgryList);
        }, (err) => {
          console.log("In errorr");
        });
      return;
    }else {
      var asrt_data = {
        "asrt_grp": this.asrtGrps
      };
      // console.log(asrt_data);
      this.apiS.post(asrt_data, 'playback/categories/assets')
        .subscribe((res) => {
          this.asrtCtgryList = res['data'];
          console.log(this.asrtCtgryList);
        }, (err) => {
          console.log("In errorr");
        });
    }
  };
  // getTypeList(catId) {
  //   this.asrt_ctgry_id = catId;
  //   this.asrtctgrs = catId;

  //   if (this.asrtctgrs == 'select') {
  //     this.asrtctgrs = 0;
  //     this.asrt_ctgry_id = 0;
  //   }

  // };
  closedrvrlst(){
    this.opnSdebar = false
  }
  plotTntBounds() {
    if (this._tntBndLyr != undefined) {
      this._tntBndLyr.clearLayers();
    }
    setTimeout(() => {
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == this.selectedtenants) {
          this.heatmap.setView([this.tntLst[i].lat, this.tntLst[i].lng], 13)
          if(this.tntLst[i].bndry_jsn!=null){
            // console.log(this.tntLst[i].bndry_jsn)
            this.tntPlygons = L.polygon(JSON.parse(this.tntLst[i].bndry_jsn),
            {
              color: '#f1cee0',
              fill: false,
              // weight: 1,
            
            }
          ).addTo(this._tntBndLyr);
          this._tntBndLyr.addTo(this.heatmap);
          }
  
        }
      } 
    }, 2000);

  }
}
