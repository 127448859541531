import { Component, OnInit,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Output() ClickEvent = new EventEmitter<boolean>();
  menulst: any = [];
  constructor() {
    this.menulst = [
      {
        prnt_mnu_itm_id: 1,
        prnt_mnu_itm_nm: 'MY TICKETS',
        sub_mnus: [{
          mnu_itm_nm: 'Created by me',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'C',
          mnu_icn_tx_bg: '#93b5ff',
          mnu_icn_tx_clr: '#0046df'
        }, {
          mnu_itm_nm: 'Assigned to me',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'A',
          mnu_icn_tx_bg: '#fdacbc',
          mnu_icn_tx_clr: '#f20534'
        }]
      }, {
        prnt_mnu_itm_id: 2,
        prnt_mnu_itm_nm: 'STATUS',
        sub_mnus: [{
          mnu_itm_nm: 'New',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'N',
          mnu_icn_tx_bg: '#b6d38a',
          mnu_icn_tx_clr: '#5d7c2e'
        }, {
          mnu_itm_nm: 'Un-Assigned',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'UN',
          mnu_icn_tx_bg: '#ffd59a',
          mnu_icn_tx_clr: '#e68800'
        }, {
          mnu_itm_nm: 'In-Progress',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'P',
          mnu_icn_tx_bg: '#c6a498',
          mnu_icn_tx_clr: '#6f4a3d'
        }, {
          mnu_itm_nm: 'On-Hold',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'H',
          mnu_icn_tx_bg: '#93b5ff',
          mnu_icn_tx_clr: '#0046df'
        }, {
          mnu_itm_nm: 'Closed',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'CL',
          mnu_icn_tx_bg: '#fdacbc',
          mnu_icn_tx_clr: '#f20534'
        }, {
          mnu_itm_nm: 'SLA Breached',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'S',
          mnu_icn_tx_bg: '#b49aff',
          mnu_icn_tx_clr: '#3d00e6'
        }]
      }, {
        prnt_mnu_itm_id: 3,
        prnt_mnu_itm_nm: 'TYPE',
        sub_mnus: [{
          mnu_itm_nm: 'Service Requests',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'SR',
          mnu_icn_tx_bg: '#93b5ff',
          mnu_icn_tx_clr: '#0046df'
        }, {
          mnu_itm_nm: 'Problem Requests',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'PR',
          mnu_icn_tx_bg: '#fdacbc',
          mnu_icn_tx_clr: '#f20534'
        }, {
          mnu_itm_nm: 'Access Requests',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'AR',
          mnu_icn_tx_bg: '#ffd59a',
          mnu_icn_tx_clr: '#e68800'
        }, {
          mnu_itm_nm: 'Outages',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'OT',
          mnu_icn_tx_bg: '#c6a498',
          mnu_icn_tx_clr: '#6f4a3d'
        }, {
          mnu_itm_nm: 'Firewall Requests',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'FR',
          mnu_icn_tx_bg: '#93b5ff',
          mnu_icn_tx_clr: '#0046df'
        }]
      },
      {
        prnt_mnu_itm_id: 4,
        prnt_mnu_itm_nm: 'CATEGORY',
        sub_mnus: [{
          mnu_itm_nm: 'HR',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'HR',
          mnu_icn_tx_bg: '#ffd59a',
          mnu_icn_tx_clr: '#e68800'
        }, {
          mnu_itm_nm: 'IT',
          mnu_itm_url_tx: '',
          mnu_icn_tx: 'IT',
          mnu_icn_tx_bg: '#c6a498',
          mnu_icn_tx_clr: '#6f4a3d'
        }]
      },
    ]
   }

  ngOnInit() {
 //   console.log(this.menulst);
    if (this.menulst[0].prnt_mnu_itm_id) {



      console.log(this.menulst[0].sub_mnus)
      this.item(this.menulst[0].sub_mnus[0].mnu_itm_nm);
    } else
      this.item(this.menulst[0].mnu_itm_nm);
   
  }
  item(data) {
 //   console.log(data);
    // this.ClickEvent=data;
    this.ClickEvent.emit(data);
    // alert("You Clicked Me!");
  }

}
