/**************************************************************************************
* Description    : Dev extreme customized grid
* Change History :
* 26/07/2024   - Krishna Oleti - Initial Code
*
***************************************************************************************/


import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import 'jspdf-autotable';
// import { exportDataGrid } from 'devextreme/excel_exporter';
// import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

import { DsGridOptns } from './grid-optns-interface';
import { ApiService } from 'src/app/providers/api.service';




@Component({
  selector: 'ds-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})




export class CustomGridComponent {

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('gridDialogContent') gridDialogContent: TemplateRef<any>;
  @Input() dsGridOptns: DsGridOptns;
  @Output() gridErrorMsgEmtr = new EventEmitter();
  @Output() onClickActionEvntEmtr = new EventEmitter();

  dataSource = [];
  colDef = [];
  buttons = [];
  minPageSizes: number = 10;
  enableMultiSelect: boolean = false;
  title: string = '';
  exportFormats = [];
  analytics = [];
  gridSettings = null;

  dataGridSlctnInstance = null;
  grdDialogRef = null;

  data = {
    anlytcs_crd_json: [],
    anlytcs_grph_json: [],
    grdSlctdRws: [],
    dataSrcFltrCln: [],
    slctdRowObj: null,
    slctdBtnObj: null,
    dmyChrt: null
  }

  settings = {
    err_msg: '',
    col_def_lvl_ct: 0,
    shw_pg_sz: false,
    pg_sz: 10,
    shw_smry: false,
    smry_arry: [],
    pg_sz_arry: [],
    ttl_rec_cnt: 0,
    row_buttons: [],
    fltr_pnl_enbl: false,
    fltr_grp_pnl_enbl: false,
    loader: false,
    exprt_fl_nm: ``,
    shw_anlytc: false,
    dlg_stngs: {
      alrt_bdy_msg: '',
      alrt_bx_btns: []
    },
    fltr_apld_rst_btn_in: false
  }

  formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  constructor(private apiSrvc: ApiService) { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.prpreCmnpntGrdOptnsInpt();
    this.preprDmyChrt()
  }
  shwCstmAdd: boolean = false;

  prpreCmnpntGrdOptnsInpt() {
    console.log(this.dsGridOptns)
    if (this.dsGridOptns && typeof this.dsGridOptns == 'object') {
      this.dataSource = this.dsGridOptns.dataSource;
      this.colDef = this.dsGridOptns.colDef;
      this.buttons = this.dsGridOptns.buttons;
      this.minPageSizes = this.dsGridOptns.minPageSizes;
      this.enableMultiSelect = this.dsGridOptns.enableMultiSelect;
      this.title = this.dsGridOptns.title;
      this.exportFormats = this.dsGridOptns.exportFormats;
      this.analytics = this.dsGridOptns.analytics;
      this.gridSettings = this.dsGridOptns.gridSettings;

      this.prpreGrdBscInf();
      this.calColDfLevels();

    }

    if (!this.dataSource) {
      this.shwCstmAdd = true;
    }
  }

  calColDfLevels() {

    this.settings.shw_smry = false;
    this.settings.col_def_lvl_ct = 0;
    this.settings.smry_arry = [];
    this.settings.err_msg = ''
    if (this.colDef && Array.isArray(this.colDef) && this.colDef.length) {
      ++this.settings.col_def_lvl_ct;
      this.colDef.filter((c) => {
        this.prpareGrdLevels(c)
      })

      this.prprePagnstnStngs();
      this.prprGrdAnlytcs();
    }
    else {
      if (this.dataSource && this.dataSource.length) {
        this.colDef = [];
      }
      else {
        this.settings.err_msg = 'No data available.'
      }

    }
    this.headrClrChng();
  }

  prpareGrdLevels = (body) => {
    console.log("BODYYYYYYYYYYYYYYYYYYYYYYY ", body)
    let smry_obj = null
    Object.keys(body).filter((k) => {
      if (body[k] && Array.isArray(body[k])) {
        ++this.settings.col_def_lvl_ct;
        body[k].filter((a) => {
          if (a && typeof a == "object") {
            this.prpareGrdLevels(a)
          }
        });
      }
      else {
        if (body['summary'] == true) {
          this.settings.shw_smry = true;
          smry_obj = body;
        }
      }

    });
    if (smry_obj) {
      let valueFormat = {};
      if (smry_obj['crncy_in']) {
        valueFormat = { style: 'currency', currency: 'INR', useGrouping: true, precision: 4 };
      }
      this.settings.smry_arry.push({ crncy_in: smry_obj['crncy_in'], format: this.currencyINRFormater, column: smry_obj['field'], summaryType: smry_obj['summaryType'], summaryDisplayFormat: smry_obj['summaryDisplayFormat'], valueFormat: valueFormat, showInSmryColumnTxt: smry_obj['showInSmryColumnTxt'] })
    }

  }

  currencyINRFormater = (val: string) => {
    return this.formatter.format(parseInt(val, 10));
  }

  prprePagnstnStngs = () => {
    this.settings.shw_pg_sz = false;
    this.settings.pg_sz_arry = [];
    this.settings.ttl_rec_cnt = 0;
    this.settings.fltr_grp_pnl_enbl = false;

    if (this.dataSource && Array.isArray(this.dataSource) && this.dataSource.length) {

      this.settings.fltr_grp_pnl_enbl = true;
      this.settings.ttl_rec_cnt = this.dataSource.length;
      let chnk_sz = _.chunk(this.dataSource, this.settings.pg_sz);
      if (chnk_sz && chnk_sz.length > 1) {
        this.settings.shw_pg_sz = true;
        let grd_pg_sz = this.settings.pg_sz
        this.settings.pg_sz_arry = [grd_pg_sz];
        for (let index = 1; index < chnk_sz.length; index++) {
          grd_pg_sz += grd_pg_sz;
          if (grd_pg_sz >= 100) {
            this.settings.pg_sz_arry.push(100)
            break;
          }
          else {
            this.settings.pg_sz_arry.push(grd_pg_sz)
          }

        }
      }

    }
  }

  prpreGrdBscInf() {
    this.settings.err_msg = '';
    let prst_dt = new Date();
    this.data.dataSrcFltrCln = []
    this.data.dataSrcFltrCln = _.cloneDeep(this.dataSource);
    console.log("innnnnnnnnn", this.data.dataSrcFltrCln)
    if (this.minPageSizes) {
      this.settings.pg_sz = this.minPageSizes;
    }
    else {
      this.settings.pg_sz = 10
    }
    if (this.title) {
      this.settings.exprt_fl_nm = `${this.title}_${prst_dt.getDate()}-${prst_dt.getMonth()}-${prst_dt.getFullYear()}`;
    }
    else {
      this.settings.exprt_fl_nm = `Report_${prst_dt.getDate()}-${prst_dt.getMonth()}-${prst_dt.getFullYear()}`;
    }
    if (!this.exportFormats) {
      this.exportFormats = ['xlsx'];
    }
  }

  onCellClick(event) {

  }

  onToolbarPreparing(e) {
    this.settings.row_buttons = [];
    if (this.buttons && Array.isArray(this.buttons) && this.buttons.length) {
      for (let index = 0; index < this.buttons.length; index++) {
        const element = this.buttons[index];
        if (element['btn_plc'] == 'grid') {
          e.toolbarOptions.items.unshift(
            {
              location: 'after',
              widget: 'dxButton',
              options: {
                icon: element['icn_nm'],
                text: element['label'],
                onClick: this.gridButtonClckEvnt.bind(this, element),

              }
            });
        }
        if (element['btn_plc'] == 'row') {
          let cellTemplate = `cstmTmpltBtn_${index + 1}`;
          let btn_id = `button_${index + 1}`
          this.settings.row_buttons.push({
            label: element['label'],
            btn_type: element['btn_type'],
            width: element['width'],
            btn_id: btn_id,
            color: element['color'],
            icon: element['icn_nm'],
            btn_hndlr_txt: element['btn_hndlr_txt'],
            btn_cnfrm_msg_in: element['btn_cnfrm_msg_in'] || false,
            btn_cnfrm_msg_txt: element['btn_cnfrm_msg_txt'],
            btn_cnfrm_msg_btns: element['btn_cnfrm_msg_btns'],
            cellTemplate: cellTemplate
          })
        }

      }
    }


  }

  onContentReady(e) {

    this.headrClrChng();
  }

  headrClrChng() {
    let elements = document.querySelectorAll(`.dx-datagrid-action`);
    console.log("ELEMENTS ---------------------------------- ", elements)
    // if (elements && elements.length > 0) {
    //   for (let index = 0; index < elements.length; index++) {
    //     const element = elements[index];
    //     element['style']['backgroundColor'] = "red";

    //   }
    // }
  }

  gridButtonClckEvnt(elemnt_data) {
    let row_data = [];
    if (this.data.grdSlctdRws && this.data.grdSlctdRws.length) {
      row_data = this.data.grdSlctdRws;
    }

    this.onClickActionEvntEmtr.emit({
      actn_type: 'grid_button_click',
      clckd_btn_hndlr: elemnt_data['btn_hndlr_txt'],
      cnfrm_cb_res_cd: null,
      row_data: row_data
    })
  }

  onRowBtnClkEvnt(data, btn_obj) {
    this.data.slctdRowObj = data;
    this.data.slctdBtnObj = btn_obj;
    if (btn_obj['btn_cnfrm_msg_in']) {
      this.settings.dlg_stngs.alrt_bdy_msg = btn_obj['btn_cnfrm_msg_txt'];
      this.settings.dlg_stngs.alrt_bx_btns = btn_obj['btn_cnfrm_msg_btns'];
      this.prpreDlgBxBtns();
      this.openGrdDialog();
    }
    else {
      this.onClickActionEvntEmtr.emit({
        actn_type: 'row_button_click',
        clckd_btn_hndlr: btn_obj['btn_hndlr_txt'],
        cnfrm_cb_res_cd: null,
        row_data: data
      })
    }

  }

  // onExporting(e) {
  //   this.settings.loader = true;
  //   if (e.format === 'xlsx') {
  //     const workbook = new Workbook();
  //     const worksheet = workbook.addWorksheet(this.settings.exprt_fl_nm);
  //     const rpt_nm = this.settings.exprt_fl_nm;
  //     var PromiseArray = [];
  //     exportDataGrid({
  //       component: e.component,
  //       worksheet,
  //       autoFilterEnabled: true,
  //       loadPanel: {
  //         enabled: false
  //       },
  //       customizeCell: (options) => {
  //         var { excelCell, gridCell } = options;
  //         if (gridCell.rowType === "data") {
  //           if (gridCell.column.dataField === "imge_url_tx") {
  //             excelCell.value = undefined;
  //             PromiseArray.push(
  //               new Promise((resolve, reject) => {
  //                 this.addImage(gridCell.value ? this.apiSrvc.getConcatServerFilePath(gridCell.value) : null, workbook, worksheet, excelCell, resolve, reject);
  //               })
  //             );
  //           }
  //         }
  //       }
  //     }).then(function () {
  //       Promise.all(PromiseArray)
  //         .then(() => {
  //           workbook.xlsx.writeBuffer().then((buffer) => {
  //             saveAs(new Blob([buffer], { type: 'application/octet-stream' }), rpt_nm + '.xlsx');
  //             loadFun();
  //           });
  //         });
  //     })
  //     e.cancel = true;
  //   }
  //   else if (e.format === 'pdf') {
  //     const doc = new jsPDF("l", "mm", "a3", true);
  //     doc.setTextColor("Blue");
  //     doc.text('', 15, 18, { align: "center" });
  //     doc.setFontSize(10);
  //     doc.setTextColor(100);
  //     doc.text(this.settings.exprt_fl_nm, 170, 23, { align: "center" });
  //     doc.setFontSize(8);
  //     exportDataGridToPdf({
  //       jsPDFDocument: doc,
  //       component: e.component,
  //       topLeft: { x: 15, y: 30 },
  //       customizeCell(options) {
  //         const { gridCell } = options;
  //         const { pdfCell } = options;
  //         if (gridCell.rowType === 'header') {
  //           pdfCell.textColor = '#000000'
  //         }
  //       },
  //     }).then(() => {
  //       doc.save(this.settings.exprt_fl_nm + '.pdf');
  //       loadFun();
  //     })
  //   }
  //   var loadFun = () => {
  //     this.settings.loader = false;
  //   }

  // }

  addImage(url, workbook, worksheet, excelCell, resolve, reject) {
    if (url) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          if (reader.result != null) {
            var base64data = reader.result;
            const image = workbook.addImage({
              base64: base64data,
              extension: 'jpeg',
            })
            worksheet.getRow(excelCell.row).height = 75;
            worksheet.addImage(image, {
              tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
              br: { col: excelCell.col, row: excelCell.row }
            })
            resolve();
          }
          else {
            reject('image not found');
          }

        }
      }
      xhr.onerror = function () {
        console.error('could not add image to excel cell', xhr)
      };
      xhr.send();
    }
    else {
      resolve();
    }
  }

  prprGrdAnlytcs() {
    this.data.anlytcs_crd_json = [];
    this.settings.shw_anlytc = false;
    if (this.analytics && Array.isArray(this.analytics) && this.analytics.length) {
      this.settings.shw_anlytc = true;
      for (let index = 0; index < this.analytics.length; index++) {
        const element = this.analytics[index];
        if (element['anlytc_type'] && element['anlytc_type'].length) {
          let crd_enbl = false;
          let grph_enbl = false;

          for (let sub_index = 0; sub_index < element['anlytc_type'].length; sub_index++) {
            const sub_element = element['anlytc_type'][sub_index];
            if (sub_element == 'cards') {
              crd_enbl = true;
            }
            if (sub_element == 'graph') {
              grph_enbl = true;
            }
          }
          this.prpreCardsAnlytcs(element, crd_enbl, grph_enbl);
        }
      }
    }
  }

  prpreCardsAnlytcs(card_elmnts, crd_enbl, grph_enbl) {

    for (let index = 0; index < card_elmnts['anlytc_cards'].length; index++) {
      const card_elmnt = card_elmnts['anlytc_cards'][index];
      let anlytc_grp_key = card_elmnt['anlytc_grp_key'];
      let crd_hdr_nm = '';
      if (card_elmnt['anlytc_grp_title']) {
        crd_hdr_nm = card_elmnt['anlytc_grp_title'];
      }
      else {
        crd_hdr_nm = this.getColumnHeaderName(anlytc_grp_key);
      }



      let crd_val = [];
      if (card_elmnt['anlytc_grp_typ'] == 'group_count') {
        _.forIn(_.groupBy(this.dataSource, anlytc_grp_key), (value, key) => {
          if (card_elmnt['anlytc_grp_typ'] == 'group_count') {
            crd_val.push({
              title: key,
              fltr_key_nm: anlytc_grp_key,
              fltr_typ: 'group_count',
              fltr_val: value[0][anlytc_grp_key],
              cnt_val: value.length,
              sb_anltc_cnts: this.subGrpAnltcs(card_elmnt, value)
            })
          }
        });
      }
      else if (card_elmnt['anlytc_grp_typ'] == 'count') {
        let count = 0;
        this.dataSource.filter((v) => {
          if (v[anlytc_grp_key]) {
            ++count;
          }
        })
        crd_val.push({
          title: this.getColumnHeaderName(anlytc_grp_key),
          fltr_key_nm: anlytc_grp_key,
          fltr_typ: '',
          fltr_val: '',
          cnt_val: count
        });

      }
      else if (card_elmnt['anlytc_grp_typ'] == 'sum') {
        let sum = 0;
        this.dataSource.filter((v) => {
          if (v[anlytc_grp_key] && typeof v[anlytc_grp_key] == 'number') {
            sum += v[anlytc_grp_key];
          }
        })
        crd_val.push({
          title: this.getColumnHeaderName(anlytc_grp_key),
          fltr_key_nm: anlytc_grp_key,
          fltr_typ: 'sum',
          fltr_val: '',
          cnt_val: sum
        });

      }



      this.data.anlytcs_crd_json.push({
        shw_crds_in: crd_enbl,
        shw_grph_in: grph_enbl,
        vw_type: 'card',
        crd_hdr_nm: crd_hdr_nm,
        crd_val: crd_val
      });

    }


    if (grph_enbl) {
      this.preprGrphAnlytcs(card_elmnts)
    }

    console.log("DATA ANALYTICS ----------------------------- ", this.data.anlytcs_crd_json)
  }

  subGrpAnltcs(card_elmnt, grp_val) {
    let sb_crd_val = []
    if (card_elmnt['anlytc_grp_sub_keys'] && card_elmnt['anlytc_grp_sub_keys'].length) {
      card_elmnt['anlytc_grp_sub_keys'].filter((s) => {

        if (s['anlytc_sub_typ'] == 'group_count') {
          _.forIn(_.groupBy(grp_val, s['anlytc_sub_key']), (s_value, s_key) => {
            sb_crd_val.push({
              title: s_key,
              fltr_typ: 'group_count',
              fltr_key_nm: s['anlytc_sub_key'],
              fltr_val: s_value[0][s['anlytc_sub_key']],
              cnt_val: s_value.length
            })
          })
        }
        else if (card_elmnt['anlytc_grp_typ'] == 'count') {
          let count = 0;
          this.dataSource.filter((v) => {
            if (v[s['anlytc_sub_key']]) {
              ++count;
            }
          })
          sb_crd_val.push({
            title: this.getColumnHeaderName(s['anlytc_sub_key']),
            fltr_key_nm: s['anlytc_sub_key'],
            fltr_typ: 'count',
            fltr_val: '',
            cnt_val: count
          });
        }
        else if (s['anlytc_sub_typ'] == 'sum') {
          let sum = 0;
          grp_val.filter((v) => {
            if (v[s['anlytc_sub_key']] && typeof v[s['anlytc_sub_key']] == 'number') {
              sum += v[s['anlytc_sub_key']];
            }
          })
          sb_crd_val.push({
            title: this.getColumnHeaderName(s['anlytc_sub_key']),
            fltr_typ: 'sum',
            fltr_key_nm: s['anlytc_sub_key'],
            fltr_val: '',
            cnt_val: sum
          });
        }
      })
    }

    console.log("SUB ANLYTICS ------------------------------ ", sb_crd_val);
    return sb_crd_val;
  }

  getColumnHeaderName(key_nm) {
    let hdr_nm = '';
    for (let index = 0; index < this.colDef.length; index++) {
      const element = this.colDef[index];
      if (element['field'] == key_nm) {
        hdr_nm = element['headerName'];
        break;
      }
    }
    return hdr_nm;
  }

  saveGridInstance(evnt) {
    this.dataGridSlctnInstance = evnt.component;
  }

  selectionChanged(event: any) {
    this.data.grdSlctdRws = event.selectedRowKeys ? event.selectedRowKeys : event;
  }

  fltrDataGrd(fltr_obj1, fltr_obj2) {

    this.data.anlytcs_crd_json.filter((a) => {
      if (a['crd_val']) {
        a['crd_val'].filter((c) => {
          c['crd_hghlt_in'] = false;
          if (c['sb_anltc_cnts']) {
            c['sb_anltc_cnts'].filter((s) => {
              s['crd_hghlt_in'] = false;
            })
          }
        })
      }
    })

    this.settings.fltr_apld_rst_btn_in = true;
    if (fltr_obj1['fltr_typ'] == 'group_count') {
      let fltr_json = [];
      this.data.dataSrcFltrCln.filter((k) => {
        if (k[fltr_obj1['fltr_key_nm']] == fltr_obj1['fltr_val']) {

          if (fltr_obj2 && fltr_obj2['fltr_typ'] == 'group_count') {
            if (k[fltr_obj2['fltr_key_nm']] == fltr_obj2['fltr_val']) {
              fltr_obj2['crd_hghlt_in'] = true;
              fltr_json.push(k);
            }
          }
          else {
            fltr_obj1['crd_hghlt_in'] = true;
            fltr_json.push(k)
          }
        }
      });
      this.dataSource = fltr_json;
    }
    this.prprePagnstnStngs();
  }

  toggleAnltcView(crd_obj) {
    if (crd_obj['vw_type'] == 'card') {
      crd_obj['vw_type'] = 'graph'
    }
    else if (crd_obj['vw_type'] == 'graph') {
      crd_obj['vw_type'] = 'card'
    }
  }

  openGrdDialog(): void {
    // this.grdDialogRef = this.dialog.open(this.gridDialogContent, {
    //   width: '380px',
    //   // height: '400px',
    // });
    this.isVisible = true



    // this.modal.create({
    //   nzTitle: 'Dialog Title',
    //   nzContent: DialogComponent,
    //   nzWidth: '250px',
    //   nzComponentParams: { name: 'Angular' },
    //   nzOnOk: () => console.log('OK clicked'),
    //   nzOnCancel: () => console.log('Cancel clicked'),
    // });

  }

  isVisible = true
  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
      this.onClickActionEvntEmtr.emit({
        actn_type: 'row_button_click',
        clckd_btn_hndlr: this.data.slctdBtnObj['btn_hndlr_txt'],
        cnfrm_cb_res_cd: null,
        row_data: this.data.slctdRowObj
      })
  }

  prpreDlgBxBtns() {
    if (this.settings.dlg_stngs.alrt_bx_btns && this.settings.dlg_stngs.alrt_bx_btns.length) {
      this.settings.dlg_stngs.alrt_bx_btns = _.orderBy(this.settings.dlg_stngs.alrt_bx_btns, 'sqnc', 'asc')
      this.settings.dlg_stngs.alrt_bx_btns.filter((k) => {
        if (k['title']) {
          k['color'] = 'blue';
          let text = '' + k['title']
          let ok_result = text.match(/ok/gi);
          if (ok_result) {
            k['color'] = 'primary';
          }

          let cncl_result = text.match(/cancel/gi);
          if (cncl_result) {
            k['color'] = 'warn';
          }

          let del_result = text.match(/delete/gi);
          if (del_result) {
            k['color'] = 'warn';
          }
        }
      })
    }
  }

  onBtnClick(res): void {
    this.grdDialogRef.close(res);

  }

  clrGrdFltrs() {
    this.data.anlytcs_crd_json.filter((a) => {
      if (a['crd_val']) {
        a['crd_val'].filter((c) => {
          c['crd_hghlt_in'] = false;
          if (c['sb_anltc_cnts']) {
            c['sb_anltc_cnts'].filter((s) => {
              s['crd_hghlt_in'] = false;
            })
          }
        })
      }
    })
    this.dataSource = _.cloneDeep(this.data.dataSrcFltrCln);
    this.prprePagnstnStngs();
  }

  getGrphLabels = (crd_val, lbl_kys, grph_typ) => {
    let lbls = [];
    if (Array.isArray(lbl_kys)) {
      lbl_kys.filter((a) => {
        let lbl_obj = null
        if (crd_val && crd_val.length) {
          crd_val.filter((l) => {
            if (l['fltr_key_nm'] == a) {
              lbl_obj = { lbl: l['fltr_val'], key: a }
            }
            if (l['sb_anltc_cnts'] && l['sb_anltc_cnts'].length) {
              l['sb_anltc_cnts'].filter((s) => {
                if (s['fltr_key_nm'] == a) {
                  lbl_obj = { lbl: l['fltr_val'], key: a }
                }
              })
            }
          })
        }
        if (lbl_obj) {
          lbls.push(lbl_obj)
        }
      });
    }
    else {
      if (crd_val && crd_val.length) {
        crd_val.filter((l) => {
          if (l['fltr_key_nm'] == lbl_kys) {
            lbls.push({ lbl: l['fltr_val'], key: lbl_kys })
          }
          if (l['sb_anltc_cnts'] && l['sb_anltc_cnts'].length) {
            l['sb_anltc_cnts'].filter((s) => {
              if (s['fltr_key_nm'] == lbl_kys) {
                lbls.push({ lbl: l['fltr_val'], key: lbl_kys })
              }
            })
          }
        })
      }
    }

    return lbls;
  }

  getPieGrphDataSrs = (crd_val, data_keys, grph_typ) => {
    let srs = [];
    if (Array.isArray(data_keys)) {
      data_keys.filter((d) => {
        if (crd_val && crd_val.length) {
          crd_val.filter((l) => {
            if (l['fltr_key_nm'] == d) {
              srs.push(l['cnt_val'])
            }
            if (l['sb_anltc_cnts'] && l['sb_anltc_cnts'].length) {
              l['sb_anltc_cnts'].filter((s) => {
                if (s['fltr_key_nm'] == d) {
                  srs.push(l['cnt_val'])
                }
              })
            }
          })
        }
      })
    }
    else {
      if (crd_val && crd_val.length) {
        crd_val.filter((l) => {
          if (l['fltr_key_nm'] == data_keys) {
            srs.push(l['cnt_val'])
          }
          if (l['sb_anltc_cnts'] && l['sb_anltc_cnts'].length) {
            l['sb_anltc_cnts'].filter((s) => {
              if (s['fltr_key_nm'] == data_keys) {
                srs.push(l['cnt_val'])
              }
            })
          }
        })
      }
    }


    return srs;
  }

  getBarGrphDataSrs = (crd_val, data_keys, grph_typ) => {
    let srs = [];
    if (Array.isArray(data_keys)) {
      data_keys.filter((d) => {
        let brs_src_obj = [];
        // {
        //   name: '',
        //   data: []
        // }
        if (crd_val && crd_val.length) {
          crd_val.filter((l) => {
            if (l['fltr_key_nm'] == d) {

            }
            if (l['sb_anltc_cnts'] && l['sb_anltc_cnts'].length) {
              l['sb_anltc_cnts'].filter((s) => {
                if (s['fltr_key_nm'] == d) {

                }
              })
            }
          })
        }
      })
    }



    return srs;
  }

  prprePieChrtGrpAnltcs(grph_obj) {
    let lbls = [];
    let data_srs = [];
    _.forIn(_.groupBy(this.dataSource, grph_obj['grph_grp_key']), (value, key) => {
      lbls.push(key)
      if (grph_obj['grph_data_keys'] && grph_obj['grph_data_keys'].length) {
        grph_obj['grph_data_keys'].filter((g) => {
          if (g['anltc_typ'] == 'count') {
            data_srs.push(value.length)
          }
          else if (g['anltc_typ'] == 'sum') {
            let sum = 0;
            value.filter((v) => {
              if (g['dt_key'] && typeof g['dt_key'] == 'number') {
                sum += g['dt_key'];
              }
            })
            data_srs.push(sum)
          }
        })
      }
    });
    return { lables: lbls, series: data_srs }
  }


  prpreBarChrtGrpAnltcs(grph_obj) {
    let lbls = [];
    let data_srs = [];
    let grp_data = [];
    _.forIn(_.groupBy(this.dataSource, grph_obj['grph_grp_key']), (value, key) => {
      lbls.push(key);
      grp_data.push({ key: key, data: value })
    });

    grph_obj['grph_data_keys'].filter((g) => {
      let srs_obj = {
        name: g['label'],
        data: []
      }
      lbls.filter((l) => {
        if (g['anltc_typ'] == 'count') {
          grp_data.filter((d) => {
            if (l == d['key']) {
              srs_obj.data.push(d.data.length)
            }
          })
        }
        // else if (g['anltc_typ'] == 'group_count') {
        //   grp_data.filter((d) => {
        //     if(l == d['key'])
        //     {
        //       let srs_obj = {
        //         name: '',
        //         data: []
        //       }
        //       _.forIn(_.groupBy(d.data, g['dt_key']), (value, key) => {
        //         srs_obj = {
        //           name: key,
        //           data: []
        //         }
        //       });
        //       // srs_obj.data.push(d.data.length)
        //     }
        //   })
        // }
      })
      data_srs.push(srs_obj)
    });

    // if (grph_obj['grph_data_keys'] && grph_obj['grph_data_keys'].length) {
    //   grph_obj['grph_data_keys'].filter((g) => {
    //     if (g['anltc_typ'] == 'count') {
    //       data_srs.push({ name : g['label'], data : [value.length]})
    //     }
    //     else if (g['anltc_typ'] == 'sum') {
    //       let sum = 0;
    //       value.filter((v) => {
    //         if (g['dt_key'] && typeof g['dt_key'] == 'number') {
    //           sum += g['dt_key'];
    //         }
    //       })
    //       data_srs.push(sum)
    //     }
    //   })
    // }
    return { lables: lbls, series: data_srs }
  }


  preprGrphAnlytcs(grph_elmnt) {

    if (grph_elmnt['anlytc_graphs'] && grph_elmnt['anlytc_graphs'].length) {


      for (let index = 0; index < grph_elmnt['anlytc_graphs'].length; index++) {
        const grph_obj = grph_elmnt['anlytc_graphs'][index];
        let anlytc_grp_key = grph_obj['anlytc_grp_key'];
        let crd_hdr_nm = '';
        if (grph_obj['anlytc_grp_title']) {
          crd_hdr_nm = grph_obj['anlytc_grp_title'];
        }
        else {
          crd_hdr_nm = this.getColumnHeaderName(anlytc_grp_key);
        }

        if (grph_obj['grph_typ'] == 'pie') {
          let chrt_data = this.prprePieChrtGrpAnltcs(grph_obj);
          let chartOptions = {
            series: chrt_data.series,
            title: grph_obj['grph_title'],
            chart: {
              width: 320,
              type: grph_obj['grph_typ']
            },
            legend: {
              show: true,
              position: 'bottom',
              floating: true,
              verticalAlign: 'bottom',
              align: 'center'
            },
            labels: chrt_data.lables,
            responsive: []
          };
          this.data.anlytcs_grph_json.push({ chartOptions: _.cloneDeep(chartOptions) })
        }
        else if (grph_obj['grph_typ'] == 'bar') {
          let chrt_data = this.prpreBarChrtGrpAnltcs(grph_obj);
          let chartOptions = {
            series: chrt_data.series,
            title: grph_obj['grph_title'],
            chart: {
              width: 380,
              type: grph_obj['grph_typ']
            },
            legend: {
              show: true,
              position: 'bottom',
              floating: true,
              verticalAlign: 'bottom',
              align: 'center'
            },
            labels: chrt_data.lables,
            responsive: []
          };
          this.data.anlytcs_grph_json.push({ chartOptions: _.cloneDeep(chartOptions) })
        }
      }

    }

  }

  preprDmyChrt() {
    this.data.dmyChrt = {
      series: [1, 2],
      title: '',
      chart: {
        width: 320,
        type: 'pie'
      },
      legend: {
        show: true,
        position: 'bottom',
        floating: true,
        verticalAlign: 'bottom',
        align: 'center'
      },
      labels: ['a', 'b'],
      responsive: []
    };
  }
}
