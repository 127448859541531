import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import { DsMapService } from './ds-map.service';
import 'leaflet-textpath';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'ds-map',
  templateUrl: './ds-map.component.html',
  styleUrls: ['./ds-map.component.scss']
})
export class MapsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() in_map_data: any = {
    ste_id: 27, dbrd_lvl: null,
    dstrt_id: null, ulb_id: null,
    mndl_id: null, urbn_in: 1,
    vlge_id: null, svm_id: null, heatGenData: [],
  };
  @Input() height;
  @Input() mapId;
  @Output() updtdData: EventEmitter<any> = new EventEmitter<any>();
  @Output() hovrData: EventEmitter<any> = new EventEmitter<any>();




  map;
  showLdr; boolean;
  data: any = {
    dstElmtns: [],
    layoutsData: [],
    popCrdDtls: { txt: '', popupTxt: '' }
  };
  _elmntLyr;
  elmntsPlygons: any;
  _dstrtLyr;
  _dstNmMrkrLyr;
  _tempDstLyr;
  _dst_temp_polgon;
  dstPlygons: any;
  _ulbLyr;
  _temp_ulb_polygon
  ulbPolygons: any;
  userDtls: any = {};
  ap_map_bounds;
  _stBndPlgn;
  stPlygn;
  _ulbMrkrLyr: any;
  _tempUlbLyr: any;
  slctDstrt;
  emitTimeout;
  slctdUlb: any;
  lyrPolygn;
  _grpLytLyr;
  _lytMrkrLyr;
  slctdLctn = 0;
  // savedMap: L.Map;
  settings: any = {
    shwLdg: true
  }
  shwPopCrd: boolean = false;
  slctdData;
  slctdDstrt_nm: any;
  mapHeight: any;
  ngStyle: string;
  slctHeatKey: any;
  tableData: [];
  slctdKeydata: any = [];
  constructor(private mapSrvce: DsMapService, private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer
  ) {

  }
  ngOnInit(): void {
    console.log('hereeeeeeeeeee');
    // this.getUserDtls();
    this.initialize();
    this.getMapElements();
    // this.ngStyle = "'{height:" + this.height + "vh}'"
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.in_map_data);
    // console.log(this.height)
    // document.documentElement.style.setProperty('--height', this.height + 'vh');

    if (changes['in_map_data']) {


      if (this.in_map_data.dbrd_lvl == 0 && this.map) {
        console.log('in level 0 and map exists')
        this.handleStateLevel();
      } else if (this.in_map_data.dbrd_lvl == 0 && !this.map) {
        console.log('in level 0 and map not exists')
        setTimeout(() => {
          this.initialize();
        }, 100);
        this.handleStateLevel();
      }
    }
    if (this.in_map_data.dbrd_lvl == 0) {
      if (this.in_map_data.keysShared && this.in_map_data.keysShared.length > 0) {
        this.plotSlctdHeatKey(this.in_map_data.keysShared[0]);
      } else if (this.in_map_data.keysShared && this.in_map_data.keysShared.length) {
        this.buildForScale();
      }
    }
    // if (this.shouldEmitUpdtdData(changes)) {
    //   this.slctDstrt = [];
    //   if (this.in_map_data.dbrd_lvl == 1) {
    //     this.handleDistrictLevel();
    //   } else if (this.in_map_data.dbrd_lvl == 2) {
    //     let slctdULb = this.data.ulbElmnts.filter(item => item.ulb_id === this.in_map_data.ulb_id);
    //     this.plotSingleULB(slctdULb[0].elmnt_id);
    //   }

    //   // Emit the data only once after changes are handled
    //   this.debounceEmitUpdtdData();
    // }
  }
  handleStateLevel() {
    if (this._tempDstLyr) this._tempDstLyr.clearLayers();
    if (this._ulbLyr) this._ulbLyr.clearLayers();
    if (this._ulbMrkrLyr) this._ulbMrkrLyr.clearLayers();
    if (this._tempUlbLyr) this._tempUlbLyr.clearLayers();
    if (this._lytMrkrLyr) this._lytMrkrLyr.clearLayers();
    this.stateBounds();
    this.getMapElements();
  }

  handleDistrictLevel() {
    console.log('in ULB level after clicking back');
    if (this._tempUlbLyr) this._tempUlbLyr.clearLayers();
    if (this._lytMrkrLyr) this._lytMrkrLyr.clearLayers();
    this.plotDstrict();
    this.slctDstrt = this.data.dstElmtns.filter(item => item.dstrt_id === this.in_map_data.dstrt_id);
    console.log(this.slctDstrt)
    if (this.slctDstrt && this.slctDstrt.length === 1) {
      this.onDstrtClk(this.slctDstrt[0].elmnt_id);
    }
  }

  debounceEmitUpdtdData() {
    clearTimeout(this.emitTimeout);
    this.emitTimeout = setTimeout(() => {
      this.updtdData.emit(this.in_map_data);  // Emit data only once after changes are handled
    }, 300); // Adjust the delay as needed
  }

  shouldEmitUpdtdData(changes: SimpleChanges): boolean {
    // Add logic to check whether an update is needed based on changes
    if (changes['in_map_data']) {
      const prevValue = changes['in_map_data'].previousValue;
      const currentValue = changes['in_map_data'].currentValue;

      // console.log(prevValue, currentValue)
      if (prevValue != undefined)
        // Only emit if there's a significant change
        return prevValue.dbrd_lvl !== currentValue.dbrd_lvl || prevValue.dstrt_id !== currentValue.dstrt_id || prevValue.ulb_id !== currentValue.ulb_id;
      else {
        return true;
      }
    }
    return true;
  }
  getUserDtls() {
    // this.userDtls = JSON.parse(localStorage.getItem("userdata")) || {};
    // console.log(this.userDtls)
    // if (this.userDtls.hrchy_id == 4) { // State
      this.in_map_data.dbrd_lvl = 0;
      this.in_map_data.dstrt_id = 0;
      this.in_map_data.ulb_id = 0;
    // }
    //  else if (this.userDtls.hrchy_id == 2) {// District
    //   this.in_map_data.dstrt_id = this.userDtls.hyrchy_ky;
    //   // this.in_map_data.dbrd_lvl = 1;
    //   this.in_map_data.ulb_id = 0;
    // } else if (this.userDtls.hrchy_id == 5) {// ULB
    //   // this.in_map_data.dbrd_lvl = 2;
    // }

  }
  initialize() {
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS ----------//
    try {
      if (this.map) {
        console.log('Map is already initialized.');
        return;
      }
      this.map = L.map(this.mapId, {
        zoomControl: false,
        trackResize: false,
        scrollWheelZoom: false,  // Disable zooming with the scroll wheel
        doubleClickZoom: false,  // Disable zooming by double-clicking
        boxZoom: false,          // Disable zooming by dragging a box
        touchZoom: false,        // Disable zooming with touch gestures
        attributionControl: false,
        dragging: false,
        maxZoom: 20,
        minZoom: 3,
      });


      // const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      //   maxZoom: 20,
      //   minZoom: 3,
      //   subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      //   attribution: ''
      // });

      // tiles.addTo(this.map);
      let apstateBounds = [[19.0, 77.0],
      [19.0, 84.5],
      [12.5, 84.5],
      [12.5, 77.0]];
      // Create a LatLngBounds object from the coordinates
      let bounds = L.latLngBounds(apstateBounds);
      // Get the zoom level for the bounds
      let zoomLevel = this.map.getBoundsZoom(bounds) + 0.49;
      // console.log(zoomLevel, bounds.getCenter())
      this.map.setView([bounds.getCenter().lat, bounds.getCenter().lng], zoomLevel);


    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }

    this.initializeLayers();
    // else {
    //   console.log('inmap initialize getting layouts');
    //   this.getLayouts();

    // }
    // this.getMapElements();

  }
  initializeLayers() {
    this._elmntLyr = L.layerGroup();
    this._dstrtLyr = L.layerGroup();
    this._ulbLyr = L.layerGroup();
    this._dstNmMrkrLyr = L.layerGroup();
    this._tempDstLyr = L.layerGroup();
    this._ulbMrkrLyr = L.layerGroup();
    this._tempUlbLyr = L.layerGroup();
    this._grpLytLyr = L.layerGroup();
    this._lytMrkrLyr = L.layerGroup();
  }
  stateBounds() {
    this._stBndPlgn = L.layerGroup();
    let options = {
      color: 'none',
      fill: false
    }
    this.stPlygn = L.polygon([
      [19.0, 77.0],
      [19.0, 84.5],
      [12.5, 84.5],
      [12.5, 77.0]
    ], options).addTo(this._stBndPlgn);
    // state coordinates with corner bounds
    this._stBndPlgn.addTo(this.map)
    this.map.setView([this.stPlygn.getCenter().lat, this.stPlygn.getCenter().lng], 6.48);
    // this.map.fitBounds(this._stBndPlgn.getBounds())

    // console.log(this.stPlygn.getCenter().lat, this.stPlygn.getCenter().lng)

    this.ap_map_bounds = this.stPlygn.getBounds();
    this.setBounds();

  }
  getMapElements() {
    let postData = {
      ste_id: 27,
      dstrt_id: 0,
      ulb_id: this.in_map_data.ulb_id,
      mndl_id: this.in_map_data.ulb_id,
      urbn_in: 1,
      vlge_id: null,
      svm_id: null
    }
    this.showLdr = true;
    this.mapSrvce.getMapElements(postData).subscribe((res) => {
      console.log(res)

      this.data.elmntsData = res.data;
      this.showLdr = false;
      // this.plotDstrict();
      if (this._dstrtLyr != undefined) {
        this._dstrtLyr.clearLayers();
      }
      if (this._ulbLyr != undefined) {
        this._ulbLyr.clearLayers();
      }
      this.plotDstrict();
    })
    if (this.map) {
      this.map.invalidateSize();
    }
  }
  plotDstrict() {
    const that: any = this;
    this.settings.shwLdg = true;
    if (this._dstrtLyr != undefined) {
      this._dstrtLyr.clearLayers();
    }
    if (this._dstNmMrkrLyr != undefined) {
      this._dstNmMrkrLyr.clearLayers();
    }

    this.data.dstElmtns = [];
    if (this.data.elmntsData && this.data.elmntsData.length) {
      // this.data.dstElmtns = this.data.elmntsData.filter(item => item.elmnt_grp_id == 1)
      if (this.in_map_data.dstrt_id > 0) {
        this.data.elmntsData.forEach(e => {
          if (e.elmnt_grp_id == 1 && e.dstrt_id == this.in_map_data.dstrt_id) {
            this.slctdDstrt_nm = e.elmnt_nm;

            this.data.dstElmtns.push(e);
          }
        });
      } else {
        this.data.dstElmtns = this.data.elmntsData.filter(item => item.elmnt_grp_id == 1)
      }

      if (this.data.dstElmtns.length > 0) {
        // console.log(this.data.dstElmtns[0])
        for (let d = 0; d < this.data.dstElmtns.length; d++) {
          if (this.data.dstElmtns[d].bndry_jsn != null) {
            this.data.dstElmtns[d].id = d + 1;
            // let previousPolygon = null;
            this.data.dstElmtns[d].opacity = 0.3;
            // this.slctdKeydata=[];
            if (this.in_map_data.heatGenData != undefined && this.in_map_data.heatGenData.length > 0 && this.in_map_data.keysShared.length == 0) {
              for (let c = 0; c < this.in_map_data.heatGenData.length; c++) {
                // console.log(this.in_map_data.heatGenData[c], 'evtttttttttttttttttttttt');
                // if (this.in_map_data.keysShared.length == 0) {

                if ((this.in_map_data.heatGenData[c].dstrt_id == this.data.dstElmtns[d].dstrt_id) && (this.in_map_data.heatGenData[c].keyData[0].value > 0)) {
                  this.data.dstElmtns[d].fl_clr_cd = this.in_map_data.heatGenData[c].keyData[0].clr_cd ? this.in_map_data.heatGenData[c].keyData[0].clr_cd : this.data.dstElmtns[d].prv_fl_clr_cd;
                  this.data.dstElmtns[d].opacity = this.in_map_data.heatGenData[c].keyData[0].opcty ? this.in_map_data.heatGenData[c].keyData[0].opcty : 0.3;
                  // this.data.dstElmtns[d].txt = (this.in_map_data.heatGenData[c].keyData[0].value != undefined || this.in_map_data.heatGenData[c].keyData[0].value != 'undefined') ? this.in_map_data.heatGenData[c].keyData[0].value : 0;


                  // this.in_map_data.heatGenData[c].keyData[0].dstrt_id = this.in_map_data.heatGenData[c].dstrt_id;
                  // this.in_map_data.heatGenData[c].keyData[0].title =  this.in_map_data.hdr_settings.sb_title;
                  // // this.in_map_data.heatGenData[c].keyData[k].dstrt_nm=this.data.dstElmtns.filter(item => item.dstrt_id === this.in_map_data.heatGenData[c].dstrt_id);
                  // this.slctdKeydata.push(this.in_map_data.heatGenData[c].keyData[0]);

                }
                // } else {


              }


            } else if (this.in_map_data.heatGenData != undefined && this.in_map_data.heatGenData.length > 0 && this.in_map_data.keysShared.length > 0) {
              console.log("check1", this.in_map_data.heatGenData);

              for (let c = 0; c < this.in_map_data.heatGenData.length; c++) {

                for (let k = 0; k < this.in_map_data.heatGenData[c].keyData.length; k++) {

                  if (this.in_map_data.heatGenData[c].keyData[k].ky == this.slctHeatKey) {
                    // console.log(this.in_map_data.heatGenData[c].keyData[k])
                    if ((this.in_map_data.heatGenData[c].dstrt_id == this.data.dstElmtns[d].dstrt_id)) {
                      if ((this.in_map_data.heatGenData[c].keyData[k].value > 0 && this.in_map_data.heatGenData[c].keyData[k].value != undefined)) {

                        this.data.dstElmtns[d].fl_clr_cd = this.in_map_data.heatGenData[c].keyData[k].clr_cd ? this.in_map_data.heatGenData[c].keyData[k].clr_cd : this.data.dstElmtns[d].prv_fl_clr_cd;
                        this.data.dstElmtns[d].opacity = this.in_map_data.heatGenData[c].keyData[k].opcty ? this.in_map_data.heatGenData[c].keyData[k].opcty : 0.3;
                      }
                      else {
                        this.data.dstElmtns[d].fl_clr_cd = this.data.dstElmtns[d].prv_fl_clr_cd;
                        this.data.dstElmtns[d].opacity = 0.3;
                      }
                    }
                  }


                }
              }
            }

            else {

              this.data.dstElmtns[d].fl_clr_cd = this.data.dstElmtns[d].prv_fl_clr_cd;
              this.data.dstElmtns[d].opacity = 0.3;

            }

            console.log(this.data.dstElmtns[d].fl_clr_cd, this.data.dstElmtns[d].opacity)


            this.dstPlygons = L.polygon(JSON.parse(this.data.dstElmtns[d].bndry_jsn),
              {
                color: this.data.dstElmtns[d].otlne_clr_cd,
                fillColor: this.data.dstElmtns[d].fl_clr_cd,
                fillOpacity: this.data.dstElmtns[d].opacity,
                weight: 1,
                plygn_crdts: JSON.parse(this.data.dstElmtns[d].bndry_jsn),
                elmnt_id: this.data.dstElmtns[d].elmnt_id,
                prvClr: this.data.dstElmtns[d].otlne_clr_cd,
                prvFlClr: this.data.dstElmtns[d].fl_clr_cd,
              }
            ).addTo(this._dstrtLyr).on('click', function (e) {
              // console.log('onClickkkkkkkkkkkkkkkkk')
              // if (that.userDtls.hrchy_id == 1) {
              // that.onDstrtClk(e.target.options.elmnt_id);
              // }
            }).on('mouseover', function (e) {
              this.setStyle({ fillColor: '#a855f7' });
              that.onHoverDistrict(e.target.options.elmnt_id);
              that.shwPopDetails(e.target.options.elmnt_id, 'show');

            }).on('mouseout', function (e) {
              this.setStyle({ fillColor: e.sourceTarget.options.prvFlClr });
              that.shwPopDetails(e.target.options.elmnt_id, 'hide');
            })
            this._dstrtLyr.addTo(this.map);
            // this.map.fitBounds(this.dstPlygons.getBounds());

            // let zoomLevel = this.map.getBoundsZoom(this.dstPlygons.getBounds());
            // this.map.flyTo([this.dstPlygons.getCenter().lat, this.dstPlygons.getCenter().lng], zoomLevel-4);
            // this.map.fitBounds(this.dstPlygons.getBounds());
            this.settings.shwLdg = false;
            this.data.dstElmtns[d].Coordinates = []; this.data.dstElmtns[d].marker = [];
            var coordJsn = JSON.parse(this.data.dstElmtns[d].bndry_jsn)
            for (let j = 0; j < coordJsn.length; j++) {
              this.data.dstElmtns[d].Coordinates.push([coordJsn[j][1], coordJsn[j][0]])
            }
            // Calculate centroid using Turf.js
            this.data.dstElmtns[d].centroid = turf.centroid(turf.polygon([this.data.dstElmtns[d].Coordinates]));
            this.data.dstElmtns[d].centroidCoordinates = this.data.dstElmtns[d].centroid.geometry.coordinates;
            let markerHtmlStyles = `    position: absolute;
                                        color: black;
                                        font-size: 10px;
                                        font-weight: 400;
                                        width: 3px;
                                        white-space: nowrap;`;
            this.data.dstElmtns[d].marker.push(L.marker([this.data.dstElmtns[d].centroidCoordinates[1], this.data.dstElmtns[d].centroidCoordinates[0]], {
              icon: L.divIcon({
                html: `<div style="${markerHtmlStyles}">${this.data.dstElmtns[d].sqnce_id}</div>`,
                interactive: true
              }),
              color: 'black', radius: 0.2, permanent: true, sticky: true,
            }).addTo(this._dstNmMrkrLyr));

            // if (this.userDtls.hrchy_id == 4) {
            this._dstNmMrkrLyr.addTo(this.map);

            console.log(this.data.dstElmtns[d].marker)
            // } else if (this.userDtls.hrchy_id == 2) {
            //   this.onDstrtClk(this.data.dstElmtns[d].elmnt_id);
            // }



          }


        }
        if (this.data.dstElmtns.length === 1) {
          this.map.fitBounds(this.dstPlygons.getBounds());
          let zoomLevel = this.map.getBoundsZoom(this.dstPlygons.getBounds());
          this.map.flyTo([this.dstPlygons.getCenter().lat, this.dstPlygons.getCenter().lng], zoomLevel);
        }
        if (this.in_map_data.keysShared.length == 0) {
          this.buildForScale();
        }
      }
    }
  }
  shwPopDetails(elmnt_id, prop) {
    if (prop == 'show') {
      this.shwPopCrd = true;
      let fltrdata = this.data.dstElmtns.filter(item => item.elmnt_id === elmnt_id);
      this.data.popCrdDtls.dstrt_nm = fltrdata[0].elmnt_nm;
      // this.data.popCrdDtls.txt = '';
      // this.data.popCrdDtls.ky = this.in_map_data.heatGenData[0].ky;
      for (let c = 0; c < this.in_map_data.heatGenData.length; c++) {
        if (this.in_map_data.heatGenData[c].dstrt_id === fltrdata[0].dstrt_id) {
          // console.log(this.in_map_data.heatGenData[c],fltrdata[0].dstrt_id)
          this.in_map_data.heatGenData[c].dstrt_nm = fltrdata[0].elmnt_nm;
          this.slctdData = this.in_map_data.heatGenData[c];
          this.tableData = JSON.parse(this.slctdData.hvr_tbl_data);
        }

      }


    } else {
      this.shwPopCrd = false;
    }

  }
  onDstrtClk(elmnt_id) {
    console.log(elmnt_id);
    let fltrdata = this.data.dstElmtns.filter(item => item.elmnt_id === elmnt_id)
    console.log(fltrdata);
    // if (key == 'click') {
    // 
    this.data.dstElmtns.forEach(d => {
      if (d.dstrt_id == fltrdata[0].dstrt_id) {
        d.shwSlctdDstrt = true;
        this.slctdDstrt_nm = fltrdata[0].elmnt_nm;
      } else {
        d.shwSlctdDstrt = false;
      }
    });
    if (this._tempDstLyr) {
      this._tempDstLyr.clearLayers();
    }
    if (this._dstrtLyr != undefined) {
      this._dstrtLyr.clearLayers();
    }
    if (this._dstNmMrkrLyr != undefined) {
      this._dstNmMrkrLyr.clearLayers();
    }
    if (this.map) {
      this.map.invalidateSize();
    }
    this.shwPopCrd = false;
    for (let d = 0; d < this.data.dstElmtns.length; d++) {
      if (this.data.dstElmtns[d].elmnt_id == elmnt_id) {
        this._dst_temp_polgon = L.polygon(JSON.parse(this.data.dstElmtns[d].bndry_jsn), { color: this.data.dstElmtns[d].otlne_clr_cd, fillColor: '#53ac74' }).addTo(this._tempDstLyr);
        this._tempDstLyr.addTo(this.map);

        this.map.fitBounds(this._dst_temp_polgon.getBounds());
        // Create a LatLngBounds object from the coordinates
        let bounds = L.latLngBounds(JSON.parse(this.data.dstElmtns[d].bndry_jsn));

        // Get the zoom level for the bounds
        let zoomLevel = this.map.getBoundsZoom(bounds);

        // Optionally, set the map view to these bounds with the calculated zoom level
        // map.setView(bounds.getCenter(), zoomLevel);
        this.map.flyTo([this._dst_temp_polgon.getCenter().lat, this._dst_temp_polgon.getCenter().lng], zoomLevel - 0.15);
        // this.setBounds();

      }
    }
    let data = {
      elmnt_id: elmnt_id,
      dstrt_id: fltrdata[0].dstrt_id,
      ulb_id: 0,
      hndlr: 'dstrt',
      elmnt_nm: fltrdata[0].elmnt_nm,
      // actnTyp: key
      dbrd_lvl: 1
    }
    // this.in_map_data.dbrd_lvl = 1;
    // this.in_map_data.dstrt_id = fltrdata[0].dstrt_id;

    this.plotUlbsByDstrct(fltrdata[0].dstrt_id);
    this.updtdData.emit(data);


  }
  onHoverDistrict(elmnt_id) {
    this.data.dstElmtns.forEach(e => {
      if (e.elmnt_id == elmnt_id) {
        e.shwHvr = true;
      } else {
        e.shwHvr = false;
      }
    });
    // let fltrdata = this.data.dstElmtns.filter(item => item.elmnt_id === elmnt_id);
    // let data = {
    //   dstrt_id: fltrdata[0].dstrt_id,
    // }
    // this.hovrData.emit(data);
  }
  plotUlbsByDstrct(dstrt_id) {
    const that: any = this;
    if (this._ulbLyr != undefined) {
      this._ulbLyr.clearLayers();
    }
    if (this._ulbMrkrLyr != undefined) {
      this._ulbMrkrLyr.clearLayers();
    }
    this.data.ulbElmnts = [];
    if (dstrt_id > 0) {
      if (this.data.elmntsData.length) {
        this.data.ulbElmnts = this.data.elmntsData.filter(item => item.elmnt_grp_id == 2 && item.dstrt_id == dstrt_id)
        console.log(this.data.ulbElmnts)
        if (this.data.ulbElmnts.length > 0) {
          for (let u = 0; u < this.data.ulbElmnts.length; u++) {
            this.data.ulbElmnts[u].marker = [];
            let ulbMarker = `position: absolute;
            color: black;
            font-size: 10px;
            font-weight: 400;
            width: 3px;
            white-space: nowrap;
            text-align:auto;
            left: 10px;`;
            this.data.ulbElmnts[u].Coordinates = []; this.data.ulbElmnts[u].marker = []; this.data.ulbElmnts[u].circleMarker = [];
            var coordJsn = JSON.parse(this.data.ulbElmnts[u].bndry_jsn)
            for (let j = 0; j < coordJsn.length; j++) {
              this.data.ulbElmnts[u].Coordinates.push([coordJsn[j][1], coordJsn[j][0]])
            }
            // Calculate centroid using Turf.js
            this.data.ulbElmnts[u].centroid = turf.centroid(turf.polygon([this.data.ulbElmnts[u].Coordinates]));
            this.data.ulbElmnts[u].centroidCoordinates = this.data.ulbElmnts[u].centroid.geometry.coordinates;
            this.data.ulbElmnts[u].marker.push(L.marker([this.data.ulbElmnts[u].centroidCoordinates[1], this.data.ulbElmnts[u].centroidCoordinates[0]], {
              icon: L.divIcon({
                html: `<div style="${ulbMarker}">${this.data.ulbElmnts[u].elmnt_nm}</div>`,
                interactive: true
              }),
              color: 'black', radius: 0.2, permanent: true, sticky: true, elmnt_id: this.data.ulbElmnts[u].elmnt_id,
            }).addTo(this._ulbMrkrLyr).on('click', (e) => {
              console.log(e.sourceTarget.options.elmnt_id);
              that.plotSingleULB(e.sourceTarget.options.elmnt_id);
            }));

            this._ulbMrkrLyr.addTo(this.map);

            this.data.ulbElmnts[u].circleMarker.push(L.circleMarker([this.data.ulbElmnts[u].centroidCoordinates[1], this.data.ulbElmnts[u].centroidCoordinates[0]], {
              color: 'black', radius: 0.2, permanent: true, sticky: true,
            }).addTo(this._ulbMrkrLyr));
            this._ulbMrkrLyr.addTo(this.map);
          }

        }
      }
    }

  }
  onULBClk(elmnt_id) {
    console.log(elmnt_id)
    let fltrdata = this.data.ulbElmnts.filter(item => item.elmnt_id === elmnt_id);
    console.log(fltrdata)
    let data = {
      elmnt_id: elmnt_id,
      dstrt_id: fltrdata[0].dstrt_id,
      ulb_id: fltrdata[0].ulb_id,
      elmnt_nm: fltrdata[0].elmnt_nm,
      dst_nm: this.slctdDstrt_nm,
      hndlr: 'ulb',
      dbrd_lvl: 2
    }
    this.slctdDstrt_nm = fltrdata[0].elmnt_nm;
    // this.in_map_data.dbrd_lvl = 2;
    // this.in_map_data.ulb_id = fltrdata[0].ulb_id;
    // this.in_map_data.dstrt_id = fltrdata[0].dstrt_id;
    // this.getMapElements();
    this.updtdData.emit(data);
  }

  setBounds() {
    if (this.map.getBounds().intersects(this.ap_map_bounds)) {
      return;
    } else {
      this.map.fitBounds(this.ap_map_bounds);
    }
  }
  plotSingleULB(elmnt_id) {
    if (this._ulbLyr != undefined) {
      this._ulbLyr.clearLayers();
    }
    if (this._ulbMrkrLyr != undefined) {
      this._ulbMrkrLyr.clearLayers();
    }
    if (this._tempDstLyr) {
      this._tempDstLyr.clearLayers();
    }
    if (this._tempUlbLyr) {
      this._tempUlbLyr.clearLayers();
    }

    if (this.map) {
      this.map.invalidateSize();
    }
    let snglUlb = this.data.ulbElmnts.filter(item => item.elmnt_id == elmnt_id)[0];
    this.slctDstrt = snglUlb.dstrt_id;
    this.slctdUlb = snglUlb.ulb_id;
    this.data.ulbElmnts.forEach(u => {
      if (u.elmnt_id === elmnt_id) {
        u.shwSlctdULB = true;
      } else {
        u.shwSlctdULB = false
      }
    });
    this._temp_ulb_polygon = L.polygon(JSON.parse(snglUlb.bndry_jsn), { color: snglUlb.otlne_clr_cd, fillColor: '#53ac74' }).addTo(this._tempUlbLyr);
    this._tempUlbLyr.addTo(this.map);

    let bounds = L.latLngBounds(JSON.parse(snglUlb.bndry_jsn));

    // Get the zoom level for the bounds
    let zoomLevel = this.map.getBoundsZoom(bounds);

    // Optionally, set the map view to these bounds with the calculated zoom level
    // map.setView(bounds.getCenter(), zoomLevel);
    this.map.flyTo([this._temp_ulb_polygon.getCenter().lat, this._temp_ulb_polygon.getCenter().lng], zoomLevel);

    // this.getLayouts();

    // snglUlb.marker = []
    // let ulbMarker = `position: absolute;
    // color: black;
    // font-size: 10px;
    // font-weight: 400;
    // width: 3px;
    // white-space: nowrap;
    // text-align:auto;
    // left: 10px;`;
    // snglUlb.Coordinates = []; snglUlb.marker = []; snglUlb.circleMarker = [];
    // var coordJsn = JSON.parse(snglUlb.bndry_jsn)
    // for (let j = 0; j < coordJsn.length; j++) {
    //   snglUlb.Coordinates.push([coordJsn[j][1], coordJsn[j][0]])
    // }
    // // Calculate centroid using Turf.js
    // snglUlb.centroid = turf.centroid(turf.polygon([snglUlb.Coordinates]));
    // snglUlb.centroidCoordinates = snglUlb.centroid.geometry.coordinates;
    // snglUlb.marker.push(L.marker([snglUlb.centroidCoordinates[1], snglUlb.centroidCoordinates[0]], {
    //   icon: L.divIcon({
    //     html: `<div style="${ulbMarker}">${snglUlb.elmnt_nm}</div>`,
    //     interactive: true
    //   }),
    //   color: 'black', radius: 0.2, permanent: true, sticky: true
    // }).addTo(this._tempUlbLyr));




    let data = {
      elmnt_id: elmnt_id,
      dstrt_id: snglUlb.dstrt_id,
      dst_nm: this.slctdDstrt_nm,
      ulb_id: snglUlb.ulb_id,
      hndlr: 'ulb',
      elmnt_nm: snglUlb.elmnt_nm,
      dbrd_lvl: 2
    }
    // this.in_map_data.dbrd_lvl = 2;
    // this.in_map_data.ulb_id = snglUlb.ulb_id;
    // this.in_map_data.dstrt_id = snglUlb.dstrt_id;
    this.updtdData.emit(data);

  }
  onUlbNmClick(elmnt_id) {

    console.log(elmnt_id)

  }

  // getLayouts() {
  //   var postData = {
  //     dstrt_id: this.slctDstrt,
  //     ulb_id: this.slctdUlb,
  //     elmnt_grp_id: [5, 6]// 5 for layouts,6 for blocks
  //   }
  //   console.log(postData)
  //   this.mapSrvce.getLayouts(postData).subscribe((res) => {
  //     this.data.layoutsData = res.data;
  //     console.log(this.data.layoutsData);
  //     this.plotLayout();
  //   })
  // }
  // plotLayout() {
  //   const that: any = this;
  //   this.data.layoutData = [];
  //   if (this._ulbMrkrLyr) {
  //     this._ulbMrkrLyr.clearLayers();
  //   }
  //   if (this._lytMrkrLyr) {
  //     this._lytMrkrLyr.clearLayers();
  //   }
  //   if (this._grpLytLyr) {
  //     this._grpLytLyr.clearLayers();
  //   }

  //   console.log("in plotting")
  //   if (this.in_map_data.lctn_id > 0) {
  //     this.data.layoutsData.forEach(e => {
  //       if (e.elmnt_grp_id == 5 && e.elmnt_cd == this.in_map_data.lctn_id) {
  //         this.data.layoutData.push(e);
  //       }
  //     });
  //   } else {
  //     this.data.layoutData = this.data.layoutsData.filter(item => item.elmnt_grp_id == 5)
  //   }
  //   console.log(this.data.layoutData)
  //   if (this.data.layoutData.length > 0) {
  //     for (let s = 0; s < this.data.layoutData.length; s++) {
  //       // if (this.data.layoutData[s].elmnt_grp_id == 5) {
  //       if (this.data.layoutData[s].bndry_jsn != undefined) {
  //         this.lyrPolygn = L.polygon(JSON.parse(this.data.layoutData[s].bndry_jsn),
  //           {
  //             color: this.data.layoutData[s].otlne_clr_cd,
  //             // fill: false,
  //             'fillColor': this.data.layoutData[s].fl_clr_cd,
  //             fillOpacity: 0,
  //             weight: 2,
  //             imgUrl: this.data.layoutData[s].img_url,
  //             adrs_tx: this.data.layoutData[s].addrs_tx,
  //             bnfcy_nm: this.data.layoutData[s].bnfcy_nm,
  //             elmnt_grp_id: this.data.layoutData[s].elmnt_grp_id,
  //             elmnt_id: this.data.layoutData[s].elmnt_id,
  //             lat: this.data.layoutData[s].lat,
  //             lng: this.data.layoutData[s].lng
  //             // dashArray: '1,6'
  //           }
  //         ).addTo(this._grpLytLyr);
  //         if (this.in_map_data.dbrd_lvl == 3) {
  //           console.log('here in grppppppppppppppppp add lyr');
  //           if (this.map) this._grpLytLyr.addTo(this.map);
  //         }
  //         // .on('click', function (e) {
  //         //   console.log(e);              
  //         // });

  //         // this._grpLytLyr.addTo(this.map);

  //         // this.map.fitBounds(this.lyrPolygn.getBounds());
  //         // this.LayerArry.push({ lyrPly: this.lyrPolygn });
  //         // if(this.map==undefined){
  //         //   this.initialize();
  //         // }
  //         console.log(this.map, that.map)


  //         this.data.layoutData[s].srvyCoordinates = [];
  //         this.data.layoutData[s].srvyJsn = JSON.parse(this.data.layoutData[s].bndry_jsn);

  //         for (let j = 0; j < this.data.layoutData[s].srvyJsn.length; j++) {
  //           this.data.layoutData[s].srvyCoordinates.push([this.data.layoutData[s].srvyJsn[j][1], this.data.layoutData[s].srvyJsn[j][0]])
  //         }
  //         // Calculate centroid using Turf.js
  //         this.data.layoutData[s].centroid = turf.centroid(turf.polygon([this.data.layoutData[s].srvyCoordinates]));
  //         this.data.layoutData[s].centroidCoordinates = this.data.layoutData[s].centroid.geometry.coordinates;
  //         let lblMrkr = `color: black;
  //           font-size:12 px;
  //           display: inline-block;
  //           text-align: center;
  //           font-weight:bold`;
  //         // Create a marker for the centroid
  //         // this.data.layoutData[s].centroidMarker =  L.circleMarker([this.data.layoutData[s].centroidCoordinates[1], this.data.layoutData[s].centroidCoordinates[0]], { color: this.data.layoutData[s].fl_clr_cd, radius: 0.01 }).addTo(this.map);
  //         this.data.layoutData[s].centroidMarker = L.marker([this.data.layoutData[s].centroidCoordinates[1], this.data.layoutData[s].centroidCoordinates[0]], {
  //           icon: L.divIcon({

  //             html: `<div style="${lblMrkr}" >${this.data.layoutData[s].elmnt_nm}</div>`
  //           }),
  //           radius: 0.2, permanent: true, sticky: true, opacity: 1.2,
  //         }).addTo(this._lytMrkrLyr);
  //         this._lytMrkrLyr.addTo(this.map);
  //         // let bounds = L.latLngBounds(this.lyrPolygn);

  //         // // Get the zoom level for the bounds
  //         // let zoomLevel = this.map.getBoundsZoom(bounds);

  //         // Optionally, set the map view to these bounds with the calculated zoom level
  //         // map.setView(bounds.getCenter(), zoomLevel);
  //         // this.map.fitBounds(this.lyrPolygn.getBounds());
  //         // console.log(this.lyrPolygn.getBounds())
  //         this.map.flyTo([this.lyrPolygn.getCenter().lat, this.lyrPolygn.getCenter().lng], 8);
  //         // this.data.layoutData[s].centroidMarker.bindTooltip('# ' + this.data.layoutData[s].elmnt_nm, { permanent: false, offset: L.point(0, 0), className: 'Area-lable', minZoom: 7, maxZoom: 7 }).openTooltip();
  //         // this.lyrcntrdArry.push({ cntrdMrkr: this.data.layoutData[s].centroidMarker });
  //       }
  //       // }

  //       this.plotSlctdLayoutBlocks();
  //     }
  //   }
  // }
  // plotSlctdLayoutBlocks() {

  // }
  formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  formatter2 = new Intl.NumberFormat('en-IN');
  currencyINRFormater = (val: string) => {
    return this.formatter.format(parseFloat(val));
  }
  numberINDFormater = (val: string) => {
    return this.formatter2.format(parseFloat(val));
  }
  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    };
  }
  plotSlctdHeatKey(k) {
    this.slctHeatKey = k.ky;
    this.in_map_data.hdr_settings.sb_title = k.dsply_nm;
    this.slctdKeydata = [];
    for (let c = 0; c < this.in_map_data.heatGenData.length; c++) {

      for (let h = 0; h < this.in_map_data.heatGenData[c].keyData.length; h++) {
        if (this.in_map_data.heatGenData[c].keyData[h].ky == this.slctHeatKey) {
          this.in_map_data.heatGenData[c].keyData[h].dstrt_id = this.in_map_data.heatGenData[c].dstrt_id;
          this.in_map_data.heatGenData[c].keyData[h].title = k.dsply_nm;
          // this.in_map_data.heatGenData[c].keyData[k].dstrt_nm=this.data.dstElmtns.filter(item => item.dstrt_id === this.in_map_data.heatGenData[c].dstrt_id);
          this.slctdKeydata.push(this.in_map_data.heatGenData[c].keyData[h]);
        }

      }
    }
    document.documentElement.style.setProperty('--bgClr', this.slctdKeydata.length ? this.slctdKeydata[0].clr_cd : '#fffff');
    console.log(this.slctdKeydata)
    this.buildScale();
    this.in_map_data.keysShared.forEach(e => {
      if (e.ky == this.slctHeatKey) {
        e.slctd = true;
        // e.hdrBgClr=slctdClr;
      } else {
        e.slctd = false
      }
    });
    console.log('callingggggggggggggggggg', this.in_map_data.keysShared);
    // code for color change while button clicked
    this.data.dstElmtns.forEach(d => {
      d.clr_cd = d.prv_fl_clr_cd;
    });
    // if (this.in_map_data.dbrd_lvl == 1) {
    //   this.handleDistrictLevel();
    // }
    this.getMapElements();
    this.plotDstrict();

  }
  buildForScale() {
    this.slctdKeydata = [];
    console.log(this.in_map_data.heatGenData)
    if(this.in_map_data.heatGenData){
      for (let c = 0; c < this.in_map_data.heatGenData.length; c++) {

        // for (let h = 0; h < this.in_map_data.heatGenData[c].keyData.length; h++) {
        this.in_map_data.heatGenData[c].keyData[0].dstrt_id = this.in_map_data.heatGenData[c].dstrt_id;
        this.in_map_data.heatGenData[c].keyData[0].title = this.in_map_data.hdr_settings.sb_title;
        // this.in_map_data.heatGenData[c].keyData[k].dstrt_nm=this.data.dstElmtns.filter(item => item.dstrt_id === this.in_map_data.heatGenData[c].dstrt_id);
        this.slctdKeydata.push(this.in_map_data.heatGenData[c].keyData[0]);
  
        // }
      }
    }

    document.documentElement.style.setProperty('--bgClr', this.slctdKeydata[0].clr_cd);
    console.log(this.slctdKeydata)
    this.buildScale();
  }
  marks: any = {};
  scale = {
    minValue: 0,
    maxValue: 0
  };
  gradientBackground: string = '';
  hoveredValue: number | null = null;
  hoverdTitle: string | null = null;
  tooltipPosition: number = 0;
  buildScale() {
    console.log(this.slctdKeydata);

    // Find min and max values for the scale
    this.scale.minValue = Math.min(...this.slctdKeydata.map(val => val.value));
    this.scale.maxValue = Math.max(...this.slctdKeydata.map(val => val.value));

    // Find min and max opacity values
    const minOpacity = Math.min(...this.slctdKeydata.map(val => parseFloat(val.opcty)));
    const maxOpacity = Math.max(...this.slctdKeydata.map(val => parseFloat(val.opcty)));

    // Normalize opacity values between 0 and 1
    this.slctdKeydata.forEach(val => {
      val.normalizedOpcty = this.normalizeOpacity(val.opcty, minOpacity, maxOpacity);
    });
    // Sort the data by color luminance (from lighter to darker)
    this.slctdKeydata.sort((a, b) => {
      const rgbA = this.extractRGB(a.clr_cd).split(',').map(Number); // Extract RGB values and convert to numbers
      const rgbB = this.extractRGB(b.clr_cd).split(',').map(Number);

      const luminanceA = this.calculateLuminance(rgbA);
      const luminanceB = this.calculateLuminance(rgbB);

      return luminanceA - luminanceB; // Sort by luminance (lighter to darker)
    });

    // Create gradient stops with proper color and opacity
    const gradientStops = this.slctdKeydata.map((val) => {
      const percentage = ((val.value - this.scale.minValue) / (this.scale.maxValue - this.scale.minValue)) * 100;
      const rgbValues = this.extractRGB(val.clr_cd); // Extract RGB values
      return `rgba(${rgbValues}, ${val.normalizedOpcty}) ${percentage}%`;
    }).join(', ');
    // Create linear-gradient background
    this.gradientBackground = `linear-gradient(to right, ${gradientStops})`;

    console.log(this.gradientBackground);
  }
  calculateLuminance([r, g, b]: number[]): number {
    return 0.299 * r + 0.587 * g + 0.114 * b; // Weighted average for perceived brightness
  }
  normalizeOpacity(opacity: string, min: number, max: number): number {
    // Convert opacity to a float and normalize between 0 and 1
    const opctyValue = parseFloat(opacity);

    // Normalize the opacity if it's a valid number, otherwise set to 1
    if (!isNaN(opctyValue)) {
      return (opctyValue - min) / (max - min); // Normalize opacity based on min and max values
    } else {
      return 1; // Return 1 if invalid opacity
    }


  }

  // Helper to extract RGB part from the color string (works for both hex and rgb)
  extractRGB(color: string): string {
    // Check if it's a hex code (e.g., "#85d3ff")
    if (color.startsWith('#')) {
      color = color.replace('#', ''); // Remove '#' from hex

      // Handle shorthand hex code (e.g., "#abc" => "#aabbcc")
      if (color.length === 3) {
        color = color.split('').map(c => c + c).join('');
      }

      const bigint = parseInt(color, 16); // Convert hex to integer
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`; // Return RGB values as "r, g, b"
    }

    // Check if it's an RGB string (e.g., "rgb(166, 244, 206)")
    const rgbValues = color.match(/\d+/g); // Extracts [166, 244, 206]
    if (rgbValues && rgbValues.length === 3) {
      return `${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}`; // Properly formatted "r, g, b"
    } else {
      return ''; // Return empty string if invalid color
    }
  }
  onHover(event: MouseEvent) {
    const lineWidth = (event.target as HTMLElement).clientWidth;
    const position = event.offsetX;

    const value = this.scale.minValue + (position / lineWidth) * (this.scale.maxValue - this.scale.minValue);
    this.tooltipPosition = position;

    console.log(this.slctdKeydata)
    // Find the nearest color based on value
    const nearestData = this.slctdKeydata.reduce((prev, curr) => Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev);

    this.hoveredValue = nearestData.value;
    this.hoverdTitle = nearestData.title;
  }
  onLeave() {
    this.hoveredValue = null;
  }
}
