<app-loading *ngIf="loader == true"></app-loading>
<!-- Page -->
<div class="page">
    <!-- Page Header -->
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Hikes</h3>
            </div>
            <div class="col-auto float-end ms-auto">
                <a class="btn add-btn" class="btn add-btn" routerLink="/layout/payroll/add-hikes"><i
                        class="fa fa-plus"></i> Add New Hike</a>
            </div>
        </div>
    </div>
    <!-- /Page Header -->
    <!-- Page Content -->
    <div class="content container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div>
                    <!-- <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" -->

                    <table  class="table table-striped custom-table datatable mb-0">
                        <thead>
                            <tr>
                                <th style="width: 30px;">S.No</th>
                                <th>Hike Name</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Month/Year</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lstSubDepartment">
                                <td>{{item.sno}}</td>
                                <td>{{item.pyrle_hke_nm}}</td>
                                <td>{{item.dscn_tx}}</td>
                                <td>{{item.hke_ctgry_nm}}</td>
                                <td>{{item.mnth}}</td>
                                <td class="text-end">
                                    <div class="dropdown dropdown-action">
                                        <a class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false" style="cursor: pointer;"><i
                                                class="material-icons">more_vert</i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item"
                                                [queryParams]="{id: item.lve_prfle_id , name : 'view'}"
                                                routerLink="/layout/policies/add-leaves-profile"><i
                                                    class="fa fa-eye m-r-5"></i> View & Employees</a>
                                            <a class="dropdown-item" [queryParams]="{id: item.lve_prfle_id}"
                                                routerLink="/layout/policies/add-leaves-profile"><i
                                                    class="fa fa-pencil m-r-5"></i>
                                                Edit</a>
                                            <a class="dropdown-item" data-bs-toggle="modal"
                                                data-bs-target="#delete_department"
                                                (click)="tempId = item.lve_prfle_id"><i class="fa fa-trash-o m-r-5"></i>
                                                Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- /Page Content -->
</div>
<!-- / Page -->


<!-- Add Department Modal -->
<!-- <div id="add_subdepartment" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Hike</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form [formGroup]="addHikeForm">
                    <div class="row">
                        <div class="form-group">
                            <label>Payroll Hike Name<span class="text-danger">*</span></label>
                            <input class="form-control" type="text"
                                [class.invalid]="addHikeForm.get('pyrle_hke_nm').invalid && addHikeForm.get('pyrle_hke_nm').touched"
                                formControlName="pyrle_hke_nm">
                            <div
                                *ngIf="addHikeForm.get('pyrle_hke_nm').invalid && addHikeForm.get('pyrle_hke_nm').touched">
                                <small
                                    *ngIf="addHikeForm.get('pyrle_hke_nm').invalid && addHikeForm.get('pyrle_hke_nm').touched"
                                    class="text-danger"> *Payroll Hike Name is required</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea class="form-control" type="text" formControlName="dscn_tx"></textarea>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Select Hike Category <span class="text-danger">*</span></label>
                                <select class="form-select floating form-control" formControlName="hke_ctgry_id"
                                    [class.invalid]="addHikeForm.get('hke_ctgry_id').invalid && addHikeForm.get('hke_ctgry_id').touched">
                                    <option *ngFor="let d of hikesCat" [value]="d.hke_ctgry_id">{{d.hke_ctgry_nm}}
                                    </option>
                                </select>
                                <div
                                    *ngIf="addHikeForm.get('hke_ctgry_id').invalid && addHikeForm.get('hke_ctgry_id').touched">
                                    <small
                                        *ngIf="addHikeForm.get('hke_ctgry_id').invalid && addHikeForm.get('hke_ctgry_id').touched"
                                        class="text-danger"> *Hike Category is required</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Default Hike Percentage (%) <span class="text-danger">*</span></label>
                                <input class="form-control" type="number"
                                    [class.invalid]="addHikeForm.get('dflt_hke_pct_ct').invalid && addHikeForm.get('dflt_hke_pct_ct').touched"
                                    formControlName="dflt_hke_pct_ct">
                                <div
                                    *ngIf="addHikeForm.get('dflt_hke_pct_ct').invalid && addHikeForm.get('dflt_hke_pct_ct').touched">
                                    <small
                                        *ngIf="addHikeForm.get('dflt_hke_pct_ct').invalid && addHikeForm.get('dflt_hke_pct_ct').touched"
                                        class="text-danger"> *Default Hike Percentage is required</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Proposed Date<span class="text-danger">*</span></label>
                                <div class="cal-icon">
                                    <input class="form-control datetimepicker" type="text" bsDatepicker type="text"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',  returnFocusToInput: true }"
                                        [class.invalid]="addHikeForm.get('prpsd_dt').invalid && addHikeForm.get('prpsd_dt').touched"
                                        formControlName="prpsd_dt">
                                    <div
                                        *ngIf="addHikeForm.get('prpsd_dt').invalid && addHikeForm.get('prpsd_dt').touched">
                                        <small
                                            *ngIf="addHikeForm.get('prpsd_dt').invalid && addHikeForm.get('prpsd_dt').touched"
                                            class="text-danger"> *Proposed Date is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Approved Date<span class="text-danger">*</span></label>
                                <div class="cal-icon">
                                    <input class="form-control datetimepicker" type="text" bsDatepicker type="text"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',  returnFocusToInput: true }"
                                        [class.invalid]="addHikeForm.get('aprvd_dt').invalid && addHikeForm.get('aprvd_dt').touched"
                                        formControlName="aprvd_dt">
                                    <div
                                        *ngIf="addHikeForm.get('aprvd_dt').invalid && addHikeForm.get('aprvd_dt').touched">
                                        <small
                                            *ngIf="addHikeForm.get('aprvd_dt').invalid && addHikeForm.get('aprvd_dt').touched"
                                            class="text-danger"> *Approved Date is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Announce Date<span class="text-danger">*</span></label>
                                <div class="cal-icon">
                                    <input class="form-control datetimepicker" type="text" bsDatepicker type="text"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',  returnFocusToInput: true }"
                                        [class.invalid]="addHikeForm.get('ance_dt').invalid && addHikeForm.get('ance_dt').touched"
                                        formControlName="ance_dt">
                                    <div
                                        *ngIf="addHikeForm.get('ance_dt').invalid && addHikeForm.get('ance_dt').touched">
                                        <small
                                            *ngIf="addHikeForm.get('ance_dt').invalid && addHikeForm.get('ance_dt').touched"
                                            class="text-danger"> *Announce Date is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Effective Date<span class="text-danger">*</span></label>
                                <div class="cal-icon">
                                    <input class="form-control datetimepicker" type="text" bsDatepicker type="text"
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',  returnFocusToInput: true }"
                                        [class.invalid]="addHikeForm.get('eftve_dt').invalid && addHikeForm.get('eftve_dt').touched"
                                        formControlName="eftve_dt">
                                    <div
                                        *ngIf="addHikeForm.get('eftve_dt').invalid && addHikeForm.get('eftve_dt').touched">
                                        <small
                                            *ngIf="addHikeForm.get('eftve_dt').invalid && addHikeForm.get('eftve_dt').touched"
                                            class="text-danger"> *Effective Date is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Select Year<span class="text-danger">*</span></label>
                                <div class="cal-icon">
                                    <input class="form-control datetimepicker" formControlName="yr_id" type="text"
                                        bsDatepicker [bsConfig]="{dateInputFormat: 'YYYY'}"
                                        (onShown)="onOpenYearCalendar($event) ">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Select Month<span class="text-danger">*</span></label>
                                <div class="cal-icon">
                                    <input class="form-control datetimepicker" formControlName="mnth_id" type="text"
                                        bsDatepicker [bsConfig]="{dateInputFormat: 'MMM'}"
                                        (onShown)="onOpenCalendar($event) ">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label style="margin-top: 2%;">Arrears Paid ? <span class="text-danger">*</span></label>
                            <div class="col-md-10">
                                <input type="radio" name="ars_pd_in" [value]="1" (change)="chngeTax()"
                                    formControlName="ars_pd_in">
                                <span style="padding-right: 15px;">Yes</span>

                                <input type="radio" name="ars_pd_in" [value]="0" (change)="chngeTax()"
                                    formControlName="ars_pd_in">
                                <span>No</span>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="shwAmnt">
                            <label>Total Arrears Amount <span class="text-danger">*</span></label>
                            <input class="form-control" type="number"
                                [class.invalid]="addHikeForm.get('tl_ars_at').invalid && addHikeForm.get('tl_ars_at').touched"
                                formControlName="tl_ars_at">
                            <div *ngIf="addHikeForm.get('tl_ars_at').invalid && addHikeForm.get('tl_ars_at').touched">
                                <small
                                    *ngIf="addHikeForm.get('tl_ars_at').invalid && addHikeForm.get('tl_ars_at').touched"
                                    class="text-danger"> *Total Arrears Amount is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="submit-section">
                        <button class="btn btn-primary submit-btn" [disabled]="addHikeForm.invalid || loader"
                            type="submit" (click)="saveHike()">
                            <span class="spinner-border spinner-border-sm" *ngIf="loader" role="status"
                                aria-hidden="true"></span>
                            <span class="sr-only" *ngIf="loader">Loading...</span>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->
<!-- /Add Department Modal -->

<!-- Delete Department Modal -->
<div class="modal custom-modal fade" id="delete_department" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-header">
                    <h3>Delete Leave Policy</h3>
                    <p>Are you sure want to delete?</p>
                </div>
                <div class="modal-btn delete-action">
                    <div class="row">
                        <div class="col-6">
                            <a class="btn btn-primary continue-btn" (click)="deleteDepartment()">
                                <span class="spinner-border spinner-border-sm" *ngIf="loader" role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only" *ngIf="loader">Loading...</span>
                                Delete</a>
                        </div>
                        <div class="col-6">
                            <a data-bs-dismiss="modal" class="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Delete Department Modal -->