<div class="page-header">
    <div class=" align-items-md-center">


        <div class="row hdrBar">
            <div class="col-lg-6 col-md-6">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <!-- <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" >
                                <span class="slctDteLbl">Select Date:</span>
                                <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                                    (ngModelChange)="onChange($event)" class="dte_picker"></nz-date-picker>
                
                
                            </div> -->
        </div>
    </div>
</div>

<div class="apsfl_dshbrd">

    <!-- <button (click)="open()"></button> -->

    <app-oms-dashbourd-cards [slctd_dstrt]="slctd_dstrt" (slctdata)="selctedCatrd($event)"></app-oms-dashbourd-cards>


    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
                <div class="row" style="margin-top:-10px;margin-bottom:5px">
                    <div class="col-md-8">
                        <div class="card_hdr_ttl" *ngIf="selctedcrd?.crd_hdr == 'WORKS'" style="position: relative;">
                            Activities</div>
                        <div class="card_hdr_ttl" *ngIf="selctedcrd?.crd_hdr == 'TRIPS'" style="position: relative;">
                            Travel Registry
                        </div>
                        <div class="card_hdr_ttl" *ngIf="selctedcrd?.crd_hdr == 'EXPENSES'" style="position: relative;">
                            Expenses</div>
                    </div>

                    <div class="col-md-4" style="text-align: right;">
                        <!-- <div class="col-md-3" style="text-align: left;"> -->
                        <div class="vws_data" *ngFor="let v of viewsdata" (click)="slectdvw(v.vw_id)"
                            [ngClass]="v.vw_id == slctdvw ? 'slct_vw' : 'dflt_vw' ">
                            <span nz-icon [nzType]="v.vw_icn" nzTheme="outline"></span>
                        </div>
                    </div>
                </div>
                <div class="card_ttl">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row pstnCls">

                                <div class="col-md-12" style="text-align: left; padding: 0px;" *ngIf="tb_id != '3'">
                                    <div class="tabs_data" *ngFor="let t of tabsdata" (click)="ChangeLevel(t.tab_id)"
                                        [ngClass]="t.tab_id == lvl_id ? 'slct_tab' : 'dflt_tab' ">
                                        {{t.tab_nm}}
                                    </div>


                                    <nz-range-picker *ngIf="lvl_id!=3" [(ngModel)]="dateRnge"
                                        (ngModelChange)="onChangeDate($event)" [nzFormat]="'dd-MM-yyyy'"
                                        style="height: 37px;border-radius: 4px;"></nz-range-picker>


                                </div>

                                <div class="col-md-12" style="text-align: left; padding: 0px;" *ngIf="tb_id == '3'">
                                    <div class="tabs_data" *ngFor="let t of tabsdata1" (click)="ChangeLevel(t.tab_id)"
                                        [ngClass]="t.tab_id == lvl_id ? 'slct_tab' : 'dflt_tab' ">
                                        {{t.tab_nm}}
                                    </div>


                                    <nz-range-picker *ngIf="lvl_id!=3" [(ngModel)]="dateRnge"
                                        (ngModelChange)="onChangeDate($event)" [nzFormat]="'dd-MM-yyyy'"
                                        style="height: 37px;border-radius: 4px;"></nz-range-picker>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8 apsflgrid_sumry" *ngIf="slctdvw==1" style="padding:0px">
                    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [showColumnLines]="true" [focusedRowEnabled]="false" [focusedRowIndex]="focusedRowIndex"
                        [showRowLines]="true" [showBorders]="true" style="width:100%"
                        (onCellPrepared)="onCellPrepared($event)" (onCellClick)="onCellClick($event)"  (onExporting)="onExporting($event)">
                        <dxo-paging [pageSize]="26"></dxo-paging>
                        <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager> -->
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                        <dxo-group-panel [visible]="false"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                        <dxo-column-chooser [enabled]="false" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName="OMS Dashboard"></dxo-export>
                        <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                        <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [visible]="d.visible"
                            [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                            [format]="d.format" [groupIndex]="d.groupIndex"
                            [sortOrder]="d.field === 'amt' ? 'desc' : null">
                        </dxi-column>

                        <dxo-summary>
                            <!-- [customizeText]="addSumLabel('Total Activities')" -->
                            <dxi-total-item column="ttl" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="no_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="inprg" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="cmpltd" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="dtnc_trvl" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="wrk_incld_expns" summaryType="sum"
                                [displayFormat]="sumcurrencyINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="trvld_vehs" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="oprd_veh" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="not_op_veh" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="amt" summaryType="sum" [displayFormat]="sumcurrencyINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="plnd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="un_plnd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="cstmr_efctd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="in_prgrs" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="ttl_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="cmpltd_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="actl_trvl_dstne_ct" summaryType="sum"
                                [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-group-item column="wrk_incld_expns" summaryType="sum" alignByColumn="true"
                                [displayFormat]="sumcurrencyINRFormater" [customizeText]="removeSumText">
                            </dxi-group-item>

                            <dxi-group-item column="amt" summaryType="sum" alignByColumn="true"
                                [displayFormat]="sumcurrencyINRFormater" [customizeText]="removeSumText">
                            </dxi-group-item>
                        </dxo-summary>
                    </dx-data-grid>
                </div>
                <div class="col-md-12 p-8 "
                    *ngIf="slctdvw==2 && (tb_id=='1' || tb_id=='2' || (  tb_id=='3' && lvl_id!=2))">
                    <div class="crd_style">
                        <div class="apxgrphone">
                            <div id="chart1">
                                <apx-chart [series]="chartOptionsFour.series" [chart]="chartOptionsFour.chart"
                                    [dataLabels]="chartOptionsFour.dataLabels"
                                    [plotOptions]="chartOptionsFour.plotOptions" [xaxis]="chartOptionsFour.xaxis"
                                    [yaxis]="chartOptionsFour.yaxis" [fill]="chartOptionsFour.fill"
                                    [responsive]="chartOptionsFour.responsive" [legend]="chartOptionsFour.legend"
                                    [colors]="chartOptionsFour.colors" [states]="chartOptionsFour.states"
                                    [stroke]="chartOptionsFour.stroke" [labels]="chartOptionsFour.labels"
                                    [title]="chartOptionsFour.title" [grid]="chartOptionsFour.grid"
                                    [theme]="chartOptionsFour.theme">
                                </apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="slctdvw==2 && (tb_id=='3' && lvl_id==2)">

                    <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 30px;">
                        <div class="row">
                            <div class="col-lg-9 col-md-9"></div>
                            <div class="col-lg-2 col-md-2">
                                <span>Expense Category</span>
                                <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectdCtgry"
                                    (ngModelChange)="onCtgrySlct($event)" nzPlaceHolder="Expense Category"
                                    [ngModelOptions]="{standalone: true}">
                                    <nz-option *ngFor="let t of expenseCtgry" [nzValue]="t.expne_ctgry_id"
                                        [nzLabel]="t.expne_ctgry_nm"></nz-option>
                                </nz-select>
                            </div>
                            <div class="col-lg-1 col-md-1"></div>
                        </div>

                        <div class="col-lg-12 col-md-12 mgnb-2 p-8 " *ngIf="expnsGrapData && expnsGrapData.length>0">
                            <div id="multichart">
                                <apx-chart [series]="multiChartOptions?.series" [chart]="multiChartOptions?.chart"
                                    [xaxis]="multiChartOptions?.xaxis" [markers]="multiChartOptions?.markers"
                                    [stroke]="multiChartOptions?.stroke" [yaxis]="multiChartOptions?.yaxis"
                                    [dataLabels]="multiChartOptions?.dataLabels" [title]="multiChartOptions?.title"
                                    [fill]="multiChartOptions?.fill" [tooltip]="multiChartOptions?.tooltip"
                                    [legend]="multiChartOptions?.legend"></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-md-12">
            <div class="crd_style">
                <div class="card_ttl">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row pstnCls" style="position: relative;">
                                <div class="col-md-12">
                                    <div class="card_hdr_ttl" style="position: relative;left:0px">Activities</div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row" style="    padding: 10px;
                            margin-bottom: 0px;
                            background: linear-gradient(45deg, #fff4e7, #ffeded);
                            border-radius: 10px 10px 0px 0px;">
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">District</div>
                                    <nz-select nzShowSearch class="slctBx" [(ngModel)]="tnt_id"
                                        (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}">
                                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                                        <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                                        </nz-option>
                                    </nz-select>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">Work Group</div>
                                    <nz-select nzShowSearch class="slctBx" [(ngModel)]="wrk_grp_id"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                                        <nz-option *ngFor="let t of wrkGrpLst" [nzValue]="t.wrk_grp_id"
                                            [nzLabel]="t.wrk_grp_nm">
                                        </nz-option>
                                    </nz-select>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">Work Status</div>
                                    <nz-select nzShowSearch class="slctBx" [(ngModel)]="oms_wrk_sts_id"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                                        <nz-option *ngFor="let t of wrkStsLst" [nzValue]="t.oms_wrk_sts_id"
                                            [nzLabel]="t.wrk_sts_nm">
                                        </nz-option>
                                    </nz-select>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <!-- <div class="fltrLbls">From</div>
                                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat"
                                        [(ngModel)]="frmDt" (ngModelChange)="onChange($event)"
                                        style="    width: 100%; height: 32px; "></nz-date-picker>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">To</div>
                                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat"
                                        [(ngModel)]="toDt" (ngModelChange)="onDtChange($event)"
                                        style="    width: 100%; height: 32px; "></nz-date-picker> -->
                                        <div class="fltrLbls">Select Date</div>
                                        <nz-range-picker  [(ngModel)]="dateRange"
                                        (ngModelChange)="onChange($event)" [nzFormat]="'dd-MM-yyyy'"
                                        style="height: 37px;border-radius: 4px;"></nz-range-picker>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <button (click)="getInPrgrsWrks()" style="    margin-top: 24px;
                                    background: #f15a22;
                                    color: #fff;
                                    border: currentColor;
                                    border-radius: 4px;
                                    border: 2px solid #ffb49f;
                                    cursor: pointer;">Get Data</button>
                                </div>
                            </div>



                        </div>
                        <div id="dbrdMap"></div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="row">

        <div class="col-lg-12 col-md-12">
            <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 30px;" *ngIf="tb_id=='1'">
                <div class="row">
                    <div class="col-lg-9 col-md-9"></div>
                    <div class="col-lg-2 col-md-2">
                        <span>Consumable Fiber Categories</span>
                        <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectdAsstCtgry"
                            (ngModelChange)="onAssetCtgrySlct($event)" nzPlaceHolder="Expense Category"
                            [ngModelOptions]="{standalone: true}">
                            <nz-option *ngFor="let t of AsstCtgry" [nzValue]="t.wrk_ast_id"
                                [nzLabel]="t.wrk_ast_nm"></nz-option>
                        </nz-select>
                    </div>
                    <div class="col-lg-1 col-md-1"></div>
                </div>

                <div class="col-lg-12 col-md-12 mgnb-2 p-8 " *ngIf="AsstGrapData && AsstGrapData.length>0">
                    <div id="AsstMultichart">
                        <apx-chart [series]="AsstMultiChartOptions?.series" [chart]="AsstMultiChartOptions?.chart"
                            [xaxis]="AsstMultiChartOptions?.xaxis" [markers]="AsstMultiChartOptions?.markers"
                            [stroke]="AsstMultiChartOptions?.stroke" [yaxis]="AsstMultiChartOptions?.yaxis"
                            [dataLabels]="AsstMultiChartOptions?.dataLabels" [title]="AsstMultiChartOptions?.title"
                            [fill]="AsstMultiChartOptions?.fill" [tooltip]="AsstMultiChartOptions?.tooltip"
                            [legend]="AsstMultiChartOptions?.legend"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>






</div>




<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="1500" [nzVisible]="visible"
    nzPlacement="right" nzTitle="Work View" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <app-wrok-profile [wrkData]="wrkData"></app-wrok-profile>
    </ng-container>
</nz-drawer>