<app-loading *ngIf="loader"></app-loading>
<div class="page">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">LOP</h3>
            </div>
        </div>
    </div>
    <div class="row" style="padding-left: 30px;
    padding-right: 30px;">
        <div class="alert alert-primary" role="alert" style="margin-top:20px;">
            NOTE : Payroll/Actual working days are calculated based on the holiday profile assigned to the
            employee.
            You can change holiday profile of the employee in their respective profile.
        </div>
    </div>
    <div class="page-header page-sub-header">
        <div class="row">
        
            <div class="col-md-2">
            </div>

            <!-- <form nz-form [formGroup]="searchForm">
                <div nz-row style="margin-top: 5px;">
                    <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                        <div class="filter-box">
                            <div class="form-label">Payroll Year </div>
                            <nz-select nzShowSearch formControlName="yr_id" style="width : 90%;margin-bottom: 30px;">
                                <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
                                </nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                        <div class="filter-box" >
                            <div class="form-label">District</div>
                            <nz-select nzShowSearch formControlName="dstrt_id" style="width : 90%;margin-bottom: 30px;">
                                <nz-option [nzValue]="zeroVal" [nzLabel]="'All'">
                                </nz-option>
                                <nz-option *ngFor="let o of data.dstrct_lst" [nzValue]="o.lctn_id"
                                    [nzLabel]="o.lctn_nm">
                                </nz-option>
                            </nz-select>
                        </div>
                        <div class="filter-box" *ngIf="data.dstrct_lst.length == 1">
                            <div class="form-label">District</div>
                            <div>{{data.dstrct_lst[0].lctn_nm}}</div>
                        </div>
                    </div>
                    <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                        <div class="filter-box">
                            <div class="form-label">Employee Type</div>
                            <nz-select nzShowSearch formControlName="emple_type_id"
                                style="width : 90%;margin-bottom: 30px;">
                                <nz-option [nzValue]="zeroVal" nzLabel="All">
                                </nz-option>
                                <nz-option *ngFor="let o of data.empleTypeLst" [nzValue]="o.emple_type_id"
                                    [nzLabel]="o.emple_type_nm">
                                </nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-col nzXs="24" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                        <div class="filter-box">
                            <div class="form-label">Employee Designation</div>
                            <nz-select nzShowSearch formControlName="dsgns_id"
                                style="width : 90%;margin-bottom: 30px;margin-left: 10px;">
                                <nz-option [nzValue]="zeroVal" nzLabel="All">
                                </nz-option>
                                <nz-option *ngFor="let o of data.dsgnsLst" [nzValue]="o.dsgns_id" [nzLabel]="o.dsgns_nm">
                                </nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-col nzXs="12" nzSm="3" nzMd="3" nzLg="3" nzXl="3">
                        <button nz-button nzType="primary" (click)="getPayrolls()"
                            style="margin-left:20px;margin-top:20px">Get
                            Payrolls</button>
                    </div>
                    <div nz-col nzXs="12" nzSm="3" nzMd="3" nzLg="3" nzXl="3"
                        *ngIf="data.hyrchy_id != 2 && data.hyrchy_id != 1" style="margin-top:20px;">
                        <button nz-button nzType="primary" (click)="gotogenerate()">
                            Generate Payroll
                        </button>
                    </div>
                </div>
            </form> -->



            <div class="col-md-3">

                <div class="form-label">Payroll Year </div>
                <nz-select nzShowSearch formControlName="yr_id" style="width : 90%;margin-bottom: 30px;">
                    <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
                    </nz-option>
                </nz-select>


                <!-- <div class="form-group">
                    <label class="">Select Year</label>
                    <div class="cal-icon">
                      

                        <nz-date-picker nzMode="year" [(ngModel)]="slctdyear" [nzFormat]="'YYYY'"
                            [nzPlaceHolder]="'Select Year'" (nzOnPanelChange)="onOpenYearCalendar($event)">
                        </nz-date-picker>

                    </div>
                </div> -->
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label class="">Select Month</label>
                    <div class="cal-icon">


                        <nz-date-picker nzMode="month" [(ngModel)]="slctdmnth" [nzFormat]="'MMM'"
                            [nzPlaceHolder]="'Select Month'" (nzOnPanelChange)="onOpenCalendar($event)">
                        </nz-date-picker>

                        <!-- <input class="form-control datetimepicker" [(ngModel)]="slctdmnth" type="text" bsDatepicker
                            [bsConfig]="{dateInputFormat: 'MMM'}" (onShown)="onOpenCalendar($event) "> -->
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button class="btn btn-success w-100" (click)="gotoWorkingDays()" style="margin-top: 17px;">
                    Get Data
                </button>
            </div>
            <div class="col-md-2">
            </div>
        </div>
    </div>

    <div class="content container-fluid">
        <div class="row" style="margin-top:15px" *ngIf="slctdEmplysLvsmry.length">
            <div class="col-md-12">
                <div>
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                        class="table table-striped custom-table datatable mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Total days in a month</th>
                                <th>Total Working Days in a month</th>
                                <th>Total pay days in a Month</th>
                                <th>Total loss of pay days in a Month</th>
                                <th>Overrided Pay Days Count</th>
                                <th>Overrided Loss of Pay days count</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of slctdEmplysLvsmry">
                                <td>{{item.fst_nm}} ({{item.emple_nu}})</td>
                                <td>{{item.tl_dy_ct}}</td>
                                <td>{{item.wk_dy_ct}}</td>
                                <td>{{item.py_dy_ct}}</td>
                                <td>{{item.lp_dy_ct}}</td>
                                <td> <input type="number" [(ngModel)]="item.updt_py_dy_ct"> </td>
                                <td> <input type="number" [(ngModel)]="item.updt_lp_dy_ct"> </td>
                                <td class="text-center" (click)="updateEmplyLveSmry(item)" style="cursor: pointer;">
                                    <span class="badge bg-inverse-danger">Save</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>