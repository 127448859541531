import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HolidaysComponent } from './holidays/holidays';
import { EmployeePayrollsComponent } from './employee-payrolls/employee-payrolls.component';
import { GeneratePayrollComponent } from './generate-payroll/generate-payroll.component';
import { PayrollApprovalsComponent} from './payroll-approvals/payroll-approvals.component';
import { PayrollDashboardComponent } from './payroll-dashboard/payroll-dashboard.component';
import { LopComponent } from './lop/lop.component';
// import { DistrictPayrollDashboardComponent } from './payroll-dashboard/district-payroll-dashboard/district-payroll-dashboard.component';
import { AdjustmentsArrearsComponent } from './adjustments-arrears/adjustments-arrears.component';
import { SalaryViewComponent } from './salary-view/salary-view.component';
import { HikesListComponent } from './hikes/hikes-list/hikes-list.component';
import { HolidaysComponents } from './holidays/holidays.component';
// import { PayrollItemsComponent } from './payroll-items/payroll-items.component';

const routes: Routes = [
  { path: 'holiday', component: HolidaysComponent },
  { path: 'holidays', component: HolidaysComponents },

   { path: 'payroll', component: EmployeePayrollsComponent },
  { path: 'dashboard', component: PayrollDashboardComponent },
  // { path: 'dashboard/district', component: DistrictPayrollDashboardComponent },
   { path: 'generate-payroll', component: GeneratePayrollComponent },
  { path: 'payroll-approvals', component: PayrollApprovalsComponent },
  {
    path: 'lop',
    component: LopComponent
  },
  {
    path: 'adjustments-arrears',
    component: AdjustmentsArrearsComponent
  },
  {
    path: "salary-view",
    component: SalaryViewComponent
  },
  {
    path: 'hikes',
    component: HikesListComponent
  },
  // {
  //   path: "payroll-items",
  //   component: PayrollItemsComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayrollRoutingModule { }