<div style="margin: -25px -25px -25px -15px;">
  <nz-layout>
    <div class='livemap-container'>
      <div id="livemap" style="height:88vh;"></div>
      <div class="filters" [ngStyle]="{'border-radius' :sidebarcollapsed?'50%':'' }">
        <div
          [ngStyle]="{'box-shadow': sidebarcollapsed ? '':'-4px 0px 5px 0 rgba(193, 193, 193, 0.78)','background': sidebarcollapsed ? 'transparent':''}">
          <h2 [ngStyle]="{'display': sidebarcollapsed ? 'none':'block'}">APSFL Network</h2>
          <button class="toggle-btn" style="margin-left: auto;" (click)="toggle()">
            <span nz-icon [nzType]="sidebarcollapsed ?'filter':'close'" nzTheme="outline"
              [ngStyle]="{'font-size': sidebarcollapsed? '30px':'20px'}"></span>
          </button>
        </div>
        <div class="sidebar-filters" [ngStyle]="{'display': sidebarcollapsed ? 'none':'block'}">
          <div class="sidebar-container">
            <form nz-form [formGroup]="form">
              <!-- District Dropdown -->
              <nz-form-item>
                <nz-form-label [nzSpan]="5" nzFor="district">District</nz-form-label>
                <nz-form-control [nzSpan]="18">
                  <nz-select formControlName="district" nzPlaceHolder="Select District">
                    <nz-option nzValue="0" nzLabel="All"></nz-option>
                    <nz-option *ngFor="let district of districts" [nzValue]="district.dstrt_id"
                      [nzLabel]="district.dstrt_nm"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <!-- Circle Dropdown -->
              <nz-form-item>
                <nz-form-label [nzSpan]="5" nzFor="circle">Circle</nz-form-label>
                <nz-form-control [nzSpan]="18">
                  <nz-select formControlName="circle" nzPlaceHolder="Select Circle" (nzFocus)="onCircleFocus()">
                    <nz-option *ngIf="circles?.length" nzValue="0" nzLabel="All"></nz-option>
                    <nz-option *ngFor="let circle of circles" [nzValue]="circle.oms_crcle_id"
                      [nzLabel]="circle.oms_crcle_nm"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <!-- Division Dropdown -->
              <nz-form-item>
                <nz-form-label [nzSpan]="5" nzFor="division">Division</nz-form-label>
                <nz-form-control [nzSpan]="18">
                  <nz-select formControlName="division" nzPlaceHolder="Select Division" (nzFocus)="onDivisionFocus()">
                    <nz-option *ngIf="divisions?.length" nzValue="0" nzLabel="All"></nz-option>
                    <nz-option *ngFor="let division of divisions" [nzValue]="division.oms_dvsn_id"
                      [nzLabel]="division.oms_dvsn_nm"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <!-- Ring Dropdown -->
              <nz-form-item>
                <nz-form-label [nzSpan]="5" nzFor="ring">Ring</nz-form-label>
                <nz-form-control [nzSpan]="18">
                  <nz-select formControlName="ring" nzPlaceHolder="Select Ring" (nzFocus)="onRingFocus()">
                    <nz-option *ngIf="rings?.length" nzValue="0" nzLabel="All"></nz-option>
                    <nz-option *ngFor="let ring of rings" [nzValue]="ring.oms_rng_id"
                      [nzLabel]="ring.oms_rng_nm"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <!-- <button nz-button nzType="primary" nzSize="small" nzShape="round">
                <span nz-icon nzType="download"></span>
                get latlongs
              </button> -->

              <!-- Date Range Picker -->
              <!-- <nz-form-item>
                <nz-form-label [nzSpan]="5" nzFor="dateRange">Date Range</nz-form-label>
                  <nz-form-control [nzSpan]="18">
                    <nz-range-picker formControlName="dateRange"></nz-range-picker>
                  </nz-form-control>
                </nz-form-item> -->
            </form>
          </div>
        </div>
      </div>
    </div>

  </nz-layout>
</div>