<div>
    <i nz-icon nzType="folder" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Generate
        Payroll</b>
</div>
<ds-loading *ngIf="settings.loader"></ds-loading>
<div style="text-align: right;">
    <button nz-button nzType="default" nzDanger (click)="gotopayrolls()">
        <span>Go Back</span>
    </button>
</div>
<div class="m-20">
    <div class="fltr_crd">
        <div style="color: black;font-weight: 500;">Select Month & Year For Which Payroll Need To Be Generated</div>
        <form nz-form [formGroup]="searchForm">
            <div nz-row style="margin-top: 5px;">
                <div nz-col nzSpan="4">
                    <div class="filter-box">
                        <div class="form-label">Year: </div>
                        <nz-select nzShowSearch formControlName="yr_id" style="width : 90%;margin-bottom: 30px;"
                            (ngModelChange)="getMonths()">
                            <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="4">
                    <div class="filter-box">
                        <div class="form-label">Month: </div>
                        <nz-select nzShowSearch formControlName="mnth_id"
                            style="width : 90%;margin-bottom: 30px;margin-left: 10px;">
                            <nz-option *ngFor="let o of data.mnths" [nzValue]="o.mnth_id" [nzLabel]="o.name">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="4">
                    <div class="filter-box">
                        <div class="form-label">Employee Type</div>
                        <nz-select nzShowSearch formControlName="emple_type_id"
                            style="width : 90%;margin-bottom: 30px;">
                            <nz-option [nzValue]="zeroVal" nzLabel="All"></nz-option>
                            <nz-option *ngFor="let o of data.empleTypeLst" [nzValue]="o.emple_type_id"
                                [nzLabel]="o.emple_type_nm">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="4">
                    <div class="filter-box">
                        <div class="form-label">Employee Designation</div>
                        <nz-select nzShowSearch formControlName="dsgns_id"
                            style="width : 90%;margin-bottom: 30px;margin-left: 10px;">
                            <nz-option [nzValue]="zeroVal" nzLabel="All"></nz-option>
                            <nz-option *ngFor="let o of data.dsgnsLst" [nzValue]="o.dsgns_id" [nzLabel]="o.dsgns_nm">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="3">
                    <button nz-button nzType="primary" (click)="getEmployees()"
                        style="margin-left:30px;margin-top:20px">Get
                        Employees</button>
                </div>
            </div>
        </form>
    </div>


    <div *ngIf="settings.shwNtFndDiv == false">
        <label nz-checkbox [(ngModel)]="data.pyrle_rn_in" style="margin-left: 20px;margin-bottom: 22px;"
            (ngModelChange)="shwNotAssigned()">Exclude
            employees with payroll already generated</label>
    </div>


    <div *ngIf="settings.shwNtFndDiv == false">
        <!-- <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input nz-input placeholder="Search here..." [(ngModel)]="data.filterTerm" />
        </nz-input-group>
        <ng-template #prefixTemplateUser><i nz-icon nzType="search"></i></ng-template> -->
        <div nz-row [nzGutter]="24" style="margin-top:20px">
            <div nz-col [nzSpan]="8">
                Total Employees : <b>{{data.allEmplesLst.length}}</b>
            </div>
            <div nz-col [nzSpan]="8">
                Selected Employees : <b>{{data.slctdEmples.length}}</b>
            </div>
            <div nz-col [nzSpan]="8">
                Payroll Generated Employees : <b>{{data.pyrleGnrtdEmpleLst.length}}</b>
            </div>
        </div>
        <div id="data-grid-demo">
            <dx-data-grid id="gridContainer" [dataSource]="data.allEmplesLst"
                (onSelectionChanged)="empSelectionChanged($event)" (onEditorPreparing)="overrideOnValueChanged($event)"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showColumnLines]="true"
                [showRowLines]="true" [allowColumnResizing]="true" [wordWrapEnabled]="true">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                </dxo-pager>
                <dxo-search-panel [visible]="true">
                </dxo-search-panel>
                <dxo-editing mode="cell" [allowUpdating]="false" [allowAdding]="false" [allowDeleting]="false">
                </dxo-editing>
                <dxo-selection mode="multiple"></dxo-selection>
                <!-- <dxi-column *ngFor="let f of data.columnDefs" [visible]="f.visible" [dataField]="f.field"
                    [caption]="f.headerName" [width]="f.width" [alignment]="f.alignment" [allowResizing]="true"
                    [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter">
                </dxi-column> -->
                <dxi-column dataField="sno" caption="S.No" width="50" alignment="center"></dxi-column>
                <dxi-column dataField="dstrt_nm" caption="District" width="200" alignment="left"></dxi-column>
                <dxi-column dataField="lctn_nm" caption="Rythu Bazar" width="200" alignment="left"></dxi-column>
                <dxi-column dataField="emple_nm" caption="Employee Name" width="200" alignment="left"></dxi-column>
                <dxi-column dataField="emple_nu" caption="Employee Number" width="150" alignment="left"></dxi-column>
                <dxi-column dataField="dsgns_nm" caption="Designation" width="200" alignment="left"></dxi-column>
                <dxi-column dataField="emple_type_nm" caption="Employee Type" width="200" alignment="left">
                </dxi-column>
                <dxi-column dataField="wkng_dy_ct" caption="Working Days" width="100" alignment="center"></dxi-column>
                <dxi-column dataField="pyrl_dy_ct" caption="Pay Days" width="100" alignment="center"></dxi-column>
                <dxi-column dataField="tl_dy_ct" caption="Total Days" width="100" alignment="center"></dxi-column>
                <dxi-column dataField="bsc_at" caption="Remuneration" width="150" alignment="center"></dxi-column>
                <dxi-column caption="Employer Contribution">
                    <dxi-column dataField="emplr_epf_pct_at" caption="EPF" width="150" alignment="center"></dxi-column>
                    <dxi-column dataField="emplr_esi_pct_at" caption="ESI" width="150" alignment="center"></dxi-column>
                </dxi-column>
                <dxi-column caption="Employee Contribution">
                    <dxi-column dataField="emple_epf_pct_at" caption="EPF" width="150" alignment="center"></dxi-column>
                    <dxi-column dataField="emple_esi_pct_at" caption="ESI" width="150" alignment="center"></dxi-column>
                </dxi-column>
                <dxi-column dataField="it_pct_at" caption="IT Amount" width="100" alignment="center"></dxi-column>
                <dxi-column dataField="pt_at" caption="PT" width="50" alignment="center"></dxi-column>
                <dxi-column dataField="gst_pct_at" caption="GST" width="100" alignment="center"></dxi-column>
                <dxi-column dataField="nt_at" caption="Total to be claimed" width="150" alignment="center"></dxi-column>
                <dxi-column dataField="bnk_nm" caption="Bank Name" width="200" alignment="center"></dxi-column>
                <dxi-column dataField="bnk_acnt_nu" caption="Bank Amount Number" width="200" alignment="center">
                </dxi-column>
                <dxi-column dataField="ifsc_cd" caption="Bank IFSC Code" width="200" alignment="center"></dxi-column>
                <dxi-column dataField="bnk_brnch_nm" caption="Bank Branch Name" width="200" alignment="center">
                </dxi-column>
                <dxi-column dataField="pyrle_sts_nm" caption="Payroll Status" width="200" alignment="left">
                </dxi-column>

                <dxo-summary>
                    <dxi-total-item column="bsc_at" summaryType="sum" alignment="right"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="emplr_epf_pct_at" summaryType="sum" alignment="right"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="emplr_esi_pct_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="emple_epf_pct_at" summaryType="sum" alignment="right"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="emple_esi_pct_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="it_pct_at" summaryType="sum" alignment="right"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="pt_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="gst_pct_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="Total: {0}">
                    </dxi-total-item>
                    <dxi-total-item column="nt_at" summaryType="sum"
                        [valueFormat]="{type:'currency',currency:'INR', precision:'2'}" alignment="right"
                        displayFormat="Total: {0}">
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
        <div style="text-align: center;margin-top:30px">
            <button nz-button nzType="primary" (click)="done()" [disabled]="data.slctdEmples.length == 0">
                Generate Payroll
            </button>
        </div>
    </div>
    <div *ngIf="settings.shwNtFndDiv == true">
        <div class="shwNtFndDiv">No Employees Data Found</div>
    </div>
</div>