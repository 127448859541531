import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import { UserService } from 'src/app/providers/user/user.serivce';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    searchVisible: boolean = false;
    quickViewVisible: boolean = false;
    isFolded: boolean;
    isExpand: boolean;
    clntDtls: any=[];
    usrDtls: any;
    ulbList: string[] = [];
    listOfSelectedValue = [];
    shwSdmnu = false;shwMblSdnav = false;
    lstlogdt: any;
    hostname;
    lgnPgData:any={};
    err_msg: string = '';
  loader: boolean = false;
    img: string;
    tntheaderstrip: string;
    clntheaderstrip: string;
    constructor(private themeService: ThemeConstantService, public router: Router, public apiSrc: ApiService, public userService: UserService) {
        this.hostname = location.hostname;
        console.log(this.hostname,JSON.parse(localStorage.getItem('wto_clients'))[0].clnt_id);
        if(JSON.parse(localStorage.getItem('wto_clients'))[0].clnt_id == 3000000){
            this.hostname='APSFL'

        }
        this.apiSrc.get('admin/web/login/page-details/' + this.hostname).subscribe((res) => {
          if (res['status'] == 200) {
            this.loader = false;
            if (res['data']) {
              console.log('kkkkkkkk',res['data'])
               this.lgnPgData = res['data'][0];
               console.log(this.lgnPgData)
            } else {
              this.loader = false;
              this.err_msg = "Something went wrong. Please contact the administrator.";
            }
          } else {
            this.loader = false;
            this.err_msg = "Something went wrong. Please contact the administrator.";
          }
        }, (err) => {
          this.loader = false;
          this.err_msg = "Something went wrong. Please contact the administrator.";
        })
        this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
        this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
        // this.chckMbl(true);
        console.log(this.clntDtls[0])
        this.img=`url(${this.clntDtls[0].tnts[0].tnt_banr_img_url_tx})`
        // this.img='url(/'+this.clntDtls[0].tnts[0].tnt_banr_img_url_tx+')'
        this.clntheaderstrip =`url(${this.clntDtls[0].clnt_banr_img_url_tx})`
        this.tntheaderstrip =`url(${this.clntDtls[0].tnts[0].tnt_banr_img_url_tx})`

        console.log(this.img)
    }

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.ulbList = this.clntDtls[0].tnts;
    }
    onUlbChange() {
        if (this.listOfSelectedValue.length) {
            this.listOfSelectedValue.map((val) => {
                if (val == 'select') {
                    this.listOfSelectedValue = [];
                    this.ulbList.map((val) => {
                        this.listOfSelectedValue.push(val['tnt_id']);
                    })
                    return;
                } else if (val == 'deselect') {
                    this.listOfSelectedValue = [];
                    return;
                }
            })
        }
    }
    ulbSelected() {
        // console.log("Selected ULBS :: ", this.listOfSelectedValue);
    }
    chckMbl(val){
        this.shwMblSdnav = val;
        // this.chkSdeVal = this.shwMblSdnav;
        console.log('INn',this.shwMblSdnav);
    }
    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }
    // toggleExpand() {
    //     this.isFolded = true;
    //     this.isExpand = !this.isExpand;
    //     this.themeService.toggleExpand(this.isExpand);
    //     this.themeService.toggleFold(this.isFolded);
    // }
    toggleExpand() {
        this.isExpand = !this.isExpand;
        this.themeService.toggleFold(this.isExpand);
    }
    logout() {
        this.apiSrc.get("auth/logout")
            .subscribe(res => {
                if (res['status'] == 200) {
                    this.userService.loggedIn(false);
                    localStorage.clear();
                    this.router.navigate(['/internal'], { replaceUrl: true });
                } else {
                }
            }, (err) => {
            })

    }

    editprfl() {
        this.router.navigateByUrl('internal/pages/profile')
    }


    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }
    gstLastLog() {
        const rte = `dashboard/enterprise/get_lastLogged`;
        this.apiSrc.get(rte).subscribe((res) => {
            if (res['status'] == 200) {
                this.lstlogdt = res['data'];
            }
        })
    }

}
