import { Component } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import { Router } from '@angular/router';
import { UserService } from './providers/user/user.serivce';
import { CstmSocketIOService } from './providers/socket-io.service';
import { HttpClient } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TransfereService } from './providers/transfer/transfer.service';
// import { VehicleMonitoringService } from './providers/vehicle-monitoring.service';
// import { PushNotificationsService } from './providers/push-notifications.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    selectedHeaderColor: string;
    empdata: any;
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;
    url: string;
    full_screen: boolean;
    clntDtls: any;
    usrDtls;
    socketId;
    interval: any;
    vehicleData: any;
    allRcvdData = [];
    constructor(private router: Router, public userService: UserService, public apiSrv: AppsService, private themeService: ThemeConstantService,
        private socketService: CstmSocketIOService, private http: HttpClient,
        // private notificationService: PushNotificationsService,
        private notification: NzNotificationService,
        public TransfereService: TransfereService,
    ) {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
        this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    }
    ngOnInit(): void {

        this.userService.userLoggedIn.subscribe((event: any) => {
            console.log(event);
            if (event == true) {
                this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
                this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));

                console.log(this.socketId, "ssssssssssssssssssssss");

                if (this.usrDtls.rle_id == 3000005) {
                    // if (Notification.permission === 'default') {
                    //     Notification.requestPermission().then((permission) => {
                    //         if (permission === 'granted') {
                    //             console.log('Push notifications enabled');
                    //         }
                    //     });
                    // }
                    // this.notificationService.requestPermission();
                    // this.notificationService.showNotification();
                    // this.http.get(`/alerts/veh/notifications/${this.socketId}`).subscribe(response => {
                    // console.log('Notifications:', response);
                    this.socketService.initSocketIOCnct();
                    // this.socketId = this.socketService.getSocketIOSessionID();
                    this.socketService.getSocketIOSessionID().subscribe(socketId => {
                        this.socketId = socketId;
                        console.log('Retrieved Socket ID:', this.socketId);
                    });
                    this.saveScktIdToUser();
                    this.listenToSocketNotifs();


                    // });
                }
            } else {
                this.clntDtls = null;
                this.usrDtls = null;
            }
        });
        this.url = this.router.url;
        if (this.router.url == "/") this.full_screen = true;
        else this.full_screen = false;
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

        // if (this.usrDtls.rle_id == 3000005) {
        //     if (Notification.permission === 'default') {
        //         Notification.requestPermission().then((permission) => {
        //             if (permission === 'granted') {
        //                 console.log('Push notifications enabled');
        //             }
        //         });
        //     }
        //     setInterval(() => {
        //         this.vehicleMonitorService.fetchVehicleData();
        //     }, 60000);
        // }

    }
    listenToSocketNotifs() {
        this.socketService.listenForNotifications().subscribe((msg: any) => {
            // console.log('Socket Notification:', msg);
            this.vehicleData = msg.msg;
            // if (this.vehicleData.length > 0) {
            // console.log(this.vehicleData);

            this.vehicleData.forEach((vehicle: any) => {
                // this.interval = setInterval(() => {
                this.shwNotifications(vehicle);
                // }, 600);
            });
            // this.allRcvdData.push(this.vehicleData)
            // }
        });
    }
    shwNotifications(vehicle) {
        // const notification = this.notificationService.showNotification(
        //     'Vehicle Stopped',
        //     `${vehicle.alert_tx}`
        // );
        // if (notification) {
        //     notification.onclick = () => {
        //         clearInterval(this.interval);
        //         this.router.navigate(['/internal/notifications/list']);
        //         window.focus();
        //     };
        // } else {
        //     console.warn('Notification could not be created. Check if permissions are granted.');
        // }
        // console.log(vehicle)
        this.notification.blank(
            '',
            `<div class="ntfctn">
            <div >
               <img src="/assets/images/logo/icon.png" >            
            </div>
            <div >
              <div class="ntfctn_title">Vehicle Stoppage Notification </div>
                ${vehicle.alert_tx}           
            </div>
        </div>
        `,
            { nzDuration: 20000, nzStyle: { boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '10px', margin: '10px',nzCloseIcon: true } }
        )
            .onClick.subscribe(() => {
                clearInterval(this.interval);
                this.TransfereService.setLoclData('vehData', this.vehicleData)
                this.router.navigate(['/internal/notifications/list']);
            })
    }
    saveScktIdToUser() {
        console.log(this.socketId);
        if (this.socketId != undefined) {
            this.http.post('/alerts/user/save-socket-id', { sckt_id: this.socketId }).subscribe(response => {
                console.log('Socket ID saved:', response);
            });
        }
    }
}
