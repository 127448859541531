<div class="ldngOvrlyCss">
      <div class="loading-indicator">
        <div class="spinner-2" *ngIf="lgnPgData && lgnPgData.app_hdr_nm == 'CLAP' && clntDtls[0].spr_admn_in!=1">
          <img src="assets/images/logo/swatchAndraLgo.png" style="max-width: 150%;    max-width: 215%;margin-left: -40%; margin-top: -36%; padding: 10%;">
        </div>
        <div class="spinner-2" *ngIf="lgnPgData && lgnPgData.app_hdr_nm == 'Wetrackon' || (lgnPgData && lgnPgData.app_hdr_nm == 'CLAP' && clntDtls[0].spr_admn_in==1)">
          <img src="assets/images/logo/icon.png" style="max-width: 150%;    max-width: 215%;margin-left: -40%; margin-top: -36%; padding: 10%;">
        </div>
        <div class="spinner-2 apsfl" *ngIf="lgnPgData && lgnPgData.app_hdr_nm == 'APSFL' || (lgnPgData && lgnPgData.app_hdr_nm == 'APSFL')">
          <img src="assets/images/apsfl_login_logo.png" style="max-width: 150%;    max-width: 215%;margin-left: -40%; margin-top: -36%; padding: 10%;">
        </div>
      </div>
</div>
