import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { AppsService } from 'src/app/shared/services/apps.service';
import * as _ from 'lodash'
import * as am5radar from "@amcharts/amcharts5/radar";
import { error } from 'console';
import { differenceInCalendarDays } from 'date-fns';

import { ActivatedRoute, Router } from '@angular/router';


import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { DsGridOptns } from 'src/app/apps/custom-grid/grid-optns-interface';
@Component({
  selector: 'app-attndce-dashboard',
  templateUrl: './attndce-dashboard.component.html',
  styleUrls: ['./attndce-dashboard.component.scss']
})
export class AttndceDashboardComponent implements OnInit, OnDestroy {
  clntDtls: any;
  tntLst: any;
  header_label = {
    name: `attendance Dashboard`,
    dtepkrlbl: false
  };
  selectedtenant: any;
  userDtls: any;
  isVisible: boolean;
  slctdDt = new Date();
  curDate: string;
  dateFormat = 'dd/MM/yyyy';
  monthFormat = 'yyyy/MM';
  today = new Date();
  filters: boolean;
  showLdr = false;
  emply_lst = []
  private root: am5.Root;  // Store reference to the chart root element
  columnDefs: any;
  header: 'attendance Dashboard'
  peChrtDta: any[];
  prsnt_cnt: any;
  absnt_cnt: any;
  emply_cnt: any;
  grd_lvl = 0
  slctdTnt: any;
  summary
  svnDyDta: any;
  pieRoot: am5.Root;
  pieChart: any;
  stackedRoot: am5.Root;
  stackedChart: any;
  assetCardsData: any[];
  pstAbstnc: any;
  ttlEmplys: any;
  tnt_nm: any;
  dsgnAtndnce: any;
  emlpyLst7DyDta: any;
  wrkLcnVal: any;
  crdsDta: any;
  hstyroot: am5.Root;
  wrkLctnroot: am5.Root;
  topFiveroot: am5.Root;
  ovrlPrcntRoot: am5.Root;
  tdyHrsroot: am5.Root;
  ovrlAtndnc: am5.Root;
  EmployData: any;
  columnEmplyDefs: ({ headerName: string; field: string; alignment: string; width: number; } | { headerName: string; field: string; width: string; alignment: string; } | { headerName: string; field: string; alignment: string; width?: undefined; })[];
  sidenav: boolean;
  drwerTitle: string;
  wrkData: any;
  selectedMonth: any
  months = [
    { "mn_id": 1, "mn_nm": "January" },
    { "mn_id": 2, "mn_nm": "February" },
    { "mn_id": 3, "mn_nm": "March" },
    { "mn_id": 4, "mn_nm": "April" },
    { "mn_id": 5, "mn_nm": "May" },
    { "mn_id": 6, "mn_nm": "June" },
    { "mn_id": 7, "mn_nm": "July" },
    { "mn_id": 8, "mn_nm": "August" },
    { "mn_id": 9, "mn_nm": "September" },
    { "mn_id": 10, "mn_nm": "October" },
    { "mn_id": 11, "mn_nm": "November" },
    { "mn_id": 12, "mn_nm": "December" }
  ];
  Years=[{ "myr_id": 1, "yr_nm": 2021 },
    { "myr_id": 2, "yr_nm": 2022 },
    { "myr_id": 3, "yr_nm": 2023 },
    { "myr_id": 4, "yr_nm": 2024 },
    { "myr_id": 5, "yr_nm": 2025 },
    { "myr_id": 6, "yr_nm": 2026 }
  ]
  settings = {
    shwGrd: false
  }
  binddate: string;
  selecteYear: any;
  sidenav11: boolean;

  constructor(public apiServ: AppsService,) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.selectedtenant = 0
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    const currentMonth = new Date().getMonth() + 1;
    this.selectedMonth = currentMonth
    const currentYear = new Date().getFullYear();
    this.selecteYear =currentYear
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  // Initialize the chart when the component is created
  ngOnInit() {
    // this.intializeChartOvrlAtndc()
    // this.intializeChartTdyWrksHrs()
    // this.intializeChartOvrlWrkdHrs()
  this.binddate=moment(this.slctdDt).format('DD-MM-YYYY')
    this.getEmplyAtndce();
    this.getLst7daysAtndce();
    this.getWrkLctnDta();
    this.getCrdsDta();
    this.getDistEmplay(this.selectedMonth,this.selecteYear);
  }




  intializeChartOvrlAtndc() {
    this.ovrlAtndnc = am5.Root.new("chartdiv");
    this.ovrlAtndnc.setThemes([
      am5themes_Animated.new(this.ovrlAtndnc)
    ]);
    let chart = this.ovrlAtndnc.container.children.push(
      am5radar.RadarChart.new(this.ovrlAtndnc, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    chart.getNumberFormatter().set("numberFormat", "#'%'");
    let axisRenderer = am5radar.AxisRendererCircular.new(this.ovrlAtndnc, {
      innerRadius: -40
    });

    axisRenderer.grid.template.setAll({
      stroke: this.ovrlAtndnc.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.ovrlAtndnc, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    let axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(this.ovrlAtndnc, {
      pinRadius: 50,
      radius: am5.percent(100),
      innerRadius: 50,
      bottomWidth: 0,
      topWidth: 0
    });

    clockHand.pin.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });
    clockHand.hand.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.5
    });

    let bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(this.ovrlAtndnc, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(this.ovrlAtndnc, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em"
      })
    );

    axisDataItem.set("value", 50);
    bullet.get("sprite").on("rotation", function () {
      let value = axisDataItem.get("value");
      label.set("text", Math.round(value).toString() + "%");
    });

    setInterval(function () {
      let value = Math.round(Math.random() * 100);

      axisDataItem.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange0.animate({
        key: "endValue",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange1.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }, 2000);

    chart.bulletsContainer.set("mask", undefined);

    let colorSet = am5.ColorSet.new(this.ovrlAtndnc, {});

    let axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: 50
      })
    );

    axisRange0.get("axisFill").setAll({
      visible: true,
      fill: colorSet.getIndex(0)
    });

    axisRange0.get("label").setAll({
      forceHidden: true
    });

    let axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 50,
        endValue: 100
      })
    );

    axisRange1.get("axisFill").setAll({
      visible: true,
      fill: colorSet.getIndex(4)
    });

    axisRange1.get("label").setAll({
      forceHidden: true
    });

    // Make stuff animate on load
    chart.appear(1000, 100);
  }
  intializeChartTdyWrksHrs() {
    this.tdyHrsroot = am5.Root.new("chartdivCrPrcntg");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    this.tdyHrsroot.setThemes([
      am5themes_Animated.new(this.tdyHrsroot)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = this.tdyHrsroot.container.children.push(
      am5radar.RadarChart.new(this.tdyHrsroot, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    chart.getNumberFormatter().set("numberFormat", "#'%'");

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    let axisRenderer = am5radar.AxisRendererCircular.new(this.tdyHrsroot, {
      innerRadius: -40
    });

    axisRenderer.grid.template.setAll({
      stroke: this.tdyHrsroot.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.tdyHrsroot, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    let axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(this.tdyHrsroot, {
      pinRadius: 50,
      radius: am5.percent(100),
      innerRadius: 50,
      bottomWidth: 0,
      topWidth: 0
    });

    clockHand.pin.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });
    clockHand.hand.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.5
    });

    let bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(this.tdyHrsroot, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(this.tdyHrsroot, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em"
      })
    );

    axisDataItem.set("value", 50);
    bullet.get("sprite").on("rotation", function () {
      let value = axisDataItem.get("value");
      label.set("text", Math.round(value).toString() + "%");
    });

    setInterval(function () {
      let value = Math.round(Math.random() * 100);

      axisDataItem.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange0.animate({
        key: "endValue",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange1.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }, 2000);

    chart.bulletsContainer.set("mask", undefined);

    let colorSet = am5.ColorSet.new(this.tdyHrsroot, {});

    let axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: 50
      })
    );

    axisRange0.get("axisFill").setAll({
      visible: true,
      fill: colorSet.getIndex(0)
    });

    axisRange0.get("label").setAll({
      forceHidden: true
    });

    let axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 50,
        endValue: 100
      })
    );

    axisRange1.get("axisFill").setAll({
      visible: true,
      fill: colorSet.getIndex(4)
    });

    axisRange1.get("label").setAll({
      forceHidden: true
    });

    // Make stuff animate on load
    chart.appear(1000, 100);
  }
  intializeChartOvrlWrkdHrs() {
    this.ovrlPrcntRoot = am5.Root.new("chartdivOvrlPrcntg");
    this.ovrlPrcntRoot.setThemes([
      am5themes_Animated.new(this.ovrlPrcntRoot)
    ]);
    let chart = this.ovrlPrcntRoot.container.children.push(
      am5radar.RadarChart.new(this.ovrlPrcntRoot, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    chart.getNumberFormatter().set("numberFormat", "#'%'");
    let axisRenderer = am5radar.AxisRendererCircular.new(this.ovrlPrcntRoot, {
      innerRadius: -40
    });

    axisRenderer.grid.template.setAll({
      stroke: this.ovrlPrcntRoot.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.ovrlPrcntRoot, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );
    let axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(this.ovrlPrcntRoot, {
      pinRadius: 50,
      radius: am5.percent(100),
      innerRadius: 50,
      bottomWidth: 0,
      topWidth: 0
    });

    clockHand.pin.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 1,
      strokeDasharray: [2, 2]
    });
    clockHand.hand.setAll({
      fillOpacity: 0,
      strokeOpacity: 0.5,
      stroke: am5.color(0x000000),
      strokeWidth: 0.5
    });

    let bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(this.ovrlPrcntRoot, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(this.ovrlPrcntRoot, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em"
      })
    );

    axisDataItem.set("value", 50);
    bullet.get("sprite").on("rotation", function () {
      let value = axisDataItem.get("value");
      label.set("text", Math.round(value).toString() + "%");
    });

    setInterval(function () {
      let value = Math.round(Math.random() * 100);

      axisDataItem.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange0.animate({
        key: "endValue",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });

      axisRange1.animate({
        key: "value",
        to: value,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }, 2000);

    chart.bulletsContainer.set("mask", undefined);

    let colorSet = am5.ColorSet.new(this.ovrlPrcntRoot, {});

    let axisRange0 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 0,
        endValue: 50
      })
    );

    axisRange0.get("axisFill").setAll({
      visible: true,
      fill: colorSet.getIndex(0)
    });

    axisRange0.get("label").setAll({
      forceHidden: true
    });

    let axisRange1 = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
        value: 50,
        endValue: 100
      })
    );

    axisRange1.get("axisFill").setAll({
      visible: true,
      fill: colorSet.getIndex(4)
    });

    axisRange1.get("label").setAll({
      forceHidden: true
    });

    // Make stuff animate on load
    chart.appear(1000, 100);

  }
  intializeHstryChrt() {
    // Dispose of the existing root if it exists
    if (this.hstyroot) {
      this.hstyroot?.dispose();
    }

    // Create a new root
    this.hstyroot = am5.Root.new("hstryChart");
    this.hstyroot.setThemes([am5themes_Animated.new(this.hstyroot)]);

    // Create the chart
    let chart = this.hstyroot.container.children.push(am5xy.XYChart.new(this.hstyroot, {
      panX: false,
      panY: false,
      paddingLeft: 0,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: this.hstyroot.verticalLayout
    }));

    let legend = chart.children.push(am5.Legend.new(this.hstyroot, {
      centerX: am5.p50,
      x: am5.p50
    }));

    // Check if data is available
    let data = this.svnDyDta;
    console.log('Data:', data);  // Log the data to check its structure

    // Define x-axis renderer and create x-axis
    let xRenderer = am5xy.AxisRendererX.new(this.hstyroot, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.hstyroot, {
      categoryField: "frmtd_dt",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(this.hstyroot, {})
    }));

    xRenderer.grid.template.setAll({ location: 1 });

    xAxis.get("renderer").labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100
    });

    xAxis.data.setAll(data);

    // Create y-axis
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.hstyroot, {
      renderer: am5xy.AxisRendererY.new(this.hstyroot, {
        strokeOpacity: 0.1
      })
    }));

    // Create series as an arrow function to maintain `this` context
    const makeSeries = (name: string, fieldName: string) => {
      let series = chart.series.push(am5xy.ColumnSeries.new(this.hstyroot, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "frmtd_dt"
      }));

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0
      });

      series.data.setAll(data);
      series.appear();

      series.bullets.push(() => {
        return am5.Bullet.new(this.hstyroot, {
          locationY: 0,
          sprite: am5.Label.new(this.hstyroot, {
            text: "{valueY}",
            fill: this.hstyroot.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      legend.data.push(series);
    };

    // Create series for each data field
    makeSeries("Present Count", "prsnt_cnt");
    makeSeries("Absent Count", "absnt_cnt");
    makeSeries("On-time Check-in", "onTm_chk_in");
    makeSeries("Late Check-in", "lt_chk_in");
    makeSeries("Early Check-in", "erly_chk_in");
    makeSeries("Early Check-out", "erly_chk_out");
    makeSeries("Over Time", "ovr_tm");
    makeSeries("Under Time", "undr_tm");

    // Make chart appear
    chart.appear(1000, 100);
  }

  intializeWrkLctnChrt() {
    if (this.wrkLctnroot) {
      this.wrkLctnroot?.dispose();
    }
    this.wrkLctnroot = am5.Root.new("wrkLctnChrt");
    this.wrkLctnroot.setThemes([
      am5themes_Animated.new(this.wrkLctnroot)
    ]);
    let chart = this.wrkLctnroot.container.children.push(am5percent.PieChart.new(this.wrkLctnroot, {
      layout: this.wrkLctnroot.verticalLayout,
      innerRadius: am5.percent(50)
    }));
    let series = chart.series.push(am5percent.PieSeries.new(this.wrkLctnroot, {
      valueField: "value",
      categoryField: "category",
      alignLabels: false
    }));

    series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0
    });
    series.data.setAll(this.wrkLcnVal);
    let legend = chart.children.push(am5.Legend.new(this.wrkLctnroot, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      marginTop: 15,
      marginBottom: 15,
    }));

    legend.data.setAll(series.dataItems);
    series.appear(1000, 100);
  }
  intializeTop5chart() {
    if (this.topFiveroot) {
      this.topFiveroot?.dispose()
    }
    this.topFiveroot = am5.Root.new("atndncTop5Chart");
    this.topFiveroot.setThemes([
      am5themes_Animated.new(this.topFiveroot)
    ]);
    let data = this.emply_lst
    let chart = this.topFiveroot.container.children.push(
      am5xy.XYChart.new(this.topFiveroot, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        paddingRight: 30,
        wheelX: "none",
        wheelY: "none"
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(this.topFiveroot, {
      minorGridEnabled: true
    });
    yRenderer.grid.template.set("visible", false);
    if (this.grd_lvl == 0) {
      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(this.topFiveroot, {
          categoryField: "tnt_nm",
          renderer: yRenderer,
          paddingRight: 40
        })
      );
    }
    else {
      var yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(this.topFiveroot, {
          categoryField: "emple_nm",
          renderer: yRenderer,
          paddingRight: 40
        })
      );
    }

    let xRenderer = am5xy.AxisRendererX.new(this.topFiveroot, {
      minGridDistance: 80,
      minorGridEnabled: true
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(this.topFiveroot, {
        min: 0,
        renderer: xRenderer
      })
    );
    if (this.grd_lvl == 0) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(this.topFiveroot, {
          name: "Income",
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "prsnt_cnt",
          categoryYField: "tnt_nm",
          sequencedInterpolation: true,
          calculateAggregates: true,
          maskBullets: false,
          tooltip: am5.Tooltip.new(this.topFiveroot, {
            dy: -30,
            pointerOrientation: "vertical",
            labelText: "{valueX}"
          })
        })
      );
    }
    else {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(this.topFiveroot, {
          name: "Income",
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: "prsnt_cnt",
          categoryYField: "emple_nm",
          sequencedInterpolation: true,
          calculateAggregates: true,
          maskBullets: false,
          tooltip: am5.Tooltip.new(this.topFiveroot, {
            dy: -30,
            pointerOrientation: "vertical",
            labelText: "{valueX}"
          })
        })
      );
    }

    series.columns.template.setAll({
      strokeOpacity: 0,
      cornerRadiusBR: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusTL: 10,
      maxHeight: 50,
      fillOpacity: 0.8
    });

    let currentlyHovered;

    series.columns.template.events.on("pointerover", function (e) {
      handleHover(e.target.dataItem);
    });

    series.columns.template.events.on("pointerout", function (e) {
      handleOut();
    });

    function handleHover(dataItem) {
      if (dataItem && currentlyHovered != dataItem) {
        handleOut();
        currentlyHovered = dataItem;
        let bullet = dataItem.bullets[0];
        bullet.animate({
          key: "locationX",
          to: 1,
          duration: 600,
          easing: am5.ease.out(am5.ease.cubic)
        });
      }
    }

    function handleOut() {
      if (currentlyHovered) {
        let bullet = currentlyHovered.bullets[0];
        bullet.animate({
          key: "locationX",
          to: 0,
          duration: 600,
          easing: am5.ease.out(am5.ease.cubic)
        });
      }
    }


    let circleTemplate = am5.Template.new({});

    series.bullets.push(function (root, series, dataItem) {
      let bulletContainer = am5.Container.new(root, {});
      let circle = bulletContainer.children.push(
        am5.Circle.new(
          root,
          {
            radius: 34
          }
        )
      );

      let maskCircle = bulletContainer.children.push(
        am5.Circle.new(root, { radius: 27 })
      );
      let imageContainer = bulletContainer.children.push(
        am5.Container.new(root, {
          mask: maskCircle
        })
      );
      let image = imageContainer.children.push(
        am5.Picture.new(root, {
          templateField: "pictureSettings",
          centerX: am5.p50,
          centerY: am5.p50,
          width: 60,
          height: 60
        })
      );

      return am5.Bullet.new(root, {
        locationX: 0,
        sprite: bulletContainer
      });
    });
    series.set("heatRules", [
      {
        dataField: "valueX",
        min: am5.color(0xe5dc36),
        max: am5.color(0x5faa46),
        target: series.columns.template,
        key: "fill"
      },
      {
        dataField: "valueX",
        min: am5.color(0xe5dc36),
        max: am5.color(0x5faa46),
        target: circleTemplate,
        key: "fill"
      }
    ]);

    series.data.setAll(data);
    yAxis.data.setAll(data);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(this.topFiveroot, {}));
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    cursor.events.on("cursormoved", function () {
      let dataItem = series.get("tooltip").dataItem;
      if (dataItem) {
        handleHover(dataItem)
      }
      else {
        handleOut();
      }
    })
    series.appear();
    chart.appear(1000, 100);
  }
  getEmplyAtndce() {
    var postDta
    if (this.grd_lvl == 0) {
      postDta = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
      }

    }
    else {
      postDta = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
        slctd_tnt: this.selectedtenant
      }
    }
    console.log(postDta)
    this.apiServ.post(postDta, `apsfl/employe/attendence`).subscribe((res) => {
      if (res['status'] == 200) {
        this.emply_lst = res['data'];
        console.log(this.emply_lst);
        this.intializeTop5chart()
      }
    }
      , (err) => {
        console.log(err)
      });
  }
  getLst7daysAtndce() {
    console.log('this function called')
    var post
    if (this.grd_lvl == 0) {
      post = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
      }

    }
    else {
      post = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
        slctd_tnt: this.selectedtenant
      }
    }
    console.log(post)
    this.apiServ.post(post, `apsfl/employe/lst7dayAtndnce`).subscribe((res) => {
      if (res['status'] == 200) {
        this.svnDyDta = res['data'];
        console.log(this.svnDyDta);
        this.intializeHstryChrt()
      }
      else {
        throw new Error('Something Went Wrong, please try again');
      }
    },
      (error) => {
        console.error('Fetch error:', error);

      }
    )
  }
  getWrkLctnDta() {
    var post
    console.log('getWrkLctnDta')
    if (this.grd_lvl == 0) {
      post = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
      }

    }
    else {
      post = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
        slctd_tnt: this.selectedtenant
      }
    }
    this.apiServ.post(post, `apsfl/employe/wrkLcnDta`).subscribe((res) => {
      if (res['status'] == 200) {
        this.wrkLcnVal = res['data'];
        console.log(this.wrkLcnVal);
        this.intializeWrkLctnChrt()
      }
      else {
        throw new Error('Something Went Wrong, please try again');

      }
    }, (error) => {
      console.error('Fetch error:', error);

    })

  }
  getCrdsDta() {
    var post
    console.log('getWrkLctnDta')
    if (this.grd_lvl == 0) {
      post = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
      }

    }
    else {
      post = {
        date: moment(this.slctdDt).format('YYYY-MM-DD'),
        grd_lvl: this.grd_lvl,
        slctd_tnt: this.selectedtenant
      }
    }
    this.apiServ.post(post, `apsfl/employe/cardsDta`).subscribe((res) => {
      if (res['status'] == 200) {
        this.crdsDta = res['data'];
        console.log(this.crdsDta)
      }
      else {
        throw new Error('Something Went Wrong, please try again');
      }
    }, (error) => {
      console.log(error)
    })
  }
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.curDate = moment(this.slctdDt).format('DD-MM-YYYY');
    this.getEmplyAtndce();
    this.getLst7daysAtndce();
    this.getWrkLctnDta();
    this.getCrdsDta()
  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) > 0;
  };
  ontntSelect(value) {
    this.binddate=moment(this.slctdDt).format('DD-MM-YYYY')
    if (value == 0) {
      this.grd_lvl = 0
      this.selectedtenant = 0;
    }
    else {
      this.grd_lvl = 1
      this.selectedtenant = value;
      this.tnt_nm = this.tntLst.find((tn) => tn.tnt_id == value)?.tnt_nm
    }
    this.getEmplyAtndce();
    this.getLst7daysAtndce();
    this.getWrkLctnDta();
    this.getCrdsDta()
  }

  // continue from clearing charts instance
  cleChrtRoot() {
    if (this.stackedChart) {
      this.stackedRoot?.dispose();
      this.stackedChart = null; // Clear the reference
    }

    if (this.pieChart) {
      this.pieRoot?.dispose();
      this.pieChart = null; // Clear the reference
    }
  }
  goBack() {
    console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
    this.tdyHrsroot
    if (this.grd_lvl == 1) {
      this.grd_lvl = 0;
      this.selectedtenant = 0
      this.getEmplyAtndce();
      this.getLst7daysAtndce();
      this.getWrkLctnDta();
      this.getCrdsDta()
    }
  }



  getDistEmplay(mnth,yr) {
    var postData = {}
    this.apiServ.get(`apsfl/Attendence/attemploye/gridData/${mnth}/${yr}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.EmployData = res['data'];
        var count = 1
        this.EmployData.forEach(e => {
          e['sno'] = count++
        });

        this.columnEmplyDefs = [
          // { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
          // { headerName: 'District Name', field: 'tnt_nm', width: 300, alignment: 'left', groupIndex: 0 },
          { headerName: 'Employee Name', field: 'emple_nm', width: 300, alignment: 'left' },
          { headerName: 'Mobile Number ', field: 'mble_ph', width: 'auto', alignment: 'center' },
          { headerName: 'License', field: 'emple_nu', alignment: 'left' },
          { headerName: 'Date Of Joining', field: 'DOJ', alignment: 'center' },
          { headerName: 'Working Days', field: 'wrkng_dys', width: 'auto', alignment: 'center' },
          { headerName: 'Present', field: 'prsnt_cnt', alignment: 'center' },
          { headerName: 'Absent', field: 'absnt_cnt', alignment: 'center' },
        ];
        // this.prepareDsDataGrdOptns(this.columnEmplyDefs, this.EmployData)
      }

    })
  }


  onExporting(e: DxDataGridTypes.ExportingEvent) {
    console.log('onExporting')
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), ' Vehicles Summary.xlsx');
      });
    });
  }
  isGrouped = false;
  // onContentReady(e) {
  //   const groupedColumns = e.component.getVisibleColumns().filter(c => c.groupIndex !== undefined);
  //   this.isGrouped = groupedColumns.length > 0;
  // }

  focusedRowKey = 1;
  autoNavigateToFocusedRow = true;
  onFocusedRowChanging(e) {
    console.log(e)
    if (e) {
      const rowsCount = e.component.getVisibleRows().length;
      const pageCount = e.component.pageCount();
      const pageIndex = e.component.pageIndex();
      console.log("rowsCount", rowsCount)
      console.log("pageCount", pageCount)
      console.log("pageIndex", pageIndex)
    }

  }
  gotoProfile(data, cnd) {
    
    console.log(data)
    this.sidenav11 = true;



    // wrkDtls[0]?.wrk_typ_nm}



    this.drwerTitle = ` ${data.data.emple_nm}`


    this.wrkData = data.data;
  }

  closeSideBar() {
   
    try {
      this.sidenav11 = false
    } catch (error) {
      console.error('Error while closing drawer:', error);
    }
  }
  getmonthWiseDeatils(e) {
    this.getDistEmplay(this.selectedMonth,this.selecteYear)
  }
  dsGridOptns: DsGridOptns = {
    dataSource: [],
    colDef: [],
    buttons: [],
    analytics: [],
    title: 'Employees',
    minPageSizes: 10,
    enableMultiSelect: false,
    exportFormats: [],
    gridSettings: { header: { color: '' } }
  };
  prepareDsDataGrdOptns(colDefDtls, dataSourceDtls) {
    console.log(colDefDtls)
    console.log(dataSourceDtls)

    let btns = [

      {
        btn_plc: 'row',
        btn_type: 'button',
        icn_nm: 'view',
        label: 'Profile',
        color: 'prmary',
        btn_hndlr_txt: 'vw_emp_prfle',
      }
    ];

    this.dsGridOptns.colDef = colDefDtls;
    this.dsGridOptns.dataSource = dataSourceDtls;
    this.dsGridOptns.buttons = btns;

    console.log(this.dsGridOptns)

    this.dsGridOptns.analytics = [];
    this.dsGridOptns.title = '';
    this.dsGridOptns.minPageSizes = 10;
    this.dsGridOptns.enableMultiSelect = false;
    this.dsGridOptns.exportFormats = ['xlsx'];
    this.settings.shwGrd = true;
    this.dsGridOptns = { ...this.dsGridOptns };
  }

  grdClckActnEmtr(e) {
    console.log(e)
    this.sidenav = true;
    this.drwerTitle = ` ${e.row_data
      .emple_nm}`
    this.wrkData = e.row_data
      ;
  }
}
