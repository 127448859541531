<div class="row">
    <div class="col-md-3">

        <div class="crd_style summary-card " style="height: 90%;">
            <div class="icon-container">
                <img src="assets/images/apsfl/employees.gif" alt="">
            </div>
            <div class=" rwpdng ">
                <div class="cnt">{{cntcrd ?.tot_emps}}
                </div>
                <div class="crd_title"> <span class="cstmfnt" style="color: #3F51B5;"> Total Employees</span>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-3">
        <div class="crd_style summary-card" style="height: 90%;">
            <div class="icon-container">
                <img src="assets/images/apsfl/truck.gif" alt="">
            </div>
            <div class=" rwpdng ">
                <div class="cnt">{{cntcrd ?.tot_vhs}}
                </div>
                <div class="crd_title"> <span class="cstmfnt" style="color: #3F51B5;"> Total Vehicles</span>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-3">
        <div class="crd_style summary-card " style="height: 90%;">
            <div class="icon-container">
                <img src="assets/images/apsfl/substation.gif" alt="">
            </div>
            <div class=" rwpdng ">
                <div class="cnt">{{INRFormater(cntcrd?.substn_ct -3) || 0}}
                </div>
                <div class="crd_title"> <span class="cstmfnt" style="color: #3F51B5;"> Sub Stations</span>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-3">
        <div class="crd_style summary-card " style="height: 90%;">
            <div class="icon-container">
                <img src="assets/images/apsfl/connection.gif" alt="">
            </div>
            <div class=" rwpdng ">
                <div class="cnt">{{INRFormater(cntcrd?.olt_ct ) || 0}}
                    <!-- {{cntcrd?.tot_vhs}} -->
                </div>
                <div class="crd_title"> <span class="cstmfnt" style="color: #3F51B5;"> OLT's</span>
                </div>
            </div>

        </div>
    </div>

    <div class="col-md-12 mt-20">
        <div class="row">
            <div class="col-md-4">
                <div class="crd_style" (click)="selctedcard('WORKS', '#544795')">
                    <div class="row">
                        <div class="col-md-5 cstmcol">
                            <div class="actvts" style="    background: #544795;">Activities <span
                                    style="color: #ffdaa1;">({{WorksCrdData?.ttl_wrks}})</span></div>
                        </div>
                        <div class="col-md-7 lftpdngnone" style="    text-align: right;">
                            <!-- <div class="col-md-6" style="text-align: center;"> -->
                            <div class="tabs_data" *ngFor="let t of tabsActdata" (click)="slectdActTab(t)"
                                [ngClass]="t.tab_id == slctdActdt ? 'slct_dt' : 'dflt_dt' ">
                                {{t.tab_nm}}
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="row rwpdng">

                        <div class="col-md-3">
                            <div class="cnt">{{WorksCrdData?.in_prgrs}}
                                <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                                    <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
                     color: #d76f52;    vertical-align: baseline;"></span>
                                  </a> -->

                            </div>
                            <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;"> In Progress</span>
                            </div>
                        </div>


                        <div class="col-md-3">
                            <div class="cnt">{{WorksCrdData?.cmpltd_wrks}}
                                <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                                    <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
                     color: #d76f52;    vertical-align: baseline;"></span>
                                  </a> -->

                            </div>
                            <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;"> Completed</span>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="cnt">{{ INRFormater(WorksCrdData?.cstmr_efctd_ct || 0)  }}
                          

                            </div>
                            <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> CAF's Effected(In Progress) </span>
                            </div>
                        </div>



                        <!-- <div class="col-md-4">
                            <div class="cnt">{{WorksCrdData?.tot_emps}} -->
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                                    <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
                     color: #d76f52;    vertical-align: baseline;"></span>
                                  </a> -->

                        <!-- </div>
                            <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> Total
                                    Employees</span>
                            </div>
                        </div> -->


                    </div>

                    <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                        [stroke]="chartOptions?.stroke" [fill]="chartOptions?.fill" [yaxis]="chartOptions?.yaxis"
                        [xaxis]="chartOptions?.xaxis" [colors]="chartOptions?.colors">
                    </apx-chart>
                    <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
                    left: 50%;
                    font-size: 30px;    color: #544795;" *ngIf="slectdCrd == 'WORKS'"></span>
                </div>

            </div>
            <div class="col-md-4">
                <div class="crd_style" (click)="selctedcard('EXPENSES','#d1861d')">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="actvts" style="background: #d1861d;">Expenses</div>
                        </div>
                        <div class="col-md-7" style="    text-align: right;">
                            <!-- <div class="col-md-6" style="text-align: center;"> -->
                            <div class="tabs_data" *ngFor="let t of tabsExpdata" (click)="slectdExpTab(t)"
                                [ngClass]="t.tab_id == slectdExpdt ? 'slct_dt' : 'dflt_dt' ">
                                {{t.tab_nm}}
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="row rwpdng">
                        <div class="col-md-8">
                            <div class="cnt">{{ SumINRFormater(expnsCrdData?.tot_exp_at) || 0}}
                                <!-- <a nz-dropdown [nzDropdownMenu]="menu3" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'">
                                <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
            color: #d49640;    vertical-align: baseline;"></span> 
            </a> -->
                            </div>
                            <div class="crd_title" style="margin-top: 19px;"> <span class="cstmfnt"
                                    style="color: #f19000;    ">
                                </span></div>
                        </div>
                        <!-- <div class="col-md-4"></div> -->
                        <!-- <div class="col-md-4"></div> -->
                    </div>
                    <apx-chart [series]="chartOptionsthree?.series" [chart]="chartOptionsthree?.chart"
                        [stroke]="chartOptionsthree?.stroke" [fill]="chartOptionsthree?.fill"
                        [yaxis]="chartOptionsthree?.yaxis" [xaxis]="chartOptionsthree?.xaxis"
                        [colors]="chartOptionsthree?.colors">
                    </apx-chart>
                    <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
                    left: 50%;
                    font-size: 30px;    color: #d1861d;" *ngIf="slectdCrd == 'EXPENSES'"></span>
                </div>
            </div>


            <div class="col-md-4">
                <div class="crd_style" (click)="selctedcard('TRIPS','#3e8fb3')">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="actvts" style="    background: #3e8fb3;">Travel Registry <span
                                    style="color: #ffdaa1;">({{tripCrdData?.ttl_trps}})</span></div>
                        </div>
                        <div class="col-md-7" style="    text-align: right;">
                            <!-- <div class="col-md-6" style="text-align: center;"> -->
                            <div class="tabs_data" *ngFor="let t of tabsTrvlRgstrydata" (click)="slectdTrvlRgstryTab(t)"
                                [ngClass]="t.tab_id == slctdTrvldt ? 'slct_dt' : 'dflt_dt' ">
                                {{t.tab_nm}}
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="row rwpdng">
                        <div class="col-md-4">
                            <div class="cnt">{{tripCrdData?.in_trp_ct}}
                                <!-- <a nz-dropdown [nzDropdownMenu]="menu2" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'">
                                    <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;color: #6cbee3;    vertical-align: baseline;"></span>
                                  </a> -->

                            </div>
                            <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;"> Running Trips</span>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="cnt">{{tripCrdData?.cmpltd_trps}}
                                <!-- <a nz-dropdown [nzDropdownMenu]="menu2" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'">
                                    <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;color: #6cbee3;    vertical-align: baseline;"></span>
                                  </a> -->

                            </div>
                            <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;"> Completed</span>
                            </div>
                        </div>

                        <!-- <div class="col-md-4">
                            <div class="cnt">{{tripCrdData?.tot_vhs}} -->
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                                    <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
                color: #d76f52;    vertical-align: baseline;"></span>
                                  </a> -->

                        <!-- </div>
                            <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> Total
                                    Vehicles</span>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-4"></div> -->
                        <!-- <div class="col-md-4"></div> -->
                    </div>
                    <apx-chart [series]="chartOptionstwo?.series" [chart]="chartOptionstwo?.chart"
                        [stroke]="chartOptionstwo?.stroke" [fill]="chartOptionstwo?.fill"
                        [yaxis]="chartOptionstwo?.yaxis" [xaxis]="chartOptionstwo?.xaxis"
                        [colors]="chartOptionstwo?.colors">
                    </apx-chart>
                    <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
                    left: 50%;
                    font-size: 30px;    color: #3e8fb3;" *ngIf="slectdCrd == 'TRIPS'"></span>
                </div>
            </div>



        </div>
    </div>


</div>


<!-- <nz-dropdown-menu #menu="nzDropdownMenu"> -->

<!-- <ul nz-menu> -->
<!-- <li> -->
<!-- <div class="row rwpdng">
        <div class="col-md-4">
            <div class="drp_cnt">320</div>
            <div class="drp_crd_title"> Total</div>
        </div>

        <div class="col-md-4">
            <div class="drp_cnt">70</div>
            <div class="drp_crd_title"> In Progress</div>
        </div>
        <div class="col-md-4">
            <div class="drp_cnt">250</div>
            <div class="drp_crd_title"> Completed</div>
        </div>

    
        </div> -->
<!-- </li> -->
<!-- </ul> -->

<!-- 
    <ul nz-menu nzSelectable>
        <li nz-menu-item>
            <span nz-icon="" class="anticon" style="color: #3F51B5;
                font-size: 12px;
                margin-right: 5px;">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                    <path
                        d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z">
                    </path>
                </svg></span>
           <span style="margin-right:10px"> Forwarded from previous months</span>
            <span style="float: right;font-weight: 700;">28</span>
        </li>
        <li nz-menu-item>
            <span nz-icon="" class="anticon" style="color: #ffad35;
                font-size: 12px;
                margin-right: 5px;">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                    <path
                        d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z">
                    </path>
                </svg></span>
    
                <span style="margin-right:10px"> Current month</span> <span style="float: right;font-weight: 700;">1</span>
        </li>
    
    </ul>


  </nz-dropdown-menu>


<nz-dropdown-menu #menu2="nzDropdownMenu">
    <ul nz-menu nzSelectable>
        <li nz-menu-item>
            <span nz-icon="" class="anticon" style="color: #3F51B5;
                font-size: 12px;
                margin-right: 5px;">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                    <path
                        d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z">
                    </path>
                </svg></span>
           <span style="margin-right:10px"> Forwarded from previous months</span>
            <span style="float: right;font-weight: 700;">45</span>
        </li>
        <li nz-menu-item>
            <span nz-icon="" class="anticon" style="color: #ffad35;
                font-size: 12px;
                margin-right: 5px;">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                    <path
                        d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z">
                    </path>
                </svg></span>
    
                <span style="margin-right:10px"> Current month</span> <span style="float: right;font-weight: 700;">4</span>
        </li>
    </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #menu3="nzDropdownMenu">
    <ul nz-menu nzSelectable>
        <li nz-menu-item>
            <span nz-icon="" class="anticon" style="color: #3F51B5;
                font-size: 12px;
                margin-right: 5px;">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                    <path
                        d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z">
                    </path>
                </svg></span>
           <span style="margin-right:10px"> Forwarded from previous months</span>
            <span style="float: right;font-weight: 700;">₹20,000</span>
        </li>
        <li nz-menu-item>
            <span nz-icon="" class="anticon" style="color: #ffad35;
                font-size: 12px;
                margin-right: 5px;">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
                    <path
                        d="M864 64H160C107 64 64 107 64 160v704c0 53 43 96 96 96h704c53 0 96-43 96-96V160c0-53-43-96-96-96z">
                    </path>
                </svg></span>
    
                <span style="margin-right:10px"> Current month</span> <span style="float: right;font-weight: 700;">₹1,024</span>
        </li>
    </ul>
</nz-dropdown-menu> -->