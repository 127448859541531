import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { PayrollService } from '../payroll.service';
// import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AppsService } from 'src/app/shared/services/apps.service';
// import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-lop',
  templateUrl: './lop.component.html',
  styleUrls: ['./lop.component.scss']
})

export class LopComponent implements OnInit {
  // @ViewChild(DataTableDirective, { static: false })
  // public dtElement: DataTableDirective;
  // public dtOptions: DataTables.Settings = {};
  public dtTrigger: Subject<any> = new Subject();
  slctdyear = null;
  slctdmnth = null;
  loader: boolean;
  slctdEmplysLvsmry = [];
  frstTym = true;
  constructor(private Serv: PayrollService, public http: HttpClient,private apisrvc: AppsService,) {

  }

  ngOnInit(): void {
    this.getYears()
    // this.dtOptions = {
    //   // ... skipped ...
    //   pageLength: 10,
    //   dom: "lrtip",
    // };
  }
  data = {
    permissions: { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 },
    years: [],
    empleLst: [],
    pyrlsLst: [],
    columnDefs: [],
    dsgnsLst: [],
    empleTypeLst: [],
    pyrle_rn_in: false,
    dstrct_lst: [],
    hyrchy_id: null,
    payrlRuns: [],
    columnDefs1: [],
    columnDefs2: [],
    mnth_nm: null,
    sidebarHeading: '',
    emple_type_id: 0,
    pyrle_mnth_rn_id: null,
    excelName: '',
    allEmpleLst: [],
    empleColumnDefs: [],
    AllEmpleLst: [],
    yr_id: null,
    mnth_id: null,
    lctns_id: null,
    rjctn_rsn_tx: null
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }
  onOpenYearCalendar(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('year');
  }

  gotoWorkingDays() {
    if (this.slctdmnth != null && this.slctdmnth != '' && this.slctdmnth != undefined && this.slctdyear != null && this.slctdyear != '' && this.slctdyear != undefined) {
      this.loader = true;
      let stDta = {
        yr: moment(this.slctdyear).format('YYYY'),
        mnth: moment(this.slctdmnth).format('M'),
        emplys: []
      }
      this.Serv.getEmplysLvsmryLst(stDta).subscribe((res) => {
        this.loader = false;
        if (res['status'] == 200) {
          if (this.frstTym == true) {
            this.slctdEmplysLvsmry = res['data'];
            setTimeout(() => {
              this.dtTrigger.next();
            }, 200)
            this.frstTym = false;
          } else {
            // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            //   dtInstance.destroy();
            // });
            this.slctdEmplysLvsmry = res['data'];
            setTimeout(() => {
              this.dtTrigger.next();
            }, 200)
          }
        } else {
          // this.toastr.error(res['message'], "Error");
        }
      });
    } else {
      // this.toastr.error('Please Select Year and Month', "Error");
    }
  }


  
  getYears() {
   
    const rte = `payroll/years`;
    this.apisrvc.get(rte).subscribe(res => {

      this.data.years = res['data'];
      // setTimeout(() => {
      //   this.searchForm.controls['yr_id'].setValue(parseInt(moment(new Date()).format('YYYY')));
      //   this.getDistricts();
      // }, 50)
    });
  }

  updateEmplyLveSmry(data) {
    this.loader = true;
    if (data.updt_py_dy_ct > data.wk_dy_ct) {
      // this.toastr.error('Overrided Payroll Day count Cannot be greater than working days count', "Error");
      return;
    } else {
      let obj = {
        updt_pydays: data.updt_py_dy_ct,
        updt_lopdays: data.updt_lp_dy_ct,
        emply: data.emple_id,
        mnth_id: data.mnth_id,
        yr_id: data.yr_id
      }
      this.Serv.updEmplysLvsmryLst(obj).subscribe((res) => {
        this.loader = false;
        if (res['status'] == 200) {
          // this.toastr.success("Leaves Overrided Successfully", "Success");
        } else {
          // this.toastr.error(res['message'], "Error");
        }
      });
    }
  }

}

