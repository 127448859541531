import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { map, catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from "../api.service";


export const environment = {
    // origin: 'http://rmc.dreamstep.info/apiv1',
    origin: 'https://ent.wetrackon.com/apiv1'
};

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    private isRefreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private accessToken = null;
    public _sessionLogout: boolean = false;

    constructor(private router: Router, private message: NzMessageService, private modal: NzModalService, private apiServ: ApiService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.accessToken = localStorage.getItem('x-access-token');
        // Taking an access token
        if (this.accessToken && this.accessToken == 'undefined') this.accessToken = '';

        let url1 = req.url.indexOf("http") > -1 ? req.url : environment.origin + req.url;
        req = req.clone({ url: url1 });
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

        // cloning a request and adding Authorization header with token
        req = this.addToken(req, this.accessToken);
        req = req.clone({ withCredentials: true });


        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.headers.get('x-access-token')) {
                        let newToken = event.headers.get('x-access-token');
                        localStorage.setItem('x-access-token', newToken);
                    }
                    // console.log(event)
                    if (event.status == 200)
                        if (event.body.status == 404) {
                            this.accessDenied(event.body.message);
                        }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    // calling refresh token api and if got success extracting token from response and calling failed api due to token expired                   
                    return this.handle401Error(req, next);
                } // If api not throwing 406 or 403 but gives an error throwing error 
                else if (error.status == 406 || error.status == 403) {
                    if (!this._sessionLogout && !(this.router.url === '/internal' || this.router.url === '/')) {
                        localStorage.clear();
                        this.sessionError(error.error.message);
                        this.router.navigate(['/'], { replaceUrl: true });
                        this._sessionLogout = true;
                    }
                }
                return throwError(error);
            }));
    }



    private addToken(req: HttpRequest<any>, token: string) {
        if (token) {
            req = req.clone({ headers: req.headers.set('x-access-token', token) });
            req = req.clone({ headers: req.headers.set('Cache-Control', 'no-cache') });
            req = req.clone({ headers: req.headers.set('Pragma', 'no-cache') });
        }
        return req;
    }


    /**
     * This method will called when any api fails due to 401 and calsl for refresh token
     */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // If Refresh token api is not already in progress
        if (this.isRefreshing == false) {
            // updating variable with api is in progress
            this.isRefreshing = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            return this.apiServ.create(localStorage.getItem('x-access-token'), 'auth2/admin/refresh-token').pipe(switchMap(
                (res: any) => {
                    if (res && res.data) {
                        let newToken = res.data;
                        if (newToken && newToken != 'undefined') {
                            localStorage.setItem('x-access-token', `${newToken}`);
                            this.refreshTokenSubject.next(newToken);
                            // updating value of expires in variable                    
                            return next.handle(this.addToken(request, newToken));
                        }
                    }
                })
                , catchError((error) => {
                    if (error.status == 406 || error.status == 403) {
                        if (!this._sessionLogout) {
                            localStorage.clear();
                            if (!(this.router.url === '/internal' || this.router.url === '/')) {
                                this.sessionError(error.error.message);
                                this.router.navigate(['/'], { replaceUrl: true });
                            }
                            this._sessionLogout = true;
                        }
                    }
                    return throwError(error);
                })
                , finalize(() => {
                    this.isRefreshing = false;
                })
            );

        } else {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject
                .pipe(
                    filter(token => token != null),
                    take(1),
                    switchMap(jwt => {
                        return next.handle(this.addToken(request, jwt))
                    }));

        }
    }
    public accessDenied(message) {
        this.message.create('error', `${message}`);
    }
    public sessionError(message) {
        const modal: NzModalRef = this.modal.error({
            nzTitle: `Please login again.`,
            nzContent: `${message}`,
            nzMaskClosable: false,
            nzClosable: false,
            nzFooter: [
                {
                    label: 'Ok',
                    shape: 'round',
                    onClick: () => {
                        modal.destroy();
                        localStorage.clear();
                        this.router.navigate(['/'], { replaceUrl: true });

                    }
                }
            ]
        });
    }
}