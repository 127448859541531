<div style="width: 100%;" class="bg-img_top">
    <!-- <div style="font-size: 12px;
    text-align: center;
    margin-top: 10px;
    font-weight: 600;    margin-top: 20px;
    padding-bottom: 4px;">Vehicle Number</div>
    <div style="width: 50%;font-weight: 600 ; font-size: 12px;    font-weight: 600;
    font-size: 12px;
    background: #e7af65;
    width: fit-content;
    color: #fff;
    padding: 2px 10px;
    border-radius: 29px;
    height: 20px;
    line-height: 16px;
    margin-top: 5px;    margin: 0px auto;">{{trpPnts[0]?.asrt_nm}}</div> -->
    <div class="infoval row">
        <div class="col-md-4">Activities <span style="color:#00bcd4;font-weight: 600;">({{trpPnts[0]?.ttl_wrks}})</span>
        </div>
        <div class="col-md-8"><span>: </span>{{trpPnts[0]?.wrk_typs}}</div>
    </div>
    <div class="infoval row">
        <div class="col-md-4">Travelled Employees<span
                style="color:#00bcd4;font-weight: 600;">({{trpPnts[0]?.emp_ct}})</span>
        </div>
        <div class="col-md-8"><span>: </span>{{trpPnts[0]?.emps}}</div>
    </div>
</div>



<div class="">
    <div id="map"></div>
</div>

<div style="    margin-top: 15px;">
    <div class="timeline-item" *ngFor="let s of trpPnts">
        <div class="timeline-icon"></div>
        <div class="timeline-content bg-img">
            
            <div class="content-right-container" style="max-width: 100%;width: 100%;">
                <div class="content-right">
                    <div class="p">

                        <div style="display: flex;width: 100%;">
                            <div style="width: 50%; font-size: 12px;" *ngIf="s.img_upld_txt == 'start'">Trip Started
                                On:
                            </div>
                            <div style="width: 50%; font-size: 12px;" *ngIf="s.img_upld_txt == 'End'">Trip Completed
                                On:
                            </div>
                            <div style="width: 50%;font-weight: 600 ;font-size: 12px;"
                                *ngIf="s.img_upld_txt == 'start'">{{
                                s.actl_strt_ts }}</div>
                            <div style="width: 50%;font-weight: 600 ;font-size: 12px;"
                                *ngIf="s.img_upld_txt == 'End'">{{
                                s.actl_end_ts }}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="cntnt_hdr">Attachments</div>

                    <div class="row">
                        <div class="col-md-3" *ngFor="let i of s.imgs">
                            <div >
                                <img [src]=i.img_url_tx>
                                <div class="">
                                    {{i.img_typ_nm}}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="meta-date">
                <span class="date">{{ s.Sno }}</span>
            </div>
        </div>
    </div>
</div>