<div class="row">
    <div class="col-md-6">

        <div class=" cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
            <div class="row">
                <div class="col-md-5 cstmcol">
                    <div class="actvts" style="    background: #544795;">Activities <span
                            style="color: #ffdaa1;">({{wrkData && wrkData.no_of_wrk_assigned ?
                            wrkData.no_of_wrk_assigned : 0 }})</span></div>
                </div>
                <div class="col-md-7 lftpdngnone" style="    text-align: right;">

                    <div class="tabs_data" *ngFor="let t of tabsActdata" (click)="slectdActTab(t)"
                        [ngClass]="t.tab_id == slctdActdt ? 'slct_dt' : 'dflt_dt' ">
                        {{t.tab_nm}}
                    </div>

                </div>
            </div>
            <div class="row rwpdng">


                <div class="col-md-4">

                    <div class="cnt">{{empdtls && empdtls.length ? empdtls[0].in_prgrs: 0}}
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    In Progress
                </div>


                <div class="col-md-4">
                    <div class="cnt">{{empdtls && empdtls.length ? empdtls[0].cmpleted: 0}}

                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> Completed</span>
                    </div>
                </div>


            </div>
        </div>

    </div>
    <div class="col-md-6">

        <div class=" cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
            <div class="row">
                <div class="col-md-5 cstmcol">
                    <div class="actvts" style="    background: #544795;">Attendance <span
                            style="color: #ffdaa1;"></span></div>
                </div>
                <div class="col-md-7 lftpdngnone" style="    text-align: right;">

                    <div class="tabs_data" *ngFor="let t of tabsAtnddata" (click)="slectdAtnTab(t)"
                        [ngClass]="t.tab_id == slctdAtnd ? 'slct_dt' : 'dflt_dt' ">
                        {{t.tab_nm}}
                    </div>

                </div>
            </div>
            <div class="row rwpdng">

                <div class="col-md-4">
                    <div class="cnt">{{empdtlsr && empdtlsr.length ? empdtlsr[0].total_days : 0}}
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;"> Total Days</span></div>
                </div>


                <div class="col-md-4">
                    <div class="cnt">{{empdtlsr && empdtlsr.length ? empdtlsr[0].present: 0}}
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;"> Present</span></div>
                </div>


                <div class="col-md-4">
                    <div class="cnt">{{empdtlsr && empdtlsr.length ? empdtlsr[0].absent: 0}}
                        <!-- <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomLeft" [nzOverlayClassName]="'cstmdrpdwn'" style="padding-left: 10px;">
                            <span nz-icon nzType="info-circle" nzTheme="outline" style="font-size: 20px;
        color: #d76f52;    vertical-align: baseline;"></span>
                          </a> -->

                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #3F51B5;"> Absent</span>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-4"></div>

    <div class="col-md-6">

        <div class="col-lg-12 col-md-12">
     
            <!-- <nz-date-picker   [nzFormat]="dateFormat"    (ngModelChange) = "getfrmdt(frm_dt)" [(ngModel)]="frm_dt" ></nz-date-picker> -->

            <nz-range-picker [(ngModel)]="dateFormat" nzFormat="dd MM yyyy"
                (ngModelChange)="onChange($event)"></nz-range-picker>
        </div>

        <!-- <div class="col-lg-4 col-md-6">
                <span>To Date:</span>
                <nz-date-picker [nzFormat]="dateFormat"   (ngModelChange) = "getfrmdts(to_dt)"   [(ngModel)]="to_dt" ></nz-date-picker>
            </div> -->



    </div>
    <div class="col-lg-2 col-md-2">
        <!-- Optional content like buttons, empty space -->
    </div>
</div>




<div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
    <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">

        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="grddtls" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                    [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                </dxo-pager> -->
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
            </dxo-search-panel>
            <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-column-chooser [enabled]="false" mode="select">
            </dxo-column-chooser>
            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
            <dxo-export [enabled]="true" fileName=""></dxo-export>
            <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
            <dxi-column *ngFor="let d of columnEmplyDefs" [dataField]="d.field" [visible]="d.visible"
                [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" type="buttons" [width]="100" caption="View"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="gotoProfile(data,'employ')">
                        <i nz-icon nzType="eye" nzTheme="outline"></i>
                        <p>View Profile</p>
                    </div>
                </div>
            </dxi-column>

        </dx-data-grid>
    </div>
</div>


<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="1000" [nzVisible]="sidenav"
    [nzTitle]="drwerTitle" (nzOnClose)="closeSideBar()" nzWrapClassName="wrkPrfleDrawer">
    <ng-container *nzDrawerContent>
        <!-- <div *ngIf="appCond==1"> -->
        <app-wrok-profile [wrkData]="wrksdata"></app-wrok-profile>
        <!-- </div> -->
    </ng-container>
</nz-drawer>