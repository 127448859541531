<nz-layout class="rmv_mrgn">
  <nz-layout>
    <nz-content>
      <div class="inner-content">

        <button nz-button (click)="closeSidebar();" *ngIf="opnSdebar == true" class="sdNavBtn rt-4h">
          <!-- <i nz-icon nzType="filter" nzTheme="outline"></i> -->
          Close</button>
        <button nz-button (click)="openSidebar();" *ngIf="opnSdebar == false" class="sdNavBtn2 rt-40">
          <i nz-icon nzType="filter" nzTheme="outline"></i>Filters</button>
        <div id="playbackmap"></div>
      </div>
    </nz-content>
  </nz-layout>


  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzWidth] = "390" [nzTrigger]="null" *ngIf="opnSdebar == true" class="sdr" >
    <h2 class="sdHdr">Playback
      <span nz-icon nzType="close-circle" nzTheme="outline" style="    float: right;
      margin-top: 3px;" (click) = "closedrvrlst()"></span>
    </h2>
   
    <form nz-form [formGroup]="plyBckform">

      <div class="row mg-b6">
        <nz-form-label nzRequired *ngIf="clntDtls[0].clnt_id==3000000">District</nz-form-label>
        <nz-form-label nzRequired *ngIf="clntDtls[0].clnt_id==7">Show ULB/Mandal</nz-form-label>
        <nz-form-label nzRequired *ngIf="clntDtls[0].clnt_id!=7 && clntDtls[0].clnt_id!=3000000">Show ULB</nz-form-label>

        <!-- <div class="col-lg-4 col-md-4">
            </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>

              <nz-select nzShowSearch class="slctBx" [(ngModel)]="selectedtenants"
                (ngModelChange)="getvehiclesFunctn($event)" [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'Select All'" [nzValue]="0" *ngIf="showAll"></nz-option>
                <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="1"></nz-option> -->
                <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm"></nz-option>
              </nz-select>

            </nz-form-control>
          </nz-form-item>


        </div>
      </div>

      <div class="row mg-b6" *ngIf="ShowClap">
        <nz-form-label nzRequired>Select Vehicle Vendor</nz-form-label>
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedvndr" (ngModelChange)="onvndrSelect($event)"
                [ngModelOptions]="{standalone: true}">
                <nz-option [nzLabel]="'All'" [nzValue]="0" *ngIf="!showVndr"></nz-option>
                <nz-option *ngFor="let v of vendorList" [nzValue]="v.vndr_id" [nzLabel]="v.vndr_nm"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>


        </div>
      </div>
      <!-- <div class="row mg-b6">
        <nz-form-label nzRequired>Select Location</nz-form-label>
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>

              <nz-select class="slctBx" [nzMaxTagCount]="2" nzPlaceHolder="Please select Location"
                (ngModelChange)="updt($event)" formControlName="lctn">
                <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option>
                <nz-option *ngFor="let l of activeGroups" [nzLabel]="l.fnce_grp_nm" [nzValue]="l.fnce_grp_id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div> -->
      <div class="row mg-b6">
        <nz-form-label nzRequired>Select Location Group</nz-form-label>
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-select [nzMaxTagCount]="2" nzPlaceHolder="Please select Location group" [(ngModel)]="locModel"
                (ngModelChange)="onLocationSelect($event)" formControlName="lctn">
                <!-- <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option> -->
                <nz-option *ngFor="let l of activeGroups" [nzLabel]="l.fnce_grp_nm" [nzValue]="l.fnce_grp_id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="row mg-b6">
        <nz-form-label nzRequired>Select Location</nz-form-label>
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-select [nzMaxTagCount]="2" nzPlaceHolder="Please select Location" [(ngModel)]="fnclocModel"
                (ngModelChange)="getLocOnMap()" formControlName="lctnFnce">
                <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option>
                <nz-option *ngFor="let l of fncsLst" [nzLabel]="l.fnce_nm" [nzValue]="l.fnce_id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>


      <div class="row mg-b6">
        <nz-form-label nzRequired>Select Vehicle</nz-form-label>
        <!-- <div class="col-lg-4 col-md-4">
          <nz-form-label nzRequired>Select Vehicle</nz-form-label>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-select class="slctBx" nzShowSearch nzAllowClear nzPlaceHolder="Please select Vehicle"
                formControlName="vhcl" (ngModelChange)="getTripsbyAsrt($event)">
                <nz-option *ngFor="let v of vhclsDta" [nzLabel]="v.asrt_nm" [nzValue]="v.asrt_id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row mg-b6">
        <nz-form-label nzRequired>Select PlaybackType</nz-form-label>
        <!-- <div class="col-lg-4 col-md-4">
          <nz-form-label nzRequired>Select PlaybackType</nz-form-label>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-select class="slctBx" nzShowSearch nzAllowClear nzPlaceHolder="Please select Playback Type"
                formControlName="plybtype" (ngModelChange)="getPlybackType($event)">
                <nz-option nzLabel="ByTrip" nzValue="1"></nz-option>
                <nz-option nzLabel="ByTime" nzValue="2"></nz-option>

              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="row mg-b6" *ngIf="trpdate">
        <nz-form-label nzRequired>Select TripDate</nz-form-label>
        <!-- <div class="col-lg-4 col-md-4">
          <nz-form-label nzRequired>Select TripDate</nz-form-label>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-date-picker formControlName="trpdt" (ngModelChange)="getTrpDate($event)"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>


      <div class="row mg-b6" *ngIf="trpdtl">
        <nz-form-label nzRequired>Select Trip</nz-form-label>
        <!-- <div class="col-lg-4 col-md-4">
          <nz-form-label nzRequired>Select Trip</nz-form-label>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-select class="slctBx" nzShowSearch nzAllowClear nzPlaceHolder="Please select Trip"
                formControlName="trp" (ngModelChange)="getTripData($event)">
                <nz-option *ngFor="let t of trpDta" [nzLabel]="t.rte_nm" [nzValue]="t.trp_rn_id"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="row mg-b6" *ngIf="strtdate">
        <nz-form-label nzRequired>Start Date</nz-form-label>
        <!-- <div class="col-lg-4 col-md-4">
          <nz-form-label nzRequired>Start Date</nz-form-label>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-date-picker nzShowTime formControlName="strtTm"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="row mg-b6" *ngIf="enddate">
        <nz-form-label nzRequired>End Date</nz-form-label>
        <!-- <div class="col-lg-4 col-md-4">
          <nz-form-label nzRequired>End Date</nz-form-label>
        </div> -->
        <div class="col-lg-12 col-md-12">
          <nz-form-item>
            <nz-form-control>
              <nz-date-picker nzShowTime formControlName="endTm"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="input-row" nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn btnGrp">
          <!-- <button nz-button nzType="primary">Primary Button</button>
                    <button nz-button nzType="default">Default Button</button> -->
          <button nz-button nzType="track" (click)=trackVehicle()>Track</button>
          <button nz-button nzType="pause" (click)=pause() *ngIf="pauseVal == true">Pause</button>
          <button nz-button nzType="play" (click)=play() *ngIf="pauseVal == false">Play</button>
        </div>
        <ds-loading *ngIf="showLdr"></ds-loading>
      </div>



    </form>


    <!-- <label nz-checkbox  *ngFor="let c of cntxt"  [(ngModel)]="checked" (ngModelChange)="chckFun($event)" [nzValue]="c"   *ngIf="cntxt.length!=0">{{c.fnce_nm}}</label> -->
    <!-- <div *ngFor="let c of cntxt; let i=index"  *ngIf="cntxt.length!=0">
      <label>
        <input type="checkbox" [value]="c.fnce_id" (change)="chckFun($event)" />
        {{c.fnce_nm}}
      </label>
    </div> -->
    <!-- <div style="border-bottom: 1px solid rgb(233, 233, 233);" *ngIf="cntxt.length!=0">
      <label nz-checkbox [(ngModel)]="allChecked" [nzValue]="cntxt" (ngModelChange)="updateAllChecked($event)" [nzIndeterminate]="indeterminate">
        Check all
      </label>
    </div>
    <br />
    <nz-checkbox-group [(ngModel)]="cntxt" [nzValue]="cntxt"   (ngModelChange)="updateSingleChecked($event)"></nz-checkbox-group> -->

    <!-- <div style="text-align:center" *ngIf="cntxt.length!=0">
      <h6>
        ------ Trip Coverage Areas ------
      </h6>

      <div class="container">
        <div class="text-center" style="margin-top: 3px;">
          <div class="row">
            <ul class="list-group">
              <li class="list-group-item">
                <span>
                  Select/ Unselect All
                </span>
                <span>
                  <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                    (change)="checkUncheckAll()" />
                </span>
              </li>
            </ul>
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let item of checklist">
                <span>
                  {{item.value}}
                </span>
                <span>
                  <input type="checkbox" [(ngModel)]="item.isSelected" name="list_name" value="{{item.id}}"
                    (change)="isAllSelected()" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <div class="input-row plyBck" nz-row *ngIf="shwSpdMtr == true">
      <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="mnuprflMrgn sliderCrd">
        <p>Playback Speed:
          <b>{{ sliderValue }}</b>
        </p>
        <div class="icon-wrapper test-class">
          <i nz-icon>100</i>
          <nz-slider [nzMin]="100" [nzMax]="250" [nzTooltipVisible]="'always'" [(ngModel)]="sliderValue"></nz-slider>
          <i nz-icon>250</i>
        </div>

      </div>
    </div>
    <div class="crdTrp" *ngIf="shwSpdMtr == true">
      <!-- <h6>
        ------ Target Tripwise Details ------
      </h6>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 nopdng rgtbrdr">
          <p>{{trackVehicleTimestamp}}</p>
          <h2>(Actual Time)</h2>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 nopdng ">
          <p>{{totalTravelTime}}</p>
          <h2>(Travel Time)</h2>
        </div>
      </div>
      <div class="row">

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 nopdng rgtbrdr">
          <p>{{trackVehicleSpeed}}</p>
          <h2>(Speed)</h2>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 nopdng ">
          <p>{{travelDist}}</p>
          <h2>(Travel Distance)</h2>
        </div>
      </div>

      <div class="row">

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 nopdng rgtbrdr">
          <p>{{drvr_nm}}</p>
          <h2>(Driver Name)</h2>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 nopdng ">
          <p>{{drvr_mbl_nu}}</p>
          <h2>(Drive Mobile Number)</h2>
        </div>
      </div> -->




      <div class="row">
        <div class="col-md-12">
          <table class="dtlstble">
            <div class="tblehdng">Target Tripwise Details</div>
            <tr>
              <td>
                <p>{{trackVehicleTimestamp}}</p>
                <h2>(Actual Time)</h2>
              </td>
              <td>
                <p>{{totalTravelTime}}</p>
                <h2>(Travel Time)</h2>
              </td>
            </tr>
            <tr>
              <td>
                <p>{{trackVehicleSpeed}}</p>
                <h2>(Speed)</h2>
              </td>
              <td>
                <p>{{travelDist}}</p>
                <h2>(Travel Distance)</h2>
              </td>
            </tr>
            <tr>
              <td>
                <p>{{drvr_nm}}</p>
                <h2>(Driver Name)</h2>
              </td>
              <td>
                <p>{{drvr_mbl_nu}}</p>
                <h2>(Drive Mobile Number)</h2>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px;">
      <div class="row" *ngIf="showTimeline == true">
        <div class="container table-responsive">
          <table class="table table-striped header-fixed">
            <thead>
              <tr>
                <th>S.no </th>
                <th>Status</th>
                <th>Address</th>
                <th>In Time</th>
                <th>Out Time</th>
                <th>Stoppage</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let f of fencInOutDtls ; let i = index">
                <td class="filterable-cell" ng-style="{'background-color': f.cvr_sts === 1 ? '#f76767' : ''}">{{i+1}}
                </td>
                <td class="filterable-cell" *ngIf="f.fnce_cvr_sts == 1">
                  <img src="assets/images/markers/mapIcons/m/dumperBin_r_m.png" class="plbckTmlneImg crsrNodrp">
                </td>
                <td class="filterable-cell" *ngIf="f.fnce_cvr_sts == 0 || f.fnce_cvr_sts == null">
                  <img src="assets/images/markers/mapIcons/m/dumperBin_r_m.png" class="plbckTmlneImg crsrNodrp">
                </td>
                <!-- ng-click="showGarbagePointOnMap(f.fnce_id,f.lati,f.longi);" -->
                <td class="filterable-cell">{{f.fnce_nm}}</td>
                <td class="filterable-cell">{{f.actl_arvl_ts}}</td>
                <td class="filterable-cell">{{f.actl_dprte_ts}}</td>
                <td class="filterable-cell">{{f.stoppage}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="fencInOutDtls && fencInOutDtls.length">
        <img src="assets/images/infoMsg/location_not_found.png" class="noLctnFmdIMg" *ngIf="fencInOutDtls.length != 0">
      </div>
    </div>


  </nz-sider>
</nz-layout>


<!-- <button nz-button nzType="primary" (click)="trackVehicle()">PLAYBACK</button> -->