import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-rotatedmarker';
import 'leaflet.animatedmarker/src/AnimatedMarker';
import 'leaflet.markercluster';
import { isAfter, isSameDay, startOfDay, } from 'date-fns';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/providers/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-apsfl-live-tracking',
  templateUrl: './apsfl-live-tracking.component.html',
  styleUrls: ['./apsfl-live-tracking.component.scss']
})
export class ApsflLiveTrackingComponent {
  sidebarcollapsed = true;
  liveMap: any;
  form: FormGroup;
  districts: any;
  circles: any;
  divisions: any;
  rings: any;
  clntDtls: any;
  filtersData: any;
  slctd_dstrct: any = null;
  slctd_crcle: any = null;
  slctd_dvsn: any = null;
  slctd_rng: any = null;


  // // Add markers
  // coordinates = [
  //   [15.5311756333488, 78.5231182024431],
  //   [15.5311750002261, 78.5231220002801]
  // ];

  constructor(private fb: FormBuilder, private datePipe: DatePipe, private apiServ: ApiService, private message: NzMessageService) {
    this.form = this.fb.group({
      district: [null],
      circle: [null],
      division: [null],
      ring: [null],
    });
  }

  ngOnInit() {
    this.subscribeFormFields();
    this.apiServ.post({ dist: '0', circle: null, division: null, ring: null }, 'apsfl/rings',).subscribe((res) => {
      console.log(res);
      this.districts = res['data'];
    });
    // this.message.error('This is an error message');
    // this.message.success('This is a success message');
    // this.message.info('in oninit function');
    // this.getLatLongs(3421);
  }

  async onFieldChange(field: string, value: any) {
    console.log(`${field} changed to:`, value);

    switch (field) {
      case 'district':
        this.slctd_dstrct = value;
        // setting dependent dropdowns as empty
        this.form.get('circle').setValue(null);
        this.form.get('division').setValue(null);
        this.form.get('ring').setValue(null);
        this.slctd_crcle = this.slctd_dvsn = this.slctd_rng = this.divisions = this.rings = null;
        //getting circles data
        this.circles = await this.getRings(this.slctd_dstrct, '0', null, null);
        if (this.circles.length) console.log('selected circles', this.circles);
        else this.createMessage('Error', 'No circles found for the selected district !');
        break;

      case 'circle':
        this.slctd_crcle = value;

        // setting dependent dropdowns as empty
        this.form.get('division').setValue(null);
        this.form.get('ring').setValue(null);
        this.slctd_dvsn = this.slctd_rng = this.rings = null;
        this.divisions = await this.getRings(this.slctd_dstrct, this.slctd_crcle, '0', null);
        if (this.divisions.length) console.log('selected divisions', this.divisions);
        else this.createMessage('Error', 'No divisions found for the selected circle !');
        break;

      case 'division':
        this.slctd_dvsn = value;

        // setting dependent dropdowns as empty
        this.form.get('ring').setValue(null);
        this.slctd_rng = null;
        this.rings = await this.getRings(this.slctd_dstrct, this.slctd_crcle, this.slctd_dvsn, '0');
        if (this.rings.length) console.log('selected rings', this.rings);
        else this.createMessage('Error', 'No rings found for the selected division !');

        break;

      case 'ring':
        this.slctd_rng = value;
        console.log('selected ring:', this.slctd_rng);
        this.getLatLongs(this.slctd_rng);
        break;

      default:
        console.log('Filters are not applicable');
        break;
    }
    console.log('filters', this.slctd_dstrct, this.slctd_crcle, this.slctd_dvsn, this.slctd_rng);
  }

  getRings(dist = null, crcle = null, dvsn = null, rng = null): Promise<Object> {
    let filters = {
      dist: dist,
      circle: crcle,
      division: dvsn,
      ring: rng
    };
    console.log('Filters:', filters);

    return new Promise((resolve, reject) => {
      this.apiServ.post(filters, 'apsfl/rings').subscribe(
        (res) => {
          const data = res['data'];
          console.log(data);
          resolve(data);
        },
        (error) => {
          console.error('Error:', error);
          reject(error);
        }
      );
    });
  }

  getLatLongs(ringId: any) {
    // this.rings = [
    //   {
    //     "dstrt_id": 3,
    //     "dstrt_nm": "East Godavari",
    //     "dstrt_cd": "EG",
    //     "dstrt_cncs_id": 545,
    //     "oms_crcle_id": 4,
    //     "oms_crcle_nm": "Rajahmundry",
    //     "oms_dvsn_id": 33,
    //     "oms_dvsn_nm": "Rajahmundry",
    //     "oms_rng_id": 3217,
    //     "oms_rng_nm": "SS Spur>COTTON - DHAVALESWARAM",
    //     "dst_ct": 0.52,
    //     "boundaries": "[[16.9380063,81.7848514],[16.9382393,81.7848344],[16.9383919,81.7851604],[16.9387494,81.7852392],[16.9402815,81.78445],[16.94104,81.7841978],[16.941569,81.7841816],[16.9416223,81.7840566],[16.9420393,81.7839421],[16.942134948515,81.7840727718104]]"
    //   },       
    //   {
    //     "dstrt_id": 3,
    //     "dstrt_nm": "East Godavari",
    //     "dstrt_cd": "EG",
    //     "dstrt_cncs_id": 545,
    //     "oms_crcle_id": 4,
    //     "oms_crcle_nm": "Rajahmundry",
    //     "oms_dvsn_id": 33,
    //     "oms_dvsn_nm": "Rajahmundry",
    //     "oms_rng_id": 3616,
    //     "oms_rng_nm": "Mandal Ring>KORUKONDA -  Gokavaram",
    //     "dst_ct": 3.07,
    //     "boundaries": "[[17.2025673,81.8438878],[17.2011436,81.8431661],[17.2003279997374,81.8428339997894],[17.1996205,81.8424983],[17.1990566,81.8442278],[17.1954625,81.8427361],[17.1909436,81.8406954],[17.188913,81.8398347],[17.1880097,81.8397634],[17.1863885,81.8391587],[17"
    //   }
    // ];

    const [selectedRing, _] = [...this.rings.filter(ring => ring.oms_rng_id === ringId)];
    let coordinatesArray = [];
    if (selectedRing?.boundaries.length) {
      // Remove outer brackets and split into coordinate pairs
      coordinatesArray = selectedRing.boundaries.slice(2, -2).split("],[").map(pair => {
        // Split each pair by comma and convert to numbers
        const [lat, lon] = pair.split(",").map(Number);
        return [lat, lon];
      });
    }
    else {
      console.log('Selected ring does not have boundaries');
    }
    console.log(coordinatesArray, 'coordinates array');
  }

  subscribeFormFields() {
    // Subscribe to each field's value changes
    this.form.get('district')?.valueChanges.subscribe(value => {
      if (value) this.onFieldChange('district', value);
    });
    this.form.get('circle')?.valueChanges.subscribe(value => {
      if (value) this.onFieldChange('circle', value);
    });
    this.form.get('division')?.valueChanges.subscribe(value => {
      if (value) this.onFieldChange('division', value);
    });
    this.form.get('ring')?.valueChanges.subscribe(value => {
      if (value) this.onFieldChange('ring', value);
    });

    this.form.get('dateRange')?.valueChanges.subscribe(value => {
      if (value) this.onFieldChange('dateRange', value);
    });
  }

  initialize = function () {
    // Map Initialization
    try {
      // Initialize the map
      this.liveMap = L.map('livemap', {
        center: [17.0080782, 81.7820634], // Center coordinates
        zoom: 13
      });

      // Add Google Maps tiles
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      tiles.addTo(this.liveMap);

      console.log('Map initialized');
      // this.addMarkers();      
    } catch (e) {
      console.error("Error initializing Leaflet map:", e);
    }
  }

  // private addMarkers(): void {
  //   // Check if the map instance exists before adding markers
  //   if (!this.liveMap) {
  //     console.error("Map instance is not initialized.");
  //     return;
  //   }

  //   this.coordinates.forEach(coord => {
  //     const marker = L.marker(coord).addTo(this.liveMap);
  //     console.log('Marker added at:', coord); // Log each marker
  //   });
  // }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialize();
    }, 100);  
  }

  toggle() {
    this.sidebarcollapsed = this.sidebarcollapsed ? false : true;

    // setTimeout(() => {
    //   this.liveMap.invalidateSize();
    //   // Optionally, adjust zoom or center as needed
    //   this.liveMap.setZoom(this.liveMap.getZoom());
    // }, 150);  // Adjust timeout duration based on transition effect
  }

  onCircleFocus() {
    if (!this.slctd_dstrct) this.message.error('Please select district !');
  }

  onDivisionFocus() {
    if (!this.slctd_dstrct) this.message.error('Please select district !');
    else if (!this.slctd_crcle) this.message.error('Please select circle !');
  }

  onRingFocus(): void {
    if (!this.slctd_dstrct) this.message.error('Please select district !');
    else {
      if (!this.slctd_crcle) this.message.error('Please select circle !');
      else {
        if (!this.slctd_dvsn) this.message.error('Please select division !');
      }
    }
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
}
