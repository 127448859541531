import { Component, OnInit, ViewChild } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
// import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import { NavigationExtras, Router } from '@angular/router';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexDataLabels
} from 'ng-apexcharts';
@Component({
  selector: 'app-payroll-dashboard',
  templateUrl: './payroll-dashboard.component.html',
  styleUrls: ['./payroll-dashboard.component.scss']
})

export class PayrollDashboardComponent implements OnInit {
  public chartOptions: {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    dataLabels: ApexDataLabels;
  } = {
    series: [],
    chart: {
      type: 'bar',
      height: 350
    },
    xaxis: {
      categories: []
    },
    title: {
      text: ''
    },
    dataLabels: {
      enabled: false
    }
  };
  settings = {
    showLdr: false,
    mnthly_crd_lbl: '',
    dsct_crd_lbl: ''
  };

  // public barChartType: ChartType = 'bar';
  // public barChartLegend = true;
  // public barChartPlugins = [];
  // barChartLabels = [];
  // public barChartColors: Color[] = [
  //   { backgroundColor: '#16a086' },
  //   { backgroundColor: '#e77e23' }
  // ];

  data = {
    emple_type_id: 0,
    years: [],
    yr_id: new Date().getFullYear(),
    mnths: [],
    mnth_id: new Date().getMonth() + 1,
    dstct_ws_cnt: [],
    mnthly_sts: [],
    barChartLabels: [],
    empleTypeLst: [],
    dstct_ws_ttl_cnts: {
      ttl_cmplt_cnt: 0,
      ttl_pnd_cnt: 0,
      ttl_rt_bzr: 0,
      ttl_prl_rt_bzr: 0,
      ttl_empls: 0,
      ttl_prl_empls: 0,
      ttl_prl_at: 0,
      ttl_emplr_dctn_at: 0,
      ttl_pndng_aprvl_ct: 0,
      ttl_pyrle_run_ct : 0
    },
    barDataTtlAt: [],
    barDataCntrctAt: [],
    barDataOutsrcAt: [],
    barXaxis: [],
    bar_chrt_tlt_cnts: {
      ttl_prl_at: 0,
      ttl_emplr_dctn_at: 0,
      ttl_cntrct_nt_slry_at: 0,
      ttl_outsrcd_nt_slry_at: 0
    },
    lctnWsePyroll: [],
    columnDefs: [],
    excelName: ''
  }


  @ViewChild('fullScreen') divRef;
  constructor(private apisrvc: AppsService, private router : Router ) {
    this.data.mnths = [{ mnth_id: 1, name: 'Jan' }, { mnth_id: 2, name: 'Feb' }, { mnth_id: 3, name: 'Mar' }, { mnth_id: 4, name: 'Apr' }, { mnth_id: 5, name: 'May' },
    { mnth_id: 6, name: 'Jun' }, { mnth_id: 7, name: 'July' }, { mnth_id: 8, name: 'Aug' }, { mnth_id: 9, name: 'Sep' }, { mnth_id: 10, name: 'Oct' }, { mnth_id: 11, name: 'Nov' },
    { mnth_id: 12, name: 'Dec' }];

    this.getYears();
    this.getEmployeeTypes();
  }

  ngOnInit(): void {
    this.divRef = document.documentElement;
    this.getDstrctWseCnts();
    this.getMntlyStstcs();



  }

  getDstrctWseCnts() {

    this.data.dstct_ws_ttl_cnts = {
      ttl_cmplt_cnt: 0,
      ttl_pnd_cnt: 0,
      ttl_rt_bzr: 0,
      ttl_prl_rt_bzr: 0,
      ttl_empls: 0,
      ttl_prl_empls: 0,
      ttl_prl_at: 0,
      ttl_emplr_dctn_at: 0,
      ttl_pndng_aprvl_ct: 0,
      ttl_pyrle_run_ct:0
    }
    this.settings.showLdr = true;
    const rte = `payroll/dashboard/district/wise/${this.data.yr_id}/${this.data.mnth_id}/${this.data.emple_type_id}`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.showLdr = false;
      console.log("COUNTS   ", res);
      this.data.dstct_ws_cnt = res['data'];
      if (this.data.dstct_ws_cnt) {
        this.settings.dsct_crd_lbl = `  ${this.data.yr_id} - ${this.data.mnths[this.data.mnth_id - 1]['name']} `;
        this.data.dstct_ws_cnt.filter((k) => {
          if (k['pyrl_cmpl_sts_in']) {
            ++this.data.dstct_ws_ttl_cnts.ttl_cmplt_cnt;
          }
          else {
            ++this.data.dstct_ws_ttl_cnts.ttl_pnd_cnt;
          }
          this.data.dstct_ws_ttl_cnts.ttl_rt_bzr += k['ttl_ryth_bzr_cnt'];
          this.data.dstct_ws_ttl_cnts.ttl_prl_rt_bzr += k['ttl_lctn_ct'];
          this.data.dstct_ws_ttl_cnts.ttl_empls += k['ttl_empl_ct'];
          this.data.dstct_ws_ttl_cnts.ttl_prl_empls += k['ttl_pyrl_empls_ct'];
          this.data.dstct_ws_ttl_cnts.ttl_prl_at += k['ttl_emple_pyrl_at'];
          this.data.dstct_ws_ttl_cnts.ttl_emplr_dctn_at += k['ttl_emplr_dctn_at'];
          this.data.dstct_ws_ttl_cnts.ttl_pndng_aprvl_ct += k['ttl_pndng_aprvl_ct'];
          this.data.dstct_ws_ttl_cnts.ttl_pyrle_run_ct += k['ttl_prl_run_ct'];
        })
      }

      this.chartOptions = {
        series: [
          {
            name: 'Contractors amount to be claimed',
            data:  this.data.barDataCntrctAt
          },
          {
            name: 'Outsourced amount to be claimed',
            data:this.data.barDataOutsrcAt
          }
        ],
        chart: {
          type: 'bar',
          height: 350
        },
        xaxis: {
          categories: this.data.barXaxis
        },
        title: {
          text: 'Monthly Payroll Statistics for 2024 (January to November)'
        },
        dataLabels: {
          enabled: false
        }
      };

    }, (err) => {
      this.settings.showLdr = false;
    });
  }


 

  getMntlyStstcs() {

    this.data.barXaxis = [];
    this.data.barDataTtlAt = [];
    this.data.barDataCntrctAt = [];
    this.data.barDataOutsrcAt = [];

    this.data.bar_chrt_tlt_cnts = {
      ttl_prl_at: 0,
      ttl_emplr_dctn_at: 0,
      ttl_cntrct_nt_slry_at: 0,
      ttl_outsrcd_nt_slry_at: 0
    };

    const rte = `payroll/dashboard/monthly/statistics/${this.data.yr_id}/${this.data.emple_type_id}`;
    this.apisrvc.get(rte).subscribe(res => {
      console.log("mnthly_sts   ", res);
      this.data.mnthly_sts = res['data'];
      if (this.data.mnthly_sts && this.data.mnthly_sts.length) {
        this.settings.mnthly_crd_lbl = ` From ${this.data.yr_id} ( ${this.data.mnthly_sts[this.data.mnthly_sts.length - 1]['mnth_nm']} to ${this.data.mnthly_sts[0]['mnth_nm']} )`;
        this.data.mnthly_sts.filter((k) => {
          this.data.barXaxis.push(`${k['mnth_nm']}`);
          this.data.bar_chrt_tlt_cnts.ttl_prl_at += k['ttl_nt_slry_at'];
          this.data.bar_chrt_tlt_cnts.ttl_emplr_dctn_at += k['ttl_emplr_dctn_at'];
          this.data.bar_chrt_tlt_cnts.ttl_cntrct_nt_slry_at += k['ttl_cntrct_nt_slry_at'];
          this.data.bar_chrt_tlt_cnts.ttl_outsrcd_nt_slry_at += k['ttl_outsrcd_nt_slry_at'];

          this.data.barDataCntrctAt.push(k['ttl_cntrct_nt_slry_at']);
          this.data.barDataOutsrcAt.push(k['ttl_outsrcd_nt_slry_at']);
          this.data.barDataTtlAt.push(k['ttl_nt_slry_at']);
        });
        this.setGrphData();
      }

    });
  }
  getYears() {
    const rte = `payroll/years`;
    this.apisrvc.get(rte).subscribe(res => {
      this.data.years = res['data'];
    });
  }


  openFullscreen() {
    const elem = this.divRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  // barChartData: ChartDataSets[] = [
  //   { data: [], label: 'Contractors amount' },
  //   { data: [], label: 'Outsourced amount' }
  // ];


  public barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    legend: { position: 'bottom' },

    scales: {

    },



  };

  refreshFltrs() {
    this.getDstrctWseCnts();

    this.getMntlyStstcs();
  }

  setGrphData() {

  //   this.barChartData = [
  //     { data: [...this.data.barDataCntrctAt], label: 'Contractors amount to be claimed' },
  //     { data: [...this.data.barDataOutsrcAt], label: 'Outsourced amount to be claimed' }
  //   ];
  //   this.barChartLabels = [...this.data.barXaxis];
  }

  getAWdth(a) {
    return { width: a };
  }


  getEmployeeTypes() {

    const rte = `payroll/employee/types`;
    this.apisrvc.get(rte).subscribe(res => {
      this.data.empleTypeLst = res['data'];
    });
  }

  gotoDstrctDashboard(dstrt_id){
    const navigationExtras: NavigationExtras = {
      state: {
        dstrt_id : dstrt_id,
        yr_id : this.data.yr_id,
        mnth_id : this.data.mnth_id,
        emple_type_id : this.data.emple_type_id
      }
    };
    
    this.router.navigateByUrl('/internal/hrms/dashboard/district', navigationExtras);
  }

}
