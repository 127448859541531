import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';

import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-holidays',
    templateUrl: './holidays.html',
    styleUrls: ['./holidays.scss']
})
export class HolidaysComponent implements OnInit {
    weeks: any[];

    settings = {
        shwForm: false,
        shwGrid: true,
        shwDetails: false,
        loader: false,
        shwSaveBtn: false,
        shwDeleteCnfrm: false,
        shwEditHoliday: false,
        shwEditHolidayProfile: false,
        shwEditHoliday11: false,
        viewHolidayProfile: false,
        add_in: true
    }
    data = {
        columnDefs: [],
        viewcolumnDefs: [],
        rowData: [],
        permissions: { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 },
        columnDefs2: [],
        crntStep: 0,
        years: [],
        filterTerm: '',
        lctnsLst: [],
        allChecked: false,
        slctdLctns: [],
        weeks: [{ name: 'Monday', week_off: 0 }, { name: 'Tuesday', week_off: 0 }, { name: 'Wednesday', week_off: 0 },
        { name: 'Thursday', week_off: 0 }, { name: 'Friday', week_off: 0 }, { name: 'Saturday', week_off: 0 }, { name: 'Sunday', week_off: 0 },],
        hldy_prfle_id: null,
        yr_id: null,
        hldy_id: null,
        sidebarHeading: '',
        hldyprfls: [],
        toBeAsgndPrfle: null,
        columnDefss: [],
        hldy_prfle: null,
        resData: [],
        ntasgndin: null,
        alllctnsLst: [],
        yr: null
    }
    holidayForm!: FormGroup;
    hldyForm!: FormGroup;
    hldyFrm;
    visible: boolean = false;
    sidebarHeadings;
    loader: boolean;
    shwSaveBtn: boolean;
    hldyprflst: any;
    hldy_prfle_id: null;
    viewusers: any;
    shwHldyLst: boolean;
    holidays_list: any;
    shwDeletePrfCnfrm: boolean;
    hldy_prfle_nm: any;
    shwDltAlrt: string;
    unasignedPrfUsrLst: any;
    assignMdl: boolean;
    asgnPrfLst: any;
    title: string;
    asgndEmplLst: any = [];
    pfle_data: any;
    constructor(private apisrvc: AppsService, private fb: FormBuilder, private modal: NzModalService, private message: NzMessageService, private _changeDetectorRef: ChangeDetectorRef


    ) { }

    ngOnInit() {
        this.getHolidayPrfles()
        this.getUsrNtAsgndPrfles()


        this.holidayForm = this.fb.group({
            hldy_prfle_nm: ['', [Validators.required]],
            dscn_tx: [''],
            effctve_dt: ['', [Validators.required]],
            expiry_dt: ['', [Validators.required]],

        });
        this.hldyForm = this.fb.group({
            hldy_nm: ['', [Validators.required]],
            dscn_tx: [''],
            hldy_dt: ['', [Validators.required]],
        });
        // this.getYears();
        // this.getLocations();
        // this.getHolidayPrfls();
        this.getHolidayProfiles();
    }
    open(): void {
        this.sidebarHeadings = "For Help";
        this.visible = true;
    }

    close(): void {
        this.visible = false;
    }
    get form() {
        return this.holidayForm.get('hldyFrm') as FormArray;
    }

    // addHoliday() {
    //     this.form.push(this.fb.group({
    //         hldy_nm: '',
    //         hldy_dt: '',
    //         dscn_tx: '',
    //     }));
    // }


    removeHoliday(index) {
        this.form.removeAt(index);
    }
    vwHldays(data) {
        console.log(data)
        this.pfle_data = data
        this.getWeeks()
        console.log(data.data.holidays_list)
        this.holidays_list = data.data.holidays_list
        this.shwHldyLst = true

        this.settings.shwEditHoliday11 = false;
        this.settings.shwEditHoliday = true;
        this.data.sidebarHeading = 'View Holidays';


    }



    getHolidayProfiles() {
        this.settings.loader = true;
        const rte = `payroll/holidays/profiles`;
        this.apisrvc.get(rte).subscribe(res => {
            this.settings.loader = false;
            this.data.rowData = res['data'];
            this.data.columnDefs = [
                { headerName: 'Sno', field: 'sno', alignment: 'center', width: 100, filter: false },
                { headerName: 'Holiday Profile', alignment: 'left', width: 300, field: 'hldy_prfle_nm' },
                { headerName: 'Effective Date', alignment: 'center', width: 200, field: 'effctve_dt' },
                { headerName: 'Expiry Date', alignment: 'center', width: 200, field: 'expiry_dt' },

            ]
            this.data.columnDefs2 = [
                { headerName: 'Sno', field: 's_no', alignment: 'center', cellClass: "pm-grid-number-cell", width: 100, sortable: true, filter: false },
                { headerName: 'Holiday Name', field: 'hldy_nm', width: 300, alignment: 'left' },
                { headerName: 'Date', alignment: 'center', width: 200, field: 'frmd_dt' },
            ];
        });
    }

    getYears() {
        this.settings.loader = true;
        const rte = `payroll/years`;
        this.apisrvc.get(rte).subscribe(res => {
            this.settings.loader = false;
            this.data.years = res['data'];
            setTimeout(() => {
                console.log(this.data.years)
                this.data.yr_id = parseInt(moment(new Date()).format("YYYY"));
                this.getHolidayProfiles();
            }, 50)
        });
    }



    updateAllChecked() {
        this.data.slctdLctns = [];
        if (this.data.allChecked) {
            this.data.alllctnsLst = this.data.alllctnsLst.map(item => ({
                ...item,
                checked: true
            }));
            this.data.alllctnsLst.filter((k) => {
                if (k.checked == true) {
                    this.data.slctdLctns.push({ lctn_id: k.lctn_id })
                }
            })
        } else {
            this.data.alllctnsLst = this.data.alllctnsLst.map(item => ({
                ...item,
                checked: false
            }));
        }
    }

    onView(event) {
        this.settings.loader = true;
        this.data.hldy_prfle_id = event.data.hldy_prfle_id;
        this.getWorkingDaysForHolidayPrfoile(event.data.hldy_prfle_id);
        event.data.hldyFrm = [];
        if (event.data.holidays_list.length) {
            event.data.holidays_list.filter((k) => {
                if (k.hldy_id != null) {
                    event.data.hldyFrm.push(k)
                }
            })
            if (event.data.hldyFrm.length > 1) {
                event.data.hldyFrm.filter((k) => {
                    this.addHoliday();

                })
            }
        }
        this.settings.shwForm = false;
        this.settings.shwGrid = false;
        this.settings.shwDetails = true;
        setTimeout(() => {
            this.holidayForm.patchValue(event.data);
            this.settings.loader = false;
        }, 2000)
    }

    getWorkingDaysForHolidayPrfoile(hldy_prfle_id) {
        this.settings.loader = true;
        const rte = `payroll/working/days/${hldy_prfle_id}`;
        this.apisrvc.get(rte).subscribe(res => {
            this.settings.loader = false;
            this.data.weeks = res['data'];
        });
    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                text: 'Add New Holiday Profile',
                onClick: this.openSideBar.bind(this, 'new'),
                bindingOptions: {
                    'disabled': 'isEmailButtonDisabled'
                }
            }
        });
    }

    onToolbarPreparingView(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                text: 'Add New User',
                onClick: this.assignUsers.bind(this, this.pfle_data),
                bindingOptions: {
                    'disabled': 'isEmailButtonDisabled'
                }
            }
        });
    }



    onToolbarPreparingHldy(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                text: 'Add New Holiday',
                onClick: this.onEditHoliday.bind(this, this.pfle_data, 1),
                bindingOptions: {
                    'disabled': 'isEmailButtonDisabled'
                }
            }
        });
    }

    openSideBar() {

        this.holidayForm.reset();
        this.settings.add_in = true;
        this.settings.shwEditHolidayProfile = true;
        this.data.sidebarHeading = 'Add Holiday Profile';
    }

    hideForm() {
        this.data.allChecked = false;
        this.data.slctdLctns = [];

        this.data.resData = [];
        this.data.hldy_prfle = null;
        this.data.yr = null;
        // this.getLocationsHolidayPrfls();
        this.settings.shwForm = false;
        this.settings.shwGrid = true;
        this.settings.shwDetails = false;
        // this.getLocations();
    }

    pushToSlctdLst(i) {
        if (i.checked == true) {
            this.data.slctdLctns.push({ lctn_id: i.lctn_id })
        } else {
            const idx = this.data.slctdLctns.indexOf(i);
            if (idx !== -1) {
                this.data.slctdLctns.splice(idx, 1);
            }
        }
    }

    pre() {
        this.data.crntStep = this.data.crntStep - 1;
    }

    next() {
        this.data.crntStep = this.data.crntStep + 1;
    }



    done() {
        this.settings.loader = true;
        let week_offs = [];
        this.data.alllctnsLst.filter((k) => {
            week_offs.push({ lctn_id: k.lctn_id, mon_in: k.mon_in, tue_in: k.tue_in, wed_in: k.wed_in, thr_in: k.thr_in, fri_in: k.fri_in, sat_in: k.sat_in, sun_in: k.sun_in })
        })
        setTimeout(() => {
            let data = {
                slctdLctns: this.data.slctdLctns,
                hldy_prfle_id: this.data.toBeAsgndPrfle,
                week_offs: week_offs
            }
            console.log(data)
            const rte = `payroll/assign/holiday/profile`;
            this.apisrvc.post(data, rte).subscribe(res => {
                this.settings.loader = false;
                if (res['status'] === 200) {
                    // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                    this.createMessage('success', res["message"])

                    this.hideForm()
                } else {
                    // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                    this.createMessage('warning', res["message"])

                }
            });
        }, 200)
    }

    // updateHolidayProfile() {
    //     this.settings.loader = true;
    //     for (let f of this.holidayForm.value.hldyFrm) {
    //         if (f.hldy_dt && f.hldy_dt.length != 0) {
    //             let dt = moment(f.hldy_dt).format('YYYY-MM-DD');
    //             f.hldy_dt = dt;
    //         }
    //     }
    //     let data = {
    //         dscn_tx: this.holidayForm.value.dscn_tx,
    //         hldy_prfle_nm: this.holidayForm.value.hldy_prfle_nm,
    //         yr_id: this.holidayForm.value.yr_id,
    //         hldyFrm: this.holidayForm.value.hldyFrm,
    //         slctdLctns: this.data.slctdLctns,
    //         weeks: this.data.weeks
    //     }
    //     console.log(data);
    //     const rte = `payroll/holidays/profiles/${this.data.hldy_prfle_id}`;
    //     this.apisrvc.put(data, rte).subscribe(res => {
    //         this.settings.loader = false;
    //         if (res['status'] === 200) {
    //             this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //             this.hideForm()
    //         } else {
    //             this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //         }
    //     });
    // }

    onDeleteHoliday(d) {
        this.data.hldy_id = d.data.hldy_id;
        this.data.hldy_prfle_id = d.data.hldy_prfle_id;
        this.settings.shwDeleteCnfrm = true;
    }

    deleteHoliday() {
        this.settings.loader = true;
        const rte = `payroll/holiday/${this.data.hldy_prfle_id}/${this.data.hldy_id}`;
        this.apisrvc.delete(rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
                // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('success', res["message"])

                this.settings.shwDeleteCnfrm = false;
                this.getHolidayProfiles();
            } else {
                // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('warning', res["message"])

            }
        });
    }

    onEditHoliday(d, add_in) {
        console.log(d, add_in)
        if (add_in == 1) {
            this.data.hldy_prfle_id = d.data.hldy_prfle_id;
            this.settings.add_in = true;
            this.hldyForm.reset();
            this.settings.shwEditHoliday11 = true;
            this.data.sidebarHeading = `Add Holiday for ${d.data.hldy_prfle_nm} `;
        } else {
            this.data.hldy_id = d.data.hldy_id;
            this.settings.add_in = false;
            this.hldyForm.setValue({
                hldy_nm: d.data.hldy_nm,
                dscn_tx: d.data.dscn_tx,
                hldy_dt: d.data.hldy_dt,
            })
            this.settings.shwEditHoliday11 = true;
            this.data.sidebarHeading = 'Edit Holiday';
        }
    }




    onEditHolidayProfile(d) {
        this.settings.add_in = false;
        this.settings.shwEditHolidayProfile = true;
        const effectiveDate = moment(d.data.effctve_dt, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const expiryDate = moment(d.data.expiry_dt, 'DD-MM-YYYY').format('YYYY-MM-DD');
        console.log(d.data)
        this.holidayForm.setValue({
            hldy_prfle_nm: d.data.hldy_prfle_nm,
            dscn_tx: d.data.prfle_dscn_tx,
            effctve_dt: effectiveDate,
            expiry_dt: expiryDate

        })
        this.data.hldy_prfle_id = d.data.hldy_prfle_id;

        this.data.sidebarHeading = 'Edit Holiday Profile';
    }






    onDeleteHolidayProfile(d) {

        this.data.hldy_prfle_id = d.data.hldy_prfle_id;
        this.hldy_prfle_nm = d.data.hldy_prfle_nm

        this.shwDltAlrt = `Are you sure you want to delete  ${this.hldy_prfle_nm} Holiday profile?`
        this.shwDeletePrfCnfrm = true;
    }

    deleteHolidayProfile() {

        let rte = `payroll/remove/holiday/profile/${this.data.hldy_prfle_id}`
        this.apisrvc.delete(rte).subscribe((res) => {
            if (res['status'] == 200) {
                this.createMessage('success', 'Holiday Profile Deleted Successfully')
                this.shwDeletePrfCnfrm = false;
                this.getHolidayPrfles()
                this.getUsrNtAsgndPrfles()
                this.getHolidayProfiles();

            }
            else {
                this.createMessage('warning', 'Something went wrong')

            }




        })


    }

    vwUsers(d) {
        this.pfle_data = d
        let rte = `payroll/users/holiday/profile/${d.data.hldy_prfle_id}`

        this.apisrvc.get(rte).subscribe(res => {
            console.log(res['data'])
            this.viewusers = res['data']

            this.data.sidebarHeading = 'Users Assigned for Holiday Profile';
            this.settings.viewHolidayProfile = true;
            this.data.viewcolumnDefs = [
                { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: "pm-grid-number-cell", width: 100, sortable: true, },
                { headerName: 'User Name', field: 'usr_nm', width: 300, alignment: 'left' },
                { headerName: 'Employee Name', field: 'emple_nm', width: 300, alignment: 'left' },
                { headerName: 'Designation', field: 'dsgns_nm', width: 200, alignment: 'left' },
                { headerName: 'Holiday Profile', field: 'hldy_prfle_nm', width: 200, alignment: 'left' },

            ];

        })

    }

    Cancel() {
        this.settings.shwDeleteCnfrm = false;
        this.settings.shwEditHoliday = false;
        this.settings.shwEditHolidayProfile = false;
        this.settings.viewHolidayProfile = false;
        this.shwHldyLst = false
        this.shwDeletePrfCnfrm = false
        this.assignMdl = false
    }

    CancelHolyday() {
        this.settings.shwEditHoliday11 = false;
    }

    editHoliday() {
        this.settings.loader = true;
        this.hldyForm.value.hldy_dt = moment(this.hldyForm.value.hldy_dt).format('YYYY-MM-DD');
        const rte = `payroll/holiday/${this.data.hldy_id}`;
        this.apisrvc.put(this.hldyForm.value, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
                // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('success', res["message"])

                this.getHolidayProfiles();
                this.getHolidayProfiles();
                this.Cancel();
            } else {
                // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('warning', res["message"])

            }
        });
    }

    addHoliday() {
        this.settings.loader = true;
        this.hldyForm.value.hldy_dt = moment(this.hldyForm.value.hldy_dt).format('YYYY-MM-DD');
        const rte = `payroll/holiday/${this.data.hldy_prfle_id}`;
        this.apisrvc.post(this.hldyForm.value, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
                // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('success', res["message"])

                // this.getHolidayProfiles();
                // this.Cancel();
                this.CancelHolyday();
            } else {
                // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('warning', res["message"])

            }
        });
    }

    submitWeekChng() {
        this.loader = true;
        console.log(this.weeks)
        // this.weekSlctd.push(wkObj);
        // console.log(this.weekSlctd);
        let rte = `payroll/updt_week`
        this.apisrvc.update(this.weeks, rte).subscribe((res: any) => {

            // this.hldySrv.updtWeek(this.weeks).subscribe((res: any) => {
            this.shwSaveBtn = false;
            this.loader = false;
            if (res.status == 200) {
                // this.toastr.success(`${res.message}`, "Success",{timeOut: 3000});
            } else {
                // this.toastr.warning("Something went wrong", "Warn",{timeOut: 3000});
            }
        }, (err) => { this.loader = false; })
    }


    getHolidayPrfles() {

        let rte = `payroll/holidays/profiles`

        this.apisrvc.get(rte).subscribe((res) => {
            if (res['status'] == 200) {
                console.log(res['data'])
                this.hldyprflst = res['data']
                this.hldy_prfle_id = null
                if (this.hldyprflst && this.hldyprflst.length > 0) {
                    this.hldy_prfle_id = this.hldyprflst[0].hldy_prfle_id
                    this.getWeeks()
                }
            }
        })
    }



    getWeeks() {
        this.loader = true


        let rte = `payroll/getWeeks`

        let postdata = {

            hldy_prfle_id: this.hldy_prfle_id
        }

        this.weeks = []


        this.apisrvc.post(postdata, rte).subscribe((res) => {

            // this.apisrvc.get(rte).subscribe((res) => {
            this.loader = false;
            this._changeDetectorRef.markForCheck();
            if (res['status'] == 200) {
                if (res['data'].length > 0) {
                    this.weeks = res['data'];
                    this.weeks.forEach(wkobj => {
                        wkobj['slct_in'] = 0;
                    })
                    console.log(this.weeks);
                    this._changeDetectorRef.markForCheck();
                }
            }

        })
    }

    weekSlctd = [];
    editWeek(wdy_id, w, w_in) {
        this.shwSaveBtn = true;
        console.log(wdy_id, w, w_in);

        this.weeks.forEach(wkobj => {
            // console.log(wkobj);
            if (wkobj.wk_dy_id == wdy_id) {
                if (w == 'w1') {
                    wkobj.w1_in = 1;
                } else if (w == 'w2') {
                    wkobj.w2_in = 1;
                } else if (w == 'w3') {
                    wkobj.w3_in = 1;
                } else if (w == 'w4') {
                    wkobj.w4_in = 1;
                } else if (w == 'w5') {
                    wkobj.w5_in = 1;
                }
            }
            if (wkobj.wk_dy_id == wdy_id) {
                if (w == 'w1' && w_in == 1) {
                    if (w_in == 1 && wkobj.w1_in == 1) {
                        wkobj.w1_in = 0;
                    }
                } else if (w == 'w2') {
                    if (w_in == 1 && wkobj.w2_in == 1) {
                        wkobj.w2_in = 0;
                    }
                } else if (w == 'w3' && w_in == 1) {
                    if (w_in == 1 && wkobj.w3_in == 1) {
                        wkobj.w3_in = 0;
                    }
                } else if (w == 'w4' && w_in == 1) {
                    if (w_in == 1 && wkobj.w4_in == 1) {
                        wkobj.w4_in = 0;
                    }
                } else if (w == 'w5' && w_in == 1) {
                    if (w_in == 1 && wkobj.w5_in == 1) {
                        wkobj.w5_in = 0;
                    }
                }
            }
        })
        console.log(this.weeks);
    }


    addHolidayProfile() {
        this.settings.loader = true;
        const rte = `payroll/holidays/profile`;

        let postdata = {
            hldy_prfle_nm: this.holidayForm.value.hldy_prfle_nm,
            dscn_tx: this.holidayForm.value.dscn_tx,
            effctve_dt: moment(this.holidayForm.value.effctve_dt).format('YYYY-MM-DD'),
            expiry_dt: moment(this.holidayForm.value.expiry_dt).format('YYYY-MM-DD')

        }




        this.apisrvc.post(postdata, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
                // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('success', res["message"])

                this.getHolidayProfiles();
                this.Cancel();
            } else {
                // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('warning', res["message"])

            }
        });
    }

    editHolidayProfile() {
        this.settings.loader = true;

        let postdata = {
            hldy_prfle_nm: this.holidayForm.value.hldy_prfle_nm,
            dscn_tx: this.holidayForm.value.dscn_tx,
            effctve_dt: moment(this.holidayForm.value.effctve_dt).format('YYYY-MM-DD'),
            expiry_dt: moment(this.holidayForm.value.expiry_dt).format('YYYY-MM-DD')

        }

        const rte = `payroll/holidays/profile/${this.data.hldy_prfle_id}`;
        this.apisrvc.put(postdata, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
                // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('success', res["message"])

                this.getHolidayProfiles();
                this.Cancel();
            } else {
                // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('warning', res["message"])

            }
        });
    }


    shwDetails() {
        this.settings.shwDetails = true;
        this.settings.shwGrid = false;
        // this.getHolidayPrfls();
    }

    getHolidayPrfls() {
        this.settings.loader = true;
        // const rte = `payroll/holidays/profiles`;
        const rte = `payroll/holidays/profiles`;
        this.apisrvc.get(rte).subscribe(res => {
            this.settings.loader = false;
            this.data.hldyprfls = res['data'];
        });
    }


    getLocationsHolidayPrfls() {
        this.settings.loader = true;
        const rte = `payroll/holidays/profile/locations/${this.data.hldy_prfle}/${this.data.yr_id}`;
        this.apisrvc.get(rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
                this.data.resData = res['data'];
                this.data.columnDefss = [
                    { headerName: 'Sno', field: 'sno', alignment: 'center', cellClass: "pm-grid-number-cell", width: 100, sortable: true, },
                    { headerName: 'District', field: 'dstrt_nm', width: 300, alignment: 'left' },
                    { headerName: 'Rythu Bazar Name', field: 'lctn_nm', width: 300, alignment: 'left' },
                    { headerName: 'Code', field: 'lctn_cd', width: 200, alignment: 'left' },
                    { headerName: 'ULB', field: 'ulb_nm', width: 200, alignment: 'left' },
                    { headerName: 'Holiday Profile Name', field: 'txt', width: 250, alignment: 'left' },
                    { headerName: 'Number of Holidays', field: 'ct', width: 150, alignment: 'center' },
                ];
            } else {
                // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
                this.createMessage('success', res["message"])

            }
        });
    }

    shwNotAssigned() {
        this.data.alllctnsLst = [];
        if (this.data.ntasgndin) {
            this.data.lctnsLst.filter((k) => {
                if (k.assgn_in == 0) {
                    this.data.alllctnsLst.push(k)
                }
            })
        } else {
            this.data.alllctnsLst = this.data.lctnsLst
        }
    }
    createMessage(type: string, msg: string): void {
        this.message.create(type, msg);
    }



    assignUsers(d) {
        console.log(d)

        this.assignMdl = true
        this.hldy_prfle_id = null
        this.hldy_prfle_id = d.data.hldy_prfle_id
        this.hldy_prfle_nm = d.data.hldy_prfle_nm
        this.title = `Assign Users to ${this.hldy_prfle_nm}`


    }


    getUsrNtAsgndPrfles() {
        const rte = `payroll/users/unassigned/profile`;

        this.apisrvc.get(rte).subscribe(res => {
            if (res['status'] == 200) {
                this.unasignedPrfUsrLst = res['data'];
                this.unasignedPrfUsrLst = res['data'].map((user: any) => ({
                    ...user,
                    selected: false // Initialize the 'selected' property for checkbox binding
                }));
                console.log(this.unasignedPrfUsrLst)
            }
        })
    }



    assignPrfle() {
        const rte = `payroll/assign/users/profile`;
        let data = {

        }
        this.apisrvc.post(data, rte).subscribe(res => {

            if (res['status'] == 200) {

                this.createMessage('success', 'Profile Assigned to Users Successfully')

            } else {
                this.createMessage('warning', 'Something Went Wrong')

            }


        })
    }


    assignprof() {
        this.asgndEmplLst = this.unasignedPrfUsrLst.filter(item => item.selected === true);
        console.log(this.asgndEmplLst);

        let data = {
            asgndEmplLst: this.asgndEmplLst,
            hldy_prfle_id: this.hldy_prfle_id
        }

        let rte = `payroll/assign/profiles/user`
        this.apisrvc.post(data, rte).subscribe((res) => {

            if (res && res['status'] == 200) {
                this.createMessage('success', 'Profile Assigned to Users Successfully')
                this.getUsrNtAsgndPrfles()
                this.vwUsers(this.pfle_data)

            } else {
                this.createMessage('warning', 'Something Went Wrong')

            }


        })

    }

    CancelUserGrid() {
        this.assignMdl = false
    }

}
