import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
// import { ToastrService } from "ngx-toastr";
// import { AllModulesService } from "../../all-modules.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { Subject } from "rxjs";
// import { DataTableDirective } from "angular-datatables";
import { HolidayService } from "./holidays.service";
import { AppsService } from "src/app/shared/services/apps.service";
import * as moment from "moment";
import { NzMessageService } from "ng-zorro-antd/message";
// import moment from 'moment';
declare const $: any;
// import SecureLS from 'secure-ls';
// var ls = new SecureLS({ encodingType: 'base64' });
@Component({
  selector: "app-holidays",
  templateUrl: "./holidays.component.html",
  styleUrls: ["./holidays.component.scss"],
})
export class HolidaysComponents implements OnInit, OnDestroy {
  // @ViewChild(DataTableDirective, { static: false })
  // public dtElement: DataTableDirective;
  // public dtOptions: DataTables.Settings = {};
  rle_id
  isBtnLoading = false
  sidebarHeading
  hldy_prfle_id
  lstHolidays: any[];
  hldyscolumnDefs
  url: any = "holidays";
  editHolidayVisible = false
  public tempId: any;
  public editId: any;

  public rows = [];
  public srch = [];
  public statusValue;
  public dtTrigger: Subject<any> = new Subject();
  public pipe = new DatePipe("en-US");
  public addHolidayForm: FormGroup;
  public editHolidayForm: FormGroup;
  public editHolidayDate: any;
  years = [];
  slctdYr: any;
  hldys: any[];
  loader: boolean = false;
  hldySlctYr: string;
  weeks: any[];
  shwSaveBtn: boolean = false;
  shwErr: boolean = false;
  clntDtls;
  tntDtls;
  data = {
    hldy_prfle_id: null,
    hldy_id: null,
    permissions: { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 },
    years: [],
    filterTerm: '',
    allChecked: false,
    slctdEmples: [],
    ntasgndin: null,
    allEmplesLst: [],
    columnDefs: [],
    // mnths: [{ mnth_id: 1, name: 'Jan' }, { mnth_id: 2, name: 'Feb' }, { mnth_id: 3, name: 'Mar' }, { mnth_id: 4, name: 'Apr' }, { mnth_id: 5, name: 'May' },
    // { mnth_id: 6, name: 'Jun' }, { mnth_id: 7, name: 'July' }, { mnth_id: 8, name: 'Aug' }, { mnth_id: 9, name: 'Sep' }, { mnth_id: 10, name: 'Oct' }, { mnth_id: 11, name: 'Nov' },
    // { mnth_id: 12, name: 'Dec' }],
    mnths: [],
    dsgnsLst: [],
    empleTypeLst: [],
    pyrle_rn_in: false,
    empleLst: [],
    pyrle_amnt: null,
    pyrleGnrtdEmpleLst: []
  }
  addHolidayVisible: boolean;
  shwDeleteCnfrm: boolean;
  delete_holiday = false;
  add_holiday = false;
  rlelst: any;
  hldyprflst: any;
  shwSidebar: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private apisrvc: AppsService,
    // private srvModuleService: AllModulesService,
    // private toastr: ToastrService,
    private message: NzMessageService,
    private hldySrv: HolidayService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    // this.clntDtls = JSON.parse(ls.get('hms_loggedin_user_clients'));
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));

    this.tntDtls = this.clntDtls[0].tnts;
    console.log(this.clntDtls);
    console.log(this.tntDtls);
  }
  Yr;
  ngOnInit() {
    // this.loadholidays();
    this.getYears()
  
    this.getHolidayPrfles()
    this.addHolidayForm = this.formBuilder.group({
      HolidayName: ["", [Validators.required]],
      Holidaydate: ["", [Validators.required]],
      Description: [""],
    });

    // Edit Contact Form Validation And Getting Values

    this.editHolidayForm = this.formBuilder.group({
      editHolidayName: ["", [Validators.required]],
      editHolidayDate: ["", [Validators.required]],
      editDescription: [""],
      dt_id: [""],
      hldy_id: [""]
    });

    // let crnt_yr = new Date().getFullYear();
    // let bf_prvs_yr = new Date().getFullYear() - 2;
    // let prvs_yr = new Date().getFullYear() - 1;
    // let futre_yr = new Date().getFullYear() + 1;
    // let af_prvs_yr = new Date().getFullYear() + 2;

    // this.years = [
    //   {
    //     id: bf_prvs_yr,
    //     value: bf_prvs_yr
    //   },
    //   {
    //     id: prvs_yr,
    //     value: prvs_yr
    //   },
    //   {
    //     id: crnt_yr,
    //     value: crnt_yr
    //   },
    //   {
    //     id: futre_yr,
    //     value: futre_yr
    //   },
    //   {
    //     id: af_prvs_yr,
    //     value: af_prvs_yr
    //   }
    // ]
    // this.slctdYr = new Date();
    // console.log('this.slctdYr', this.slctdYr);
    this.slctdYr = new Date().getFullYear();
    console.log(this.slctdYr);
    // this.getHolidays(this.slctdYr);
    // this.getWeeks();
  }
  openAddHolidayModal() {
    this.addHolidayVisible = true
  }
  // Get Employee  Api Call
  // loadholidays() {
  //   this.srvModuleService.get(this.url).subscribe((data) => {
  //     this.lstHolidays = data;
  //     this.dtTrigger.next();
  //     this.rows = this.lstHolidays;
  //     this.srch = [...this.rows];
  //   });
  // }


  getHolidays(yr) {
    console.log(yr, 'yrrrrrrrrrrrrrrrrrrrrr')

    this.loader = true;
    // let year = yr ? new Date(yr).getFullYear() : null;

    let postdata = {

      hldy_prfle_id: this.hldy_prfle_id
    }



    let rte = `payroll/holidays/${yr} `
    // this.apisrvc.get(rte).subscribe((res) => {
    this.apisrvc.post(postdata, rte).subscribe((res) => {

      this.loader = false;
      this._changeDetectorRef.markForCheck();
      this.hldys = [];
      if (res['status'] == 200) {
        if (res['data'].length > 0) {
          this.hldys = res['data'];
          this.shwErr = false;
          console.log(this.hldys.length);
          this.rows = this.hldys;
          this.hldyscolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false, visible: true },
            { headerName: 'Title', field: 'hldy_nm', alignment: 'left', visible: true },
            { headerName: 'Holiday Date', field: 'dt', alignment: 'center', visible: true },
            { headerName: 'Day', field: 'dy_nm', alignment: 'left', visible: true },

          ]


          this.srch = [...this.rows];
          this._changeDetectorRef.markForCheck();
        }
      } else {
        this.shwErr = true;
      }

    })
  }

  // Add holidays Modal Api Call

  addholidays() {

    console.log('hhhhhhhhhhhhhhhh')
    this.loader = true
    console.log(this.addHolidayForm.value);
    let rte = `payroll/add_holiday`

    if (this.addHolidayForm.valid) {
      let obj = {
        title: this.addHolidayForm.value.HolidayName,
        holidaydate: moment(this.addHolidayForm.value.Holidaydate).format('YYYY-MM-DD'),
        description: this.addHolidayForm.value.Description,
      };
      console.log(obj);
      // this.hldySrv.addHoliday(obj).subscribe((res: any) => {
      this.apisrvc.create(obj, rte).subscribe((res: any) => {

        this.loader = false;
        if (res.status == 200) {
          this.getHolidays(this.slctdYr);
          // $("#add_holiday").modal("hide");
          this.handleCancel()


//           this.addHolidayVisible = false
// this.shwSidebar = false
          this.addHolidayForm.reset();
          this.createMessage("Success", "Holiday Added Successfully");



          // this.toastr.success(`${res.message}`, "Success",{timeOut: 3000});
        } else {
          // this.toastr.warning("Something went wrong", "Warn",{timeOut: 3000});
          this.createMessage("Error", "Something went wrong");

        }
      }, (err) => { this.loader = false; })
    }
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }



  select_Yr(evnt) {
    evnt.yearSelectHandler = (event: any): void => {
      evnt._store.dispatch(evnt._actions.select(event.date));
      this.getHolidays(event.label);
    };
    evnt.setViewMode('year');
  }

  // updateCalcs(dt) {
  //   console.log('date', dt);
  //   this._changeDetectorRef.markForCheck();

  //   console.log('this.slctdYr', this.slctdYr);
  //   this._changeDetectorRef.markForCheck();
  // }

  // Edit holidays Modal Api Call

  updateHolidays() {
    this.loader = true;
    if (this.editHolidayForm.valid) {
      let obj = {
        title: this.editHolidayForm.value.editHolidayName,
        holidaydate: moment(this.editHolidayForm.value.editHolidayDate).format('YYYY-MM-DD'),
        description: this.editHolidayForm.value.editDescription,
        dt_id: this.editHolidayForm.value.dt_id,
        hldy_id: this.editHolidayForm.value.hldy_id,
      };
      console.log(obj);
      let rte = `payroll/updt_holiday`
      this.apisrvc.update(obj, rte).subscribe((res: any) => {


        console.log(res, 'resssssssssssssssss')

        // this.hldySrv.updtHoliday(obj).subscribe((res: any) => {
        this.loader = false;
        if (res.status == 200) {
          this.getHolidays(this.slctdYr);

          // this.editHolidayVisible = false
          this.handleCancel()
          this.editHolidayForm.reset();
          // this.toastr.success(`${res.message}`, "Success",{timeOut: 3000});
          this.createMessage("Success", "Holiday Updated Successfully");

        } else {
          this.createMessage("Error", "Something went wrong");

          // this.toastr.warning("Something went wrong", "Warn",{timeOut: 3000});
        }
      }, (err) => {
        this.loader = false;
        // this.createMessage("Error", "Something went wrong");

      })
    }
  }

  // Delete holidays Modal Api Call
  delObj;
  // delete(value) {
  //   console.log(value);
  //   let obj = {
  //     dt_id: value.dt_id,
  //     hldy_id: value.hldy_id,
  //   };
  //   this.delObj = obj;
  //   this.delete_holiday = true

  // }
  deleteHolidays() {

    console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhh')
    this.loader = true;
    console.log(this.delObj);
    let rte = `payroll/del_holiday`
    if (this.delObj) {
      this.apisrvc.update(this.delObj, rte).subscribe((res: any) => {
        this.loader = false;
        if (res.status == 200) {
          this.handleCancel()


          // this.shwDeleteCnfrm = false
          // this.shwSidebar = false
          this.editHolidayForm.reset();

          this.createMessage("Success", "Holiday Deleted Successfully");
          this.getHolidays(this.slctdYr);


        } else {
          // this.toastr.warning("Something went wrong", "Warn",{timeOut: 3000});
          this.createMessage("Error", "Something went wrong");

        }
      }, (err) => { this.loader = false; })
    }

  }

  // To Get The holidays Edit Id And Set Values To Edit Modal Form
  format1: string = ""; fntedtDt;

  handleCancel() {
    this.addHolidayVisible = false
    this.editHolidayVisible = false
    this.shwDeleteCnfrm = false
    this.delete_holiday = false
    this.shwSidebar = false

    
  }
  getWeeks() {
    this.loader = true


    let rte = `payroll/getWeeks`

    let postdata = {
      rle_id: this.rle_id,
      hldy_prfle_id: this.hldy_prfle_id
    }

    this.weeks = []


    this.apisrvc.post(postdata, rte).subscribe((res) => {

      // this.apisrvc.get(rte).subscribe((res) => {
      this.loader = false;
      this._changeDetectorRef.markForCheck();
      if (res['status'] == 200) {
        if (res['data'].length > 0) {
          this.weeks = res['data'];
          this.weeks.forEach(wkobj => {
            wkobj['slct_in'] = 0;
          })
          console.log(this.weeks);
          this._changeDetectorRef.markForCheck();
        }
      }

    })
  }

  weekSlctd = [];
  editWeek(wdy_id, w, w_in) {
    this.shwSaveBtn = true;
    console.log(wdy_id, w, w_in);

    this.weeks.forEach(wkobj => {
      // console.log(wkobj);
      if (wkobj.wk_dy_id == wdy_id) {
        if (w == 'w1') {
          wkobj.w1_in = 1;
        } else if (w == 'w2') {
          wkobj.w2_in = 1;
        } else if (w == 'w3') {
          wkobj.w3_in = 1;
        } else if (w == 'w4') {
          wkobj.w4_in = 1;
        } else if (w == 'w5') {
          wkobj.w5_in = 1;
        }
      }
      if (wkobj.wk_dy_id == wdy_id) {
        if (w == 'w1' && w_in == 1) {
          if (w_in == 1 && wkobj.w1_in == 1) {
            wkobj.w1_in = 0;
          }
        } else if (w == 'w2') {
          if (w_in == 1 && wkobj.w2_in == 1) {
            wkobj.w2_in = 0;
          }
        } else if (w == 'w3' && w_in == 1) {
          if (w_in == 1 && wkobj.w3_in == 1) {
            wkobj.w3_in = 0;
          }
        } else if (w == 'w4' && w_in == 1) {
          if (w_in == 1 && wkobj.w4_in == 1) {
            wkobj.w4_in = 0;
          }
        } else if (w == 'w5' && w_in == 1) {
          if (w_in == 1 && wkobj.w5_in == 1) {
            wkobj.w5_in = 0;
          }
        }
      }
    })
    console.log(this.weeks);
  }

  submitWeekChng() {
    this.loader = true;
    console.log(this.weeks)
    // this.weekSlctd.push(wkObj);
    // console.log(this.weekSlctd);
    let rte = `payroll/updt_week`
    this.apisrvc.update(this.weeks, rte).subscribe((res: any) => {

      // this.hldySrv.updtWeek(this.weeks).subscribe((res: any) => {
      this.shwSaveBtn = false;
      this.loader = false;
      if (res.status == 200) {
        // this.toastr.success(`${res.message}`, "Success",{timeOut: 3000});
      } else {
        // this.toastr.warning("Something went wrong", "Warn",{timeOut: 3000});
      }
    }, (err) => { this.loader = false; })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        text: 'Add New Holiday ',
        onClick: this.openSideBar.bind(this, 'new'),
        bindingOptions: {
          'disabled': 'isEmailButtonDisabled'
        }
      }
    });
  }
  openSideBar() {
    // this.settings.shwForm = true;
    // this.settings.shwGrid = false;
    // this.settings.shwDetails = false;
    // this.getWeeks();
    // this.holidayForm.reset();
    this.shwSidebar = true
    this.addHolidayVisible = true;
    this.sidebarHeading= 'Add Holiday'
    // this.settings.shwEditHolidayProfile = true;
    // this.data.sidebarHeading = 'Add Holiday Profile';
  }


  getYears() {
    // this.settings.loader = true;
    const rte = `payroll/years`;
    this.apisrvc.get(rte).subscribe(res => {
      // this.settings.loader = false;
      this.data.years = res['data'];
      setTimeout(() => {
        // this.searchForm.controls['yr_id'].setValue(parseInt(moment(new Date()).format('YYYY')));
        // this.getDistricts();
      }, 50)
    });
  }
  editHoliday(value) {
    console.log(value);
    let dte = new Date(value.dte);
    console.log(dte);
    this.editHolidayForm.get('editHolidayDate').setValue(dte);
    this.editHolidayForm.get('editHolidayName').setValue(value.hldy_nm);
    this.editHolidayForm.get('editDescription').setValue(value.dscn_tx);
    this.editHolidayForm.get('dt_id').setValue(value.dt_id);
    this.editHolidayForm.get('hldy_id').setValue(value.hldy_id);
    console.log(this.editHolidayForm.value);

    this.sidebarHeading= 'Edit Holiday'

    this.shwSidebar = true

    this.editHolidayVisible = true
  }
  onDeleteHoliday(d) {
    this.sidebarHeading= 'Delete Holiday'

    console.log(d)
    this.data.hldy_id = d.data.hldy_id;
    this.data.hldy_prfle_id = d.data.hldy_prfle_id;
    this.shwSidebar = true

    this.shwDeleteCnfrm = true;

    // this.delete_holiday = true

    this.delObj = d.data
  }


  // delete(value) {
  //   console.log(value);
  //   let obj = {
  //     dt_id: value.dt_id,
  //     hldy_id: value.hldy_id,
  //   };
  //   this.delObj = obj;
  //   this.delete_holiday = true

  // }



  getHolidayPrfles() {

    let rte = `payroll/holiday/profilesByRle`

    this.apisrvc.get(rte).subscribe((res) => {
      if (res['status'] == 200) {
        console.log(res['data'])
        this.hldyprflst = res['data']
        this.hldy_prfle_id =null
        if (this.hldyprflst && this.hldyprflst.length > 0) {
          this.hldy_prfle_id = this.hldyprflst[0].app_prfle_id
          this.getHolidays(this.slctdYr);
          this.getWeeks();


          
        }
      }

    })


  }
}
