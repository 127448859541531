import { Component, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexStroke, ApexFill, ApexYAxis, ApexTitleSubtitle } from 'ng-apexcharts';
import * as moment from 'moment';
import { differenceInCalendarDays } from 'date-fns';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
export type ChartOptions = {
  series: any;
  chart: ApexChart;
  stroke: ApexStroke;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
};

@Component({
  selector: 'app-substations',
  templateUrl: './substations.component.html',
  styleUrls: ['./substations.component.scss']
})
export class SubstationsComponent {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  validateForm!: FormGroup;
  districts: any;
  mandals: any;
  sbstns: any;
  sbstndtls: any;
  WorksCrdData: any
  slctdActdt: any = 1;
  tb_id = 1
  slctdvw = 1
  showLdr = false
  astcnts: any;
  astlength: any;
  columnDefs: any;
  isGrouped: boolean;
  slctd_dstrt: any;
  gridData = []
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  frmDt = moment(this.today).subtract(7, 'days').toISOString(); // Subtract 7 days
  toDt = new Date();
  frmDate = moment(this.today).subtract(7, 'days').format('YYYY-MM-DD');
  toDate = moment(this.today).format('YYYY-MM-DD');
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  dateRange: Date[] = [];
  tb_nm: any;
  lvl_id = 1
  dateRnge: Date[] = [];
  columnDefs1: any[];
  columnDefs2: any[];
  columnDefs3: any[];
  startIcon = new L.Icon({
    iconUrl: '/assets/m/sub.png',
    iconSize: [33, 33],
    iconAnchor: [16, 28],
  });
  columnEmplyDefs: ({ headerName: string; field: string; alignment: string; width: number; } | { headerName: string; field: string; width: string; alignment: string; } | { headerName: string; field: string; alignment: string; width?: undefined; })[];
  slctdCrd: any;
  cardsdata: any;
  slectdExpdt = 3;
  wrkgrphdata: any[];
  graphData: any;
  clntDtls: any;
  dataSource1: any;
  tpAstType: any;
  dataSource2: any[];
  astGridData: any;
  dwrWdth: number;
  sidenav: boolean;
  appCond: number;
  drwerTitle: string;
  wrkData: any;
  dstrt_id: any;

  constructor(private apisrvc: AppsService, private fb: FormBuilder, private modal: NzModalService) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls, 'ttttttttttttttttttttttt')

  }
  // ngOnChanges() {
  //   this.slctd_dstrt = this.slctd_dstrt
  //   this.slctdCrd = this.slctdCrd
  //   this.header_label = {
  //     name: `OMS ${this.dstrt_nm} District Dashboard  `,
  //     dtepkrlbl: false
  //   };
  // }

  ngOnInit() {
    setTimeout(() => {
      this.initialize()
    }, 2000)
    const today = moment(this.today).toDate();
    const lastWeek = moment().subtract(7, 'days').toDate();
    // this.dateRnge = [lastWeek, today];


    const date = new Date(2024, 9, 1)
    this.dateRange = [date, today];

    // this.getDistricts()
    this.getDstrtData()
    this.validateForm = this.fb.group({
      dstrt_id: [null],
      mndl_id: 0,
      oms_pop_id: 0
    })
    this.getAstCnts()
    this.last7dayaData()
    setTimeout((k) => {
      // this.getCrdData()
      this.selctedCatrd({
        crd_hdr: 'WORKS',
        clr_cd: '#544795',
        slct_dt_in: 3
      })
      this.getDtls()


    }, 1000)
    setTimeout(() => {

    }, 500);


  }

  AssetGridData() {
    let data = {
      tnt_id: this.validateForm.value.dstrt_id,
      mndl_id: this.validateForm.value.mndl_id,
      oms_pop_id: this.validateForm.value.oms_pop_id,


    }
    this.columnDefs2 = []
    this.apisrvc.post(data, `apsfl/asset/grid/data`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.astGridData = res['data'];
        console.log(this.astGridData)
        this.gridData = this.astGridData
        this.columnDefs2 =
          [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, visible: false },
            { headerName: 'Ditrict', field: 'tnt_nm', width: 'auto', alignment: 'left' },
            { headerName: 'Asset Name', field: 'ast_nm', width: 'auto', alignment: 'left' },
            { headerName: 'Asset type', field: 'ast_typ_nm', alignment: 'left', groupIndex: 2 },
            { headerName: 'Asset Model', field: 'ast_mdl_nm', alignment: 'left' },
            { headerName: 'Asset make', field: 'ast_mke_nm', alignment: 'left' },
            { headerName: 'Assert Status', field: 'ast_sts_nm', alignment: 'left' },
          ]
        this.columnDefs = this.columnDefs2

        this.tb_id = 2


      }

    })

  }




  getDstrtData() {

    this.apisrvc.get(`apsfl/data/districts`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.districts = res['data'];

        console.log(this.districts)
        this.validateForm.controls['dstrt_id'].setValue(this.districts[0].tnt_id)
        console.log(this.validateForm.value.dstrt_id)
        this.getdistrictid(this.validateForm.value.dstrt_id)
        // this.getMndl(this.validateForm.value.dstrt_id)
      }

    })

  }


  SumINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };
  formatter1 = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  onContentReady(e) {
    const groupedColumns = e.component.getVisibleColumns().filter(c => c.groupIndex !== undefined);
    this.isGrouped = groupedColumns.length > 0;
  }
  onChangeDate() {
    this.getDtls();
  }





  selctedCatrd(e) {
    console.log(e)

    this.tb_nm = e.crd_hdr

    var today = new Date()
    const date = new Date(2024, 9, 1)
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);


    if (e.slct_dt_in == 1) {

      this.dateRnge = [date, today];
    } else if (e.slct_dt_in == 2) {

      this.dateRnge = [firstDayOfMonth, today];
    }
    else if (e.slct_dt_in == 3) {

      this.dateRnge = [today, today];
    }
    // setTimeout(() => {
    //   this.onChangeDate()
    // }, 1000);


    // if (e.crd_hdr == 'WORKS') {
    //   console.log('111111111111111111111111')
    //   this.tb_id = 1
    //   this.ChangeLevel(this.lvl_id)
    // }
    // else if (e.crd_hdr == 'ASSETS') {
    //   console.log('2222222222222222222222222222222')
    //   this.tb_id = 2
    //   this.ChangeLevel(this.lvl_id)
    // }

    this.slctdCrd = e

    this.colorChanges(e.clr_cd)
  }
  colorChanges = (clr_cd) => {
    console.log("test case")
    document.documentElement.style.setProperty('--colorcode', clr_cd);

  }
  sumcurrencyINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter1.format(parseInt(val, 10))
        ? this.formatter1.format(parseInt(val, 10))
        : 0;
    }

  };


  INRFormater = (val: any) => {
    if (typeof val == 'string') {

    } else {
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };


  onCellPrepared(e) {
    // console.log(e)
    if (e.rowType === "header" || e.rowType === "group") {
      return
    }
    if (
      e.column.dataField == "wrk_sts_nm" || e.column.dataField == "trp_sts"
    ) {
      if (e.data && e.value) {
        if (e.value == 'In Progress') {
          e.cellElement.style.color = "#f19000";
          e.cellElement.style.cursor = `pointer`;
          e.cellElement.style.fontWeight = `500`;

        } else if (e.value == 'Completed') {
          e.cellElement.style.color = "#4CAF50";
          e.cellElement.style.cursor = `pointer`;
          e.cellElement.style.fontWeight = `500`;

        }

        if (e.value == 'In Trip') {
          e.cellElement.style.color = "#f19000";
          e.cellElement.style.cursor = `pointer`;
          e.cellElement.style.fontWeight = `500`;

        } else if (e.value == 'Trip Completed') {
          e.cellElement.style.color = "#4CAF50";
          e.cellElement.style.cursor = `pointer`;
          e.cellElement.style.fontWeight = `500`;

        }

      }
    }
  }
  onFocusedRowChanged(e) {

    console.log(e)
  }

  formatter2 = new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
  });

  header_label = {

    name: 'Sub Station Dashboard'
  }
  // getDistricts() {
  //   let rte = `admin/districts`;
  //   this.apisrvc.get(rte).subscribe(res => {
  //     this.districts = res['data']

  //     console.log(this.districts)
  //     this.validateForm.controls['dstrt_id'].setValue(this.districts[0].tnt_id)
  //     // console.log(this.validateForm.value.dstrt_id)


  //      this.getdistrictid(this.validateForm.value.dstrt_id)
  //   })
  // }
  getdistrictid(e) {
    console.log(this.districts)

    this.districts.forEach((k) => {
      console.log(k.tnt_id, 'gggggggggggggggg', e)
      if (k.tnt_id == e) {

        this.getMndl(k.dstrt_id)
      }
    })
  }
  initialize = function () {
    console.log(this.clntDtls[0].lat, this.clntDtls[0].lng)
    try {

      this.livemap = L.map('livemap', {
        center: [this.clntDtls[0].lat, this.clntDtls[0].lng],
        zoom: 15
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      tiles.addTo(this.livemap);
      this.sbStnMrkr = L.marker([this.clntDtls[0].lat, this.clntDtls[0].lng], {
        icon: this.startIcon
      }).addTo(this.livemap)
    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
  };
  cnt = 0
  // getTopAssetType() {

  //   let rte = `apsfl/asset/type/counts`
  //   this.apisrvc.get(rte).subscribe(res => {
  //     this.tpAstType = res['data']

  //     console.log(this.tpAstType)
  //     console.log(this.astcnts)
  //     this.cnt = this.astcnts.length
  //     this.tpAstType.forEach((k) => {
  //       this.cnt = this.cnt - k.count
  //       console.log(this.cnt)


  //     })


  //     setTimeout((k) => {
  //       this.tpAstType.push({
  //         ast_typ_nm: 'Others',
  //         count: this.cnt



  //       })

  //     }, 5000)





  //   })

  // }


  getAstTypWs(e) {
    console.log(e)
  }
  getMndl(e) {
    console.log(e)

    let rte = `admin/mandals/${e}`
    console.log(e)
    this.apisrvc.get(rte).subscribe(res => {
      console.log(res, 'rrrrrrrrrrrrrrrrr')
      this.mandals = res['data']
      this.validateForm.controls['mndl_id'].setValue(this.mandals[0].mndl_id)
      this.getsbstn(this.validateForm.value.mndl_id)



    })
  }
  getsbstn(e) {

    this.validateForm.controls['oms_pop_id'].setValue(0)

    let rte = `apsfl/app/substnLst/${e}`
    this.apisrvc.get(rte).subscribe(res => {
      this.sbstns = res['data']
      this.validateForm.controls['oms_pop_id'].setValue(this.sbstns[0].oms_pop_id)
      this.getDtls()
      // this.getCrdData()



    })
  }
  getDtls() {
    this.getSbstnDtls()
    this.getGridData()
    this.getAstCnts()
    this.getCrdData()


  }



  getSbstnDtls() {
    this.sbstndtls = null

    this.districts.forEach((k) => {

      console.log('ddddddddddddddddddddddd', k)

      if (k.tnt_id == this.validateForm.value.dstrt_id) {
        this.dstrt_id = k.dstrt_id
      }
    })


    let rte = `apsfl/substation/details`
    let postdata = {
      tnt_id: this.validateForm.value.dstrt_id,
      mndl_id: this.validateForm.value.mndl_id,
      oms_pop_id: this.validateForm.value.oms_pop_id,
      dstrt_id: this.dstrt_id


    }

    this.apisrvc.post(postdata, rte).subscribe(res => {

      if (res['data'] && res['data'].length == 1) {
        this.sbstndtls = res['data'][0]

      }



    })



  }

  tabsActdata = [
    {
      'tab_id': 1,
      'tab_nm': 'Overall',
      'tab_hndlr': 'overall'
    },
    {
      'tab_id': 2,
      'tab_nm': 'This Month',
      'tab_hndlr': 'crntmnt'

    },
    {
      'tab_id': 3,
      'tab_nm': 'Today',
      'tab_hndlr': 'crntdt'

    },

  ]
  slectdCrd: any = 'WORKS';

  slectdActTab(t) {
    console.log(t)



    var today = new Date()
    const date = new Date(2024, 9, 1)
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);


    if (t.tab_id == 1) {

      this.dateRange = [date, today];
    } else if (t.tab_id == 2) {

      this.dateRange = [firstDayOfMonth, today];
    }
    else if (t.tab_id == 3) {

      this.dateRange = [today, today];
    }
    setTimeout(() => {
      this.onChangeDate()
    }, 1000);



    // this.slctdCrd = {
    //   crd_hdr: 'WORKS',
    //   clr_cd: '',
    //   slct_dt_in: this.slctdActdt

    // }


    // this.selctedCatrd(this.slctdCrd)
    this.WorksCrdData = []
    this.slctdActdt = t.tab_id
    console.log(this.cardsdata.WorksCrdData)

    if (this.slctdActdt == 3) {
      this.WorksCrdData = this.cardsdata.WorksCrdData[0][0]
    }
    else if (this.slctdActdt == 2) {
      this.WorksCrdData = this.cardsdata.WorksCrdData[1][0]
    }
    else if (this.slctdActdt == 1) {
      this.WorksCrdData = this.cardsdata.WorksCrdData[2][0]
    }


    this.getgraphs()



  }
  last7dayaData() {
    console.log('last7dayaDatalast7dayaDatalast7dayaDatalast7dayaDatalast7dayaData')
    this.apisrvc.get(`apsfl/data/last7dayaData/${this.slctd_dstrt ? this.slctd_dstrt : 0}`,).subscribe((res) => {
      if (res['status'] == 200) {
        this.graphData = res['data'][0];
        console.log(this.graphData)
        this.getgraphs()


      }
    }
    )
  }
  getgraphs() {

    this.wrkgrphdata = []

    if (this.slctdActdt == 3) {
      this.wrkgrphdata = this.graphData.WorksGraphData[0]

      // console.log(this.grphdata)
    }
    if (this.slctdActdt == 2) {
      this.wrkgrphdata = this.graphData.WorksGraphData[1]
    }
    if (this.slctdActdt == 1) {
      this.wrkgrphdata = this.graphData.WorksGraphData[2]
      // console.log(this.grphdata)
    }










    this.chartOptions = {
      series: [{
        name: 'Completed',
        data: this.generateDailyData(this.wrkgrphdata, 'cmpltd_wrks')
      }],
      chart: {
        type: 'area',
        height: 80,
        sparkline: {
          enabled: true
        }
      },
      xaxis: {
        categories: this.getDaysArray(this.wrkgrphdata),  // Generate array of days from 1 to 30
        title: {
          text: 'Days'
        }
      },
      stroke: {
        curve: 'smooth',
        width: 1
      },
      fill: {
        opacity: 0.3
      },
      yaxis: {
        min: 0
      },
      colors: ['#544795'],
      // title: {
      //   text: '$235,312',
      //   offsetX: 0,
      //   style: {
      //     fontSize: '24px'
      //   }
      // },
      // subtitle: {
      //   text: 'Activities',
      //   offsetX: 0,
      //   style: {
      //     fontSize: '14px'
      //   }
      // }
    };



  }
  getDaysArray(days) {
    const daysArray: string[] = [];
    for (let i = 0; i < days.length; i++) {
      daysArray.push(days[i].dt);
    }
    console.log(daysArray, 'daysArraydaysArray')
    return daysArray;
  }

  generateDailyData(days, fld) {
    console.log(days)
    const daysArray: string[] = [];
    for (let i = 0; i < days.length; i++) {
      daysArray.push(days[i][fld]);
    }
    console.log(daysArray)


    return daysArray;
  }
  getGridData() {
    console.log('yyyyyyyy')
    var postdata = {
      lvl_id: 2,
      tb_id: this.tb_id,
      dstrt_id: this.validateForm.value.dstrt_id,
      from_dt: this.lvl_id != 3 ? moment(this.dateRange[0]).format('YYYY-MM-DD') : null,
      to_dt: this.lvl_id != 3 ? moment(this.dateRange[1]).format('YYYY-MM-DD') : null,
      oms_pop_id: this.validateForm.value.oms_pop_id,
      mndl_id: this.validateForm.value.mndl_id
    }
    // this.apisrvc.post(postdata, `apsfl/data/grid`,).subscribe((res) => {
    this.apisrvc.post(postdata, `apsfl/district/data/grid`,).subscribe((res) => {

      if (res['status'] == 200) {
        var data = res['data'];
        this.dataSource1 = data[0]

        this.gridData = this.dataSource1

        console.log(data)
        var co1 = 1

        this.dataSource1.forEach(e => {
          e['sno'] = co1++
        });


        this.columnDefs1 = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60, visible: false },
          { headerName: 'Mandal ', field: 'mndl_nm', width: 'auto', alignment: 'left' },
          { headerName: 'Work Description ', field: 'oms_wrm_dsc_tx', width: 'auto', alignment: 'left' },
          { headerName: 'Work type', field: 'wrk_typ_nm', alignment: 'left', groupIndex: 2 },
          { headerName: 'Work Group', field: 'wrk_grp_nm', alignment: 'left' },
          { headerName: 'Issue Type', field: 'isue_typ_nm', alignment: 'left' },
          { headerName: 'Work Status', field: 'wrk_sts_nm', alignment: 'left' },
          { headerName: 'No of Customers Effected', field: 'cstmr_efctd_ct', alignment: 'center' },
          { headerName: 'No of Employees Assigned', field: 'asgn_emps', alignment: 'center' },
        ]

        this.columnDefs = this.columnDefs1





      }

    })

  }
  getCrdData() {
    var postdata = {
      data: null
    }
    this.cardsdata = []
    // this.apisrvc.post(postdata, `apsfl/data/cards/${this.slctd_dstrt ? this.slctd_dstrt : 0}`,).subscribe((res) => {
    this.apisrvc.post(this.validateForm.value, `apsfl/substation/cards/counts`,).subscribe((res) => {

      if (res['status'] == 200) {
        var data = res['data'][0];


        this.cardsdata = data
        console.log(this.cardsdata)

        if (this.slctdActdt == 3) {
          this.WorksCrdData = this.cardsdata.WorksCrdData[0][0]
        }
        else if (this.slctdActdt == 2) {
          this.WorksCrdData = this.cardsdata.WorksCrdData[1][0]
        }
        else if (this.slctdActdt == 1) {
          this.WorksCrdData = this.cardsdata.WorksCrdData[2][0]
        }

        this.cnt = this.astlength
        this.tpAstType = []
        this.tpAstType = this.cardsdata.astcnts

        this.tpAstType.forEach((k) => {
          this.cnt = this.cnt - k.count
          console.log(this.cnt)


        })


        setTimeout((k) => {
          this.tpAstType.push({
            ast_typ_nm: 'Others',
            count: this.cnt,
            icn_tx: 'assets/images/apsfl/others.jfif'



          })
        })


        // if (this.slectdExpdt == 3) {
        //   this.expnsCrdData = this.cardsdata.expnsCrdData[0][0]
        // }
        // else if (this.slectdExpdt == 2) {
        //   this.expnsCrdData = this.cardsdata.expnsCrdData[1][0]
        // }
        // else if (this.slctdActdt == 1) {
        //   this.expnsCrdData = this.cardsdata.expnsCrdData[2][0]

        // }




        // if (this.slctdTrvldt == 3) {
        //   this.tripCrdData = this.cardsdata.tripCrdData[0][0]
        //   console.log(this.tripCrdData)


        // }
        // else if (this.slctdTrvldt == 2) {
        //   this.tripCrdData = this.cardsdata.tripCrdData[1][0]
        // }
        // else if (this.slctdTrvldt == 1) {
        //   this.tripCrdData = this.cardsdata.tripCrdData[2][0]

        // }





        console.log(data)
      }

    })

  }

  selctedcard(e, clr_cd) {
    console.log(e)
    this.slectdCrd = e
    var slct_dt_in = null



    this.columnDefs = []
    this.gridData = []
    if (e == 'WORKS') {
      // slct_dt_in = this.slctdActdt

      this.slctdCrd = {
        crd_hdr: e,
        clr_cd: clr_cd,
        slct_dt_in: this.slctdActdt


      }

      this.tb_id = 1

      this.selctedCatrd(this.slctdCrd)


      this.columnDefs = this.columnDefs1
      this.gridData = this.dataSource1
    }


    else if (e == 'ASSETS') {


      this.slctdCrd = {
        crd_hdr: e,
        clr_cd: clr_cd,
        slct_dt_in: slct_dt_in

      }


      this.selctedCatrd(this.slctdCrd)
      this.AssetGridData()
      this.columnDefs = this.columnDefs2
      this.gridData = this.dataSource2



    }
  }

  getAstCnts() {



    let data = {
      oms_pop_id: this.validateForm.value.oms_pop_id,
      dstrt_id: this.validateForm.value.dstrt_id
    }
    let rte = `apsfl/assets/counts`;
    this.apisrvc.post(data, rte).subscribe(res => {
      this.astcnts = res['data']

      console.log(this.astcnts)

      this.astlength = this.astcnts.length
    })

  }
  onCellClick(e) {


    if (e.rowType === "header" || e.rowType === "group") {
      return
    }

    if ((!this.slctd_dstrt && e.columnIndex != 1)) {
      return;
    }
    console.log(e, 'eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
    // this.slctd_dstrt = e.data.dstrt_id;
    var disrt_ws_ctgry = []
    // this.category_data1.forEach(e => {
    //   if(e.dstrt_id==this.slctd_dstrt){
    //     disrt_ws_ctgry.push(e)
    //   }
    // });



    // this.columnDefs = this.columnDefs1


  }
  gotoProfile(data, cnd) {
    this.dwrWdth = 1200
    console.log(data)
    this.sidenav = true;
    if (cnd == 'work') {
      this.appCond = 1
      // this.drwerTitle = 'Work Profile'
      this.drwerTitle = `${data.data?.wrk_typ_nm}` + ' Work Starts On : ' + data.data?.wrk_strt_dt

      // wrkDtls[0]?.wrk_typ_nm}

    }
    // else if (cnd == 'employ') {
    //   this.appCond = 2
    //   this.drwerTitle = ` ${data.data.crw_nm}`
    // } else if (cnd == 'Vehicles') {
    //   this.selectedVehicle = data.data
    //   this.appCond = 3
    //   this.drwerTitle = 'Vehicle Profile'
    // }

    this.wrkData = data.data;
  }
  closeSideBar() {
    this.sidenav = false;

  }
  onExporting(e: DxDataGridTypes.ExportingEvent) {
    console.log('onExporting')
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), ' Vehicles Summary.xlsx');
      });
    });
  }
}

