<div class="page-header" style="margin-bottom:0px">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-10 col-md-10 brdcrumsty">
                <div>
                    <h1 style="font-size: 23px;">
                        Attendence Dashboard
                    </h1>
                </div>
                <div style="margin-top:3px;">
                    <!-- <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item" (click)="goBack()">Andhra Pradesh</li>
                            <li class="breadcrumb-item" *ngIf="grd_lvl == 1">> {{ tnt_nm }}</li>
                        </ol>
                    </nav> -->

                    <nz-breadcrumb>
                        <nz-breadcrumb-item (click)="goBack()">
                            <span nz-icon nzType="home"></span>
                            <span>Andhra Pradesh</span>
                        </nz-breadcrumb-item>
                        <nz-breadcrumb-item  *ngIf="grd_lvl == 1" >
                            <a>
                       
                                <span>District :{{ tnt_nm }}</span>
                            </a>
                        </nz-breadcrumb-item>
                   
                    </nz-breadcrumb>
            
                </div>
            </div>
        </div>
    </div>
</div>
<div class="attendence-container" style="margin-bottom :12px">
    <div class="main">
        <div class="fltrsCrd">
            <div class="row" style="align-items: end;">
                <div class="col-lg-3  customWidth">
                    <div class="drpLbl">Select Date</div>
                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                        (ngModelChange)="onChange($event)" style="width: 100%;"></nz-date-picker>
                </div>
                <div class="col-lg-3 col-md-3 customWidth" *ngIf="tntLst.length>1">
                    <div class="drpLbl">Select District</div>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant"
                        (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}"
                        style="display: inline;">
                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                        <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
                    </nz-select>
                </div>
                <div class="col-lg-3 col-md-3 customWidth" *ngIf="tntLst.length==1">
                    <div class="drpLbl">ULB</div>
                    <div class="slctBx disbledtxt">{{tntLst[0].tnt_nm}}</div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="page-layout">
    <div class=" cards-container" style="margin-bottom: 15px;" *ngIf="crdsDta && crdsDta.length">

        <div class=" p-0 card">
            <div class="img-container">
                <img src="../../../../../assets/images/team.png" />
            </div>
            <div class="content">
                <div class="heading-tag">Total Employees - </div> <span class="emply">{{crdsDta[0].ttl_cnt}}</span>
                <div class="section">
                    <div>
                        <span class="value">{{crdsDta[0].prsnt_cnt || 0}}</span>
                        <span class="label">Present</span>
                    </div>
                    <div>
                        <span class="value">{{crdsDta[0].absnt_cnt || 0}}</span>
                        <span class="label">Absent</span>
                    </div>
                </div>

            </div>
        </div>
        <div class=" p-0 card">
            <div class="img-container">
                <img src="../../../../../assets/images/on-time.png" />
            </div>
            <div class="content">
                <div class="heading-tag">Check-In</div>
                <div class="section">
                    <div>
                        <span class="value">{{crdsDta[0].onTm_chk_in || 0}}</span>
                        <span class="label">On-time</span>
                    </div>
                    <div>
                        <span class="value">{{crdsDta[0].lt_chk_in || 0}}</span>
                        <span class="label">Late</span>
                    </div>
                </div>

            </div>
        </div>
        <div class=" p-0 card">
            <div class="img-container">
                <img src="../../../../../assets/images/logout.png" />
            </div>
            <div class="content">
                <div class="heading-tag">Check out</div>
                <div class="section">
                    <div>
                        <span class="value">{{crdsDta[0].lt_chk_out || 0}}</span>
                        <span class="label">Late Check Out</span>
                    </div>
                    <div>
                        <span class="value">{{crdsDta[0].erly_chk_out || 0}}</span>
                        <span class="label">Early Check Out</span>
                    </div>
                </div>
            </div>
        </div>
        <div class=" p-0 card">
            <div class="img-container">
                <img src="../../../../../assets/images/leave.png" />
            </div>
            <div class="content">
                <div class="heading-tag">Leaves Summary</div>
                <div class="section">
                    <div>
                        <span class="value">{{crdsDta[0].aurthorized_lvs || 0}}</span>
                        <span class="label">Authorised</span>
                    </div>
                    <div>
                        <span class="value">{{crdsDta[0].un_aurthorized_lvs || 0}}</span>
                        <span class="label">Un-authorised</span>
                    </div>
                </div>

            </div>
        </div>

       
    </div>

    <div class="charts-container">
        <!-- <div class="card">
            <div class="bottom-border">
                <div id="chartdiv"></div>
                <div class="heading">Overall Attendence Percentage</div>

            </div>
            <div class="bottom-border">
                <div id="chartdivCrPrcntg"></div>
                <div class="heading">Today's Worked Hours</div>
            </div>
            <div>
                <div id="chartdivOvrlPrcntg"></div>
                <div class="heading">Current Month Worked Hours</div>
            </div>
        </div> -->
        <div>
            <div class="card">
                <div class="heading">Last 10 days of attendance statistics ({{binddate}})</div>
                <div id="hstryChart"></div>
            </div>
            <div>
                <div class="card">
                    <div class="heading">Working Location ({{binddate}})</div>
                    <div id="wrkLctnChrt"></div>
                </div>
                <div class="card">
                    <div class="heading">Least 5 districts ({{binddate}})</div>
                    <div id="atndncTop5Chart"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div  style="    display: flex;
gap: 10px;
background: #fff;
padding: 15px;
margin-top: 15px;
border-radius: 5px;">
    <div  >
        <div class="card_ttl">
            <div class="row">
                <div class="col-md-4">
                    <div style="    font-size: 15px;
                    font-weight: 700;
                    color: #4d6fa9;
                    display: inline-block;
                   
                    padding: 2px 7px;
                    border-radius: 10px;">
                        Districts Wise Monthly Employees statistics</div>
                </div>
                <div class="col-md-2" >
                    <div class="drpLbl">Select Year
                    </div>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selecteYear"
                        (ngModelChange)="getmonthWiseDeatils($event)" [ngModelOptions]="{standalone: true}"
                        style="display: inline;">
                        <nz-option *ngFor="let t of Years" [nzValue]="t.yr_nm" [nzLabel]="t.yr_nm"></nz-option>
                    </nz-select>
                </div>
                <div class="col-md-2" >
                    <div class="drpLbl">Select Month</div>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedMonth"
                        (ngModelChange)="getmonthWiseDeatils($event)" [ngModelOptions]="{standalone: true}"
                        style="display: inline;">
                        <nz-option *ngFor="let t of months" [nzValue]="t.mn_id" [nzLabel]="t.mn_nm"></nz-option>
                    </nz-select>
                </div>
                <div class="col-md-4" ></div>
              
            </div>
        </div>
        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="EmployData" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="false" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [focusedRowEnabled]="true" [showBorders]="true"
            style="width:100%" (onFocusedRowChanging)="onFocusedRowChanging($event)" keyExpr="sno"
            [(focusedRowKey)]="focusedRowKey" 
            (onExporting)="onExporting($event)" >
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                        [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                    </dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
            <dxo-column-chooser [enabled]="false" mode="select">
            </dxo-column-chooser>
            <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
            <dxo-export [enabled]="true" fileName=""></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let d of columnEmplyDefs" [dataField]="d.field" [visible]="d.visible"
                [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width"   [groupIndex]="d.groupIndex" allowResizing="true"
              >
            </dxi-column>
            <dxi-column caption="District" dataField="tnt_nm" [groupIndex]="0"></dxi-column>
            <dxi-column  [showInColumnChooser]="false" type="buttons" [width]="100" caption="View"
                cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="gotoProfile(data,'employ')">
                        <i nz-icon nzType="eye" nzTheme="outline"></i>
                        <p>View Profile</p>
                    </div>
                </div>
            </dxi-column>
        </dx-data-grid>
    </div>
  
</div>

<div *ngIf="wrkData">
    <nz-drawer [nzMaskClosable]="false" [nzWidth]="800" [nzVisible]="sidenav11"
    [nzTitle]="drwerTitle" (nzOnClose)="closeSideBar()" nzWrapClassName="wrkPrfleDrawer">
    <ng-container *nzDrawerContent>
            <app-emplye-prfle [wrkData]="wrkData"></app-emplye-prfle>
    </ng-container>
    </nz-drawer> 
    
</div>
