import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';

import * as moment from 'moment';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-generate-payroll',
  templateUrl: './generate-payroll.component.html',
  styleUrls: ['./generate-payroll.component.css']
})

export class GeneratePayrollComponent implements OnInit {
  settings = {
    loader: false,
    shwNtFndDiv: null
  }
  data = {
    permissions: { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 },
    years: [],
    filterTerm: '',
    allChecked: false,
    slctdEmples: [],
    ntasgndin: null,
    allEmplesLst: [],
    columnDefs: [],
    // mnths: [{ mnth_id: 1, name: 'Jan' }, { mnth_id: 2, name: 'Feb' }, { mnth_id: 3, name: 'Mar' }, { mnth_id: 4, name: 'Apr' }, { mnth_id: 5, name: 'May' },
    // { mnth_id: 6, name: 'Jun' }, { mnth_id: 7, name: 'July' }, { mnth_id: 8, name: 'Aug' }, { mnth_id: 9, name: 'Sep' }, { mnth_id: 10, name: 'Oct' }, { mnth_id: 11, name: 'Nov' },
    // { mnth_id: 12, name: 'Dec' }],
    mnths: [],
    dsgnsLst: [],
    empleTypeLst: [],
    pyrle_rn_in: false,
    empleLst: [],
    pyrle_amnt: null,
    pyrleGnrtdEmpleLst: []
  }
  searchForm: FormGroup;
  zeroVal = 0;
  date;
  constructor(private apisrvc: AppsService,  private fb: FormBuilder, private modal: NzModalService, public router: Router,   private message: NzMessageService ) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      dsgns_id: [0, [Validators.required]],
      emple_type_id: [0, [Validators.required]],
      yr_id: ['', [Validators.required]],
      mnth_id: ['', [Validators.required]],
    });

    
    this.getYears();
    this.getMonths()

    this.searchForm.controls['yr_id'].setValue(parseInt(moment(new Date()).format('YYYY')));

    this.searchForm.controls['mnth_id'].setValue(parseInt(moment(new Date()).format('M')));
    this.getDesignations();
    this.getEmployeeTypes();
    this.  getEmployees() ;
  }

  onChange(result: Date): void {
    // console.log('onChange: ', result);
  }


  getYears() {
    this.data.years = [];
    this.data.mnths = [];
    const currentYear = new Date().getFullYear();
    for (var i = 0; i < 3; i++) {
      const previousYear = currentYear - i;
      this.data.years.push({ dt_yr: previousYear })
    }
    // this.settings.loader = true;
    // const rte = `payroll/years`;
    // this.apisrvc.get(rte).subscribe(res => {
    //   this.settings.loader = false;
    //   this.data.years = res['data'];
    // });
  }

  getMonths() {
    this.data.mnths = [];
    if (this.searchForm.value.yr_id == new Date().getFullYear()) {
      var cnt = 1;
      var curMnth = parseInt(moment(new Date()).format('M'));
      for (var i = curMnth - 1; i > 0; i--) {
        this.data.mnths.push({ mnth_id: i, name: moment().subtract(cnt, "month").startOf("month").format('MMMM') });
        cnt++;
      }
    } else {
      this.data.mnths = [{ mnth_id: 1, name: 'January' }, { mnth_id: 2, name: 'February' }, { mnth_id: 3, name: 'March' }, { mnth_id: 4, name: 'April' }, { mnth_id: 5, name: 'May' },
      { mnth_id: 6, name: 'June' }, { mnth_id: 7, name: 'July' }, { mnth_id: 8, name: 'August' }, { mnth_id: 9, name: 'September' }, { mnth_id: 10, name: 'October' }, { mnth_id: 11, name: 'November' },
      { mnth_id: 12, name: 'December' }];
    }
  }

  getDesignations() {
    this.settings.loader = true;
    const rte = `payroll/designations`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.dsgnsLst = res['data'];
    });
  }

  getEmployeeTypes() {
    this.settings.loader = true;
    const rte = `payroll/employee/types`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.empleTypeLst = res['data'];
    });
  }

  getEmployees() {
    this.data.slctdEmples = [];
    this.settings.loader = true;
    const rte = `payroll/employees`;
    this.apisrvc.post(this.searchForm.value, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.data.allEmplesLst = res['data'];
        this.data.empleLst = res['data'];
        this.getPayrollGeneratedEmployeesCount()
        if (res['data'] && res['data'].length) {
          this.settings.shwNtFndDiv = false;
        } else {
          this.settings.shwNtFndDiv = true;
        }
      } else {
     
        this.createMessage('warning', res["message"] )

      }
    });
  }
  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }
  empSelectionChanged(Data: any) {
    this.data.slctdEmples = [];
    this.data.pyrle_amnt = 0;
    const SELECTEDKEYS = Data.selectedRowKeys.filter(i => i.chk_in === 1);
    // console.log(Data.selectedRowKeys)
    // Filters the Keys from currentSelectedRowKeys to disable the checkbox, and IsNameExists is true  
    const DISABLEDKEYS = Data.currentSelectedRowKeys.filter(i => (i.chk_in == 0));
    if (DISABLEDKEYS.length > 0) {
      Data.component.deselectRows(DISABLEDKEYS);
    }
    SELECTEDKEYS.filter((k) => {
      this.data.pyrle_amnt = k.nt_at + this.data.pyrle_amnt;
      this.data.slctdEmples.push({ emple_id: k.emple_id });
    })
  }

  overrideOnValueChanged(event) {
    if (event.parentType === "dataRow" && event.row.rowType === "data" && event.command === 'select') {
      event.editorOptions.disabled = !event.row.data.chk_in;
    }
  }

  shwNotAssigned() {
    this.data.allEmplesLst = [];
    if (this.data.pyrle_rn_in) {
      this.data.empleLst.filter((k) => {
        if (k.chk_in != 0) {
          this.data.allEmplesLst.push(k)
        }
      })
    } else {
      this.data.allEmplesLst = this.data.empleLst;
    }
  }

  getPayrollGeneratedEmployeesCount() {
    this.data.pyrleGnrtdEmpleLst = [];
    this.data.empleLst.filter((l) => {
      if (l.chk_in == 0) {
        this.data.pyrleGnrtdEmpleLst.push(l)
      }
    })
  }

  gotopayrolls() {
    this.router.navigate([`internal/hrms/payroll`]);
  }

  done() {
    this.modal.confirm({
      nzTitle: `Are you sure want to generate the payroll for ${this.searchForm.value.mnth_id}/${this.searchForm.value.yr_id} ?`,
      nzContent: `<div>Payroll Amount : <b style="color: red;">${this.data.pyrle_amnt}</b></div>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.settings.loader = true;
        let data = {
          yr_id: this.searchForm.value.yr_id,
          mnth_id: this.searchForm.value.mnth_id,
          emple_ids: this.data.slctdEmples
        }
        const rte = `payroll/generate-payroll`;
        this.apisrvc.post(data, rte).subscribe(res => {
          this.settings.loader = false;
          if (res['status'] === 200) {
            // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage("Success", res["message"]);


            this.gotopayrolls();
          } else {
            // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
            this.createMessage('warning', res["message"] )

          }
        }, (err) => {
          this.settings.loader = false;
          // this.toastr.error('Something went wrong', '', { timeOut: 10000, positionClass: 'toast-top-right', });
          this.createMessage('warning', 'Something went wrong' )

        });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }

}

