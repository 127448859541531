import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
// import { NgZorroAntdModule, NzCollapseModule, NzSkeletonModule } from 'ng-zorro-antd';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { PayrollRoutingModule } from './payroll-routing.module';
import { HolidaysComponent } from './holidays/holidays'
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { EmployeePayrollsComponent } from './employee-payrolls/employee-payrolls.component';
import { GeneratePayrollComponent } from './generate-payroll/generate-payroll.component';
import { PayrollApprovalsComponent } from './payroll-approvals/payroll-approvals.component';
import {
  DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
  DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule, DxListModule, DxDiagramModule
 } from 'devextreme-angular';
import { PayrollDashboardComponent } from './payroll-dashboard/payroll-dashboard.component';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LopComponent } from './lop/lop.component';
import { AdjustmentsArrearsComponent } from './adjustments-arrears/adjustments-arrears.component';
import { SalaryViewComponent } from './salary-view/salary-view.component';
 import { PayrollItemsComponent } from './payroll-items/payroll-items.component';
import { HolidaysComponents } from './holidays/holidays.component';
// import { HikesListComponent } from './hikes/hikes-list/hikes-list.component';

// import { DistrictPayrollDashboardComponent } from './payroll-dashboard/district-payroll-dashboard/district-payroll-dashboard.component';
@NgModule({
  declarations: [  PayrollApprovalsComponent,PayrollItemsComponent
    ,PayrollDashboardComponent,GeneratePayrollComponent,EmployeePayrollsComponent,HolidaysComponent,LopComponent,AdjustmentsArrearsComponent,SalaryViewComponent,HolidaysComponents ],
  imports: [NgChartsModule,NgApexchartsModule,
    CommonModule,
    PayrollRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCardModule,
    NzAvatarModule,
    NzRateModule,
    NzBadgeModule,
    NzProgressModule,FormsModule , 
   
   

  
    NzRadioModule,
    NzTableModule,
    NzDropDownModule,
    NzTimelineModule,
    NzTabsModule,
    NzTagModule,
    NzListModule,
    NzCalendarModule,
    NzToolTipModule,
    NzFormModule,
    NzModalModule,
    NzSelectModule,
    NzUploadModule,
    NzInputModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzMessageModule, 
    NzIconModule,
    NzLayoutModule,
    NzAlertModule,
    NzGridModule,
    NzDividerModule,
    NzDrawerModule,
    SharedModule,
    NzPopconfirmModule,
    NzStepsModule,  

    // --  DXmodules -- //
    DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule,
    DxNumberBoxModule, DxChartModule, DxSelectBoxModule, DxCheckBoxModule,
    DxTabPanelModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PayrollModule { }
