<div class="dashboard" #fullScreen>

    <div class="page-header">
        <div class="row align-items-md-center">
            <div class="col-md-8">
                <div class="media m-v-10">
                    <div class="media-body m-l-15 header-border">
                        <h6 class="mb-0 header-label">Payroll State Dashboard</h6>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <nz-button-group style="float: right;margin-top: -5px;">
                    <button (click)="openFullscreen()" nz-button nzType="primary"><i nz-icon
                            nzType="expand"></i></button>
                </nz-button-group>
            </div>
        </div>
    </div>
    <ds-loading *ngIf="settings.showLdr"></ds-loading>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-2">
            <div class="filter-box">
                <div class="form-label">Year: </div>
                <nz-select nzShowSearch [(ngModel)]="data.yr_id" style="width : 90%;" (ngModelChange)="refreshFltrs()">
                    <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">{{o.dt_yr}}
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="filter-box">
                <div class="form-label">Month: </div>
                <nz-select nzShowSearch [(ngModel)]="data.mnth_id" style="width : 90%;"
                    (ngModelChange)="refreshFltrs()">
                    <nz-option *ngFor="let o of data.mnths" [nzValue]="o.mnth_id" [nzLabel]="o.name">
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <div class="col-md-2">
            <div class="filter-box">
                <div class="form-label">Employee Type</div>
                <nz-select nzShowSearch [(ngModel)]="data.emple_type_id" style="width : 90%;"
                    (ngModelChange)="refreshFltrs()">
                    <nz-option [nzValue]="0" nzLabel="All">
                    </nz-option>
                    <nz-option *ngFor="let o of data.empleTypeLst" [nzValue]="o.emple_type_id"
                        [nzLabel]="o.emple_type_nm">
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12 mgnb-1 p-8 dsply_inlne">

            <div class=" card_grvnce mrgn-tp ">

                <div class="crd_hdr_brdr crd-pdng">
                    <div class="crd_title">
                        <div class=" row  crd-hdr-clr">
                            <div class="col-md-2">
                                <div style="float: left;">
                                    <div class="ion-text-center">
                                        <div class="leftImg">
                                            <i nz-icon nzType="file-text" nzTheme="outline"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class=" col-md-8 crd-hdr">
                                {{settings.dsct_crd_lbl}} Total Payroll
                                <i nz-icon nzType="dashboard" nzTheme="outline"
                                    style=" font-size: 12px; color: #ffffff;"></i>
                            </div>
                            <div class="col-md-2">
                                <div style="float: right;">
                                    <div class="ion-text-center">
                                        <div class="rightimg">
                                            <i nz-icon nzType="file-text" nzTheme="outline"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card_content" >

                    <div class="row content-row">

                        <div class="col-md-3 txt-cntr bdr-right">
                            <div class="contscss">
                                {{data.dstct_ws_ttl_cnts.ttl_rt_bzr}}
                            </div>
                            <div class="txt-sb-hdng1">
                                Total Districts
                            </div>

                        </div>
                        <div class="col-md-3 txt-cntr bdr-right">
                            <div class="contscss">
                                {{data.dstct_ws_ttl_cnts.ttl_prl_empls}} / {{data.dstct_ws_ttl_cnts.ttl_empls}}
                            </div>
                            <div class="txt-sb-hdng1">
                                Total Employees
                            </div>

                        </div>
                        <div class="col-md-3 txt-cntr bdr-right">
                            <div class="contscss">
                                Rs.{{data.dstct_ws_ttl_cnts.ttl_prl_at}}
                            </div>
                            <div class="txt-sb-hdng1">
                                Total amount to be claimed
                            </div>


                        </div>
                        <div class="col-md-3 txt-cntr">
                            <div class="contscss">
                                Rs.{{data.dstct_ws_ttl_cnts.ttl_emplr_dctn_at}}
                            </div>
                            <div class="txt-sb-hdng1">
                                Total Employer Deductions
                            </div>

                        </div>
                    </div>

                </div>


            </div>

            <div>
                <div class="row mt-4">
                    <div class="col-lg-12 col-md-12 col-md-12 mgnb-2 p-8">
                        <div class="dashboard-graph-card ">

                            <div class="gates-card-bg-color border-radius-3">
                                <p class="text-center font-size-18 font-weight-600 text-white  mt-0 mb-0">Monthly
                                    Payroll Statstics {{settings.mnthly_crd_lbl}}</p>
                                <div class="row text-center">
                                    <div class="col-lg-4">
                                        <div class="mnth-contscss">
                                            Rs. {{data.bar_chrt_tlt_cnts.ttl_prl_at}}
                                        </div>
                                        <div class="mnth-txt-sb-hdng1">
                                            Total amount to be claimed
                                        </div>

                                    </div>

                                    <div class="col-lg-4">
                                        <div class="mnth-contscss">
                                            Rs. {{data.bar_chrt_tlt_cnts.ttl_cntrct_nt_slry_at}}
                                        </div>
                                        <div class="mnth-txt-sb-hdng1">
                                            Contractors amount to be claimed
                                        </div>

                                    </div>


                                    <div class="col-lg-4">
                                        <div class="mnth-contscss">
                                            Rs. {{data.bar_chrt_tlt_cnts.ttl_outsrcd_nt_slry_at}}
                                        </div>
                                        <div class="mnth-txt-sb-hdng1">
                                            Outsourced amount to be claimed
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mgnb-2 p-8" style="margin-top:3%">
                                <div style="display: block;" style="margin-top: 1.5%;">
                                    <!-- <canvas baseChart class="hmechrt" [datasets]="barChartData"
                                        [labels]="barChartLabels" [options]="barChartOptions" [colors]="barChartColors"
                                        [plugins]="barChartPlugins" [legend]="barChartLegend"
                                        [chartType]="barChartType">
                                    </canvas> -->
                                    <div id="chart">
                                        <apx-chart
                                          [series]="chartOptions.series"
                                          [chart]="chartOptions.chart"
                                          [xaxis]="chartOptions.xaxis"
                                          [title]="chartOptions.title"
                                          [dataLabels]="chartOptions.dataLabels">
                                        </apx-chart>
                                      </div>
                                      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-lg-6 col-md-12 mgnb-1 p-8 dsply_inlne">

            <div class=" card_grvnce mrgn-tp ">

                <div class="crd_hdr_brdr crd-pdng">
                    <div class="crd_title">
                        <div class=" row  crd-hdr-clr">
                            <div class="col-md-1">
                                <div style="float: left;">
                                    <div class="ion-text-center">
                                        <div class="leftImg">
                                            <i nz-icon nzType="file-text" nzTheme="outline"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class=" col-md-5 crd-hdr">
                                {{settings.dsct_crd_lbl}} District Wise Payroll
                                <i nz-icon nzType="dashboard" nzTheme="outline"
                                    style=" font-size: 12px; color: #ffffff;"></i>
                            </div>
                            <div class="col-md-2">
                                <div class="s-f">
                                    <div class="cmpltd">{{data.dstct_ws_ttl_cnts.ttl_cmplt_cnt}}</div><span
                                        class="ml-1 pt-1 c-t-l">Completed</span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="s-f">
                                    <div class="pndng">{{data.dstct_ws_ttl_cnts.ttl_pnd_cnt}}</div><span
                                        class="ml-1 pt-1 c-t-l">Pending</span>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div style="float: right;">
                                    <div class="ion-text-center">
                                        <div class="rightimg">
                                            <i nz-icon nzType="file-text" nzTheme="outline"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card_content ovrflwcls">

                    <div class="row content-row">
                        <div class="col-lg-12">
                            <table style="width:100%">
                                <thead>
                                    <tr>
                                        <th colspan="2"></th>
                                        <!-- <th colspan="2" class="text-center">
                                            Rythu Bazars
                                        </th> -->
                                        <th colspan="2" class="text-center">
                                            Employees
                                        </th>
                                        <th colspan="1" class="text-center">
                                            Pending
                                        </th>
                                        <th colspan="1" class="text-center">
                                            Total
                                        </th>
                                    </tr>

                                    <tr>
                                        <th class="text-center">Sno</th>
                                        <th class="text-center">District</th>
                                        <!-- <th class="text-center">Total</th>
                                        <th class="text-center">Payroll Run</th> -->
                                        <th class="text-center">Total</th>
                                        <th class="text-center">Payroll Run</th>
                                        <th class="text-center">Payroll Approval</th>
                                        <th class="text-center">Payroll Run</th>
                                        <th class="text-center">Amount to be claimed </th>

                                    </tr>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let d of data.dstct_ws_cnt; let i = index;">
                                        <td class="text-center">{{i+1}}</td>
                                        <td [ngClass]="d.pyrl_cmpl_sts_in == 1 ? 'cmplt-css' : 'pndng-css' " (click)="gotoDstrctDashboard(d.dstrt_id)">
                                            {{d.dstrt_nm}}</td>
                                        <!-- <td class="text-center">{{d.ttl_ryth_bzr_cnt}}</td> -->
                                        <!-- <td class="text-center">{{d.ttl_lctn_ct}}</td> -->
                                        <td class="text-center">{{d.ttl_empl_ct}}</td>
                                        <td class="text-center">{{d.ttl_pyrl_empls_ct}}</td>
                                        <td class="text-center">{{d.ttl_pndng_aprvl_ct}}</td>
                                        <td class="text-center">{{d.ttl_prl_run_ct}}</td>
                                        <td class="text-right">{{d.ttl_emple_pyrl_at}}</td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>Total</td>
                                        <!-- <td class="text-center">{{data.dstct_ws_ttl_cnts.ttl_rt_bzr}}</td> -->
                                        <!-- <td class="text-center">{{data.dstct_ws_ttl_cnts.ttl_prl_rt_bzr}}</td> -->
                                        <td class="text-center">{{data.dstct_ws_ttl_cnts.ttl_empls}}</td>
                                        <td class="text-center">{{data.dstct_ws_ttl_cnts.ttl_prl_empls}}</td>
                                        <td class="text-center">{{data.dstct_ws_ttl_cnts.ttl_pndng_aprvl_ct}}</td>
                                        <td class="text-center">{{data.dstct_ws_ttl_cnts.ttl_pyrle_run_ct}}</td>
                                        <td class="text-right">{{data.dstct_ws_ttl_cnts.ttl_prl_at}}</td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    </div>

</div>