<nz-layout class="rmv_mrgn">
    <nz-layout>
        <nz-content>
            <div class="inner-content">

                <button nz-button (click)="closeSidebar();" *ngIf="opnSdebar == true" class="sdNavBtn rt-4h">
                    <!-- <i nz-icon nzType="filter" nzTheme="outline"></i> -->
                    Close
                </button>
                <button nz-button (click)="openSidebar();" *ngIf="opnSdebar == false" class="sdNavBtn2 rt-40">
                    <i nz-icon nzType="filter" nzTheme="outline"></i>Filters</button>
                <div id="livemap">
                    <!-- <div class="vhcl-sts-card">
                        <ul>
                            <li>
                                <span class="greenCls" style="height: 12px; width: 12px; "></span> Running
                            </li>
                            <li>
                                <span class="blueCls" style="height: 12px; width: 12px; "></span> Stationed for < 5Min
                                    </li>
                            <li>
                                <span class="yellowCls" style="height: 12px; width: 12px; "></span> Stationed for 5Min -
                                15Min
                            </li>
                            <li>
                                <span class="redCls" style="height: 12px; width: 12px; "></span> Stationed for > 15Min
                            </li>
                            <li>
                                <span class="greyCls"></span> Offline Vehicles
                            </li>
                        </ul>
                    </div> -->
                </div>

            </div>

        </nz-content>
    </nz-layout>


    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" *ngIf="opnSdebar == true" class="sdr">
        <h2 class="sdHdr">Activity Tracking
            <span nz-icon nzType="close-circle" nzTheme="outline" style="    float: right;
      margin-top: 3px;" (click)="closeSdr()"></span>
        </h2>



        <div  class="lvtFrm">

            <form nz-form [formGroup]="activityForm">

                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Show District</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select nzShowSearch class="slctBx" formControlName="tnt_id"
                                    (ngModelChange)="ontntSelects($event)" [ngModelOptions]="{standalone: true}">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="0"></nz-option>
                                    <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="1"></nz-option> -->
                                    <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Select Work Group</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Please select VehicleGroup"
                                    formControlName="wrk_grp_id" (ngModelChange)="onWrkGrpSlct($event)">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                                    <!-- <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option> -->
                                    <nz-option *ngFor="let v of wrkGrpLst" [nzLabel]="v.wrk_grp_nm"
                                        [nzValue]="v.wrk_grp_id"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Select Work Type</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select class="slctBx" nzShowSearch formControlName="wrk_typ_id"
                                    [ngModelOptions]="{standalone: true}">
                                    <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                                    <nz-option *ngFor="let v of wrkTypLst" [nzValue]="v.wrk_typ_id" [nzLabel]="v.wrk_typ_nm">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Select Work Status</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzMaxTagCount]="2" nzPlaceHolder="Please select Location group"
                                    formControlName="wrk_sts_id">
                                    <nz-option *ngFor="let l of wrkStsLst" [nzLabel]="l.wrk_sts_nm"
                                        [nzValue]="l.oms_wrk_sts_id">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <!-- <div class="row mg-b6">
                    <div class="col-lg-4 col-md-4 fs_11">
                        <nz-form-label nzRequired>Show Locations</nz-form-label>
                    </div>
                    <div class="col-lg-1 col-md-1 hdemblvw">
                        <nz-form-label>:</nz-form-label>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-select [nzMaxTagCount]="2" nzPlaceHolder="Please select Location"
                                    [(ngModel)]="fnclocModel" (ngModelChange)="getLocOnMap()"
                                    formControlName="lctnFnce">
                                    <nz-option [nzLabel]="'Select All'" [nzValue]="'select'"></nz-option>
                                    <nz-option [nzLabel]="'Deselect All'" [nzValue]="'deselect'"></nz-option>
                                    <nz-option *ngFor="let l of fncsLst" [nzLabel]="l.fnce_nm" [nzValue]="l.fnce_id">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div> -->




            </form>

           
        </div>
    </nz-sider>
</nz-layout>