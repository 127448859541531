<div>
    <i nz-icon nzType="folder" nzTheme="twotone" class="header-icon"></i>&nbsp;<b class="header-label">Holidays
    </b>
</div>
<ds-loading *ngIf="settings.loader"></ds-loading>
<!-- <nz-tabset style="margin-top: 25px;"> -->
<!-- <nz-tab nzTitle="Add Holiday Profiles"> -->




<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6"> -->

<!-- <div style="margin-top: 5px;">

        <div class="form-label">Select Holiday Profiles </div>
        <nz-select nzShowSearch [(ngModel)]="hldy_prfle_id" style="width : 90%;margin-bottom: 30px;"
            (ngModelChange)="getWeeks();">
            <nz-option *ngFor="let o of hldyprflst" [nzValue]="o.hldy_prfle_id" [nzLabel]="o.hldy_prfle_nm">
            </nz-option>
        </nz-select>

    </div> -->



<!-- </div> -->
<!-- 
<div class="page-header">
    <div class="row ">
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2">


        </div> -->
<!-- <div class="col-xl-5 col-lg-5 col-md-4 col-sm-4">

            <p *ngIf="!shwSaveBtn" style="font-size: 12px;  margin-bottom: 3px;color: #5e5e5e; letter-spacing: .4px; font-weight: 400;text-align: right;
            padding-right: 10px;">Update week holidays by click on week cell&nbsp;&nbsp;<b class="legendTxt"><input
                        disabled readonly class="fIpt">Selected</b>&nbsp;&nbsp;<b class="legendTxt"><input disabled
                        readonly class="sIpt">De-Selected</b>
            </p>


            <table class="bld_grp_tble" style="float: right;">
                <tr>
                    <th scope="col">Week</th>
                    <th scope="col" class="bldbrp_nms">W1</th>
                    <th scope="col" class="bldbrp_nms">W2</th>
                    <th scope="col" class="bldbrp_nms">W3</th>
                    <th scope="col" class="bldbrp_nms">W4</th>
                    <th scope="col" class="bldbrp_nms">W5</th>
                </tr>
                <tr *ngFor="let w of weeks">
                    <th scope="row">{{w.week}}</th>
                    <td (click)="editWeek(w.wk_dy_id,'w1',w.w1_in)" [ngClass]="w.w1_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w2',w.w2_in)" [ngClass]="w.w2_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w3',w.w3_in)" [ngClass]="w.w3_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w4',w.w4_in)" [ngClass]="w.w4_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w5',w.w5_in)" [ngClass]="w.w5_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2">

            <p></p>
            <p *ngIf="shwSaveBtn" (click)="submitWeekChng()"><button style="font-size: 12px;
                margin-bottom: 3px;
                color: #ffffff;
                letter-spacing: .4px;
                font-weight: 400;
                background-color: #55ce63;
                padding: 1px 18px;
                
                margin-top: -10px;
                border: none;
                line-height: 20px;
                border-radius: 32px;">Save</button>
            </p>
        </div> -->



<!-- </div>


</div> -->
<!-- 
<div style="margin-top: 20px;">


    <div class="form-label">Select Year</div>
    <nz-select nzShowSearch [(ngModel)]="data.yr_id" [ngModelOptions]="{standalone: true}" style="width : 20%">
        <nz-option nzValue="0" nzLabel="All">All
        </nz-option>
        <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
        </nz-option>
    </nz-select>
    <button nz-button nzType="primary" (click)="getHolidayProfiles()" style="margin-left:30px">Get Data</button>
</div> -->

<div nz-row class="gridTop" *ngIf="(data.permissions.slct_in==1)?true:false">
    <div class="ds-card grid">
        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="data.rowData" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
            (onToolbarPreparing)="(data.permissions.insrt_in==1)?onToolbarPreparing($event):false">
            <!-- [masterDetail]="{ enabled: true, template: 'detail' }" -->
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="(data.permissions.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                fileName="Holiday Profiles"></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let f of data.columnDefs" [visible]="!f.hide" [dataField]="f.field"
                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column>

            <!-- <dxi-column [showInColumnChooser]="false" type="buttons" [width]="200" caption="Add Holiday"
                cellTemplate="addTemplate">
                <div *dxTemplate="let data of 'addTemplate'">
                    <div class="tblEdtBtn" (click)="onEditHoliday(data,1)">
                        Add Holiday
                    </div>
                </div>
            </dxi-column> -->

            <dxi-column [showInColumnChooser]="false" type="buttons" [width]="200" caption="View"
                cellTemplate="vwHldyTemplate">
                <div *dxTemplate="let data of 'vwHldyTemplate'">
                    <div class="tblEdtBtn" (click)="vwHldays(data)">
                        View Holidays / WeekEnd
                    </div>
                </div>
            </dxi-column>
            <!-- 
            <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                type="buttons" [width]="100" caption="Edit" cellTemplate="viewTemplate">
                <div *dxTemplate="let data of 'viewTemplate'">
                    <div class="tblEdtBtn" (click)="onEditHolidayProfile(data)">
                        Edit Holiday Profile
                    </div>
                </div>
            </dxi-column> -->




            <dxi-column [showInColumnChooser]="false" type="buttons" [width]="200" caption="View"
                cellTemplate="vwTemplate">
                <div *dxTemplate="let data of 'vwTemplate'">
                    <div class="tblEdtBtn" (click)="vwUsers(data)">
                        View Users
                    </div>
                </div>
            </dxi-column>






            <!-- <dxi-column [showInColumnChooser]="false" type="buttons" [width]="200" caption="Assign"
                cellTemplate="AssgnTemplate">
                <div *dxTemplate="let data of 'AssgnTemplate'">
                    <div class="tblEdtBtn" (click)="assignUsers(data)">
                        Assign To Users
                    </div>
                </div>
            </dxi-column> -->


            <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in == 1) ? true : false"
                type="buttons" [width]="100" caption="Edit" cellTemplate="viewTemplate">
                <div *dxTemplate="let data of 'viewTemplate'">
                    <div class="tblEdtBtn" (click)="onEditHolidayProfile(data)" title="Edit Holiday Profile">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in == 1) ? true : false"
                type="buttons" [width]="100" caption="Actions" cellTemplate="actionTemplate">
                <div *dxTemplate="let data of 'actionTemplate'">
                    <!-- Delete Button -->
                    <div class="tblActionBtn" (click)="onDeleteHolidayProfile(data)" nz-tooltip
                        nzTooltipTitle="Delete Holiday Profile">
                        <i nz-icon nzType="delete" nzTheme="outline"></i>
                    </div>
                </div>
            </dxi-column>


            <!-- <div *dxTemplate="let entCust of 'detail' index as i">
                <dx-data-grid id="gridContainer" [dataSource]="entCust.data.holidays_list" style="width:100%">
                    <dxi-column *ngFor="let f of data.columnDefs2" [visible]="!f.hide" [dataField]="f.field"
                        [caption]="f.headerName" [width]="f.width" [allowResizing]="true" [alignment]="f.alignment">
                    </dxi-column>
                    <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                        type="buttons" [width]="100" caption="Edit" cellTemplate="editTemplate">
                        <div *dxTemplate="let data of 'editTemplate'">
                            <div class="tblEdtBtn" (click)="onEditHoliday(data,0)">
                                Edit
                            </div>
                        </div>
                    </dxi-column>
                    <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                        type="buttons" [width]="100" caption="Delete" cellTemplate="deleteTemplate">
                        <div *dxTemplate="let data of 'deleteTemplate'">
                            <div class="tblDelBtn" (click)="onDeleteHoliday(data)">
                                Delete
                            </div>
                        </div>
                    </dxi-column>
                </dx-data-grid>
            </div> -->
        </dx-data-grid>
    </div>
</div>

<nz-modal [(nzVisible)]="settings.shwDeleteCnfrm" nzCentered (nzOnCancel)="Cancel()"
    nzContent="Are you sure want to delete this holiday ?" (nzOnOk)="deleteHoliday()">
</nz-modal>
<!-- <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="settings.shwEditHoliday11" [nzTitle]="data.sidebarHeading"
    (nzOnClose)="Cancel()">

    <ng-container *nzDrawerContent>


        <form nz-form [formGroup]="hldyForm">






            <nz-form-label nzRequired>Holiday Name</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Holiday Name">
                    <input formControlName="hldy_nm" type="text" nz-input placeholder="" />
                </nz-form-control>
            </div>



            <nz-form-label style="margin-top: 20px;">Description</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Description">
                    <textarea formControlName="dscn_tx" type="text" nz-input placeholder=""></textarea>
                </nz-form-control>
            </div>

            <nz-form-label nzRequired style="margin-top: 20px;">Select Holiday Date</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Select Holiday Date">
                    <nz-date-picker formControlName="hldy_dt" nzFormat="yyyy-MM-dd">
                    </nz-date-picker>
                </nz-form-control>
            </div>




            <div style="margin-top: 20px;">
                <button *ngIf="settings.add_in == false" nz-button nzType="primary" class="sbmtBtm"
                    (click)="editHoliday()">Update</button>
                <button *ngIf="settings.add_in == true" nz-button nzType="primary" class="sbmtBtm"
                    (click)="addHoliday()">Submit</button>
                <button nz-button nzType="default" class="cnclBtn" (click)="Cancel()">Cancel</button>
            </div>
        </form>



    </ng-container>
</nz-drawer> -->
<nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="settings.shwEditHolidayProfile"
    [nzTitle]="data.sidebarHeading" (nzOnClose)="Cancel()">
    <ng-container *nzDrawerContent>

        <form nz-form [formGroup]="holidayForm">
            <nz-form-label nzRequired>Holiday Profile Name</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Holiday Profile Name">
                    <input formControlName="hldy_prfle_nm" type="text" nz-input placeholder="" />
                </nz-form-control>
            </div>
            <nz-form-label style="margin-top: 20px;">Description</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Description">
                    <textarea formControlName="dscn_tx" type="text" nz-input placeholder=""></textarea>
                </nz-form-control>
            </div>


            <nz-form-label style="margin-top: 20px;" nzRequired>Select Effective Date</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Select Effective Date">
                    <nz-date-picker formControlName="effctve_dt">
                    </nz-date-picker>
                </nz-form-control>
            </div>


            <nz-form-label style="margin-top: 20px;" nzRequired>Select Expiry Date</nz-form-label>
            <div nz-row [nzGutter]="24">
                <nz-form-control nzDisableAutoTips nzErrorTip="Please Select Expiry Date">
                    <nz-date-picker formControlName="expiry_dt">
                    </nz-date-picker>
                </nz-form-control>
            </div>


            <div style="margin-top: 20px;">
                <button *ngIf="settings.add_in == false" nz-button nzType="primary" class="sbmtBtm"
                    (click)="editHolidayProfile()">Update</button>
                <button *ngIf="settings.add_in == true" nz-button nzType="primary" class="sbmtBtm"
                    (click)="addHolidayProfile()">Submit</button>
                <button nz-button nzType="default" class="cnclBtn" (click)="Cancel()">Cancel</button>
            </div>
        </form>
    </ng-container>
</nz-drawer>


<nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="1400" [nzVisible]="settings.viewHolidayProfile" [nzTitle]="data.sidebarHeading"
    (nzOnClose)="Cancel()" >
    <ng-container *nzDrawerContent>






        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="viewusers" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%" (onToolbarPreparing)="onToolbarPreparingView($event)" >
            <dxo-paging [pageSize]="25"></dxo-paging>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>

            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="(data.permissions.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                fileName="Holiday Profiles"></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let f of data.viewcolumnDefs" [visible]="!f.hide" [dataField]="f.field"
                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column>

        </dx-data-grid>

        <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="600" [nzVisible]="assignMdl" [nzTitle]="title" (nzOnClose)="CancelUserGrid()">
    <ng-container *nzDrawerContent>


        <div>

            <nz-table #basicTable [nzData]="unasignedPrfUsrLst" [nzBordered]="true">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>User Name</th>
                        <th>Select</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let k of basicTable.data; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ k.emple_nm }}</td>
                        <td>
                            <!-- Checkbox to toggle selection -->
                            <input type="checkbox" [(ngModel)]="k.selected" />
                        </td>
                    </tr>
                </tbody>
            </nz-table>


        </div>



        <div>


            <button nz-button nzType="primary" (click)="assignprof()">
                Assign
            </button>

        </div>

    </ng-container>
</nz-drawer>



    </ng-container>
</nz-drawer>
<nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="1400" [nzVisible]="shwHldyLst" [nzTitle]="data.sidebarHeading"
    (nzOnClose)="Cancel()">
    <ng-container *nzDrawerContent>




        <div>

        </div>


        <dx-data-grid class="p-8" id="gridContainer" [dataSource]="holidays_list" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
            [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"
            (onToolbarPreparing)="onToolbarPreparingHldy($event)">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                [showInfo]="true"></dxo-pager>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>

            <dxo-column-chooser [enabled]="true" mode="select">
            </dxo-column-chooser>
            <dxo-export [enabled]="(data.permissions.exprt_in==1)?true:false" [allowExportSelectedData]="true"
                fileName="Holiday Profiles"></dxo-export>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column *ngFor="let f of data.columnDefs2" [visible]="!f.hide" [dataField]="f.field"
                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                type="buttons" [width]="100" caption="Edit" cellTemplate="editTemplate">
                <div *dxTemplate="let data of 'editTemplate'">
                    <div class="tblEdtBtn" (click)="onEditHoliday(data,0)">
                        Edit
                    </div>
                </div>
            </dxi-column>
            <dxi-column [showInColumnChooser]="false" [visible]="(data.permissions.updt_in==1)?true:false"
                type="buttons" [width]="100" caption="Delete" cellTemplate="deleteTemplate">
                <div *dxTemplate="let data of 'deleteTemplate'">
                    <div class="tblDelBtn" (click)="onDeleteHoliday(data)">
                        Delete
                    </div>
                </div>
            </dxi-column>

        </dx-data-grid>




        <div>

            <p *ngIf="!shwSaveBtn" style="font-size: 12px;  margin-bottom: 3px;color: #5e5e5e; letter-spacing: .4px; font-weight: 400;text-align: right;
        padding-right: 10px;">Update week holidays by click on week cell&nbsp;&nbsp;<b class="legendTxt"><input
                        disabled readonly class="fIpt">Selected</b>&nbsp;&nbsp;<b class="legendTxt"><input disabled
                        readonly class="sIpt">De-Selected</b>
            </p>


            <table class="bld_grp_tble" style="float: right;">
                <tr>
                    <th scope="col">Week</th>
                    <th scope="col" class="bldbrp_nms">W1</th>
                    <th scope="col" class="bldbrp_nms">W2</th>
                    <th scope="col" class="bldbrp_nms">W3</th>
                    <th scope="col" class="bldbrp_nms">W4</th>
                    <th scope="col" class="bldbrp_nms">W5</th>
                </tr>
                <tr *ngFor="let w of weeks">
                    <th scope="row">{{w.week}}</th>
                    <td (click)="editWeek(w.wk_dy_id,'w1',w.w1_in)" [ngClass]="w.w1_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w2',w.w2_in)" [ngClass]="w.w2_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w3',w.w3_in)" [ngClass]="w.w3_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w4',w.w4_in)" [ngClass]="w.w4_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                    <td (click)="editWeek(w.wk_dy_id,'w5',w.w5_in)" [ngClass]="w.w5_in == 1 ? 'slctd_bg' : 'bg_none'">
                    </td>
                </tr>
            </table>
        </div>
        <div>

            <p></p>
            <p *ngIf="shwSaveBtn" (click)="submitWeekChng()"><button style="font-size: 12px;
            margin-bottom: 3px;
            color: #ffffff;
            letter-spacing: .4px;
            font-weight: 400;
            background-color: #55ce63;
            padding: 1px 18px;
            
            margin-top: -10px;
            border: none;
            line-height: 20px;
            border-radius: 32px;">Save</button>
            </p>
        </div>


        <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
            [nzMaskClosable]="false" [nzWidth]="370" [nzVisible]="settings.shwEditHoliday11"
            [nzTitle]="data.sidebarHeading" (nzOnClose)="CancelHolyday()">

            <ng-container *nzDrawerContent>


                <form nz-form [formGroup]="hldyForm">






                    <nz-form-label nzRequired>Holiday Name</nz-form-label>
                    <div nz-row [nzGutter]="24">
                        <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Holiday Name">
                            <input formControlName="hldy_nm" type="text" nz-input placeholder="" />
                        </nz-form-control>
                    </div>



                    <nz-form-label style="margin-top: 20px;">Description</nz-form-label>
                    <div nz-row [nzGutter]="24">
                        <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Description">
                            <textarea formControlName="dscn_tx" type="text" nz-input placeholder=""></textarea>
                        </nz-form-control>
                    </div>

                    <nz-form-label nzRequired style="margin-top: 20px;">Select Holiday Date</nz-form-label>
                    <div nz-row [nzGutter]="24">
                        <nz-form-control nzDisableAutoTips nzErrorTip="Please Select Holiday Date">
                            <nz-date-picker formControlName="hldy_dt" nzFormat="yyyy-MM-dd">
                            </nz-date-picker>
                        </nz-form-control>
                    </div>




                    <div style="margin-top: 20px;">
                        <button *ngIf="settings.add_in == false" nz-button nzType="primary" class="sbmtBtm"
                            (click)="editHoliday()">Update</button>
                        <button *ngIf="settings.add_in == true" nz-button nzType="primary" class="sbmtBtm"
                            (click)="addHoliday()">Submit</button>
                        <button nz-button nzType="default" class="cnclBtn" (click)="CancelHolyday()">Cancel</button>
                    </div>
                </form>



            </ng-container>
        </nz-drawer>



    </ng-container>
</nz-drawer>










<!-- </nz-tab> -->
<!-- <nz-tab nzTitle="Holiday Profiles Assigned To Rythu Bazar">
        <div class="m-20" *ngIf="settings.shwGrid">
            <div nz-row>
                <div nz-col nzSpan="6">
                    <div class="filter-box">
                        <div class="form-label">Select Year</div>
                        <nz-select nzShowSearch [(ngModel)]="data.yr" (ngModelChange)="getHolidayPrfls()"
                            [ngModelOptions]="{standalone: true}" style="width : 90%;margin-bottom: 30px;">
                            <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">{{o.dt_yr}}
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="6">
                    <div class="filter-box">
                        <div class="form-label">Select Holiday Profile</div>
                        <nz-select nzShowSearch [(ngModel)]="data.hldy_prfle" [ngModelOptions]="{standalone: true}"
                            style="width : 90%;margin-bottom: 30px;margin-left: 10px;">
                            <nz-option nzValue="0" nzLabel="All">
                            </nz-option>
                            <nz-option *ngFor="let o of data.hldyprfls" [nzValue]="o.hldy_prfle_id"
                                [nzLabel]="o.hldy_prfle_nm">
                            </nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col nzSpan="6">
                    <button nz-button nzType="primary" (click)="getLocationsHolidayPrfls()"
                        style="margin-left:30px;margin-top:20px">Get
                        Data</button>
                </div>
                <div nz-col nzSpan="6">
                    <button nz-button nzType="primary" (click)="shwDetails()" style="float:right;margin-top:20px">Assign
                        Holiday Pofile
                        to
                        Rythu Bazar</button>
                </div>
            </div>
            <div nz-row class="gridTop" *ngIf="(data.permissions.slct_in==1)?true:false">
                <div class="ds-card grid">
                    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="data.resData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                        [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                            [showInfo]="true"></dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                        <dxo-column-chooser [enabled]="true" mode="select">
                        </dxo-column-chooser>
                        <dxo-export [enabled]="(data.permissions.exprt_in==1)?true:false"
                            [allowExportSelectedData]="true" fileName="Holiday Profiles"></dxo-export>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column *ngFor="let f of data.columnDefss" [visible]="!f.hide" [dataField]="f.field"
                            [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width" [allowResizing]="true">
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </div>
        </div>
        <div class="m-20" style="box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08) !important;background-color: #fff;">
            <div *ngIf="settings.shwDetails" style="padding:25px">
                <div style="text-align: right;">
                    <button nz-button nzType="default" nzDanger (click)="hideForm()">
                        <span>Cancel</span>
                    </button>
                </div>
                <div style="padding: 25px;">
                    <div nz-row>
                        <div nz-col nzSpan="8">
                            <div class="filter-box">
                                <div class="form-label">Select Year</div>
                                <nz-select nzShowSearch [(ngModel)]="data.yr" (ngModelChange)="getHolidayPrfls()"
                                    [ngModelOptions]="{standalone: true}" style="width : 90%;margin-bottom: 30px;">
                                    <nz-option *ngFor="let o of data.years" [nzValue]="o.dt_yr" [nzLabel]="o.dt_yr">
                                        {{o.dt_yr}}
                                    </nz-option>
                                </nz-select>
                            </div>
                        </div>
                        <div nz-col nzSpan="8">
                            <div class="filter-box">
                                <div class="form-label">Select Holiday Profile To Be Assigned</div>
                                <nz-select nzShowSearch [(ngModel)]="data.toBeAsgndPrfle"
                                    [ngModelOptions]="{standalone: true}"
                                    style="width : 90%;margin-bottom: 30px;margin-left: 10px;">
                                    <nz-option *ngFor="let o of data.hldyprfls" [nzValue]="o.hldy_prfle_id"
                                        [nzLabel]="o.hldy_prfle_nm">
                                    </nz-option>
                                </nz-select>
                            </div>
                        </div>
                        <div nz-col nzSpan="8">
                            <label nz-checkbox [(ngModel)]="data.ntasgndin" (ngModelChange)="shwNotAssigned()"
                                style="margin-left: 20px;margin-top: 22px;">Show Not Assigned List Only</label>
                        </div>
                    </div>
                    <nz-input-group [nzPrefix]="prefixTemplateUser">
                        <input nz-input placeholder="Search here..." [(ngModel)]="data.filterTerm" />
                    </nz-input-group>
                    <ng-template #prefixTemplateUser><i nz-icon nzType="search"></i></ng-template>
                    <div nz-row [nzGutter]="24" style="margin-top:20px;margin-bottom: -15px;">
                        <div nz-col [nzSpan]="8">
                            Total Locations : <b>{{data.alllctnsLst.length}}</b>
                        </div>
                        <div nz-col [nzSpan]="8">
                            Selected Locations : <b>{{data.slctdLctns.length}}</b>
                        </div>
                        <div nz-col [nzSpan]="8">
                            <label nz-checkbox [(ngModel)]="data.allChecked" (ngModelChange)="updateAllChecked()">Select
                                All</label>
                        </div>
                    </div>
                    <div style="margin-top: 30px;margin-bottom:12px"><b>NOTE</b>: Select week-offs of rythu bazars by selecting the week days</div>
                    <div class="demo-infinite-container">
                        <ul nz-list [nzDataSource]="data.alllctnsLst" nzBordered class="infinite-container">
                            <li nz-list-item *ngFor="let item of data.alllctnsLst | filter:data.filterTerm" nzNoFlex>
                                <ul nz-list-item-actions style="margin-top: -50px;">
                                    <nz-list-item-action>
                                        <label nz-checkbox [(ngModel)]="item.checked"
                                            (ngModelChange)="pushToSlctdLst(item)"></label>
                                    </nz-list-item-action>
                                </ul>
                                <div style="color: black;">{{ item.lctn_nm }} ({{item.dstrt_nm}})
                                    <span *ngIf="item.assgn_in" style="font-size: 12px;margin-left:10px">[ Assigned To :
                                        {{item.assgn_to}} ]</span>
                                    <span *ngIf="!item.assgn_in" style="font-size: 12px;margin-left:10px">[ Not Yet
                                        Assigned ]</span>
                                </div>
                                <div nz-row style="    color: #4747c5;">
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Monday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.mon_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Tuesday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.tue_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Wednesday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.wed_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Thursday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.thr_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Friday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.fri_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Saturday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.sat_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col nzSpan="3" style="padding: 10px;">
                                        <div nz-row>
                                            <div nz-col nzSpan="20">Sunday</div>
                                            <div nz-col nzSpan="4"><label nz-checkbox [(ngModel)]="item.sun_in"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div style="text-align: center;margin-top:30px">
                        <button nz-button nzType="default" (click)="hideForm()">
                            Cancel
                        </button>
                        <button nz-button nzType="primary" (click)="done()" style="margin-left: 15px;">
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nz-tab> -->
<!-- </nz-tabset> -->


<!-- <div class="m-20" style="box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08) !important;background-color: #fff;">
    <div *ngIf="settings.shwForm || settings.shwDetails" style="padding:25px"> -->
<!-- <div style="text-align: right;margin-bottom: 30px;">
            <button nz-button nzType="default" nzDanger (click)="hideForm()">
                <span>Cancel</span>
            </button>
        </div>
        <nz-steps [nzCurrent]="data.crntStep">
            <nz-step nzTitle="Add Holiday Profile"></nz-step>
            <nz-step nzTitle="Add Holidays To Profile"></nz-step>
            <nz-step nzTitle="Add Holiday Profile To Rythu Bazar"></nz-step>
        </nz-steps>
        <div class="steps-content">
            <form nz-form [formGroup]="holidayForm">
                <div *ngIf="data.crntStep == 0" style="padding: 25px;">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="10">
                            <nz-form-label nzRequired>Holiday Profile Name</nz-form-label>
                            <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Holiday Profile Name">
                                <input formControlName="hldy_prfle_nm" type="text" nz-input placeholder="" />
                            </nz-form-control>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="10">
                            <nz-form-label>Description</nz-form-label>
                            <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Description">
                                <textarea formControlName="dscn_tx" type="text" nz-input placeholder=""></textarea>
                            </nz-form-control>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="10">
                            <nz-form-label nzRequired>Select Year</nz-form-label>
                            <nz-form-control nzDisableAutoTips nzErrorTip="Please Select Year">
                                <nz-select nzShowSearch formControlName="yr_id" nzPlaceHolder="">
                                    <nz-option *ngFor="let g of data.years" [nzValue]="g.dt_yr" [nzLabel]="g.dt_yr"
                                        selected="selected">
                                    </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <button nz-button nzType="default" (click)="next()"
                            [disabled]="holidayForm.value.hldy_prfle_nm == '' || holidayForm.value.yr_id == ''">
                            Next
                        </button>
                    </div>
                </div>
                <div *ngIf="data.crntStep == 1" style="padding: 25px;">
                    <div class="hdng-txt">Add Week Holidays :</div>
                    <b class="legendTxt"><input disabled readonly class="fIpt">Holiday</b>&nbsp;&nbsp;<b
                        class="legendTxt"><input disabled readonly class="sIpt">Working Day</b>
                    <table class="bld_grp_tble">
                        <tr>
                            <th scope="col">Week</th>
                            <th scope="col" class="bldbrp_nms">W1</th>
                            <th scope="col" class="bldbrp_nms">W2</th>
                            <th scope="col" class="bldbrp_nms">W3</th>
                            <th scope="col" class="bldbrp_nms">W4</th>
                            <th scope="col" class="bldbrp_nms">W5</th>
                        </tr>
                        <tr *ngFor="let w of data.weeks">
                            <th scope="row">{{w.week}}</th>
                            <td (click)="editWeek(w.wk_dy_id,'w1',w.w1_in)"
                                [ngClass]="w.w1_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w2',w.w2_in)"
                                [ngClass]="w.w2_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w3',w.w3_in)"
                                [ngClass]="w.w3_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w4',w.w4_in)"
                                [ngClass]="w.w4_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                            <td (click)="editWeek(w.wk_dy_id,'w5',w.w5_in)"
                                [ngClass]="w.w5_in == 1 ? 'slctd_bg' : 'bg_none'"></td>
                        </tr>
                    </table>
                    <p style="margin-top: 3px;">(click on week cell to mark it as holiday)</p>
                    <div class="hdng-txt">Add Public Holidays :</div>
                    <div formArrayName="hldyFrm">
                        <div *ngFor="let item of form.controls; let pointIndex=index" [formGroupName]="pointIndex">
                            <div nz-row [nzGutter]="24">
                                <div nz-col [nzSpan]="6">
                                    <nz-form-label nzRequired>Holiday Name</nz-form-label>
                                    <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Holiday Name">
                                        <input formControlName="hldy_nm" type="text" nz-input placeholder="" />
                                    </nz-form-control>
                                </div>
                                <div nz-col [nzSpan]="6">
                                    <nz-form-label>Description</nz-form-label>
                                    <nz-form-control nzDisableAutoTips nzErrorTip="Please Enter Description">
                                        <textarea formControlName="dscn_tx" type="text" nz-input
                                            placeholder=""></textarea>
                                    </nz-form-control>
                                </div>
                                <div nz-col [nzSpan]="6">
                                    <nz-form-label nzRequired>Select Holiday Date</nz-form-label>
                                    <nz-form-control nzDisableAutoTips nzErrorTip="Please Select Holiday Date">
                                        <nz-date-picker formControlName="hldy_dt" nzFormat="yyyy-MM-dd">
                                        </nz-date-picker>
                                    </nz-form-control>
                                </div>
                                <div *ngIf="pointIndex == 0" nz-col [nzSpan]="1" style="margin-top: 35px;">
                                    <button nz-button nzType="default" (click)="addHoliday()">
                                        <i nz-icon nzType="plus"></i>
                                        Add More
                                    </button>
                                </div>
                                <div *ngIf="pointIndex != 0" nz-col [nzSpan]="3" style="margin-top: 35px;">
                                    <button nz-button nzType="default" nzDanger (click)="removeHoliday(pointIndex)">
                                        <i nz-icon nzType="minus"></i>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="text-align: center;margin-top:30px">
                            <button nz-button nzType="default" (click)="pre()">
                                Previous
                            </button>
                            <button nz-button nzType="default" (click)="next()" style="margin-left: 15px;"
                                [disabled]="holidayForm.value.hldyFrm[0].hldy_dt == '' || holidayForm.value.hldyFrm[0].hldy_nm == ''">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="data.crntStep == 2" style="padding: 25px;">
                <nz-input-group [nzPrefix]="prefixTemplateUser">
                    <input nz-input placeholder="Search here..." [(ngModel)]="data.filterTerm" />
                </nz-input-group>
                <ng-template #prefixTemplateUser><i nz-icon nzType="search"></i></ng-template>
                <div nz-row [nzGutter]="24" style="margin-top:20px">
                    <div nz-col [nzSpan]="10">
                        Total Locations : <b>{{data.lctnsLst.length}}</b>
                    </div>
                    <div nz-col [nzSpan]="10">
                        Selected Locations : <b>{{data.slctdLctns.length}}</b>
                    </div>
                    <div nz-col [nzSpan]="2">
                        <label nz-checkbox [(ngModel)]="data.allChecked" (ngModelChange)="updateAllChecked()">Select
                            All</label>
                    </div>
                </div>
                <div class="demo-infinite-container">
                    <ul nz-list [nzDataSource]="data.lctnsLst" nzBordered class="infinite-container">
                        <li nz-list-item *ngFor="let item of data.lctnsLst | filter:data.filterTerm" nzNoFlex>
                            <ul nz-list-item-actions>
                                <nz-list-item-action>
                                    <label nz-checkbox [(ngModel)]="item.checked"
                                        (ngModelChange)="pushToSlctdLst(item)"></label>
                                </nz-list-item-action>
                            </ul>
                            {{ item.lctn_nm }} ({{item.dstrt_nm}})
                        </li>
                    </ul>
                </div>
                <div style="text-align: center;margin-top:30px">
                    <button nz-button nzType="default" (click)="next()">
                        Next
                    </button>
                    <button *ngIf="settings.shwForm" nz-button nzType="primary" (click)="done()"
                        style="margin-left: 15px;">
                        Done
                    </button>
                    <button *ngIf="settings.shwDetails" nz-button nzType="primary" (click)="done()"
                        style="margin-left: 15px;">
                        Update
                    </button>
                </div>
            </div>
        </div> -->
<!-- </div>
</div> -->




<!-- 
<nz-modal [(nzVisible)]="shwDeletePrfCnfrm" nzCentered (nzOnCancel)="Cancel()"
    nzContent="Are you sure want to delete this holiday Profile {{hldy_prfle_nm}}?" (nzOnOk)="deleteHolidayProfile()">
</nz-modal> -->

<nz-modal [(nzVisible)]="shwDeletePrfCnfrm" nzCentered [nzTitle]="shwDltAlrt" (nzOnCancel)="Cancel()"
    (nzOnOk)="deleteHolidayProfile()">

</nz-modal>

<!-- 
<nz-modal [(nzVisible)]="assignMdl" nzCentered nzTitle="Assign Users" (nzOnCancel)="Cancel()"
    (nzOnOk)="assignPrfle()">
</nz-modal> -->


<!-- <nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="1400" [nzVisible]="assignMdl" nzTitle="Assign Users" (nzOnClose)="Cancel()">
    <ng-container *nzDrawerContent>
       
        <nz-table #basicTable [nzData]="unasignedPrfUsrLst">
            <thead>
                <tr>
                    <th>User Name</th>
                    <th>Profile</th>
          
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data">
                    <td>{{data.usr_nm}}</td>
                    <td>{{data.profilst}}</td>
                  
                </tr>
            </tbody>
        </nz-table>


    </ng-container>
</nz-drawer> -->



<nz-drawer [nzBodyStyle]="{ height: 'calc(100% - 55px)', overflow: 'auto', 'padding-bottom': '53px' }"
    [nzMaskClosable]="false" [nzWidth]="600" [nzVisible]="assignMdl" [nzTitle]="title" (nzOnClose)="Cancel()">
    <ng-container *nzDrawerContent>


        <div>

            <nz-table #basicTable [nzData]="unasignedPrfUsrLst" [nzBordered]="true">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>User Name</th>
                        <th>Select</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let k of basicTable.data; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ k.emple_nm }}</td>
                        <td>
                            <!-- Checkbox to toggle selection -->
                            <input type="checkbox" [(ngModel)]="k.selected" />
                        </td>
                    </tr>
                </tbody>
            </nz-table>


        </div>



        <div>


            <button nz-button nzType="primary" (click)="assignprof()">
                Assign
            </button>

        </div>

    </ng-container>
</nz-drawer>