<div class="page-header">
    <div class=" align-items-md-center">
        <div class="row hdrBar">
            <div class="col-lg-4 col-md-4">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
        </div>
    </div>
</div>

<div class=" py-2 " style="display: flex;justify-content: space-between;">
    <div class="" style="Padding:10px;">
        <div class="brdcrumbStyls">
            <ul>
                <li>ANDHRA PRADESH<br />
                    <div class="brd-db-txt">STATE</div>
                </li>
                <!-- <li >{{ dstrct_nm }}<br />
                    <div class="brd-db-txt">DISTRICT</div>
                </li> -->
                <li *ngIf="grd_lvl == 1">{{ tnt_nm }}<br />
                    <div class="brd-db-txt">District</div>
                </li>
            </ul>
        </div>


    </div>
    <div *ngIf="grd_lvl >= 1" class="" style="text-align: right;">
        <button type="button" (click)="goBack()" style="background-color: #a855f7;
        color: #ffffff;
        border-radius: 15px;
        width: 60px;
        cursor: pointer;
        box-shadow: none;
        border: 2px solid #d3aafb; margin-top: 16px;">
            <mat-icon class="shadow-2xl  text-purple-500" svgIcon="mat_outline:arrow_back_ios"
                style="vertical-align: middle; height:15px;min-height:15px"></mat-icon>
            Back
        </button>
    </div>
</div>
<div class="attendence-container">
    <div class="main">
        <div class="fltrsCrd">
            <div class="row" style="align-items: end;">
                <div class="col-lg-3 col-md-3 customWidth">
                    <div class="drpLbl">Select Date</div>
                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                        (ngModelChange)="onChange($event)" style="width: 100%;"></nz-date-picker>
                </div>
                <div class="col-lg-3 col-md-3 customWidth" *ngIf="tntLst.length>1">
                    <div class="drpLbl">Select District</div>
                    <nz-select class="slctBx" nzShowSearch [(ngModel)]="selectedtenant"
                        (ngModelChange)="ontntSelect($event)" [ngModelOptions]="{standalone: true}"
                        style="display: inline;">
                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                        <!-- <nz-option nzValue="0" nzLabel="All"></nz-option> -->
                        <nz-option *ngFor="let t of tntLst" [nzValue]="t.tnt_id" [nzLabel]="t.tnt_dsply_nm"></nz-option>
                    </nz-select>
                </div>
                <div class="col-lg-3 col-md-3 customWidth" *ngIf="tntLst.length==1">
                    <div class="drpLbl">ULB</div>
                    <div class="slctBx disbledtxt">{{tntLst[0].tnt_nm}}</div>
                </div>
                <!-- <div class="col-lg-3 col-md-3 customWidth">
                    <button nz-button nzType="primary" class="fltrSbmitBtn">Submit</button>
                </div> -->
            </div>
        </div>
    </div>
</div>


<div class="row" style="margin-top:15px;text-align: center;">
    <div class="col-md-3" *ngIf="emply_lst && emply_lst.length">
        <div class="crd_style">
            <div class="row">

                <div class="col-md-8 cstmcol">
                    <div class="actvts" style="background-color: #f19000;">Employees Attendence<span
                            style="color: #030303;"></span></div>
                </div>


            </div>
            <div class="row rwpdng">

                <div class="col-md-4">
                    <div class="cnt">{{ttlEmplys}}
                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="color: #f19000;">Employees</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cnt">{{summary.absent}}
                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #ff2b2b">Absent</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cnt">{{summary.present}}
                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #4CAF50;">Present</span>
                    </div>
                </div>
            </div>
            <!-- <span nz-icon nzType="caret-down" nzTheme="outline" style="position: absolute;
        left: 50%;
        font-size: 30px;    color: #544795;"></span> -->
        </div>

    </div>
    <div class="col-md-4" *ngIf="pstAbstnc && pstAbstnc.length">
        <div class="crd_style">
            <div class="row">

                <div class="col-md-8 cstmcol">
                    <div class="actvts" style="background-color: #f19000;">Check In History<span
                            style="padding: 9px;font-size: 12px;">(No Check-Ins)</span><span
                            style="color: #030303;"></span></div>
                </div>


            </div>
            <div class="row rwpdng">

                <div class="col-md-4">
                    <div class="cnt">{{pstAbstnc[0].threday_abs_ct}}
                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="color: #918a80;">Last 3 Days</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cnt">{{pstAbstnc[0].svnday_abs_ct}}
                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color:#918a80;">Last 7 Days</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cnt">{{pstAbstnc[0].mnthday_abs_ct}}
                    </div>
                    <div class="crd_title"> <span class="cstmfnt" style="    color: #918a80;">Last 1 Month</span>
                    </div>
                </div>
            </div>
            <!-- <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
        left: 50%;
        font-size: 30px;    color: #544795;"></span> -->
        </div>

    </div>
    <div class="col-md-5" *ngIf="dsgnAtndnce && dsgnAtndnce.length">
        <div class="crd_style">
            <div class="row">

                <div class="col-md-8 cstmcol">
                    <div class="actvts" style="background-color: #f19000;">Designation Wise Absentees<span
                            style="color: #030303;"></span></div>
                </div>


            </div>
            <div class="row rwpdng">

                <div *ngFor="let d of dsgnAtndnce"
                    style="display: flex;flex-direction: row; flex-grow: 1; gap: 5px;">
                    <div>
                        <div class="cnt"><img src="../../../../../assets/images/{{d.img_url_tx}}"
                                style="width: 45px;" />
                        </div>

                    </div>
                    <div class="crd_title">
                        <span class="spnCLS">{{d.absntc}}</span>
                        <span class="cstmfnt" style="color: #918a80;">{{d.dsgns_nm}}</span>
                    </div>


                </div>

            </div>
            <!-- <span nz-icon nzType="caret-down" nzTheme="outline" style="    position: absolute;
        left: 50%;
        font-size: 30px;    color: #544795;"></span> -->
        </div>

    </div>
</div>
<div class="attendence-container">
    <ds-loading *ngIf="showLdr"></ds-loading>
    <!-- <app-common-header [header_name]="header"></app-common-header> -->
    <div class="row chart-card">
        <div class="col-lg-6 col-md-6 w-50 crd">
            <h1>Employees - {{ttlEmplys}}</h1>
            <div>
                <div id="chartdiv"></div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 w-50 crd">
            <h1>Attendence Statistics</h1>
            <div id="stackedChart"></div>
        </div>
    </div>

    <nz-card style="width:100%;">
        <dx-data-grid id="grid" class="gridHeight" [dataSource]="emply_lst" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="false" [showColumnLines]="true"
            [showRowLines]="true" [showBorders]="true" style="width:100%;    max-width: 100%!important;"
            [allowColumnResizing]="true" [wordWrapEnabled]="true" (onCellPrepared)="onCellPrepared($event)"
            (onCellClick)="getEmplyDta($event)">
            <dxo-paging [pageSize]="30"></dxo-paging>

            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]" [showInfo]="true">
            </dxo-pager>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
            </dxo-search-panel>
            <dxo-filter-panel [visible]="false"></dxo-filter-panel>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
            <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
            <!-- <dxo-export [enabled]="true" [allowExportSelectedData]="true" fileName="Vechile_lst">
            </dxo-export> -->
            <dxo-export [enabled]="true" fileName="Vechile_lst"></dxo-export>
            <dxo-sorting mode="single">
            </dxo-sorting>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxi-column *ngFor="let v of columnDefs" [dataField]="v.field" [caption]="v.headerName"
                [alignment]="v.alignment" [width]="v.width" [allowResizing]="true">
            </dxi-column>
            <dxo-summary>
                <!-- ng-container for *ngFor loop to iterate through columnDefs -->
                <ng-container *ngFor="let g of columnDefs">
                    <!-- Apply *ngIf here to exclude the 'sno' field -->
                    <dxi-total-item
                        *ngIf="g.field !== 'sno' && g.field !== 'tnt_nm' && g.field !== 'emple_nm' && g.field !== 'tnt_nm' && g.field !=='lv_txt'"
                        [column]="g.field" [summaryType]="g.summaryType ? g.summaryType : ''" [alignment]="g.alignment"
                        [displayFormat]="SumINRFormater">
                    </dxi-total-item>
                </ng-container>

                <!-- Custom summary for District Name (tnt_nm) to display total count -->
                <dxi-total-item column="tnt_nm" summaryType="count" displayFormat="Total"></dxi-total-item>
            </dxo-summary>

        </dx-data-grid>
    </nz-card>
</div>