import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
  // tslint:disable-next-line:max-line-length
  DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule, DxDiagramModule, DxListModule
} from 'devextreme-angular';
import { NgChartsModule } from 'ng2-charts';
import { TemplateModule } from '../../shared/template/template.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
// import { WardsDashboardComponent } from './wards-dashboard/wards-dashboard.component';
// import { MicropocketDashboardComponent } from './micropocket-dashboard/micropocket-dashboard.component';
import { ExportService } from 'src/app/services/export.service';
import { OwmsDashboardComponent } from './owms-dashboard/owms-dashboard.component';
import { MicropocketDashboardComponent } from './micropocket-dashboard/micropocket-dashboard.component';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
// import { SachivalayamDashboardComponent } from './sachivalayam-dashboard/sachivalayam-dashboard.component';
import { SachivalyamHomeDashboardComponent } from './sachivalyam-home-dashboard/sachivalyam-home-dashboard.component';
import { GramaWardSachivalyamDashboardComponent } from './grama-ward-sachivalyam-dashboard/grama-ward-sachivalyam-dashboard.component';
import { UlbDashboardComponent } from './enterprise/ulb-dashboard/ulb-dashboard.component';
import { DesludgingDashboardComponent } from './enterprise/desludging-dashboard/desludging-dashboard.component';
import { RouteDeviationDashboardComponent } from './enterprise/route-deviation-dashboard/route-deviation-dashboard.component';
import { StateDashboardComponent } from './enterprise/state-dashboard/state-dashboard.component';
import { DoorDoorCollectionDashboardComponent } from './enterprise/door-door-collection-dashboard/door-door-collection-dashboard.component';
import { VehicleDashboardComponent } from './enterprise/vehicle-dashboard/vehicle-dashboard.component';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { VendorDashboardComponent } from './enterprise/vendor-dashboard/vendor-dashboard.component';
import { TripsDashboardComponent } from './enterprise/trips-dashboard/trips-dashboard.component';
import { VehicleSliderModule } from './enterprise/vehicle-slider/vehicle-slider.modules';
import { DriverDashboardComponent } from './enterprise/driver-dashboard/driver-dashboard.component';
import { TripsSliderModule } from './enterprise/trips-slider/trips-slider.modules';
import { SvmDashboardComponent } from './enterprise/svm-dashboard/svm-dashboard.component';
import { SvmAttendanceComponent } from './enterprise/svm-dashboard/svm-attendance/svm-attendance.component';
import { SvmDoorToDoorClctnComponent } from './enterprise/svm-dashboard/svm-door-to-door-clctn/svm-door-to-door-clctn.component';
import { SvmVehicleTrackingComponent } from './enterprise/svm-dashboard/svm-vehicle-tracking/svm-vehicle-tracking.component';
import { SvmUserchargesComponent } from './enterprise/svm-dashboard/svm-usercharges/svm-usercharges.component';
import { UrbanDashboardComponent } from './enterprise/urban-dashboard/urban-dashboard.component';
import { RuralDashboardComponent } from './enterprise/rural-dashboard/rural-dashboard.component';
import { DistrictDashboardComponent } from './enterprise/district-dashboard/district-dashboard.component';
import { MandalDashboardComponent } from './enterprise/mandal-dashboard/mandal-dashboard.component';
import { SachivalayamDashboardComponent } from './enterprise/svm-dashboard/sachivalayam-dashboard/sachivalayam-dashboard.component';
import { DriverSliderModule } from './enterprise/driver-slider/driver-slider.modules';
import { AnalysisDashboardComponent } from './enterprise/analysis-dashboard/analysis-dashboard.component';
import { LeafletDrawModule } from '../maps/lib/leaflet-draw/leaflet-draw.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TripRoutesDashboardComponent } from './enterprise/trip-routes-dashboard/trip-routes-dashboard.component';
import { MonitoringDashboardComponent } from './monitoring-dashboard/monitoring-dashboard.component';
import { UlbAttendaceComponent } from './monitoring-dashboard/ulb-attendace/ulb-attendace.component';
import { WeighmentDashboardComponent } from './monitoring-dashboard/weighment-dashboard/weighment-dashboard.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { TripsCvrgeDashboardComponent } from './enterprise/trips-cvrge-dashboard/trips-cvrge-dashboard.component';
import { UlbTripsCvrgeDashboardComponent } from './enterprise/ulb-trips-cvrge-dashboard/ulb-trips-cvrge-dashboard.component';
import { SvmTripsCvrgeDashboardComponent } from './enterprise/svm-trips-cvrge-dashboard/svm-trips-cvrge-dashboard.component';
import { StateLevelDahboardComponent } from './enterprise/revised/state-level-dahboard/state-level-dahboard.component';
import { TripsCvrgeScndryDashboardComponent } from './enterprise/trips-cvrge-scndry-dashboard/trips-cvrge-scndry-dashboard.component';
import { TripsCvrgeScndryUlbDashboardComponent } from './enterprise/trips-cvrge-scndry-ulb-dashboard/trips-cvrge-scndry-ulb-dashboard.component';
import { ScndryTripsCvrgeDashboardComponent } from './scndry-trips-cvrge-dashboard/scndry-trips-cvrge-dashboard.component';
import { SacDashboardComponent } from './enterprise/sac-dashboard/sac-dashboard.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AlerDdashboardComponent } from './aler-ddashboard/aler-ddashboard.component';
import { ApsflOmsDashboardComponent } from './apsfl/apsfl-oms-dashboard/apsfl-oms-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MapsModule } from '../dsmap/ds-map.module';
import { OmsDashbourdCardsComponent } from './apsfl/oms-dashbourd-cards/oms-dashbourd-cards.component';
import { ApsflOmsDistrictDashboardComponent } from './apsfl/apsfl-oms-district-dashboard/apsfl-oms-district-dashboard.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { WrokProfileComponent } from './apsfl/wrok-profile/wrok-profile.component';
import { EmplyePrfleComponent } from './apsfl/emplye-prfle/emplye-prfle.component';
import { TripTimelineComponent } from './apsfl/trip-timeline/trip-timeline.component';
import { OmsAssetDashboardComponent } from './apsfl/oms-asset-dashboard/oms-asset-dashboard.component';
import { SubstationsComponent } from './apsfl/substations/substations.component';
import { AttndceDashboardComponent } from './apsfl/attndce-dashboard/attndce-dashboard.component';

// import { CommonHeaderComponent } from '../pg-header/common-header/common-header.component';

// import { MapEditorModule } from '../map-editor/map-editor.module';

@NgModule({
  declarations: [
    // MapEditorModule,
    OwmsDashboardComponent,
    MicropocketDashboardComponent, SachivalayamDashboardComponent, SachivalyamHomeDashboardComponent,
    GramaWardSachivalyamDashboardComponent, UlbDashboardComponent, DesludgingDashboardComponent,
    RouteDeviationDashboardComponent, StateDashboardComponent, DoorDoorCollectionDashboardComponent,
    VehicleDashboardComponent, VendorDashboardComponent, TripsDashboardComponent, DriverDashboardComponent,
    SvmDashboardComponent, SvmAttendanceComponent, SvmDoorToDoorClctnComponent, SvmVehicleTrackingComponent, SvmUserchargesComponent,
    UrbanDashboardComponent, RuralDashboardComponent, DistrictDashboardComponent, MandalDashboardComponent, AnalysisDashboardComponent,
    TripRoutesDashboardComponent, MonitoringDashboardComponent, UlbAttendaceComponent, WeighmentDashboardComponent,
    TripsCvrgeDashboardComponent, UlbTripsCvrgeDashboardComponent, SvmTripsCvrgeDashboardComponent,OmsDashbourdCardsComponent,
    StateLevelDahboardComponent, TripsCvrgeScndryDashboardComponent, TripsCvrgeScndryUlbDashboardComponent, ScndryTripsCvrgeDashboardComponent, SacDashboardComponent, AlerDdashboardComponent, 
    ApsflOmsDashboardComponent, ApsflOmsDistrictDashboardComponent, WrokProfileComponent, EmplyePrfleComponent, TripTimelineComponent, SubstationsComponent, AttndceDashboardComponent,OmsAssetDashboardComponent
  ],
  imports: [
    CommonModule,
    // --  DXmodules -- //
    DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule,
    DxNumberBoxModule, DxChartModule, DxSelectBoxModule, DxCheckBoxModule,
    DxTabPanelModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule, DxListModule,
    SharedModule,NgApexchartsModule,
    // -- NZModules --//
    NzIconModule, NzDrawerModule,
    NzButtonModule, NzCardModule, NzAvatarModule, NzRateModule, NzBadgeModule,
    NzProgressModule, NzRadioModule, NzDropDownModule, NzTimelineModule, NzTabsModule,
    NzTagModule, NzListModule, NzCalendarModule, NzToolTipModule, NzFormModule, NzModalModule,
    NzSelectModule, NzUploadModule, NzInputModule, NzPaginationModule, NzDatePickerModule, FormsModule, ReactiveFormsModule,
    NzCheckboxModule, NzMessageModule, NzLayoutModule, NzGridModule, NzDividerModule, NgChartsModule, TemplateModule, DashboardRoutingModule, VehicleSliderModule, TripsSliderModule
    , DriverSliderModule, FormsModule, ReactiveFormsModule, NzTimePickerModule, NzBreadCrumbModule,NzCollapseModule,
    LeafletModule,
    LeafletDrawModule,MapsModule
  ],
  providers: [ExportService, TransfereService,
  ]
})
export class DashboardModule { }
