import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PayrollService } from '../payroll.service';
import { FormGroup, FormBuilder } from "@angular/forms";
// import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
// import { ToastrService } from "ngx-toastr";
// import Stepper from 'bs-stepper';
import * as _ from 'lodash';
declare const $: any;
// import { IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { ActivatedRoute, Router } from "@angular/router";
import { AppsService } from 'src/app/shared/services/apps.service';

@Component({
  selector: 'app-adjustments-arrears',
  templateUrl: './adjustments-arrears.component.html',
  styleUrls: ['./adjustments-arrears.component.css']
})

export class AdjustmentsArrearsComponent implements OnInit {
  // @ViewChild(DataTableDirective, { static: false })
  // public dtElement: DataTableDirective;
  // public dtOptions: DataTables.Settings = {};

  public dtTrigger: Subject<any> = new Subject();
  public dtTrigger1: Subject<any> = new Subject();
  // private stepper: Stepper;
  emplysMstr = [];
  // months: IMultiSelectOption[];
  months
  years
  // years: IMultiSelectOption[];
  slctdyear: number[];
  loader: boolean;
  orgntnLst: any = [];
  dprt_lst = [];
  dsgnsLst = [];
  sbDprtLst = [];
  orgnLst = [];
  adjstmntsRsns = [];
  empleCatLst = [];
  emplySts = [];
  selectAll = false;
  frstTym = true;
  selectEmployees = [];
  public searchEmployeeForm: FormGroup;
  adjsd_rsn_id = 0;
  pyrle_cmpnt_ids: number[];
  slctdmnth: number[];
  cmpnts_lst = [];
  // cmpnts_lst: IMultiSelectOption[];

  payroll_cmpnts = [];
  public rows = [];
  public srch = [];
  // mySettings: IMultiSelectSettings = {
  //   enableSearch: true,
  //   checkedStyle: 'fontawesome',
  //   buttonClasses: 'btn btn-default btn-block',
  //   dynamicTitleMaxItems: 1,
  //   displayAllSelectedText: true
  // };
  mySettings = {
    nzShowSearch: true,
    nzMaxTagCount: 1,
    nzMaxTagText: 'All selected',
    nzSize: 'large'
  };
  // myTexts: IMultiSelectTexts = {
  //   checkAll: 'Select all',
  //   uncheckAll: 'Unselect all',
  //   checked: 'item selected',
  //   checkedPlural: 'items selected',
  //   searchPlaceholder: 'Find',
  //   searchEmptyResult: 'Nothing found...',
  //   searchNoRenderText: 'Type in search box to see results...',
  //   defaultTitle: 'Select',
  //   allSelected: 'All selected',
  // };
  myTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find a year',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select Year',
    allSelected: 'All selected'
  };
  columnDefs = [];
  columnDefs1 = [];
  columnDefs2 = [];
  constructor( private Serv: PayrollService, public http: HttpClient, private formBuilder: FormBuilder, private router: Router,private apisrvc: AppsService) {
  }

  ngOnInit(): void {
    // this.dtOptions = {
    //   // ... skipped ...
    //   pageLength: 10,
    //   dom: "lrtip",
    // };
    this.getDepartments();
    this.getDesignations();
    this.getEmplyCat();
    this.getOrgnts();
    this.getEmplyStatus();
    this.getAdjstmntsRsns();
    this.getComponents();
    this.getYears();
    this.months = [{ id: 1, name: 'January' }, { id: 2, name: 'February' }, { id: 3, name: 'March' }, { id: 4, name: 'April' }, { id: 5, name: 'May' },
    { id: 6, name: 'June' }, { id: 7, name: 'July' }, { id: 8, name: 'August' }, { id: 9, name: 'September' }, { id: 10, name: 'October' }, { id: 11, name: 'November' },
    { id: 12, name: 'December' }]
    this.searchEmployeeForm = this.formBuilder.group({
      dprt_id: [0],
      sb_dprt_id: [0],
      dsgns_id: [0],
      orgn_id: [0],
      emple_ctgry_id: [0],
      emple_sts_id: [1],
    });
    // this.stepper = new Stepper(document.querySelector('#stepper2'), {
    //   linear: true,
    //   animation: true
    // })
  }

  getDepartments() {
    this.loader = true;
     const rte = `payroll/department`;   
    this.apisrvc.get(rte).subscribe((res) => {
      this.loader = false;
      this.dprt_lst = res['data'];
    });
  }

  getDesignations() {
    this.loader = true;
    this.Serv.getDesignations().subscribe((res) => {
      this.loader = false;
      this.dsgnsLst = res['data'];
    });
  }

  getEmplyCat() {
    this.loader = true;
    this.Serv.getEmplyCat().subscribe((res) => {
      this.loader = false;
      this.empleCatLst = res['data'];
    });
  }

  getEmplyStatus() {
    this.loader = true;
    this.Serv.getEmplyStatus().subscribe((res) => {
      this.loader = false;
      this.emplySts = res['data'];
    });
  }

  getSubDprt() {
    this.loader = true;
    this.Serv.getSubDprt(this.searchEmployeeForm.value.dprt_id).subscribe((res) => {
      this.loader = false;
      this.sbDprtLst = res['data'];
    });
  }

  getOrgnts() {
    this.loader = true;
    this.Serv.getOrgnts().subscribe((res) => {
      this.loader = false;
      this.orgnLst = res['data'];
    });
  }

  getAdjstmntsRsns() {
    this.loader = true;
    this.Serv.getAdjstmntsRsns().subscribe((res) => {
      this.loader = false;
      this.adjstmntsRsns = res['data'];
    });
  }

  getComponents() {
    this.Serv.getCompForAdjustments().subscribe((res) => {
      this.cmpnts_lst = res['data'];
    });
  }

  getYears() {
    this.Serv.getYearsForAdjustments().subscribe((res) => {
      this.years = res['data'];
    });
  }



  getEmployees() {
    this.loader = true;
    this.Serv.getEmployeesForAdjustments(this.searchEmployeeForm.value).subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        this.emplysMstr = res['data'];
        var sno = 0;
        this.emplysMstr.filter((k) => {
          k['chk_in'] = true;
          k['sno'] = sno + 1;
          sno++;
        })
        this.columnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 'auto', filter: false },
          { headerName: 'Employee Name', field: 'emple_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true },
          { headerName: 'Employee Number', field: 'emple_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true },
          { headerName: 'Department', field: 'dprt_nm', alignment: 'left', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
          { headerName: 'Designation', field: 'dsgns_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', sortable: true, filter: true },
        ];
      } else {
        // this.toastr.error(res['message'], "Error");
      }
    });
  }

  selectionChanged(data: any) {
    const SELECTEDKEYS = data.selectedRowKeys.filter(i => i.chk_in === true);
    console.log(SELECTEDKEYS);
    this.selectEmployees = SELECTEDKEYS;
  }


  gotoComponentsSelection() {
    if (this.selectEmployees.length && this.adjsd_rsn_id) {
      var element = document.getElementById("step1");
      element.classList.add("completed");
      // this.stepper.next();
    } else {
      // this.toastr.error('Please Select Employee & Reason', "Error");
    }

  }

  getPayrollComponents() {
    if (this.pyrle_cmpnt_ids.length && this.slctdmnth.length && this.slctdyear.length) {
      this.loader = true;
      let obj = {
        months: this.slctdmnth,
        cmpnts: this.pyrle_cmpnt_ids,
        emples: this.selectEmployees,
        years: this.slctdyear
      }
      this.Serv.getPayrollComponents(obj).subscribe((res) => {
        this.loader = false;
        if (res['status'] == 200) {
          this.payroll_cmpnts = res['data'];
          var sno = 0;
          var counter1 = 0;
          this.payroll_cmpnts.filter((k) => {
            k['sno'] = sno + 1;
            sno++;
            k.cmpnts_lst.filter((l) => {
              l['s_no'] = ++counter1;
              l['ttl'] = 0;
            });
          })
          this.columnDefs1 = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', cellClass: 'pm-grid-number-cell', width: 'auto' },
            { headerName: 'Employee Name', field: 'emple_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true },
            { headerName: 'Employee Number', field: 'emple_nu', alignment: 'center', cellClass: 'pm-grid-number-cell', filter: true },
          ];

          this.columnDefs2 = [
            { headerName: 'Sno', field: 's_no', alignment: 'center', cellClass: "pm-grid-number-cell", width: 50, sortable: true, },
          ];
        } else {
          // this.toastr.error(res['message'], "Error");
        }
      });
    } else {
      // this.toastr.error('Please select all the fields', "Error");
    }
  }

  calculate(val, item) {
    if (val != '' && val != null) {
      item.ttl = item.updt_at - item.pyrle_cmpnt_at;
    } else {
      item.ttl = 0;
    }
  }

  applyAdjustment() {
    let adjstd_cmpnts = [];
    this.loader = true;
    this.payroll_cmpnts.filter((k) => {
      k.cmpnts_lst.filter((l) => {
        if (l.ttl != 0) {
          adjstd_cmpnts.push({
            emple_prl_id: l.emple_prl_id, pyrle_cmpnt_id: l.pyrle_cmpnt_id, dctn_in: l.dctn_in, pyrle_cmpnt_at: l.updt_at,
            emple_prl_cmpnt_ky: l.emple_prl_cmpnt_ky , pyrle_diff_at : l.ttl
          });
        }
      });
    })
    var postData = {
      adjstd_cmpnts: adjstd_cmpnts,
      adjsd_rsn_id: this.adjsd_rsn_id
    }
    this.Serv.addPayrollAdjustments(postData).subscribe((res) => {
      this.loader = false;
      if (res['status'] == 200) {
        // this.toastr.success("Adjustment Applied Sucessfully", "Success");
        this.router.navigate(['/layout/payroll/adjustments-arrears-list']);
      } else {
        // this.toastr.error(res['message'], "Error");
      }
    });
  }
}

