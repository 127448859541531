import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ActivatedRoute, Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexPlotOptions,
  ApexFill,
  ApexStroke,
  ApexLegend,
  ApexTitleSubtitle,
  ApexResponsive,
  ApexTheme,
  ApexGrid
} from 'ng-apexcharts';
import { AppsService } from 'src/app/shared/services/apps.service';
import { count } from 'console';
export type chartOptionstwo = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  fill: ApexFill;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  theme: ApexTheme;
  colors: any;
  grid: ApexGrid
};


import {
  ChartComponent,
  ApexTooltip,
} from "ng-apexcharts";

export type multiChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};
import * as L from 'leaflet';
import * as moment from 'moment';
import { DsMapService } from 'src/app/apps/dsmap/ds-map.service';
import * as turf from "@turf/turf";

export type AsstMultiChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};


am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-apsfl-oms-dashboard',
  templateUrl: './apsfl-oms-dashboard.component.html',
  styleUrls: ['./apsfl-oms-dashboard.component.scss']
})
export class ApsflOmsDashboardComponent implements OnInit {
  header_label = {
    name: 'Operational Monitoring System Dashboard ',
    dtepkrlbl: true
  };
  public chartOptionstwo: Partial<chartOptionstwo>;
  public chartOptionsThree: Partial<chartOptionstwo>;
  @ViewChild("multichart") chart: ChartComponent;
  public multiChartOptions: Partial<multiChartOptions>;
  public AsstMultiChartOptions: Partial<AsstMultiChartOptions>;
  columnDefs: any;
  tb_id = ''
  slctd_dstrt = 0
  dbrd_lvl: any;
  tnt_id: any;
  WorksCrdData: any;
  tripCrdData: any;
  expnsCrdData: any;
  emplyCrdData: any;
  chartOptionsFour: any
  heatMapDtls: any[];
  heatData: any[];
  shrdKy = [];
  chartOptionsFive: {
    series: { name: string; data: any[]; }[]; chart: { type: string; height: number; stacked: boolean; }; plotOptions: { bar: { horizontal: boolean; columnWidth: string; }; }; grid: { borderColor: string; }; xaxis: { categories: any[]; title: { text: string; }; }; yaxis: { title: { text: string; }; }; fill: { opacity: number; }; legend: { position: string; horizontalAlign: string; offsetX: number; }; dataLabels: { enabled: boolean; };
    // colors: ['#5967af', '#fd8900', '#34c38f'],
    colors: string[]; title: { text: string; };
  };
  selctedcrd: any;
  slctdTab = 1
  slctdvw = 1
  visible: boolean;
  expnsGrapData: any;
  userDtls;
  dbrdmap;
  _stBndPlgn;
  stPlygn;
  ap_map_bounds;
  inPrgWrks: any;
  wrkIcon: any;
  wrkLctnArry: any = [];
  _wrkLctnLyr;
  wrkData;
  AsstGrapData: any;
  selectdAsstCtgry = 2;
  AsstCtgry: any;
  clntDtls;
  tntLst: any = [];

  dateRnge: Date[] = [];
  _dstrtLyr: any;
  constructor(public apiServ: AppsService, private router: Router, private mapSrvce: DsMapService,) {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.tntLst = this.clntDtls[0].tnts;
    this.tnt_id = 0;

  }
  lvl_id = null
  gridData = []
  columnDefs1;
  columnDefs2;
  columnDefs3;
  tabs = [
    { tb_nm: 'Activities', tb_id: '1' },
    { tb_nm: 'Trips', tb_id: '2' },
    { tb_nm: 'Expenses', tb_id: '3' }
  ]






  dataSource1 = [

  ];

  dataSource2 = [

  ];
  dataSource3 = [

  ];



  formatter1 = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  formatter2 = new Intl.NumberFormat("en-IN", {
    // style: "currency",
    currency: "INR",
  });
  mapData;
  expenseCtgry = [];
  selectdCtgry = 7;
  wrkStsLst = [];
  wrkGrpLst = [];
  wrk_grp_id = 0;
  oms_wrk_sts_id = 0;
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  frmDt = moment(this.today).subtract(7, 'days').toISOString(); // Subtract 7 days
  toDt = new Date();
  frmDate = moment(this.today).subtract(7, 'days').format('YYYY-MM-DD');
  toDate = moment(this.today).format('YYYY-MM-DD');
  dateRange: Date[] = [];

  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  showLdr;
  data: any = {
    dstElmtns: [],
  };
  dstPlygons;
  ngOnInit() {
    const today = moment(this.today).toDate();
    const lastWeek = moment().subtract(7, 'days').toDate();
    this.dateRnge = [lastWeek, today];
    this.dateRange=[lastWeek, today];

    this.lvl_id = 1
    // this.last7dayaData()
    this.columnDefs1 = [
      { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
      { headerName: 'District ', field: 'dstrt_nm', width: 'auto', alignment: 'left', visible: this.lvl_id == 1 ? true : false },
      { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left', visible: this.lvl_id == 2 ? true : false },
      { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left', visible: this.lvl_id == 3 ? true : false },
      { headerName: 'Total Activities', field: 'ttl', alignment: 'center' },
      { headerName: 'Planned', field: 'cmpltd', alignment: 'center' },
      { headerName: 'Un Planned', field: 'cmpltd', alignment: 'center' },
      { headerName: 'No.of Trips', field: 'no_trps', alignment: 'center' },
      { headerName: 'Inprogress', field: 'inprg', alignment: 'center' },
      { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
      { headerName: 'no. of CAFs effected', field: 'cmpltd', alignment: 'center' },
    ]
    this.columnDefs2 = [
      { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
      { headerName: 'District ', field: 'dstrt_nm', width: 'auto', alignment: 'left', visible: this.lvl_id == 1 ? true : false },
      { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left', visible: this.lvl_id == 2 ? true : false },
      { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left', visible: this.lvl_id == 3 ? true : false },
      { headerName: 'No. of Vehicles', field: 'ttl', alignment: 'center' },
      { headerName: 'Operated', field: 'ttl', alignment: 'center' },
      { headerName: 'Not Operated', field: 'ttl', alignment: 'center' },
      { headerName: 'Total Trips', field: 'ttl', alignment: 'center' },
      { headerName: 'In Trips', field: 'inprg', alignment: 'center' },
      { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
      { headerName: 'Distance Travel', field: 'dtnc_trvl', alignment: 'center' },
    ]
    this.columnDefs3 = [
      { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
      { headerName: 'District ', field: 'dstrt_nm', width: 'auto', alignment: 'left', visible: this.lvl_id == 1 ? true : false },
      { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left', visible: this.lvl_id == 2 ? true : false },
      { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left', visible: this.lvl_id == 3 ? true : false },
      { headerName: 'Work Include Expenses Amount', field: 'wrk_incld_expns', alignment: 'center' },
      { headerName: 'Other Expenses Amount', field: 'wrk_incld_expns', alignment: 'right' },
      { headerName: 'Total Amount', field: 'amt', alignment: 'rigth' },

    ]

    this.getAsstCtgry()

    setTimeout(() => {
      this.selctedCatrd({
        crd_hdr: 'WORKS',
        clr_cd: '#544795',
        slct_dt_in:3
      })
    }, 500);









    this.getAssetsMultiChartData()

    this.getExpnseCtgry();
    this.getMultiChartData();
    this.getDrpdownMster();
    this.initializeMap();
    this.getInPrgrsWrks()
  }
  getDrpdownMster() {
    this.apiServ.get(`apsfl/app/wrkGrpLst`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        console.log(res['data'])
        res['data'][4].forEach(e => {
          if (e.vw_in == 1) {
            this.wrkStsLst.push(e);
          }
        });

        this.wrkGrpLst = res['data'][0];
        this.wrk_grp_id = 0;
        this.oms_wrk_sts_id = 0;

      }
    });

  }
  onChange(e): void {
    this.frmDt = e;
    this.frmDate = moment(this.dateRange[0]).format('YYYY-MM-DD');
    this.toDate = moment(this.dateRange[1]).format('YYYY-MM-DD');



  }
  onDtChange(e): void {
    this.toDt = e;
    this.toDate = moment(this.toDt).format('YYYY-MM-DD');
  }
  initializeMap() {
    try {

      this.dbrdmap = L.map('dbrdMap', {
        center: [this.userDtls[0].lat, this.userDtls[0].lng],
        zoom: 16
      });
      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      tiles.addTo(this.dbrdmap);
    } catch (e) {

    }
    this._wrkLctnLyr = L.layerGroup();
    this._dstrtLyr = L.layerGroup();
    this.stateBounds();

    this.getMapElements();
    this.getInPrgrsWrks();
  }
  stateBounds() {
    this._stBndPlgn = L.layerGroup();
    let options = {
      color: 'none',
      fill: false
    }
    this.stPlygn = L.polygon([
      [19.0, 77.0],
      [19.0, 84.5],
      [12.5, 84.5],
      [12.5, 77.0]
    ], options).addTo(this._stBndPlgn);
    this._stBndPlgn.addTo(this.dbrdmap)
    this.dbrdmap.setView([this.stPlygn.getCenter().lat, this.stPlygn.getCenter().lng], 7);
    this.ap_map_bounds = this.stPlygn.getBounds();
    this.setBounds();

  }
  setBounds() {
    if (this.dbrdmap.getBounds().intersects(this.ap_map_bounds)) {
      return;
    } else {
      this.dbrdmap.fitBounds(this.ap_map_bounds);
    }
  }
  getMapElements() {
    let postData = {
      ste_id: 27,
      dstrt_id: 0,
      ulb_id: 0,
      mndl_id: 0,
      urbn_in: 1,
      vlge_id: null,
      svm_id: null
    }
    this.showLdr = true;
    this.mapSrvce.getMapElements(postData).subscribe((res) => {
      console.log(res)

      this.data.elmntsData = res.data;
      this.showLdr = false;
      // this.plotDstrict();
      if (this._dstrtLyr != undefined) {
        this._dstrtLyr.clearLayers();
      }

      this.plotDstrict();
    })
    if (this.dbrdmap) {
      this.dbrdmap.invalidateSize();
    }
  }
  plotDstrict() {
    const that: any = this;
    this.showLdr = true;
    if (this._dstrtLyr != undefined) {
      this._dstrtLyr.clearLayers();
    }


    this.data.dstElmtns = []; var latlang = [];
    if (this.data.elmntsData && this.data.elmntsData.length) {

      this.data.dstElmtns = this.data.elmntsData.filter(item => item.elmnt_grp_id == 1)

      if (this.data.dstElmtns.length > 0) {
        for (let d = 0; d < this.data.dstElmtns.length; d++) {
          if (this.data.dstElmtns[d].bndry_jsn != null) {
            // let previousPolygon = null;
            this.data.dstElmtns[d].opacity = 0.3;
            // this.slctdKeydata=[];

            this.dstPlygons = L.polygon(JSON.parse(this.data.dstElmtns[d].bndry_jsn),
              {
                color: this.data.dstElmtns[d].otlne_clr_cd,
                fillColor: this.data.dstElmtns[d].fl_clr_cd,
                fillOpacity: this.data.dstElmtns[d].opacity,
                weight: 1,
                plygn_crdts: JSON.parse(this.data.dstElmtns[d].bndry_jsn),
                elmnt_id: this.data.dstElmtns[d].elmnt_id,
                prvClr: this.data.dstElmtns[d].otlne_clr_cd,
                prvFlClr: this.data.dstElmtns[d].fl_clr_cd,
              }
            ).addTo(this._dstrtLyr)
            this._dstrtLyr.addTo(this.dbrdmap);
            this.showLdr = false;


          }


        }
        if (this.data.dstElmtns.length === 1) {
          this.dbrdmap.fitBounds(this.dstPlygons.getBounds());
          let zoomLevel = this.dbrdmap.getBoundsZoom(this.dstPlygons.getBounds());
          this.dbrdmap.flyTo([this.dstPlygons.getCenter().lat, this.dstPlygons.getCenter().lng], zoomLevel);
        } else {
          this.dbrdmap.fitBounds(this.dstPlygons.getBounds());
        }

      }
    }
  }
  getInPrgrsWrks() {
    if (this._wrkLctnLyr != undefined) {
      this._wrkLctnLyr.clearLayers();
    }
    var postData = {
      tnt_id: this.tnt_id,
      oms_wrk_sts_id: this.oms_wrk_sts_id,
      wrk_grp_id: this.wrk_grp_id,
      frm_dt: this.frmDate,
      to_dt: this.toDate
    }
    this.apiServ.post(postData, `apsfl/dashboard/works/inprogrss`).subscribe(async (res) => {
      if (res['status'] == 200) {
        this.inPrgWrks = res['data'];
        console.log(this.inPrgWrks);
        await this.wrksPrgrswithPromisepool(this.inPrgWrks, 15);


      }
    });
  }
  async wrksPrgrswithPromisepool(inPrgWrks: any, batchSize: number) {
    console.log('in promiseeeeeeeeeeeeeee')
    let that = this;
    const markerPositions: any[] = []; // Array to hold marker positions
    const promiseProducer = (points: any) => {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          try {
            console.log(points)
            if (points.w_lat != undefined && points.w_lng != undefined) {
              let icnStng = '';
              if (points.wrk_sts_id == 3) {
                icnStng = 'Workll'
              } else if (points.wrk_sts_id == 6) {
                icnStng = 'wrk_completed'
              } else {
                icnStng = 'wrk_in_prg'
              }
              this.wrkIcon = new L.Icon({
                iconUrl: '/assets/images/markers/mapIcons/m/' + icnStng + '.png',
                iconSize: [32, 35],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
              });
              this.wrkLctnArry = L.marker([points.w_lat, points.w_lng], {
                icon: this.wrkIcon, // Custom icon
                // title: "Marker Title" // Tooltip title
                options: {
                  oms_wrk_id: points.oms_wrk_id
                }
              }).addTo(this._wrkLctnLyr).on('click', function (e) {
                that.goToWorkProfile(e.target)
              });
              this._wrkLctnLyr.addTo(this.dbrdmap);
              markerPositions.push([points.w_lat, points.w_lng]);
              resolve();
            } else {
              resolve();
            }
            // }


          } catch (e) {
            console.log(e)
          }
        }, 10); // Simulating a processing delay
      });
    };

    const numBatches = Math.ceil(inPrgWrks.length / batchSize);

    for (let i = 0; i < numBatches; i++) {
      const start = i * batchSize;
      const end = start + batchSize;
      const batch = inPrgWrks.slice(start, end);
      try {
        const batchPromises = batch.map((points) => promiseProducer(points));
        await Promise.all(batchPromises);
      } catch (e) {
        console.log(e)
      }

    }

    // After all markers are added, fit the map view to the bounds of the markers
    if (markerPositions.length > 0) {
      const bounds = L.latLngBounds(markerPositions); // Create bounds from all marker positions
      this.dbrdmap.fitBounds(bounds); // Adjust map view to fit all markers
    }

  }
  ontntSelect(t) {

  }
  goToWorkProfile(e) {
    console.log(e)
    this.wrkData = e.options.options;
    this.visible = true;

  }
  getExpnseCtgry() {
    this.apiServ.get(`apsfl/expense/categories`).subscribe((res) => {
      if (res['status'] == 200) {
        this.expenseCtgry = res['data'];
        console.log(this.expenseCtgry);
        this.onCtgrySlct(0);
      }
    })
  }
  onCtgrySlct(exp_ctgry) {
    this.getMultiChartData();
  }
  selctedCatrd(e) {
    console.log(e)
    this.selctedcrd = e
    
    var today = new Date()
    const date=new Date(2024,9,1)
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    

    if (e.slct_dt_in == 1) {
      console.log('111111111111111111111111')
      this.dateRnge = [date, today];
    } else if (e.slct_dt_in == 2) {
      console.log('2222222222222222222222222222222')
      this.dateRnge = [firstDayOfMonth, today];
    }
    else if (e.slct_dt_in == 3) {
      console.log('3333333333333333333333333333333333')
      this.dateRnge = [today, today];
    }


    if (e.crd_hdr == 'WORKS') {
      this.tb_id = '1'

      this.ChangeLevel(this.lvl_id)

    } else if (e.crd_hdr == 'TRIPS') {
      this.tb_id = '2'
      this.ChangeLevel(this.lvl_id)
    } else if (e.crd_hdr == 'EXPENSES') {
      this.tb_id = '3'
      this.lvl_id = 2
      this.ChangeLevel(this.lvl_id)
    }
    this.colorChanges(e.clr_cd);
  }

  colorChanges = (clr_cd) => {
    console.log("test case")
    document.documentElement.style.setProperty('--colorcode', clr_cd);

  }


  currencyINRFormater = (val: any) => {
    if (typeof val == 'string') {

    } else {
      return this.formatter1.format(parseInt(val, 10))
        ? this.formatter1.format(parseInt(val, 10))
        : 0;
    }

  };
  INRFormater = (val: any) => {
    if (typeof val == 'string') {

    } else {
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };


  SumINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter2.format(parseInt(val, 10))
        ? this.formatter2.format(parseInt(val, 10))
        : 0;
    }

  };


  sumcurrencyINRFormater = (val: any) => {
    if (val) {
      if (val == 'Total:') {
        return val
      }
      return this.formatter1.format(parseInt(val, 10))
        ? this.formatter1.format(parseInt(val, 10))
        : 0;
    }

  };

  onChangeDate(e) {
    console.log(e)
    this.getGridData();

  }



  onCellPrepared(e) {

    if (e.rowType === "header") {
      return
    }
    if (
      e.column.dataField == "dstrt_nm"
    ) {



      e.cellElement.style.color = "rgb(39, 153, 234)";
      e.cellElement.style.cursor = `pointer`;
      e.cellElement.style.fontWeight = `500`;

      // }

    }
  }

  onCellClick(e) {
    // console.log(e,e.data.items[0].tnt_id)
    // this.focusedRowIndex = e.rowIndex;
    this.slctd_dstrt = e.data.tnt_id;
    if (e.rowType === "header") {
      return
    }

    if (e.rowType === "group" && e.columnIndex==1) {
 
      this.slctd_dstrt = e.data.items[0].tnt_id;
    }

    if ((!this.slctd_dstrt && e.columnIndex != 1)) {
      return;
    }
    console.log(this.selctedcrd)
    if(this.slctd_dstrt > 0){
      this.router.navigate(['/internal/dashboard/district-level-dashboard'], {
        queryParams: {
          dstrt_id: this.slctd_dstrt,
          crd_nm: this.selctedcrd.crd_hdr,
          crd_clr: this.selctedcrd.clr_cd,
          from_dt: this.dateRnge[0],
          to_dt: this.dateRnge[1],
          slct_dt_in:this.selctedcrd.slct_dt_in
        }
      });
    }
  


  }
  getDstrt_Wse_data() {

  }


  async ChangeLevel(e) {
    this.lvl_id = e

    console.log('eeeeeeeeeee', this.lvl_id, this.tb_id)
    // window.dispatchEvent(new Event('resize'));

    this.columnDefs1 = [];
    this.columnDefs2 = [];
    this.columnDefs3 = [];
    await this.getGridData()





  }

  addSumLabel(label: string) {
    return (e) => `${label}: ${e.value}`;
  }








  getGridData() {
    var postdata = {
      lvl_id: this.lvl_id,
      tb_id: this.tb_id,
      from_dt: this.lvl_id != 3 ? moment(this.dateRnge[0]).format('YYYY-MM-DD') : null,
      to_dt: this.lvl_id != 3 ? moment(this.dateRnge[1]).format('YYYY-MM-DD') : null,
    }
    this.apiServ.post(postdata, `apsfl/data/grid`,).subscribe((res) => {
      if (res['status'] == 200) {
        var data = res['data'];
        this.dataSource1 = data[0],
          this.dataSource2 = data[1],
          this.dataSource3 = data[2],
          console.log(data)
        var co1 = 1
        var co2 = 1
        var co3 = 1
        this.dataSource1.forEach(e => {
          e['sno'] = co1++
        });
        this.dataSource2?.forEach(e => {
          e['sno'] = co2++
        });
        this.dataSource3?.forEach(e => {
          e['sno'] = co3++
        });
        this.slectdvw(this.slctdvw)
        if (this.lvl_id == 1) {
          console.log('1111111111111111111111111111111111111111111')
          // 
          if (this.tb_id == '1') {
            this.columnDefs1 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'District ', field: 'dstrt_nm', width: 'auto', alignment: 'left', },
              { headerName: 'Total Activities', field: 'ttl', alignment: 'center' },
              { headerName: 'Planned', field: 'plnd_ct', alignment: 'center' },
              { headerName: 'Un Planned', field: 'un_plnd_ct', alignment: 'center' },
              { headerName: 'No.of Trips', field: 'no_trps', alignment: 'center' },
              { headerName: 'Inprogress', field: 'inprg', alignment: 'center' },
              { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
              { headerName: 'no. of CAFs effected', field: 'cstmr_efctd_ct', alignment: 'center' },
            ]
            this.columnDefs = this.columnDefs1
            this.gridData = this.dataSource1
            this.chartOptionsThree = this.chartOptionsThree
            console.log(this.columnDefs)
          } else if (this.tb_id == '2') {
            this.columnDefs2 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'District ', field: 'dstrt_nm', width: 'auto', alignment: 'left' },

              { headerName: 'No. of Vehicles', field: 'trvld_vehs', alignment: 'center' },
              { headerName: 'Operated', field: 'oprd_veh', alignment: 'center' },
              { headerName: 'Not Operated', field: 'not_op_veh', alignment: 'center' },
              { headerName: 'Total Trips', field: 'ttl', alignment: 'center' },
              { headerName: 'In Trips', field: 'inprg', alignment: 'center' },
              { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
              { headerName: 'Distance Travel', field: 'dtnc_trvl', alignment: 'center' },
            ]
            this.columnDefs = this.columnDefs2
            this.gridData = this.dataSource2
            this.chartOptionsThree = this.chartOptionsThree

          } else if (this.tb_id == '3') {

            this.columnDefs3 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'District ', field: 'dstrt_nm', width: 'auto', alignment: 'left' },

              { headerName: 'Work Include Expenses Amount', field: 'wrk_incld_expns', alignment: 'right', format: this.currencyINRFormater },
              // { headerName: 'Other Expenses Amount', field: 'wrk_incld_expns', alignment: 'center' },
              { headerName: 'Total Amount', field: 'amt', alignment: 'right', format: this.currencyINRFormater },


            ]
            this.columnDefs = this.columnDefs3
            this.gridData = this.dataSource3
            this.chartOptionsThree = this.chartOptionsThree
          }
        } else if (this.lvl_id == 2) {
          console.log('2222222222222222222222222222222222222222')
          // await this.getGridData();
          console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'ifffffffffffffff')
          if (this.tb_id == '1') {
            console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'tab1')
            this.columnDefs1 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left' },
              { headerName: 'Total Activities', field: 'ttl', alignment: 'center' },
              { headerName: 'Planned', field: 'plnd_ct', alignment: 'center' },
              { headerName: 'Un Planned', field: 'un_plnd_ct', alignment: 'center' },
              { headerName: 'No.of Trips', field: 'no_trps', alignment: 'center' },
              { headerName: 'Inprogress', field: 'inprg', alignment: 'center' },
              { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
              { headerName: 'no. of CAFs effected', field: 'cstmr_efctd_ct', alignment: 'center' }
            ]
            this.columnDefs = this.columnDefs1
            this.gridData = this.dataSource1
            this.chartOptionsThree = this.chartOptionsThree
            console.log(this.columnDefs)
          } else if (this.tb_id == '2') {

            console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'tab1')
            this.columnDefs2 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },

              { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left' },
              // { headerName: 'No. of Vehicles', field: 'ttl', alignment: 'center' },
              // { headerName: 'Operated', field: 'ttl', alignment: 'center' },
              // { headerName: 'Not Operated', field: 'ttl', alignment: 'center' },
              { headerName: 'Total Trips', field: 'ttl', alignment: 'center' },
              { headerName: 'In Trips', field: 'inprg', alignment: 'center' },
              { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },
              // { headerName: 'Distance Travel', field: 'dtnc_trvl', alignment: 'center' },
            ]
            this.columnDefs = this.columnDefs2
            this.gridData = this.dataSource2
            this.chartOptionsThree = this.chartOptionsThree

          } else if (this.tb_id == '3') {
            console.log('categoryyyyyyyyyyyyyyyyyyyyyy', 'tab1')
            this.columnDefs3 = [
              // { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'District ', field: 'tnt_nm', width: 'auto', alignment: 'left', groupIndex: 0 },
              { headerName: 'Category ', field: 'ctgry_nm', width: 'auto', alignment: 'left' },
              { headerName: 'Work Include Expenses Amount', field: 'wrk_incld_expns', alignment: 'right', format: this.currencyINRFormater },
              // { headerName: 'Other Expenses Amount', field: 'wrk_incld_expns', alignment: 'center' },
              { headerName: 'Total Amount', field: 'amt', alignment: 'right', format: this.currencyINRFormater, sortOrder: 'desc' },


            ]
            this.columnDefs = this.columnDefs3
            this.gridData = this.dataSource3
            this.chartOptionsThree = this.chartOptionsThree
          }
        } else if (this.lvl_id == 3) {

          console.log('333333333333333333333333333333333333333')
          // await this.getGridData();
          if (this.tb_id == '1') {
            this.columnDefs1 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left' },
              { headerName: 'Total Activities', field: 'ttl', alignment: 'center' },
              { headerName: 'Planned', field: 'plnd_ct', alignment: 'center' },
              { headerName: 'Un Planned', field: 'un_plnd_ct', alignment: 'center' },
              { headerName: 'No.of Trips', field: 'no_trps', alignment: 'center' },
              { headerName: 'Inprogress', field: 'inprg', alignment: 'center' },
              { headerName: 'Completed', field: 'cmpltd', alignment: 'center' },

              { headerName: 'no. of CAFs effected', field: 'cstmr_efctd_ct', alignment: 'center' }
            ]
            this.columnDefs = this.columnDefs1
            this.gridData = this.dataSource1
            this.chartOptionsThree = this.chartOptionsThree
            console.log(this.columnDefs)
          } else if (this.tb_id == '2') {
            this.columnDefs2 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left' },
              { headerName: 'No. of Vehicles', field: 'trvld_vehs', alignment: 'center' },
              // { headerName: 'Operated', field: 'ttl', alignment: 'center' },
              // { headerName: 'Not Operated', field: 'ttl', alignment: 'center' },
              { headerName: 'Total Trips', field: 'ttl_trps', alignment: 'center' },
              { headerName: 'In Trips', field: 'in_prgrs', alignment: 'center' },
              { headerName: 'Completed', field: 'cmpltd_trps', alignment: 'center' },
              { headerName: 'Distance Travel', field: 'actl_trvl_dstne_ct', alignment: 'center' },
            ]
            this.columnDefs = this.columnDefs2
            this.gridData = this.dataSource2
            this.chartOptionsThree = this.chartOptionsThree

          } else if (this.tb_id == '3') {
            this.columnDefs3 = [
              { headerName: 'S.No', field: 'sno', alignment: 'center', width: 60 },
              { headerName: 'Month ', field: 'mnth', width: 'auto', alignment: 'left' },
              { headerName: 'Work Include Expenses Amount', field: 'wrk_incld_expns', alignment: 'right', format: this.currencyINRFormater },
              // { headerName: 'Other Expenses Amount', field: 'wrk_incld_expns', alignment: 'center' },
              { headerName: 'Total Amount', field: 'amt', alignment: 'right', format: this.currencyINRFormater },


            ]
            this.columnDefs = this.columnDefs3
            this.gridData = this.dataSource3
            this.chartOptionsThree = this.chartOptionsThree
          }
        }


      }

    })

  }




  last7dayaData() {

    this.apiServ.get(`apsfl/data/last7dayaData`,).subscribe((res) => {
      if (res['status'] == 200) {
        var data = res['data'];
        this.chartOptionsThree = {
          series: [{
            name: 'Total',
            data: [data[1][0].ttl_trps, data[1][1].ttl_trps, data[1][2].ttl_trps, data[1][3].ttl_trps, data[1][4].ttl_trps, data[1][5].ttl_trps, data[1][6].ttl_trps,]
          }, {
            name: 'In progress',
            data: [data[1][0].in_trp_ct, data[1][1].in_trp_ct, data[1][2].in_trp_ct, data[1][3].in_trp_ct, data[1][4].in_trp_ct, data[1][5].in_trp_ct, data[1][6].in_trp_ct,]
          }, {
            name: 'completed',
            data: [data[1][0].cmpltd_trps, data[1][1].cmpltd_trps, data[1][2].cmpltd_trps, data[1][3].cmpltd_trps, data[1][4].cmpltd_trps, data[1][5].cmpltd_trps, data[1][6].cmpltd_trps,]
          }],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true
          },
          grid: {
            borderColor: '#eff2f7',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
            },
          },
          xaxis: {
            categories: [data[1][0].dt, data[1][1].dt, data[1][2].dt, data[1][3].dt, data[1][4].dt, data[1][5].dt, data[1][6].dt,],
            title: {
              text: 'Dates'
            }
          },
          yaxis: {
            title: {
              text: 'Number of Activities'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          // colors: ['#5967af', '#fd8900', '#34c38f'],

          colors: ['#5967af', '#f1b44c', '#34c38f'],

          title: {
            text: ''
          }
        };

        this.chartOptionstwo = {
          series: [{
            name: 'Total',
            data: [data[0][0].ttl_wrks, data[0][1].ttl_wrks, data[0][2].ttl_wrks, data[0][3].ttl_wrks, data[0][4].ttl_wrks, data[0][5].ttl_wrks, data[0][6].ttl_wrks,]
          }, {
            name: 'In progress',
            data: [data[0][0].in_prgrs, data[0][1].in_prgrs, data[0][2].in_prgrs, data[0][3].in_prgrs, data[0][4].in_prgrs, data[0][5].in_prgrs, data[0][6].in_prgrs,]
          }, {
            name: 'completed',
            data: [data[0][0].cmpltd_wrks, data[0][1].cmpltd_wrks, data[0][2].cmpltd_wrks, data[0][3].cmpltd_wrks, data[0][4].cmpltd_wrks, data[0][5].cmpltd_wrks, data[0][6].cmpltd_wrks,]
          }],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
            },
          },
          grid: {
            borderColor: '#eff2f7',
          },
          xaxis: {
            categories: [data[0][0].dt, data[0][1].dt, data[0][2].dt, data[0][3].dt, data[0][4].dt, data[0][5].dt, data[0][6].dt,],
            title: {
              text: 'Dates'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          // colors: ['#5967af', '#fd8900', '#34c38f'],
          colors: ['#5967af', '#f1b44c', '#34c38f'],
          title: {
            text: ''
          }
        };

        this.chartOptionsFive = {
          series: [{
            name: 'Total',
            data: [data[0][0].ttl_wrks, data[0][1].ttl_wrks, data[0][2].ttl_wrks, data[0][3].ttl_wrks, data[0][4].ttl_wrks, data[0][5].ttl_wrks, data[0][6].ttl_wrks,]
          }, {
            name: 'In progress',
            data: [data[0][0].in_prgrs, data[0][1].in_prgrs, data[0][2].in_prgrs, data[0][3].in_prgrs, data[0][4].in_prgrs, data[0][5].in_prgrs, data[0][6].in_prgrs,]
          }, {
            name: 'completed',
            data: [data[0][0].cmpltd_wrks, data[0][1].cmpltd_wrks, data[0][2].cmpltd_wrks, data[0][3].cmpltd_wrks, data[0][4].cmpltd_wrks, data[0][5].cmpltd_wrks, data[0][6].cmpltd_wrks,]
          }],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
            },
          },
          grid: {
            borderColor: '#eff2f7',
          },
          xaxis: {
            categories: [data[0][0].dt, data[0][1].dt, data[0][2].dt, data[0][3].dt, data[0][4].dt, data[0][5].dt, data[0][6].dt,],
            title: {
              text: 'Dates'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          // colors: ['#5967af', '#fd8900', '#34c38f'],
          colors: ['#5967af', '#f1b44c', '#34c38f'],
          title: {
            text: ''
          }
        };
      }

    })

  }

  tabsdata = [
    {
      'tab_id': 1,
      'tab_nm': 'By District'
    },
    {
      'tab_id': 2,
      'tab_nm': 'By Category'
    },
    {
      'tab_id': 3,
      'tab_nm': 'By Month'
    }
  ]

  tabsdata1 = [
    // {
    //   'tab_id': 1,
    //   'tab_nm': 'By District'
    // },
    {
      'tab_id': 2,
      'tab_nm': 'By Category'
    },
    {
      'tab_id': 3,
      'tab_nm': 'By Month'
    }
  ]



  viewsdata = [
    {
      'vw_id': 1,
      'vw_icn': 'table'
    },
    {
      'vw_id': 2,
      'vw_icn': 'bar-chart'
    }
  ]


  generateDailyData(days, fld) {
    const daysArray: string[] = [];
    for (let i = 0; i < days.length; i++) {
      daysArray.push(days[i][fld]);
    }
    return daysArray;
  }

  // Generate an array of days from 1 to 30
  getDaysArray(days, fld) {
    const daysArray: string[] = [];
    for (let i = 0; i < days.length; i++) {
      daysArray.push(days[i][fld]);
    }
    return daysArray;
  }


  slectdvw(v) {
    console.log(this.tb_id, this.lvl_id)
    this.slctdvw = v
    this.chartOptionsFour = null
    if (this.tb_id == '1') {
      if (this.lvl_id == 1) {
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource1, 'ttl')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource1, 'inprg')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource1, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource1, 'dstrt_nm'),
            title: {
              text: 'Districts'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      } else if (this.lvl_id == 2) {

        console.log(this.dataSource2, '222222222222222222222222')
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource1, 'ttl')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource1, 'inprg')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource1, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource1, 'ctgry_nm'),
            title: {
              text: 'Categories'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      }
      else if (this.lvl_id == 3) {
        console.log(this.dataSource2, '333333333333333333333333')
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource1, 'ttl')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource1, 'inprg')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource1, 'cmpltd')
            // }
          ],
          // , {
          //   name: 'completed',
          //   data: this.generateDailyData(this.dataSource3, 'cmpltd')
          // }
          // ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource1, 'mnth'),
            title: {
              text: 'Months'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      }
    }

    if (this.tb_id == '2') {
      if (this.lvl_id == 1) {
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource2, 'ttl')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource2, 'inprg')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource2, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource2, 'dstrt_nm'),
            title: {
              text: 'Districts'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      } else if (this.lvl_id == 2) {

        console.log(this.dataSource2, '222222222222222222222222')
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource2, 'ttl')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource2, 'inprg')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource2, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource2, 'ctgry_nm'),
            title: {
              text: 'Categories'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      }
      else if (this.lvl_id == 3) {
        console.log(this.dataSource2, '333333333333333333333333')
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource2, 'ttl_trps')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource2, 'in_prgrs')
          }
            //   , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource2, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource2, 'mnth'),
            title: {
              text: 'Months'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      }
    }

    else if (this.tb_id == '3') {
      if (this.lvl_id == 1) {
        this.chartOptionsFour = {
          series: [{
            name: 'Work Included',
            data: this.generateDailyData(this.dataSource3, 'wrk_incld_expns')
          }, {
            name: 'Total Amount',
            data: this.generateDailyData(this.dataSource3, 'amt')
          }],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource3, 'dstrt_nm'),
            title: {
              text: 'Districts'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      } else if (this.lvl_id == 2) {

        console.log(this.dataSource3, '222222222222222222222222')
        this.chartOptionsFour = {
          series: [{
            name: 'Work Included',
            data: this.generateDailyData(this.dataSource3, 'wrk_incld_expns')
          }, {
            name: 'Total Amount',
            data: this.generateDailyData(this.dataSource3, 'amt')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource2, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource3, 'ctgry_nm'),
            title: {
              text: 'Categories'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      }
      else if (this.lvl_id == 3) {
        console.log(this.dataSource2, '333333333333333333333333')
        this.chartOptionsFour = {
          series: [{
            name: 'Total',
            data: this.generateDailyData(this.dataSource3, 'wrk_incld_expns')
          }, {
            name: 'In progress',
            data: this.generateDailyData(this.dataSource3, 'amt')
          }
            // , {
            //   name: 'completed',
            //   data: this.generateDailyData(this.dataSource3, 'cmpltd')
            // }
          ],
          chart: {
            type: 'bar',
            height: 800,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // columnWidth: '50%',
            },
          },

          xaxis: {
            categories: this.getDaysArray(this.dataSource3, 'mnth'),
            title: {
              text: 'Months'
            }
          },
          yaxis: {

            title: {
              text: 'Number of Trips'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          },
          dataLabels: {
            enabled: true
          },
          colors: ['#008FFB', '#00E396', '#FEB019'],
          title: {
            text: ''
          }
        };
      }
    }






  }

  open(): void {
    let workid = 27;
    let rte = `/apsfl/assets/work/list/${workid}`;
    console.log(rte);

    this.apiServ.get(rte).subscribe(res => {
      console.log(res);
    })
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  getMultiChartData() {
    this.apiServ.get(`dashboard/enterprise/omsDashboard/getExpensesGraph/${this.selectdCtgry}`).subscribe(res => {
      if (res['status'] == 200) {
        this.expnsGrapData = res['data'];
        console.log(this.expnsGrapData);
        if (this.expnsGrapData && this.expnsGrapData.length > 0) {
          this.multiChartOptions = {
            series: [
              {
                name: "Expenses Amount",  // Column series
                type: "column",
                data: this.expnsGrapData.map(item => item.tot_exp_at || 0)
                , color: "#3e8fb3"
              },
              {
                name: "Category Wise Expense",  // Line series
                type: "line",
                data: this.expnsGrapData.map(item => item.expn_at || 0)
                , color: "#f15a22"
              }
            ],
            chart: {
              height: 400,
              type: "line",
              stacked: false,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: true
            },
            stroke: {

              width: [1, 2]
            },
            title: {
              text: "District wise Expenses Category Analysis",
              align: "left",
              offsetX: 110
            },
            xaxis: {
              categories: this.expnsGrapData.map(item => item.tnt_nm)
            },
            yaxis: [
              {
                seriesName: "Expenses Amount",  // Corresponding to column series
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#3e8fb3"
                },
                labels: {
                  style: {
                    colors: "#3e8fb3"
                  }
                },
                title: {
                  text: "Expenses Amount",
                  style: {
                    color: "#3e8fb3"
                  }
                },
                tooltip: {
                  enabled: true
                }
              },
              {
                seriesName: "Category Wise Expense",  // Corresponding to line series
                opposite: true,
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#f15a22"
                },
                labels: {
                  style: {
                    colors: "#f15a22"
                  }
                },
                title: {
                  text: "Category Wise Expense",
                  style: {
                    color: "#f15a22"
                  }
                }
              }
            ],
            tooltip: {
              fixed: {
                enabled: true,
                position: "topLeft",  // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
              }
            },
            legend: {
              horizontalAlign: "left",
              offsetX: 40
            }
          };
        }
      }
    })

  }


  getAsstCtgry() {
    this.apiServ.get(`apsfl/Asset/categories`).subscribe((res) => {
      if (res['status'] == 200) {
        this.AsstCtgry = res['data'];
        console.log(this.expenseCtgry);
        this.onAssetCtgrySlct(0);
      }
    })
  }
  onAssetCtgrySlct(exp_ctgry) {
    this.getAssetsMultiChartData();
  }
  getAssetsMultiChartData() {
    this.apiServ.get(`apsfl/getAssetGraph/${this.selectdAsstCtgry}`).subscribe(res => {
      if (res['status'] == 200) {

        this.AsstGrapData = res['data'];


        console.log(this.AsstGrapData);
        if (this.AsstGrapData && this.AsstGrapData.length > 0) {
          this.AsstMultiChartOptions = {
            series: [
              {
                name: "Wire Meters",  // Column series
                type: "column",
                data: this.AsstGrapData.map(item => item.tot_mtrs || 0)
                , color: "#3e8fb3"
              },
              {
                name: "Category Wise Meters",  // Line series
                type: "line",
                data: this.AsstGrapData.map(item => item.tot_mtrs1 || 0)
                , color: "#f15a22"
              }
            ],
            chart: {
              height: 400,
              type: "line",
              stacked: false,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            dataLabels: {
              enabled: true
            },
            stroke: {

              width: [1, 2]
            },
            title: {
              text: "Consumable Fiber Asset Category Analysis",
              align: "left",
              offsetX: 110
            },
            xaxis: {
              categories: this.AsstGrapData.map(item => item.tnt_nm)
            },
            yaxis: [
              {
                seriesName: "Meters",  // Corresponding to column series
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#3e8fb3"
                },
                labels: {
                  style: {
                    colors: "#3e8fb3"
                  }
                },
                title: {
                  text: "Meters",
                  style: {
                    color: "#3e8fb3"
                  }
                },
                tooltip: {
                  enabled: true
                }
              },
              {
                seriesName: "Category Wise Assets",  // Corresponding to line series
                opposite: true,
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#f15a22"
                },
                labels: {
                  style: {
                    colors: "#f15a22"
                  }
                },
                title: {
                  text: "Category Wise Assets",
                  style: {
                    color: "#f15a22"
                  }
                }
              }
            ],
            tooltip: {
              fixed: {
                enabled: true,
                position: "topLeft",  // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
              }
            },
            legend: {
              horizontalAlign: "left",
              offsetX: 40
            }
          };
        }
      }
    })

  }

  onExporting(e: DxDataGridTypes.ExportingEvent) {
    console.log('onExporting')
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), ' Vehicles Summary.xlsx');
      });
    });
  }
}
