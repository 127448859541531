import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()

export class AppsService {

  USER_PERMISSIONS = { "slct_in": 0, "insrt_in": 0, "updt_in": 0, "dlte_in": 0, "exprt_in": 0 };

  constructor(private http: HttpClient) { }

  create(postdata, rte) {
    return this.http.post(`/${rte}`, { data: postdata });
  }
  put(postdata, rte) {
    return this.http.put(`/${rte}`, { data: postdata });
  }
  post(postdata, rte) {
    if (rte.indexOf("http") > -1) {
      return this.http.post(`${rte}`, postdata);
    } else {
      return this.http.post(`/${rte}`, postdata);
    }
  }
  get(rte) {
    if (rte.indexOf("http") > -1) {
      return this.http.get(`${rte}`);
    } else {
      return this.http.get(`/${rte}`);
    }

  }
  update(postdata, rte) {
    return this.http.put(`/${rte}`, postdata);
  }
  delete(rte) {
    return this.http.delete(`/${rte}`);
  }
  getBlobType(rte)
  {
    console.log("GET route ::" + rte)
    if (rte.indexOf("http") > -1) {
      return this.http.get(`${rte}`, { responseType: 'blob' });
    } else {
      return this.http.get(`/${rte}`, { responseType: 'blob' });
    }
  }

  getConcatServerFilePath(fle_url) {
    var url = fle_url;
    var fl_splt = fle_url.split('/');
    let srvr_url = '';
    if (fl_splt && fl_splt.length) {
      if (fl_splt[0] == '') {
        srvr_url = `${environment.origin}${url}`
      }
      else {
        srvr_url = `${environment.origin}/${url}`
      }
    }

    return srvr_url;
  }

}