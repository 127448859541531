import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule, DxNumberBoxModule,
  // tslint:disable-next-line:max-line-length
  DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule, DxChartModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule, DxDiagramModule
} from 'devextreme-angular';
// import { NgChartsModule } from 'ng2-charts';
import { TemplateModule } from '../../shared/template/template.module';
import { SetupRoutingModule } from './setup-routing.module';
import { SetupComponent } from './setup.component';
import { DesignationsComponent } from './designations/designations.component';
import { DepartmentsComponent } from './departments/departments.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { UsersComponent } from './users/users.component';
import { NewFineAmountsComponent } from './new-fine-amounts/new-fine-amounts.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { OrganizationComponent } from './organization/organization.component';
import { WardsComponent } from './wards/wards';
import { ULBsComponent } from './ulbs/ulbs';
import { MicropocketsComponent } from './micropockets/micropockets.component';
// import { FineCategoriesComponent } from './fine-categories/fine-categories.component';
// import { DomesticUserChargesComponent } from './domestic-user-charges/domestic-user-charges.component';
// import { CommericalUserChargesComponent } from './commerical-user-charges/commerical-user-charges.component';
// import { FinesComponent } from './fines/fines.component';
// import { UserChargesStreetComponent } from './user-charges-street/user-charges-street.component';
import { SachivalayamsComponent } from './sachivalayams/sachivalayams.component';
import { BlocksComponent } from './blocks/blocks.component';
import { StreetsComponent } from './streets/streets';
import { GatesComponent } from './gates/gates';
import { GateTypesComponent } from './gateTypes/gateTypes';
import { MenuProfileComponent } from './menu-profile/menu-profile.component';
import { HouseStatusComponent } from './house-status/house-status.component';
import { HousesComponent } from './houses/houses.component';
import { DistrictsComponent } from './districts/districts.component';
import { MandalsComponent } from './mandals/mandals.component';

import { RolesComponent } from './roles/roles.component';
import { SetupProfileComponent } from './setup-profile/setup-profile.component';
import { StateComponent } from './state/state.component';
import { HelpDocumentationComponent } from './help-documentation/help-documentation.component';
// import { EditorModule } from '@tinymce/tinymce-angular';


import { ReportProfileComponent } from './report-profile/report-profile.component'
// import { ChangeLogComponent } from './change-log/change-log.component';
import { HelpDocPagesComponent } from './help-doc-pages/help-doc-pages.component';
import { HouseTypesComponent } from './house-types/house-types.component'
import { HelpFaqsComponent } from './help-faqs/help-faqs.component';
import { HelpvideosComponent } from './help-videos/help-videos.component';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
// import { CollectionTypeComponent } from './collection-type/collection-type.component';
// import { TelegramComponent } from './telegram/telegram.component';
import { RolesPremessionsComponent } from './roles-premessions/roles-premessions.component';
import { AssetCategoriesComponent } from './asset-categories/asset-categories.component';
import { AssetLocationsComponent } from './asset-locations/asset-locations.component';
import { AssetTypesComponent } from './asset-types/asset-types.component';
import { AssetGroupsComponent } from './asset-groups/asset-groups.component';
import { AssetAgreementsComponent } from './asset-agreements/asset-agreements.component';
import { AssetVendorsComponent } from './asset-vendors/asset-vendors.component';
import { AssetModelsComponent } from './asset-models/asset-models.component';
import { AssetIssueTypesComponent } from './asset-issue-types/asset-issue-types.component';
import { AssertCategoriesComponent } from './assert-categories/assert-categories.component';
import { VehicleGroupsComponent } from './vehicle-groups/vehicle-groups.component';
import { AssertStatusComponent } from './assert-status/assert-status.component';
import { NgChartjsModule } from 'ng-chartjs';
// import { EmployeesModule } from './employees/employees.module';
@NgModule({
  declarations: [
    SetupComponent, DesignationsComponent, MicropocketsComponent, WardsComponent, ULBsComponent,
    StreetsComponent,  DepartmentsComponent, OrganizationComponent, UsersComponent,
    NewFineAmountsComponent,  GatesComponent, GateTypesComponent, HelpDocumentationComponent,
    SachivalayamsComponent, BlocksComponent, MenuProfileComponent, HouseStatusComponent, HousesComponent, RolesComponent,
    SetupProfileComponent, DistrictsComponent, MandalsComponent, StateComponent, ReportProfileComponent, HouseTypesComponent,
    HelpDocPagesComponent, HelpFaqsComponent, HelpvideosComponent, RolesPremessionsComponent,
    AssetCategoriesComponent, AssetLocationsComponent, AssetTypesComponent, AssetGroupsComponent, AssetAgreementsComponent, AssetVendorsComponent, AssetModelsComponent, AssetIssueTypesComponent, AssertCategoriesComponent, VehicleGroupsComponent, AssertStatusComponent],
  imports: [
    CommonModule,
    // --  DXmodules -- //
    DxDataGridModule, DxBulletModule, DxTemplateModule, DxButtonModule,
    DxNumberBoxModule, DxChartModule, DxSelectBoxModule, DxCheckBoxModule,
    DxTabPanelModule, DxPieChartModule, DxTabsModule, DxLoadPanelModule,
    SharedModule,
    // -- NZModules --//
    NzIconModule, NzDrawerModule, NzPopconfirmModule, NzSkeletonModule,
    NzButtonModule, NzCardModule, NzAvatarModule, NzRateModule, NzBadgeModule,
    NzProgressModule, NzRadioModule, NzDropDownModule, NzTimelineModule, NzTabsModule,
    NzTagModule, NzListModule, NzCalendarModule, NzToolTipModule, NzFormModule, NzModalModule,
    NzSelectModule, NzUploadModule, NzInputModule, NzPaginationModule, NzDatePickerModule,
    NzCheckboxModule, NzMessageModule, NzLayoutModule, NzGridModule, NzDividerModule, NgChartjsModule, TemplateModule,
    SetupRoutingModule, ReactiveFormsModule, FormsModule,
    // tslint:disable-next-line:max-line-length,
    NzNotificationModule, NzAutocompleteModule, NzAlertModule, //EditorModule,
     NzBreadCrumbModule
  ],
  providers:
    [

      DatePipe
    ],
})
export class SetupModule { }
