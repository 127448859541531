import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapsComponent } from './ds-map.component';
// import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
// import { LoadingModule } from 'app/shared/loading/loading/loading.module';
// import { DemoComponent } from './demo/demo.component';



@NgModule({
  declarations: [
    MapsComponent,
    // DsMap2Component,
  ],
  imports: [
    CommonModule
  ]
  ,exports:[MapsComponent]
})
export class MapsModule { }
