import { Component, ElementRef, ViewChild } from '@angular/core';
import { log } from 'console';
import * as JSZip from 'jszip';
import * as L from 'leaflet';
import 'leaflet-rotatedmarker';
import 'leaflet.animatedmarker/src/AnimatedMarker';
import 'leaflet.markercluster';

@Component({
  selector: 'app-apsfl-live-tracking',
  templateUrl: './apsfl-live-tracking.component.html',
  styleUrls: ['./apsfl-live-tracking.component.css']
})
export class ApsflLiveTrackingComponent {
  sidebarcollapsed = false;
  liveMap: any;
  constructor() { }

  columns = [
    { dataField: 'sno', caption: 'S.no' },
    { dataField: 'atndnc', caption: 'Attendence' },
    { dataField: 'status', caption: 'Status' },
    { dataField: 'ctgry', caption: 'Category' },
    { dataField: 'distance', caption: 'Distance' }
  ];
  customers = [
    { 'sno': '1', atndnc: 'Ap 31 TG 4323', status: 'active', ctgry: 'auto', distance: '27.4' },
    { 'sno': '2', atndnc: 'Ap 39 FG 8354', status: 'inactive', ctgry: 'van', distance: '34.3' },
    { 'sno': '3', atndnc: 'Ap 31 JG 1278', status: 'active', ctgry: 'truck', distance: '19.8' },
    { 'sno': '4', atndnc: 'Ap 31 KM 0765', status: 'inactive', ctgry: 'truck', distance: '9.1' }
  ];

  ngAfterViewInit() {
    this.initialize();
  }
  initialize = function () {

    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {
      this.liveMap = L.map('livemap', {
        center: [17.0080782, 81.7820634, 65],
        zoom: 13
      }).setView([17.0080782, 81.7820634, 65]);

      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      console.log('map initilized');
      tiles.addTo(this.liveMap);

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
  };



  toggle() {
    this.sidebarcollapsed = this.sidebarcollapsed ? false : true;

    setTimeout(() => {
      this.liveMap.invalidateSize();
      // Optionally, adjust zoom or center as needed
      this.liveMap.setZoom(this.liveMap.getZoom());
    }, 150);  // Adjust timeout duration based on transition effect
  }


  distChange(dist) {
    console.log(dist);
  }
  vcleGrpChange(grp) {
    console.log(grp);
  }

  lctnGrpChange(lctngrp) {
    console.log(lctngrp);
  }
  lctnsChange(lctns) {
    console.log(lctns);
  }
  vhcleCtgryChange(vhcleCtgry) {
    console.log(vhcleCtgry);

  }
}
