import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-door-door-collection-dashboard',
  templateUrl: './door-door-collection-dashboard.component.html',
  styleUrls: ['./door-door-collection-dashboard.component.scss']
})
export class DoorDoorCollectionDashboardComponent implements OnInit {
  cnt_dtlscolumnDefs: any;
  cvrgDta: any;
  HsDta: any;
  showLdr = false;
  postCntdata: any;
  cnt_dtls: any;
  target;
  clntDtls: any;
  trnsfrPnts;trnsfrStns;prcngPnts;mrfPnts;
  constructor(public apiServ: AppsService,private vps: ViewportScroller) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
   
  }

  ngOnInit() {
    this.get_totalTripsCount();
    // this.get_HousesTripsCount();
  }
  scroll(id) {
    console.log(id);
    this.vps.scrollToAnchor(id);
  }
  get_totalTripsCount(): any {
    this.showLdr = true;
    const rte = `dashboard/enterprise/get_solidwaste_CvrgDta`;
    this.apiServ.get(rte).subscribe((res) => {
        this.cvrgDta = res['data'];
        console.log(this.cvrgDta);
        if(this.cvrgDta && this.cvrgDta.length > 0){
          for(let i =0; i< this.cvrgDta.length; i++){
            if(this.cvrgDta[i].fnce_grp_id == 1000012){
              this.trnsfrPnts = this.cvrgDta[i];
              console.log(this.trnsfrPnts);
            } else if(this.cvrgDta[i].fnce_grp_id == 1000013){
              this.trnsfrStns = this.cvrgDta[i];
              console.log(this.trnsfrStns);
            } else if(this.cvrgDta[i].fnce_grp_id == 1000015){
              this.mrfPnts = this.cvrgDta[i];
              console.log(this.mrfPnts);
            } else if(this.cvrgDta[i].fnce_grp_id == 1000020){
              this.prcngPnts = this.cvrgDta[i];
              console.log(this.prcngPnts);
            }
          }
          this.showLdr = false;
        }
    }, (err) => {
      console.log(err);
    });
  }
  get_HousesTripsCount(): any {
    this.showLdr = true;
    const rte = `dashboard/enterprise/get_houses_CvrgDta`;
    this.apiServ.get(rte).subscribe((res) => {
        if(res['data'] && res['data'].length > 0){
          this.HsDta = res['data'][0];
          this.showLdr = false;
        }
        console.log(this.HsDta);
    }, (err) => {
      console.log(err);
    });
  }
  getDtls(dta,cd){
    console.log(dta,cd);
    this.showLdr = true;
    this.postCntdata = {
      grp_dta: dta,
      typ: cd
    }
    let rte = `dashboard/enterprise/get_solidwaste_CntDta`;
    this.apiServ.post(this.postCntdata, rte).subscribe((res) => {
      if (res['status'] == 200) {
        var res_data = res['data'];
        if (res_data && res_data.length > 0) {
          this.cnt_dtls = res_data;
          this.cnt_dtlscolumnDefs = [
            { headerName: 'S.No', field: 'sno', alignment: 'center', width: 80, filter: false },
            { headerName: 'Fence Group', alignment: 'center', width: 200, field: 'fnce_grp_nm' },
            { headerName: 'Fence Category', alignment: 'center', width: 200, field: 'fnce_ctgry_nm' },
            { headerName: 'Fence', alignment: 'center', width: 200, field: 'fnce_nm' },
            { headerName: 'In Time', alignment: 'center', width: 200, field: 'in_tm' },
            { headerName: 'Out Time', alignment: 'center', width: 200, field: 'ot_tm' },
            { headerName: 'Status', alignment: 'center', width: 200, field: 'sts' },
          ]
        }
        this.showLdr = false;
      }
    }, (err) => {
      console.log(err);
    });
  }

}
