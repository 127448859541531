import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
// import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { DxTemplateModule, DxDataGridModule, DxBulletModule, DxButtonModule,
// tslint:disable-next-line:max-line-length
//     DxPolarChartModule,DxGanttModule,DxFileManagerModule, DxNumberBoxModule, DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule } from 'devextreme-angular';
// tslint:disable-next-line:max-line-length
import {
    DxTemplateModule, DxDataGridModule, DxBulletModule, DxButtonModule,
    DxNumberBoxModule, DxCheckBoxModule, DxSelectBoxModule, DxTabPanelModule
} from 'devextreme-angular';
import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { HttpConfigInterceptor } from './providers/http/http.interceptor';
import { ReportsModule } from './apps/reports/reports.module';

import { DashboardModule } from './apps/dashboards/dashboard.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';

import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';

import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSliderModule } from 'ng-zorro-antd/slider';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';

import { AuthModule } from './apps/auth/auth.module';
import { ApiService } from './providers/api.service';
import { SetupModule } from './apps/setup/setup.module';
import { AuthGuardService } from './apps/auth/auth-guard.service';
import { CrmModule } from './apps/crm/crm.module';
import { SupportModule } from './apps/support/support.module';
// import dxFileManager from 'devextreme/ui/file_manager';

import { AppsComponent } from './apps/apps.component';
import { FullscreenAppComponent } from './layouts/fullscreen-app/fullscreen-app.component';
import { LayoutAppComponent } from './layouts/layout-app/layout-app.component';
import { UserService } from './providers/user.service';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

import { AppsService } from './shared/services/apps.service';

import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { PlayBackModule } from './apps/play-back/play-back.module';
import { LiveTrackingModule } from './apps/live-tracking/live-tracking.module';
import { ScheduleTripsModule } from './apps/schedule-trips/schedule-trips.module';
import { TransfereService } from './providers/transfer/transfer.service';
import { AmsVehiclesModule } from './apps/ams-vehicles/ams-vehicles.module';
import { DriversModule } from './apps/drivers/drivers.module';
import { HeatmapModule } from './apps/heatmap/heatmap.module';
import { BillingModule } from './apps/billing/billing.module';

// import { LocationModule } from './apps/location/location.module';
// import { MapEditorModule } from './apps/map-editor/map-editor.module';
import { TimelineModule } from './apps/timeline/timeline.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayrollModule } from './apps/dashboards/apsfl/payroll/payroll.module';

const ngZorroConfig: NzConfig = {
    message: { nzMaxStack: 1 }
};

registerLocaleData(en);




@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        AppsComponent,
        FullscreenAppComponent,
        LayoutAppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NzBreadCrumbModule,
        TemplateModule,
        SharedModule,
        DxTemplateModule, DxDataGridModule, DxBulletModule
        , DxButtonModule, DxNumberBoxModule, DxCheckBoxModule
        , DxSelectBoxModule, DxTabPanelModule,
        // NZModules
        NzIconModule, NzLayoutModule, NzGridModule, NzSkeletonModule,
        NzButtonModule, NzCardModule, NzAvatarModule, NzRateModule, NzBadgeModule,
        NzProgressModule, NzRadioModule,  NzDropDownModule, NzTimelineModule, NzTabsModule,
        NzTagModule, NzListModule, NzCalendarModule, NzToolTipModule, NzFormModule, NzModalModule,
        NzSelectModule, NzUploadModule, NzInputModule, NzPaginationModule, NzDatePickerModule,
        NzCheckboxModule, NzMessageModule, NzTimePickerModule, NzDrawerModule, NzSliderModule,
        ReportsModule, PlayBackModule, LiveTrackingModule, ScheduleTripsModule, BillingModule, HeatmapModule,
        DashboardModule, SetupModule, ReactiveFormsModule, FormsModule, SupportModule,  AmsVehiclesModule, DriversModule,PayrollModule,
        // LocationModule,MapEditorModule,
        TimelineModule,
    ],
    providers: [
        ApiService, AppsService, AuthGuardService, UserService, TransfereService,
        {
            provide: NZ_I18N,
            useValue: en_US,
        },
        ThemeConstantService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        { provide: NZ_CONFIG, useValue: ngZorroConfig }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [],
})

export class AppModule {
}

