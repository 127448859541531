<app-loading *ngIf="loader"></app-loading>
<div class="page">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Adjustments/Arrears</h3>
            </div>
            <div class="col-auto float-end ms-auto">
                <a class="btn add-btn" routerLink="/layout/payroll/adjustments-arrears-list"><i
                        class="fa fa-chevron-left"></i>
                    Go Back </a>
            </div>
        </div>
    </div>

    <div class="content container-fluid">
        <div class="row" style="    padding: 5px;
        margin-top: -15px;">
            <div class="alert alert-primary" role="alert">
                NOTE : This page can be used for adjusting existing payroll or to add arrears to the existing payroll
            </div>
        </div>
        <div id="stepper2" class="bs-stepper">
            <div class="bs-stepper-header">
                <div id="step1" class="step" data-target="#test-l-1">
                    <button class="step-trigger">
                        <span class="bs-stepper-circle">1</span>
                        <span class="bs-stepper-label">Select Employees & Reason</span>
                    </button>
                </div>
                <div class="line"></div>
                <div id="step2" class="step" data-target="#test-l-2">
                    <button class="step-trigger">
                        <span class="bs-stepper-circle">2</span>
                        <span class="bs-stepper-label">Apply Adjustments</span>
                    </button>
                </div>
            </div>
            <div class="bs-stepper-content">
                <div id="test-l-1" class="content">
                    <div class="row" style="    padding: 5px;
        margin-top: -15px;">
                        <div class="alert alert-primary" role="alert">
                            NOTE : Select Employees for which adjustments/arrears to be calculated
                        </div>
                    </div>



                    <form nz-form [formGroup]="searchEmployeeForm">

                        <div nz-row [nzGutter]="24">
                            <!-- <div nz-col [nzSpan]="3">
                                <div class="inptLbl"> Asset Department&nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="dprt_id"
                                    (ngModelChange)="dprt_id()">
                                    <nz-option [value]="0">All</nz-option>

                                    <nz-option *ngFor="let d of categories" [nzValue]="d.dprt_id" [nzLabel]="d.dprt_nm">
                                    </nz-option>

                                </nz-select>
                            </div> -->

                         


                            <div nz-col [nzSpan]="3">
                                <div class="inptLbl">  Department&nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="dprt_id">

                                    <nz-option [value]="0">All</nz-option>
                                    <nz-option *ngFor="let d of dprt_lst" [nzValue]="d.dprt_id" [nzLabel]="d.dprt_nm">
                                    </nz-option>

                                </nz-select>
                            </div>

                            <!-- <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="col-form-label">Department <span class="text-danger">*</span></label>
                                    <select class="form-select floating form-control" formControlName="dprt_id"
                                        (change)="getSubDprt()">
                                        <option value="0">All</option>
                                        <option *ngFor="let d of dprt_lst" [value]="d.dprt_id">{{d.dprt_nm}}</option>
                                    </select>
                                </div>
                            </div> -->


                            <div nz-col [nzSpan]="3">
                                <div class="inptLbl"> Sub Department&nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="sb_dprt_id">

                                    <nz-option [value]="0">All</nz-option>
                                    <nz-option *ngFor="let d of sbDprtLst" [nzValue]="d.dprt_id" [nzLabel]="d.dprt_nm">
                                    </nz-option>

                                </nz-select>
                            </div>

                            <div nz-col [nzSpan]="3">
                                <div class="inptLbl"> Organization&nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="orgn_id">

                                    <nz-option [value]="0">All</nz-option>
                                    <nz-option *ngFor="let d of orgnLst" [nzValue]="d.orgn_id" [nzLabel]="d.orgn_nm">
                                    </nz-option>

                                </nz-select>
                            </div>

                            <div nz-col [nzSpan]="3">
                                <div class="inptLbl"> Designation&nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="dsgns_id">

                                    <nz-option [value]="0">All</nz-option>
                                    <nz-option *ngFor="let d of dsgnsLst" [nzValue]="d.dsgns_id" [nzLabel]="d.dsgns_nm">
                                    </nz-option>

                                </nz-select>
                            </div>

                            <div nz-col [nzSpan]="3">
                                <div class="inptLbl"> Employee Category &nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="emple_ctgry_id">

                                    <nz-option [value]="0">All</nz-option>
                                    <nz-option *ngFor="let d of empleCatLst" [nzValue]="d.emple_ctgry_id"
                                        [nzLabel]="d.emple_ctgry_nm">
                                    </nz-option>

                                </nz-select>
                            </div>
                            <div nz-col [nzSpan]="3">
                                <div class="inptLbl"> Employee Status &nbsp;<span class="impFld">*</span></div>
                                <nz-select style="width:100%;" nzPlaceHolder="" formControlName="emple_sts_id">

                                    <nz-option [value]="0">All</nz-option>
                                    <nz-option *ngFor="let d of emplySts" [nzValue]="d.emple_sts_id"
                                        [nzLabel]="d.emple_sts_nm">
                                    </nz-option>

                                </nz-select>
                            </div>



                            <div class="col-sm-2" style="margin-top: 30px;">
                                <button nz-button style="margin-right: 8px;" (click)="getEmployees()"
                                    [disabled]="loader" nzType="primary" nzDanger>
                                    <span class="spinner-border spinner-border-sm" *ngIf="loader" role="status"
                                        aria-hidden="true"></span>
                                    <span class="sr-only" *ngIf="loader">Loading...</span> Get Data</button>


                            </div>
                        </div>








                    </form>


                    <div *ngIf="emplysMstr.length" class="row" style="margin: 10px;">
                        <div class="col-sm-4">
                            Total Employees Count : {{emplysMstr.length || 0}}
                        </div>
                        <div class="col-sm-4">
                            Selected Employees Count : {{selectEmployees.length || 0}}
                        </div>
                    </div>
                    <div *ngIf="emplysMstr.length" id="data-grid-demo">
                        <dx-data-grid id="gridContainer" [dataSource]="emplysMstr"
                            (onSelectionChanged)="selectionChanged($event)">
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"
                                [showInfo]="true">
                            </dxo-pager>
                            <dxo-search-panel [visible]="true">
                            </dxo-search-panel>
                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                            <dxo-editing mode="cell" [allowUpdating]="false" [allowAdding]="false"
                                [allowDeleting]="false">
                            </dxo-editing>
                            <dxo-selection mode="multiple"></dxo-selection>
                            <dxi-column *ngFor="let f of columnDefs" [visible]="f.visible" [dataField]="f.field"
                                [caption]="f.headerName" [width]="f.width" [alignment]="f.algnmnt"
                                [allowResizing]="true" [allowFiltering]="f.filter" [allowHeaderFiltering]="f.filter">
                            </dxi-column>
                        </dx-data-grid>
                    </div>

                    <div class="row" *ngIf="emplysMstr.length" style="    margin-top: 40px;">
                        <div class="col-sm-3">
                            <label class="col-form-label">Select Reason For Adjustment<span
                                    class="text-danger">*</span></label>
                        </div>
                        <div class="col-sm-7">
                            <select class="form-select floating form-control" [(ngModel)]="adjsd_rsn_id">
                                <option *ngFor="let d of adjstmntsRsns" [value]="d.adjsd_rsn_id">{{d.adjsd_rsn_nm}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="emplysMstr.length" class="submit-section">
                        <button class="btn btn-primary submit-btn" (click)="gotoComponentsSelection()"
                            [disabled]="!selectEmployees.length || !adjsd_rsn_id">Next</button>
                    </div>
                </div>
                <div id="test-l-2" class="content">
                    <div class="alert alert-primary" role="alert">
                        NOTE : Select Payroll Years & Months & Components for which adjustments/arrears to be calculated
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group" style="margin-bottom: 0px;">
                                <label class="col-form-label" style="    margin-top: -20px;
                                margin-bottom: -23px;">Select Payroll Years<span class="text-danger">*</span></label>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group" style="margin-bottom: 0px;">
                                <label class="col-form-label" style="    margin-top: -20px;
                                margin-bottom: -23px;">Select Payroll Months<span class="text-danger">*</span></label>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group" style="margin-bottom: 0px;">
                                <label class="col-form-label" style="    margin-top: -20px;
                            margin-bottom: -23px;">Select Allowances/Deductions<span
                                        class="text-danger">*</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">

                                <!-- <ngx-bootstrap-multiselect class="multple_sel" style="background: white;border-radius: 5px;
                                border: 1px solid #e3e3e3;
                                padding-left: 3px;
                                padding-top: 10px;
                                padding-bottom: 6px;" [options]="years" [texts]="myTexts" [settings]="mySettings"
                                    [(ngModel)]="slctdyear">
                                </ngx-bootstrap-multiselect> -->



                                <nz-select class="multple_sel" [(ngModel)]="slctdyear" [nzMode]="'multiple'"
                                    [nzPlaceHolder]="myTexts.defaultTitle" [nzShowSearch]="true"
                                    [nzSearchPlaceholder]="myTexts.searchPlaceholder"
                                    [nzNotFoundContent]="myTexts.searchEmptyResult"
                                    style="background: white; border-radius: 5px; border: 1px solid #e3e3e3; padding: 10px 3px 6px;">

                                    <nz-option *ngFor="let year of years" [nzValue]="year.id" [nzLabel]="year.name">
                                    </nz-option>

                                </nz-select>

                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">

                                <!-- <ngx-bootstrap-multiselect class="multple_sel" style="background: white;border-radius: 5px;
                                border: 1px solid #e3e3e3;
                                padding-left: 3px;
                                padding-top: 10px;
                                padding-bottom: 6px;" [options]="months" [texts]="myTexts" [settings]="mySettings"
                                    [(ngModel)]="slctdmnth">
                                </ngx-bootstrap-multiselect> -->

                                <nz-select class="multple-sel" [(ngModel)]="slctdmnth" [nzMode]="'multiple'"
                                    [nzPlaceHolder]="'Select months'" [nzShowSearch]="true"
                                    style="background: white; border-radius: 5px; border: 1px solid #e3e3e3; padding: 10px 3px 6px;">
                                    <nz-option *ngFor="let month of months" [nzValue]="month.id" [nzLabel]="month.name">
                                    </nz-option>
                                </nz-select>


                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <ngx-bootstrap-multiselect class="multple_sel" style="background: white;border-radius: 5px;
                                border: 1px solid #e3e3e3;
                                padding-left: 3px;
                                padding-top: 10px;
                                padding-bottom: 6px;" [options]="cmpnts_lst" [texts]="myTexts" [settings]="mySettings"
                                    [(ngModel)]="pyrle_cmpnt_ids">
                                </ngx-bootstrap-multiselect>
                            </div>

                        </div>
                        <div class="col-sm-2" style="margin-top: -5px;">
                            <button class="btn btn-success w-100" (click)="getPayrollComponents()" [disabled]="loader">
                                <span class="spinner-border spinner-border-sm" *ngIf="loader" role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only" *ngIf="loader">Loading...</span>
                                Get Data
                            </button>
                        </div>
                    </div>
                    <div *ngIf="payroll_cmpnts.length" id="data-grid-demo">
                        <dx-data-grid id="gridContainer" [dataSource]="payroll_cmpnts" [remoteOperations]="false"
                            [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true"
                            [rowAlternationEnabled]="true" [showColumnLines]="true" [showRowLines]="true"
                            [showBorders]="true" [showBorders]="true" style="width:100%"
                            [masterDetail]="{ enabled: true, template: 'detail' }">
                            <dxo-header-filter [visible]="true"></dxo-header-filter>
                            <dxo-paging [pageSize]="10"></dxo-paging>
                            <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                                [allowedPageSizes]="[10, 25, 50, 100]" [showInfo]="true">
                            </dxo-pager>
                            <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
                            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
                            <dxo-group-panel [visible]="true"></dxo-group-panel>
                            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
                            <dxo-column-chooser [enabled]="true" mode="select">
                            </dxo-column-chooser>
                            <dxo-filter-row [visible]="true"></dxo-filter-row>
                            <dxi-column *ngFor="let f of columnDefs1" [visible]="!f.hide" [dataField]="f.field"
                                [caption]="f.headerName" [alignment]="f.alignment" [width]="f.width"
                                [allowResizing]="true" [allowFiltering]="f.filter">
                            </dxi-column>
                            <div *dxTemplate="let entCust of 'detail' index as i">
                                <dx-data-grid id="gridContainer" [dataSource]="entCust.data.cmpnts_lst">
                                    <dxi-column *ngFor="let f of columnDefs2" [visible]="!f.hide" [dataField]="f.field"
                                        [caption]="f.headerName" [width]="f.width" [allowResizing]="true"
                                        [alignment]="f.alignment"></dxi-column>
                                    <dxi-column dataField="cmpnt_type" caption="Allowance/Deduction" alignment="left">
                                    </dxi-column>
                                    <dxi-column dataField="pyrle_cmpnt_nm" caption="Allowance/Deduction Name"
                                        alignment="center" filter="true"></dxi-column>
                                    <dxi-column dataField="pyrle_mnth" caption="Payroll Month/Year" alignment="center"
                                        filter="true"></dxi-column>
                                    <dxi-column dataField="pyrle_cmpnt_at" caption=" Existing Payroll Amount"
                                        alignment="center">
                                    </dxi-column>
                                    <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons"
                                        [width]="170" caption="Updated Payroll Amount" cellTemplate="timelineTemplate">
                                        <div *dxTemplate="let data of 'timelineTemplate'">
                                            <input type="number" [(ngModel)]="data.data.updt_at"
                                                (input)="calculate($event.target.value , data.data)">
                                        </div>
                                    </dxi-column>
                                    <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons"
                                        [width]="170" caption="Adjusted Difference amount"
                                        cellTemplate="AdjustedTemplate">
                                        <div *dxTemplate="let data of 'AdjustedTemplate'">
                                            <input type="number" [(ngModel)]="data.data.ttl" disabled>
                                        </div>
                                    </dxi-column>
                                </dx-data-grid>
                            </div>
                        </dx-data-grid>
                    </div>
                    <div *ngIf="payroll_cmpnts.length" class="submit-section" (click)="applyAdjustment()">
                        <button class="btn btn-primary submit-btn" [disabled]="loader">
                            <span class="spinner-border spinner-border-sm" *ngIf="loader" role="status"
                                aria-hidden="true"></span>
                            <span class="sr-only" *ngIf="loader">Loading...</span>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>