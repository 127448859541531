import { Component, OnInit } from '@angular/core';
import { AppsService } from 'src/app/shared/services/apps.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';

import * as moment from 'moment';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-employee-payrolls',
  templateUrl: './employee-payrolls.component.html',
  styleUrls: ['./employee-payrolls.component.css']
})

export class EmployeePayrollsComponent implements OnInit {

  settings = {
    loader: false,
    shwDetails: false,
    shwGrid: false,
    isVisible: false,
    shwRjctClmn: false,
    shwCnclClmn: false,
    shwRjctRsnClmn: false
  }

  data = {
    permissions: { "slct_in": 1, "insrt_in": 1, "updt_in": 1, "dlte_in": 1, "exprt_in": 1 },
    years: [],
    empleLst: [],
    pyrlsLst: [],
    columnDefs: [],
    dsgnsLst: [],
    empleTypeLst: [],
    pyrle_rn_in: false,
    dstrct_lst: [],
    hyrchy_id: null,
    payrlRuns: [],
    columnDefs1: [],
    columnDefs2: [],
    mnth_nm: null,
    sidebarHeading: '',
    emple_type_id: 0,
    pyrle_mnth_rn_id: null,
    excelName: '',
    allEmpleLst: [],
    empleColumnDefs: [],
    AllEmpleLst: [],
    yr_id: null,
    mnth_id: null,
    lctns_id: null,
    rjctn_rsn_tx: null
  }
  searchForm: FormGroup;
  zeroVal = 0;
  constructor(private apisrvc: AppsService, private fb: FormBuilder, private modal: NzModalService, public router: Router,   private message: NzMessageService) {
    let usrData = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(usrData);
    // this.data.hyrchy_id = usrData.hyrchy_id;
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      dsgns_id: [0, [Validators.required]],
      emple_type_id: [0, [Validators.required]],
      yr_id: ['', [Validators.required]],
      dstrt_id : 0,
    });

    const currentYear = new Date().getFullYear();
    this.searchForm.controls['yr_id'].setValue(parseInt(moment(new Date()).format('YYYY')));
    this.getYears();
    this.getDesignations();
    this.getEmployeeTypes();
    this.getDistricts();
    this.getPayrolls()
  }

  ngAfterViewInit() {
  }


  getYears() {
    this.settings.loader = true;
    const rte = `payroll/years`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.years = res['data'];
      setTimeout(() => {
        this.searchForm.controls['yr_id'].setValue(parseInt(moment(new Date()).format('YYYY')));
        this.getDistricts();
      }, 50)
    });
  }

  getDistricts() {
    this.settings.loader = true;
    const rte = `payroll/assigned/districts`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.dstrct_lst = res['data'];
      if (this.data.dstrct_lst.length == 1) {
        this.searchForm.controls['lctn_id'].setValue(this.data.dstrct_lst[0].lctn_id);
        this.getPayrolls();
      } else if (this.data.dstrct_lst.length > 1) {
        this.searchForm.controls['lctn_id'].setValue(this.zeroVal);
        this.getPayrolls();
      }
    });
  }

  getDesignations() {
    this.settings.loader = true;
    const rte = `payroll/designations`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.dsgnsLst = res['data'];
    });
  }

  getEmployeeTypes() {
    this.settings.loader = true;
    const rte = `payroll/employee/types`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      this.data.empleTypeLst = res['data'];
    });
  }

  getPayrolls() {
    this.settings.loader = true;
    const rte = `payroll/list`;
    this.apisrvc.post(this.searchForm.value, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.settings.shwGrid = true;
        this.data.pyrlsLst = res['data'];
        var sno = 0;
        this.data.pyrlsLst.filter((k) => {
          sno++;
          k.sno = sno;
        })
        this.data.columnDefs = [
          { headerName: 'Month/Year', field: 'mnth_yr', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '250' },
          { headerName: 'Payroll generated for employees', field: 'ttl_empls_ct', alignment: 'center', cellClass: 'pm-grid-number-cell'  },
          { headerName: 'Payroll Amount', field: 'ttl_nt_slry_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true },
          { headerName: 'Contractors Employees Count', field: 'ttl_cntrct_emple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell',  sortable: true },
          { headerName: 'Contractors Employees Payroll Amount', field: 'ttl_cntrct_emple_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true },
          { headerName: 'Outsourced Employees Count', field: 'ttl_otsrcd_emple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '150' },
          { headerName: 'Outsourced Employees Payroll Amount', field: 'ttl_otsrcd_emple_at', alignment: 'right', cellClass: 'pm-grid-number-cell',  sortable: true },
          // { headerName: 'Payrolls Sent For Approval', field: 'ttl_cmplt_snd_aprvl_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '200', sortable: true },
          // { headerName: 'Send To Approval Pending ', field: 'ttl_pnd_snd_aprvl_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '200', sortable: true },
        ];
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

      }
    });
  }

  createMessage(type: string, msg: string): void {
    this.message.create(type, msg);
  }


  viewDetails(d) {
    console.log(d.data);
    this.settings.loader = true;
    const rte = `payroll/runs`;
    let data = {
      yr_id: d.data.yr_id,
      mnth_id: d.data.mnth_id,
      lctn_id: d.data.lctn_id,
    }
    this.data.emple_type_id = 0;
    this.getEmployeesPayrollInMonth(d.data.yr_id, d.data.mnth_id, d.data.lctn_id);
    this.data.mnth_nm = d.data.mnth_nm;
    this.data.sidebarHeading = 'Payroll Runs for the month of ' + d.data.mnth_nm;
    this.apisrvc.post(data, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.settings.shwDetails = true;
        this.data.payrlRuns = res['data'];
        if (this.data.payrlRuns && this.data.payrlRuns.length) {


          console.log('length is more')
          this.data.excelName = 'Payroll_' + this.data.payrlRuns[0].mnth_nm + '/' + this.data.payrlRuns[0].yr_id + '_' + this.data.pyrlsLst[0].lctn_nm;
          this.settings.shwRjctClmn = false;
          this.settings.shwCnclClmn = false;
          this.settings.shwRjctRsnClmn = false;
          this.data.payrlRuns.filter((l) => {
            if (l.btn_in == 1) {
              this.settings.shwRjctClmn = true;
              this.settings.shwCnclClmn = true;
            }
            if (l.rjct_in == 1) {
              this.settings.shwRjctRsnClmn = true;
            }
          })
        }
        this.data.columnDefs1 = [
          { headerName: 'Month/Year', field: 'mnth_nm', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '100', },
          { headerName: 'Payroll Generated Date', field: 'pyrle_rn_ts', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '150', },
          { headerName: 'Payroll generated for employees', field: 'ttl_empls_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '100' },
          { headerName: 'Payroll Amount', field: 'ttl_nt_slry_at', alignment: 'right', cellClass: 'pm-grid-number-cell', sortable: true, width: '150' },
          { headerName: 'Contractors Employees Count', field: 'ttl_cntrct_emple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '100', sortable: true },
          { headerName: 'Contractors Employees Payroll Amount', field: 'ttl_cntrct_emple_at', alignment: 'right', cellClass: 'pm-grid-number-cell', width: '150', sortable: true },
          { headerName: 'Outsourced Employees Count', field: 'ttl_otsrcd_emple_ct', alignment: 'center', cellClass: 'pm-grid-number-cell', width: '100' },
          { headerName: 'Outsourced Employees Payroll Amount', field: 'ttl_otsrcd_emple_at', alignment: 'right', cellClass: 'pm-grid-number-cell', width: '150', sortable: true },
        ];
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )


      }
    });
  }

  getEmployeesPayrollInMonth(yr_id, mnth_id, lctn_id) {
    this.settings.loader = true;
    this.data.yr_id = yr_id;
    this.data.mnth_id = mnth_id;
    this.data.lctns_id = lctn_id;
    let data = {
      yr_id: yr_id,
      mnth_id: mnth_id,
      lctn_id: lctn_id,
    }
    const rte = `payroll/employees/locations/details/${this.data.emple_type_id}`;
    this.apisrvc.post(data, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.data.allEmpleLst = res['data'];
        // this.data.AllEmpleLst = res['data'];
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

      }
    });
  }

  changeEmpleType(e) {
    console.log(e);
    this.data.empleLst = [];
    // this.data.allEmpleLst = [];
    // if (this.data.emple_type_id == 0) {
    //   this.data.allEmpleLst = this.data.AllEmpleLst;
    // } else {
    //   var sno = 0;
    //   this.data.AllEmpleLst.filter((l) => {
    //     if (l.emple_type_id == this.data.emple_type_id) {
    //       sno++;
    //       l.sno = sno;
    //       this.data.allEmpleLst.push(l)
    //     }
    //   })
    // }
    let data = {
      yr_id: this.data.yr_id,
      mnth_id: this.data.mnth_id,
      lctn_id: this.data.lctns_id,
    }
    const rte = `payroll/employees/locations/details/${e}`;
    this.apisrvc.post(data, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.data.allEmpleLst = res['data'];
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

      }
    });
  }

  viewEmplyeDetails(d) {
    this.data.allEmpleLst = [];
    console.log(d.data);
    this.data.emple_type_id = 0;
    this.settings.loader = true;
    this.data.pyrle_mnth_rn_id = d.data.pyrle_mnth_rn_id;
    const rte = `payroll/employees/details/${d.data.pyrle_mnth_rn_id}/${this.data.emple_type_id}`;
    this.apisrvc.get(rte).subscribe(res => {

           this.settings.loader = false;

           this.data.empleLst = []
      if (res['status'] === 200) {
        this.data.empleLst = res['data'];
        var sno = 0;
        this.data.empleLst.filter((k) => {
          sno++;
          k.sno = sno;
        })
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

      }
    });
  }

  filterByEmpleType() {
    this.settings.loader = true;
    const rte = `payroll/employees/details/${this.data.pyrle_mnth_rn_id}/${this.data.emple_type_id}`;
    this.apisrvc.get(rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        this.data.empleLst = res['data'];
        var sno = 0;
        this.data.empleLst.filter((k) => {
          sno++;
          k.sno = sno;
        })
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

      }
    });
  }

  hideForm() {
    this.data.rjctn_rsn_tx = null;
    this.settings.shwDetails = false;
    this.data.empleLst = [];
    this.getPayrolls();
  }

  gotogenerate() {
    this.router.navigate([`internal/hrms/generate-payroll`]);
  }

  approve(d) {
    console.log(d.data);
    this.data.pyrle_mnth_rn_id = d.data.pyrle_mnth_rn_id;
    if (d.data.snd_aprvl_in == 0) {
      this.modal.confirm({
        nzTitle: 'Are you sure want to send this payroll to approval ?',
        nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOnOk: () => {
          console.log('Send to approval');
          this.settings.loader = true;
          let data = {
            pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id
          }
          const rte = `payroll/send-to-approval`;
          this.apisrvc.post(data, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
              // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('success', res["message"] )

              this.hideForm();
            } else {
              // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

            }
          });
        },
        nzCancelText: 'No',
        nzOnCancel: () => console.log('Cancel')
      });
    } else if (d.data.lvl_1_aprvl_in == 0) {
      this.modal.confirm({
        nzTitle: 'Are you sure want to approve this payroll?',
        nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOnOk: () => {
          console.log('level 1 Approval');
          this.settings.loader = true;
          let data = {
            pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id
          }
          const rte = `payroll/level1-approval`;
          this.apisrvc.post(data, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
              // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('success', res["message"] )

              this.hideForm();
            } else {
              // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

            }
          });
        },
        nzCancelText: 'No',
        nzOnCancel: () => console.log('Cancel')
      });
    } else if (d.data.lvl_2_aprvl_in == 0) {
      this.modal.confirm({
        nzTitle: 'Are you sure want to approve this payroll?',
        nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOnOk: () => {
          console.log('level 2 Approval');
          this.settings.loader = true;
          let data = {
            pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id
          }
          const rte = `payroll/level2-approval`;
          this.apisrvc.post(data, rte).subscribe(res => {
            this.settings.loader = false;
            if (res['status'] === 200) {
              // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('success', res["message"] )

              this.hideForm();
            } else {
              // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
              this.createMessage('warning', res["message"] )

            }
          });
        },
        nzCancelText: 'No',
        nzOnCancel: () => console.log('Cancel')
      });
    }
  }

  reject(d) {
    // this.modal.confirm({
    //   nzTitle: 'Are you sure want to reject this payroll ?',
    //   nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
    //   nzOkText: 'Yes',
    //   nzOkType: 'primary',
    //   nzOnOk: () => {
    //     this.settings.loader = true;
    //     let data = {
    //       pyrle_mnth_rn_id: d.data.pyrle_mnth_rn_id
    //     }
    //     const rte = `payroll/reject`;
    //     this.apisrvc.post(data, rte).subscribe(res => {
    //       this.settings.loader = false;
    //       if (res['status'] === 200) {
    //         this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //         this.hideForm();
    //       } else {
    //         this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
    //       }
    //     });
    //   },
    //   nzCancelText: 'No',
    //   nzOnCancel: () => console.log('Cancel')
    // });
    this.data.pyrle_mnth_rn_id = d.data.pyrle_mnth_rn_id;
    this.settings.isVisible = true;
  }

  cancel(d) {
    this.modal.confirm({
      nzTitle: 'Are you sure want to cancel this payroll ?',
      nzContent: `<div>Payroll Amount : <b style="color: red;">${d.data.ttl_nt_slry_at}</b></div>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.settings.loader = true;
        let data = {
          pyrle_mnth_rn_id: d.data.pyrle_mnth_rn_id
        }
        const rte = `payroll/cancel`;
        this.apisrvc.post(data, rte).subscribe(res => {
          this.settings.loader = false;
          if (res['status'] === 200) {
            // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('success', res["message"] )

            this.hideForm();
          // } else {
          //   this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

          }
        });
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel')
    });
  }


  handleCancel() {
    this.settings.isVisible = false;
    this.data.rjctn_rsn_tx = null;
  }

  handleOk() {
    this.settings.loader = true;
    let data = {
      pyrle_mnth_rn_id: this.data.pyrle_mnth_rn_id,
      rjctn_rsn_tx: this.data.rjctn_rsn_tx
    }
    const rte = `payroll/reject`;
    this.apisrvc.post(data, rte).subscribe(res => {
      this.settings.loader = false;
      if (res['status'] === 200) {
        // this.toastr.success(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('success', res["message"] )

        this.hideForm();
        this.settings.isVisible = false;
      } else {
        // this.toastr.error(res["message"], '', { timeOut: 10000, positionClass: 'toast-top-right', });
        this.createMessage('warning', res["message"] )

      }
    });
  }
}
