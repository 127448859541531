import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import { TransfereService } from 'src/app/providers/transfer/transfer.service';
import { AppsService } from 'src/app/shared/services/apps.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { CommonModule } from '@angular/common';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-wards-coverage-dashboard',
  templateUrl: './wards-coverage-dashboard.component.html',
  styleUrls: ['./wards-coverage-dashboard.component.scss']
})
export class WardsCoverageDashboardComponent implements OnInit {
  header_label={
    name:"Coverage Dashboard"
  }
  slctdDt = new Date();
  today = new Date();
  dateFormat = 'dd/MM/yyyy';
  curDate; 
  showLdr;
  clntDtls: any;
  userDtls: any;
  tdyCoveredTrips: any = [];
  dbrd_ky = 'all';
  tripsData: any = [];
  columnDefs: any = [];
  rowselected: any;
  curYr: string;
  toptripsData: any = [];
  topTripscolumnDefs: any = [];
  liveId: NodeJS.Timer;
  refresh_tm: string;
  ttlColumnDefs: any[];
  cvrge_prcnt: number;
  avg_cvrg: string;
  constructor(public apiServ: AppsService, public TransfereService: TransfereService, public route: Router) {
    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    this.userDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    console.log(this.userDtls)
    if (this.userDtls.rle_id == 37) {
      // this.TransfereService.setLoclData('ulbDta', this.rowselected);
      // this.route.navigate([`internal/dashboard/ulbtripCvrge-dashboard`]);
      this.rowselected = {
        tnt_id: this.userDtls.tnt_id,
        slctdDt: new Date(),
      }
      this.TransfereService.setLoclData('ulbDta', this.rowselected);
      this.route.navigate([`internal/dashboard/wrdtripCvrge-dashboard`]);
    }
  }

  ngOnInit(): void {
    this.curYr = moment(this.today).format('YYYY');
    setTimeout(() => {
      this.refresh();
    }, 1000)

    this.liveId = setInterval(() => {
      this.refresh();
    }, 15 * 60 * 1000);
  }
  refresh() {
    const date = new Date();

    this.refresh_tm = moment(date).format('DD-MM-YYYY HH:mm:ss');
    console.log(this.refresh_tm)
    if (this.liveId) {
      clearInterval(this.liveId);

      this.liveId = setInterval(() => {
        this.refresh();
      }, 15 * 60 * 1000);

    } else {
      this.liveId = setInterval(() => {
        this.refresh();
      }, 15 * 60 * 1000);
    }
    this.getCoveredTripsCount();
    this.getTripsData();
    this.get_lst7DaysDistanceDta();
    this.getTop5Trips();

  }
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  onChange(e): void {
    this.slctdDt = e;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    this.getCoveredTripsCount();
    this.getTripsData();
    this.get_lst7DaysDistanceDta();
    this.getTop5Trips();

  }
  getCoveredTripsCount(): any {
    this.tdyCoveredTrips = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: 0
    }
    let rte = `dashboard/enterprise/wards/getCoveredTripsCount/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tdyCoveredTrips = res['data'];
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  getTripsData() {
    this.tripsData = [];
    this.ttlColumnDefs = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: 0
    }
    let rte = `dashboard/enterprise/wards/getCoveredTripsDetails`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.tripsData = res['data'];
        console.log(this.tripsData)
        this.cvrge_prcnt = 0
        this.tripsData.forEach(e => {
          this.cvrge_prcnt += e.coverage_per?e.coverage_per:0;
          console.log(this.cvrge_prcnt)

        })
        this.avg_cvrg = (this.cvrge_prcnt / this.tripsData.length).toFixed(2)
        console.log(this.avg_cvrg)
        this.columnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
          { headerName: 'Zone', field: 'ZONE',  },
          { headerName: 'Total Vehicles', field: 'TOT_VEHICLES', alignment: 'left' },
          { headerName: 'Total Wards', field: 'TOTAL_SVMS', alignment: 'left' },
          { headerName: 'Total Trips', field: 'TOTAL_TRIPS',  },
          { headerName: 'Completed Trips', field: 'OPERATED', },
          { headerName: 'Started Trips', field: 'STARTED_TRIP', },
          // { headerName: 'Initiated but Not Started Trips', field: 'INITIATED_BUT_NOT_STARTED', alignment: 'Center', width: 150 },
          // { headerName: 'Completed Trips', field: 'COMPLETED', alignment: 'Center', width: 150 },
          { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED',  },
          { headerName: 'Travel Distance(KM)', field: 'trvl_dist', },
          { headerName: 'Coverage %', field: 'coverage_per',  },
        ]
      }
      this.ttlColumnDefs = [{ headerName: 'Total Vehicles', field: 'TOT_VEHICLES', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Wards', field: 'TOTAL_SVMS', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Total Trips', field: 'TOTAL_TRIPS', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Completed Trips', field: 'OPERATED', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Started Trips', field: 'STARTED_TRIP', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Route Deviated', field: 'ROUTE_DEVIATED', alignment: 'Center', summaryType: 'sum' },
      { headerName: 'Travel Distance', field: 'trvl_dist', alignment: 'Center', summaryType: 'sum', format: { type: 'fixedPoint', precision: 2 } },
      { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center', summaryType: 'avg', format: { type: 'fixedPoint', precision: 2 } },

      ]

      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });
  }
  onCellPrepared(colDef, e) {

    if (e.rowType === 'data' && e.row.data && e.column.dataField == 'ZONE') {
      e.cellElement.style.color = 'rgb(0 142 255)';
      e.cellElement.style.fontWeight = 500;
      e.cellElement.style.borderRadius = '10px';
      e.cellElement.style.cursor = 'pointer';
    }

  }
  devCellclk(devclmns, dvclkent) {
    if (dvclkent.columnIndex == 1) {
      if (dvclkent.data.ZONE != undefined) { 
        this.rowselected = dvclkent.data;
        this.rowselected.slctdDt = this.slctdDt
        this.TransfereService.setLoclData('ulbDta', this.rowselected);
        this.TransfereService.setLoclData('trpDta', this.tripsData);
        console.log(this.rowselected)
        this.route.navigate([`internal/dashboard/wrdtripCvrge-dashboard`]);
      }
    }
  }
  // lst7DayDstnc;
  get_lst7DaysDistanceDta(): any {
    console.log('function called in primary dashoard')
    let chart = am4core.create("coverageChart", am4charts.XYChart);
    chart.data = [];
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD');
    var postslctdDt = {
      dt: this.curDate,
    }
    let rte = `dashboard/enterprise/wards/trpsCvrgeLast7Days/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      chart.data = res['data'];
      console.log(chart.data);

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      console.log(categoryAxis)
      categoryAxis.dataFields.category = "dt";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.title.text = ` ----- Date(${this.curYr})----- `;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      valueAxis.title.text = " ----- Covered % ----- ";
      chart.colors.list = [
        am4core.color("#f7babd"),
        // am4core.color("#7d64c4"),
      ]
      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "dt";
        series.name = name;
        series.columns.template.tooltipText = "{name} Vehicles in {categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = false;

        let columnTemplate1 = series.columns.template;
        columnTemplate1.strokeWidth = 1;
        let valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueY}";
        valueLabel.label.fontSize = 15;
        valueLabel.label.dy = 10;
        columnTemplate1.column.cornerRadiusTopLeft = 5;
        columnTemplate1.column.cornerRadiusTopRight = 5;
        columnTemplate1.strokeOpacity = 1;

        let categoryLabel = series.bullets.push(new am4charts.LabelBullet());

      }

      createSeries("coverage_per", "Coverage");
    });
  }
  getTop5Trips() {
    this.toptripsData = [];
    this.showLdr = true;
    this.curDate = moment(this.slctdDt).format('YYYY-MM-DD')
    var postslctdDt = {
      dt: this.curDate,
      slctd_tnt: 0
    }
    let rte = `dashboard/enterprise/wards/getTop5Coverages/${this.dbrd_ky}`;
    this.apiServ.post(postslctdDt, rte).subscribe((res) => {
      if (res['status'] == 200) {
        this.toptripsData = res['data'];

        this.topTripscolumnDefs = [
          { headerName: 'S.No', field: 'sno', alignment: 'center', width: 50, filter: false },
          // { headerName: 'ULB', field: 'clnt_nm', alignment: 'left', width: 200 },
          { headerName: 'Zone', field: 'asrt_grp_nm', alignment: 'left', width: 200 },
          { headerName: 'Coverage %', field: 'coverage_per', alignment: 'Center', width: 'auto' },
        ]
      }
      this.showLdr = false;
    }, (err) => {
      // console.log(err);
    });

  }

  onExporting1(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Zone wise Trip Coverage Status.xlsx');
      });
    });
  }

  onExporting2(e: DxDataGridTypes.ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Employees');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Zone wise Top 10 Coverages.xlsx');
      });
    });
  }

  ngOnDestroy() {
    clearInterval(this.liveId);
  }
}
