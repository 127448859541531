import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import { animate } from '@angular/animations';
import { Location, DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormGroup, FormControl, Validator, Validators } from "@angular/forms";
import * as moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import 'leaflet-rotatedmarker';

import 'leaflet.animatedmarker/src/AnimatedMarker';
import 'leaflet.markercluster';

am4core.useTheme(am4themes_frozen);
am4core.useTheme(am4themes_animated); 
@Component({
  selector: 'app-live-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.scss']
})
export class LiveTrackingComponent implements OnInit {
  plypoint1: any = [];
  fncepolygon: L.Polygon<any>;
  chart: am4charts.XYChart;
  chart1: am4charts.XYChart;
  livemap;
  isCollapsed = false;
  dropdownSettings: any = {};
  isRefresh = true;
  opnSdebar: boolean;
  livetrackform: FormGroup;
  activeGroups: any = [];
  points1: any = [];
  locModel: any = [];
  refreshmarkersList: any = [];
  locations: any = [];
  _lastIndex: any = [];
  pointMarker;
  deletemarkers: any = [];
  ShowFilter = false;
  assrtGroupList: any = [];
  asrtTypeList: any = [];
  asrt_type_id: any;
  liveId: any;
  asrt_ctgry_id: any;
  gtdrctns: any = [];
  asrtCtgryList: any = [];
  allVhcls: any = [];
  _unspiderfy: any;
  mov_marker: any = [];
  asrt_grp_id: any;
  vehiclesList: any = [];
  markers: any = [];
  animatedMarker;
  markersList: any = [];
  bounds: any = [];
  masterVehicleMarkers: any = [];
  totalOfflineVehicleMarkers: any = [];
  offlineVehicles: any = [];
  data: any;
  tab = 1;
  m_prv_lat: any = [];
  m_prv_lng: any = [];
  showOffline: any = [];
  repairVehicles: any = [];
  notOpratedVehicles: any = [];
  contractExpired: any = [];
  vhclCount: any;
  mcg;
  stslist: any = [];
  tabs; vehDta;
  seletedIndex = 0;
  shwDta: boolean = true;
  shwMrkerDta: boolean = false;
  selectedtenants: any;
  clntDtls: any = [];
  tntLst: any = [];
  asrtGrps: any = [];
  asrtctgrs: any = [];
  vendorList: any = [];
  selectedvndr: any;
  curYr: any;
  today = new Date();
  visible = false; selectedVehicle;
  userDtls: any;
  showVndr: boolean = false;
  clap_in: number;
  ShowClap: boolean = false;
  usrDtls: any;
  showAll: boolean = false;
  slctdDfltTnt: any;
  isUrban: boolean;
  lbl: string;
  grpLbl: string;
  fncsGrpId: any;
  fncsLst: any;
  slctdDt: any;
  currentTimeStmp: any;
  recievedTime: any;
  diffTimeInMin: any;
  diffTimeInHrs: any;
  diffTimeInDys: any;
  fnclocModel: any = [];
  iconAngle;
  constructor(private apiS: ApiService, private router: Router, private ref: ChangeDetectorRef, public datepipe: DatePipe, private message: NzMessageService) {
    this.curYr = moment(this.today).format('YYYY');
    if (this.liveId) {
      clearInterval(this.liveId);
    }

    this.clntDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.clntDtls)
    this.tntLst = this.clntDtls[0].tnts;
    this.usrDtls = JSON.parse(localStorage.getItem('wto_w_user'));
    this.clap_in = this.usrDtls.clap_in;
    if (this.clap_in == 1) {
      this.ShowClap = true;
    }
    console.log('seletedIndex --------------- ', this.seletedIndex);
    console.log(this.tntLst.length);
    if(this.clntDtls[0].clnt_id==7){
      if (this.tntLst[0].urban_in == 1) {
        this.isUrban = true;
        this.lbl = 'ULB';
        this.grpLbl = 'Vehicle Group';
  
      } else {
        this.isUrban = false;
        this.lbl = 'Mandal';
        this.grpLbl = 'Panchayati';
      }
    }else{
      this.grpLbl = 'Vehicle Group';
    }

    if (this.tntLst.length == 1) {
      this.selectedtenants = this.tntLst[0].tnt_id;
      this.slctdDfltTnt = this.selectedtenants;
      console.log(this.selectedtenants);
      this.showAll = false;
    } else {
      this.selectedtenants = 0;
      this.slctdDfltTnt = this.selectedtenants;
      console.log(this.selectedtenants);
      this.showAll = true;
    }

    this.livetrackform = new FormGroup({
      lctn: new FormControl(''),
      lctnFnce: new FormControl(''),
      vhcl: new FormControl(''),
      ctrgy: new FormControl(''),
      typ: new FormControl(''),
    });

  }
  ngOnInit() {
    this.opnSdebar = true;
    this.selectedvndr = 0;
    this.selectedtenants = 0;
    this.getvndrsList();
    this.initialize();
 


    this.liveId = setInterval(() => {
      if (!this.isRefresh) return;
      this.refreshVehicleMarkers();
    }, 12000);


  }
  ontntSelects(value) {
    console.log(value);
    this.selectedtenants = value;
    // this.selectedvndr = value;

    // console.log(this.deletemarkers);
    if (this.mcg) { this.livemap.removeLayer(this.mcg); }

    if (this._lastIndex) {
      for (var k = 0; k < this._lastIndex.length; k++) {
        this.livemap.removeLayer(this._lastIndex[k]);
      }
    }

    if (this.mov_marker) {
      for (var i = 0; i < this.mov_marker.length; i++) {
        // gmc.addTo(null);
        if (this.mov_marker[i] != undefined) {
          // gmc.clearLayers();
          this.livemap.removeLayer(this.mov_marker[i]);
        }
      }

    }

    if (this.deletemarkers) {
      for (var i = 0; i < this.deletemarkers.length; i++) {
        // gmc.addTo(null);

        // gmc.clearLayers();
        this.livemap.removeLayer(this.deletemarkers[i]);

      }

    }
    // this._lastIndex = [];
    // this.mov_marker = [];
    // this.vendorList = [];
    // this.assrtGroupList = [];
    // this.markersList = [];
    // this.tnt_id = value;
    // 15.912327, 79.739238,7
    // 16.501894, 80.642869
    if (value == 0) {
      console.log(this.tntLst,"--------this.tntLst--------") //#change
      this.livemap.setView([17.6905, 83.2123], 13)
      this.selectedtenants = 0;
    } else if (value == 1) {
      // this.tntLst = [];
    } else {
      this.selectedtenants = value;
      for (var i = 0; i < this.tntLst.length; i++) {
        if (this.tntLst[i].tnt_id == this.selectedtenants) {
          this.livemap.setView([this.tntLst[i].lat, this.tntLst[i].lng], 13)

        }
      }
    }
    this.getvndrsList();

    this.apiS.get(`playback/vehicle/groups/${this.selectedtenants}`).subscribe((res) => {
      console.log(res)
      if (res['status'] == 200) {
        this.assrtGroupList = res['data'];
      }
    }, (err) => {
      console.log("In errorr");
    })


    this.apiS.get(`playback/landmark/groups/${this.selectedtenants}`)
      .subscribe((res) => {

        this.activeGroups = res['data'];
        console.log(this.activeGroups);
      }, (err) => {
        console.log("In errorr");
      });

    // this.apiS.get(`playback/vehicles/master/${this.selectedtenants}`)
    this.apiS.get(`playback/vehicles/master/vehicles/${this.selectedtenants}/vendor/${this.selectedvndr}`)


      .subscribe((res) => {

        this.markersList = res['data'];
        console.log(this.markersList);
        this.vhclCount = res['data'].length;
        this.setVehicleMarkers();
      }, (err) => {
        console.log("In errorr");
      });

    this.apiS.get('playback/location/points')
      .subscribe((res) => {
        this.points1 = res['data'];
        console.log(this.points1);
      }, (err) => {
        console.log("In errorr");
      });


  }
  // ngAfterViewInit() {

  // }
  getvndrsList() {
    if (this.usrDtls.dsgns_id == 1000032) {
      this.selectedvndr = this.usrDtls.vndr_id;
      this.showVndr = true;
      this.apiS.get(`billing/vendors/list/${this.selectedvndr}/byId`).subscribe((res) => {
        console.log(res)
        if (res['status'] == 200) {
          this.vendorList = res['data'];
          this.getAssertList()

        }
      }, (err) => {
        console.log("In errorr");
      })
    } else if (this.clap_in == 0 || this.clap_in == 2) {
      this.vendorList = [];
      this.selectedvndr = 0;
      this.getAssertList()

    } else {
      this.apiS.get(`billing/vehicle-wise/vendors/list/${this.selectedtenants}`).subscribe((res) => {
        if (res['status'] === 200) {
          this.vendorList = res['data'];
          // this.USER_PERMISSIONS = res['perm'][0];
          if (this.vendorList.length > 1) {
            this.selectedvndr = 0;
          } else {
            this.selectedvndr = this.vendorList[0].vndr_id;
          }
          this.getAssertList()

        }
      }, (err) => {
        console.log('In errorr');
      });
    }
  }
  getdistanceChart() {

    // chart code
    let chart1 = am4core.create("tripChart", am4charts.XYChart);


    // Add data
    chart1.data = [{
      "date": "30-06",
      "distance": 302
    }, {
      "date": "01-07",
      "distance": 188
    }, {
      "date": "02-07",
      "distance": 180
    }, {
      "date": "03-07",
      "distance": 132
    }, {
      "date": "04-07",
      "distance": 132
    }, {
      "date": "05-07",
      "distance": 132
    }, {
      "date": "06-07",
      "distance": 132
    }];
    console.log(chart1.data);
    // Create axes
    // this.lst7DayDstnc = this.chart.data;
    // console.log(chart.data);
    let categoryAxis = chart1.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.title.text = ` ----- Date (${this.curYr})  ----- `;
    // categoryAxis.renderer.labels.template.rotation = -45;

    let valueAxis = chart1.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = " ----- Distance ----- ";

    // Create series
    let series = chart1.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "distance";
    series.dataFields.categoryX = "date";
    series.name = "Date";
    series.columns.template.tooltipText = "Distance travelled in {categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = .8;
    series.columns.template.fill = am4core.color("rgb(129, 199, 132)");
    series.columns.template.stroke = am4core.color("rgb(56, 142, 60)");
    let columnTemplate1 = series.columns.template;
    columnTemplate1.strokeWidth = 1;
    columnTemplate1.strokeOpacity = 1;

    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{distance}";
    valueLabel.label.fontSize = 15;

    var columnTemplate = series.columns.template;
    columnTemplate.width = 30;
    columnTemplate.column.cornerRadiusTopLeft = 20;
    columnTemplate.column.cornerRadiusTopRight = 20;
    columnTemplate.strokeOpacity = 0;
  };

  getTripsChart() {

    // chart code
    let chart = am4core.create("distanceChart", am4charts.XYChart);

    // let chart = am4core.create("tripChart", am4charts.XYChart);
    // Add data
    chart.data = [{
      "date": "30-06",
      "trip": 14
    }, {
      "date": "01-07",
      "trip": 36
    }, {
      "date": "02-07",
      "trip": 17
    }, {
      "date": "03-07",
      "trip": 34
    }, {
      "date": "04-07",
      "trip": 22
    }, {
      "date": "05-07",
      "trip": 132
    }, {
      "date": "06-07",
      "trip": 15
    }];
    console.log(chart.data);
    // Create axes
    // this.lst7DayDstnc = this.chart.data;
    // console.log(chart.data);
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.title.text = ` ----- Date (${this.curYr})  ----- `;
    // categoryAxis.renderer.labels.template.rotation = -45;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = " ----- Trips ----- ";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "trip";
    series.dataFields.categoryX = "date";
    series.name = "Date";
    series.columns.template.tooltipText = "Trips travelled in {categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = .8;
    series.columns.template.fill = am4core.color("#ff9800");
    series.columns.template.stroke = am4core.color("rgb(236 144 10)");
    let columnTemplate1 = series.columns.template;
    columnTemplate1.strokeWidth = 1;
    columnTemplate1.strokeOpacity = 1;

    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "{trip}";
    valueLabel.label.fontSize = 15;

    var columnTemplate = series.columns.template;
    columnTemplate.width = 30;
    columnTemplate.column.cornerRadiusTopLeft = 20;
    columnTemplate.column.cornerRadiusTopRight = 20;
    columnTemplate.strokeOpacity = 0;
  };


  tabchange(evnt) {
    this.seletedIndex = evnt.index;
    console.log(this.seletedIndex);
    // console.log(this.allVhcls);
    this.stslist = [];
    // this.stslist = this.vehiclesList;
    if (this.seletedIndex == 0) {
      this.tabs[0].actv_in = 1;
      this.tabs[1].actv_in = 0;
      this.tabs[2].actv_in = 0;
      this.tabs[3].actv_in = 0;
      this.stslist = this.vehiclesList;
      let counter = 0;
      let counter1 = 0;
      this.stslist.filter((k) => {
        counter1 = 0;
        k['sno'] = ++counter;
      });
    } else if (this.seletedIndex == 1) {
      this.tabs[0].actv_in = 0;
      this.tabs[1].actv_in = 1;
      this.tabs[2].actv_in = 0;
      this.tabs[3].actv_in = 0;
      this.stslist = this.offlineVehicles;
      let counter = 0;
      let counter1 = 0;
      this.stslist.filter((k) => {
        counter1 = 0;
        k['sno'] = ++counter;
      });
    } else if (this.seletedIndex == 2) {
      this.tabs[0].actv_in = 0;
      this.tabs[1].actv_in = 0;
      this.tabs[2].actv_in = 1;
      this.tabs[3].actv_in = 0;
      this.stslist = this.repairVehicles;
      let counter = 0;
      let counter1 = 0;
      this.stslist.filter((k) => {
        counter1 = 0;
        k['sno'] = ++counter;
      });
    } else if (this.seletedIndex == 3) {
      this.tabs[0].actv_in = 0;
      this.tabs[1].actv_in = 0;
      this.tabs[2].actv_in = 0;
      this.tabs[3].actv_in = 1;
      this.stslist = this.notOpratedVehicles;
      let counter = 0;
      let counter1 = 0;
      this.stslist.filter((k) => {
        counter1 = 0;
        k['sno'] = ++counter;
      });
    }

  }
  closeSidebar() {
    this.isCollapsed = true;
    this.opnSdebar = false;
    this.shwDta = false;
    this.shwMrkerDta = false;

  }

  openSidebar() {
    this.isCollapsed = false;
    this.opnSdebar = true;
    this.shwDta = true;
    this.shwMrkerDta = false;


  }
  initialize = function () {
    this.userDtls = JSON.parse(localStorage.getItem('wto_clients'));
    console.log(this.userDtls);
    //----------Map Initialization (Default openstreetmap  Layer) -- Leaflet OS Playback----------//
    try {

      this.livemap = L.map('livemap', {
        center: [this.userDtls[0].lat, this.userDtls[0].lng],
        zoom: 13
      });

      // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   // maxZoom: 15,
      //   // minZoom: 3,
      //   attribution: ''
      // });

      const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 22,
        minZoom: 3,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
      tiles.addTo(this.livemap);
      if (this.slctdDfltTnt) {
        this.ontntSelects(this.slctdDfltTnt);
      }

      // this.livemap.setView([this.userDtls[0].lat, this.userDtls[0].lng], 13)

    } catch (e) {
      console.log("please check map Initialization of leaflet:" + e);
    }
    // this.setVehicleMarkers();

  };
  event;
  ngOnDestroy() {
    clearInterval(this.liveId);
  }
  onLocationSelect(evt) {
    this.fncsGrpId = evt;
    if(this.fncsGrpId > 0)
     this.fncsByGrp(this.fncsGrpId);
  }
  fncsByGrp(grp_id) {
    this.apiS.get(`playback/landmark/bygroups/${grp_id}`).subscribe((res) => {
      console.log(res)
      this.fncsLst = res['data'];
      console.log(this.fncsLst);
    }, (err) => {
      console.log("In errorr");
    })
  }
  getLocOnMap() {
    this.locations = [];

    if (this.pointMarker) {
      for (var k = 0; k < this._lastIndex.length; k++) {
        this.livemap.removeLayer(this._lastIndex[k]);
      }
    }

    if (this.plypoint1) {
      for (var k = 0; k < this.plypoint1.length; k++) {
        this.livemap.removeLayer(this.plypoint1[k].ply);
      }
    }
    if (this.fnclocModel) {
      if (this.fnclocModel == 'select') {
        for (var j = 0; j < this.fncsLst.length; j++) {

          for (var k = 0; k < this.points1.length; k++) {
            if (this.fncsLst[j].fnce_grp_id == this.points1[k].fnce_grp_id) {
              console.log(this.fncsLst[j].fnce_grp_id == this.points1[k].fnce_grp_id);

              this.locations.push(this.points1[k]);
            }
          }

        }
      }
      else if (this.fnclocModel == 'deselect') {
        this.locations = [];
      } else {

        for (var j = 0; j < this.fncsLst.length; j++) {

          if (this.fnclocModel == this.fncsLst[j].fnce_id) {

            for (var k = 0; k < this.points1.length; k++) {
              if (this.fncsLst[j].fnce_id == this.points1[k].fnce_id) {
                console.log(this.fncsLst[j].fnce_id == this.points1[k].fnce_id);

                this.locations.push(this.points1[k]);
              }
            }

          }
        }
      }

      for (var l = 0; l < this.locations.length; l++) {
        // console.log("In location1");

        if (!this.locations[l].fncs_icn_pth) {
          // console.log("In location2");

          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/fenceIcon.png',
            iconSize: [32, 30],
            iconAnchor: [10, 39],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
        } else if (this.locations[l].fncs_icn_pth == 'dumperBin') {
          // console.log("In location3");

          if (this.locations[l].covr_status == 1) {
            // console.log("In location4");
            var icn = "_r_m";
          } else {
            // console.log("In location5");

            var icn = "_o_m";
          }
          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
            iconSize: [32, 30],
            iconAnchor: [22, 45],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });
        } else {
          // console.log("In location6");

          if (this.locations[l].covr_status == 1) {
            // console.log("In location7");

            var icn = "_r_m";
          } else {
            // console.log("In location8");

            var icn = "_o_m";
          }
          var thisIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
            // iconSize: [32, 30],
            // // iconAnchor: [22, 45],
            // iconAnchor: [16, 28],
            // // popupAnchor: [1, -34],
            // shadowSize: [41, 41]
            iconSize: [33, 33],
            iconAnchor: [16, 28],
          });
        }

        if (this.locations[l].lat && this.locations[l].lng) {
          this.pointMarker = L.marker([this.locations[l].lat, this.locations[l].lng], {
            draggable: false, // Make the icon dragable
            // title: this.locations[l].fnce_nm, // Add a title
            opacity: 5,
            icon: thisIcon
          }).addTo(this.livemap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7, offset: [0, -20] }).openTooltip();;
          var circleMarker = L.circle([this.locations[l].lat, this.locations[l].lng], { radius: this.locations[l].rds_ct }).addTo(this.livemap);
          circleMarker.setStyle({ fillColor: '#f55555', stroke: false, fillOpacity: 0.07 })

          this._lastIndex.push(this.pointMarker);
          this._lastIndex.push(circleMarker);
          if (this.locations[l].fnce_arc_lne_tx) {
            this.fncepolygon = L.polygon(JSON.parse(this.locations[l].fnce_arc_lne_tx) as []
              , { color: this.locations[l].fncs_clr_cd }).addTo(this.livemap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
            this.plypoint1.push({ ply: this.fncepolygon, fnce_id: this.locations[l].fnce_id });
            this.livemap.fitBounds(this.fncepolygon.getBounds());
          }
        }
      }
    }
  }
  // updt() {
  //   // this.locModel=locModel;
  //   console.log(this.locModel)    // mcg.clearLayers();
  //   this.locations = [];
  //   if (this.pointMarker) {
  //     for (var k = 0; k < this._lastIndex.length; k++) {
  //       this.livemap.removeLayer(this._lastIndex[k]);
  //     }
  //   }

  //   if (this.plypoint1) {
  //     for (var k = 0; k < this.plypoint1.length; k++) {
  //       this.livemap.removeLayer(this.plypoint1[k].ply);
  //     }
  //   }
  //   if (this.locModel) {

  //     //   this.locModel.map((val) => {
  //     if (this.locModel == 'select') {
  //       for (var j = 0; j < this.activeGroups.length; j++) {

  //         for (var k = 0; k < this.points1.length; k++) {
  //           if (this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id) {
  //             console.log(this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id);

  //             this.locations.push(this.points1[k]);
  //           }
  //         }

  //       }
  //     }
  //     else if (this.locModel == 'deselect') {
  //       this.locations = [];
  //     }
  //     else {

  //       for (var j = 0; j < this.activeGroups.length; j++) {

  //         if (this.locModel == this.activeGroups[j].fnce_grp_id) {
  //           // console.log("In iff2");
  //           // console.log(this.locModel[i], this.activeGroups[j].fnce_grp_id);

  //           for (var k = 0; k < this.points1.length; k++) {
  //             if (this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id) {
  //               console.log(this.activeGroups[j].fnce_grp_id == this.points1[k].fnce_grp_id);

  //               this.locations.push(this.points1[k]);
  //             }
  //           }

  //         }
  //       }
  //     }
  //     // }
  //     // console.log(this.locations);

  //     for (var l = 0; l < this.locations.length; l++) {
  //       // console.log("In location1");

  //       if (!this.locations[l].fncs_icn_pth) {
  //         // console.log("In location2");

  //         var thisIcon = new L.Icon({
  //           iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/fenceIcon.png',
  //           iconSize: [32, 30],
  //           iconAnchor: [10, 39],
  //           popupAnchor: [1, -34],
  //           shadowSize: [41, 41]
  //         });
  //       } else if (this.locations[l].fncs_icn_pth == 'dumperBin') {
  //         // console.log("In location3");

  //         if (this.locations[l].covr_status == 1) {
  //           // console.log("In location4");
  //           var icn = "_r_m";
  //         } else {
  //           // console.log("In location5");

  //           var icn = "_o_m";
  //         }
  //         var thisIcon = new L.Icon({
  //           iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
  //           iconSize: [32, 30],
  //           iconAnchor: [22, 45],
  //           popupAnchor: [1, -34],
  //           shadowSize: [41, 41]
  //         });
  //       } else {
  //         // console.log("In location6");

  //         if (this.locations[l].covr_status == 1) {
  //           // console.log("In location7");

  //           var icn = "_r_m";
  //         } else {
  //           // console.log("In location8");

  //           var icn = "_o_m";
  //         }
  //         var thisIcon = new L.Icon({
  //           iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.locations[l].fncs_icn_pth + icn + '.png',
  //           iconSize: [32, 30],
  //           iconAnchor: [22, 45],
  //           popupAnchor: [1, -34],
  //           shadowSize: [41, 41]
  //         });
  //       }

  //       if (this.locations[l].lat && this.locations[l].lng) {
  //         this.pointMarker = L.marker([this.locations[l].lat, this.locations[l].lng], {
  //           draggable: false, // Make the icon dragable
  //           title: this.locations[l].fnce_nm, // Add a title
  //           opacity: 5,
  //           icon: thisIcon
  //         }).addTo(this.livemap);
  //         var circleMarker = L.circle([this.locations[l].lat, this.locations[l].lng], {radius: this.locations[l].rds_ct}, {
  //           stroke: false,
  //           fillColor: '#f55',
  //           fillOpacity: 0.06,
  //         });
  //         this._lastIndex.push(this.pointMarker);
  //         this._lastIndex.push(circleMarker);
  //         if (this.locations[l].fnce_arc_lne_tx) {
  //           this.fncepolygon = L.polygon(JSON.parse(this.locations[l].fnce_arc_lne_tx) as []
  //             , { color: this.locations[l].fncs_clr_cd }).addTo(this.livemap).bindTooltip(this.locations[l].fnce_nm, { permanent: true, opacity: 0.7 }).openTooltip();
  //           this.plypoint1.push({ ply: this.fncepolygon, fnce_id: this.locations[l].fnce_id });
  //           this.livemap.fitBounds(this.fncepolygon.getBounds());
  //         }
  //       }
  //     }
  //   }
  // }

  getCatLists(grpId) {

    // console.log("gettttttttttttttttttttttttttttt");
    console.log(grpId)

    this.asrt_grp_id = grpId;
    this.asrtGrps = grpId;
    this.asrtCtgryList = [];
    this.asrt_ctgry_id = 0;
    console.log(this.asrtGrps);
    console.log(grpId);

    if (this.asrtGrps == 'select') {
      this.asrtGrps = 0;
      this.asrt_grp_id = 0;
      var asrt_data = {
        "asrt_grp": this.asrtGrps
      };
      // console.log(asrt_data);
      this.apiS.post(asrt_data, 'playback/categories/assets')
        .subscribe((res) => {
          this.asrtCtgryList = res['data'];
          console.log(this.asrtCtgryList);
        }, (err) => {
          console.log("In errorr");
        });
    }
    else if (this.asrtGrps == 'deselect') {
      this.asrtGrps = [];
      return;
    } else if (this.clap_in == 1) {
      this.asrtGrps = [];
      this.asrt_grp_id = 0;
      return;
    }

    else {
      var asrt_data = {
        "asrt_grp": this.asrtGrps
      };
      // console.log(asrt_data);
      this.apiS.post(asrt_data, 'playback/categories/assets')
        .subscribe((res) => {
          this.asrtCtgryList = res['data'];
          console.log(this.asrtCtgryList);
        }, (err) => {
          console.log("In errorr");
        });
    }


    // this.getTypeList();
    this.getAssertList();

  };
  getTypeList(catId) {
    this.asrt_ctgry_id = catId;
    this.asrtctgrs = catId;

    if (this.asrtctgrs == 'select') {
      this.asrtctgrs = 0;
      this.asrt_ctgry_id = 0;
      // var asrt_data = {
      //   "asrt_grp": this.asrtGrps
      // };
      // console.log(asrt_data);
    }

    this.getAssertList();
  };


  // Sorting vehicle markers on selection of category type
  getAssertList() {
    if (this.mcg) { this.livemap.removeLayer(this.mcg); }

    // console.log(this.mov_marker.length,this.deletemarkers.length);
    for (var j = 0; j < this.deletemarkers.length; j++) {
      this.livemap.removeLayer(this.deletemarkers[j]);
    }

    // console.log(this.asrt_ctgry_id,this.asrt_grp_id);
    this.markersList = [];
    // this.apiS.get(`playback/vehicles/master/${this.selectedtenants}`)
    this.apiS.get(`playback/vehicles/master/vehicles/${this.selectedtenants}/vendor/${this.selectedvndr}`) 
      .subscribe((res) => {
        this.allVhcls = res['data'];
        console.log(this.allVhcls)
        // console.log(gmc); 
        // gmc.clearLayers();

        //console.log(this._unspiderfy)
        if (this._unspiderfy) {
          this._unspiderfy();
        }

        for (var j = 0; j < this.deletemarkers.length; j++) {
          this.livemap.removeLayer(this.deletemarkers[j]);
        }
        for (var i = 0; i < this.mov_marker.length; i++) {
          // gmc.addTo(null);
          if (this.mov_marker[i] != undefined) {
            // gmc.clearLayers();
            this.livemap.removeLayer(this.mov_marker[i]);
          }
        }
        if ((this.asrt_ctgry_id == 0 || this.asrt_ctgry_id == null) &&
          (this.asrt_grp_id == 0 || this.asrt_grp_id == null)) {
          this.vehiclesList = this.allVhcls;
          this.markers = [];

        } else {
          this.vehiclesList = [];
          this.markers = [];

          for (var i = 0; i < this.allVhcls.length; i++) {
            if (this.allVhcls[i].asrt_grp_id == this.asrt_grp_id &&
              (this.asrt_ctgry_id == undefined || this.asrt_ctgry_id == null || this.asrt_ctgry_id == 0)) {

              this.vehiclesList.push(this.allVhcls[i]);
            } else if (this.allVhcls[i].asrt_grp_id == this.asrt_grp_id &&
              (this.asrt_ctgry_id == undefined || this.asrt_ctgry_id == null || this.asrt_ctgry_id == 0)) {

              this.vehiclesList.push(this.allVhcls[i]);
            } else if (this.allVhcls[i].asrt_ctgry_id == this.asrt_ctgry_id &&
              this.allVhcls[i].asrt_grp_id == this.asrt_grp_id) {

              this.vehiclesList.push(this.allVhcls[i]);
            } else if (this.allVhcls[i].asrt_ctgry_id == this.asrt_ctgry_id &&
              (this.asrt_grp_id == 0 || this.asrt_grp_id == undefined || this.asrt_grp_id == null)) {
              this.vehiclesList.push(this.allVhcls[i]);
            }
          }
          for (i = 0; i < this.vehiclesList.length; i++) {
            this.vehiclesList[i].checked = true;
          }
        }
        this.markersList = this.vehiclesList;
        // console.log("****************");
        console.log(this.markersList);
        // var count=0;
        // for(var j=0;j<this.stslist.length;j++){
        //   count++;
        //   this.stslist['id']=count;
        // }
        this.stslist = this.vehiclesList;
        let counter = 0;
        let counter1 = 0;
        this.stslist.filter((k) => {
          counter1 = 0;
          k['sno'] = ++counter;
        });
        this.setVehicleMarkers();
      }, (err) => {
        console.log("In errorr");
      });
      console.log(this.stslist,'-------this.stslist------'); //#change
  }

  onvndrSelect(value) {

    if (value == 0) {
      this.selectedvndr = 0;
    } else if (value == 1) {
      // this.tntLst = [];
    } else {
      this.selectedvndr = value;
    }
    this.getAssertList();
  }
  getEahVehicle(data) {
    console.log(data.data);
    var v = data.data;
    var bounds = [];
    var locBound = [data.data.lat, data.data.lng];
    bounds.push(locBound);
    this.livemap.fitBounds(bounds);
    var coords = L.latLng(locBound);
    L.popup().setLatLng(coords).setContent(v.asrt_nm + "-" + v.asrt_ctgry_nm + " </br> " + v.asrt_grp_nm).openOn(this.livemap);
  }
  setVehicleMarkers() {
    // this.mcg.clearLayers();
    this.mcg = new L.MarkerClusterGroup().addTo(this.livemap);
    this.slctdDt = new Date();

    // console.log(this.markersList);
    // leafletdata.getMap().then(function (map) {

    var startTime = performance.now();
    // var markersList = [];

    // $http.get($rootScope.BaseUrl + "/vehicles/master").success(function (data) {
    this.markers = [];
    this.vehiclesList = [];
    this.masterVehicleMarkers = [];
    this.totalOfflineVehicleMarkers = [];
    this.offlineVehicles = [];
    // this.markersList = [];
    // markersList = this.data.data;


    for (var i = 0; i < this.markersList.length; i++) {
      this.data = this.markersList[i];
      this.m_prv_lat[this.data.asrt_id] = this.data.lat;
      this.m_prv_lng[this.data.asrt_id] = this.data.lng;
      // console.log(this.data);
      var markerData = {
        asrt_id: this.data.asrt_id,
        ast_sts_id: this.data.ast_sts_id,
        asrt_grp_id: this.data.asrt_grp_id,
        crnt_Ast_sts_id: this.data.crnt_Ast_sts_id,
        asrt_ctgry_id: this.data.asrt_ctgry_id,
        asrt_sts_id: this.data.asrt_sts_id,
        asrt_type_id: this.data.asrt_type_id,
        asrt_ctgry_nm: this.data.asrt_ctgry_nm,
        asrt_type_nm: this.data.asrt_type_nm,
        asrt_sts_nm: this.data.asrt_sts_nm,
        asrt_nm: this.data.asrt_nm,
        asrt_grp_nm: this.data.asrt_grp_nm,
        startFence: this.data.startFence,
        dev_imei_nu: this.data.dev_imei_nu,
        data_received_ts: this.data.data_received_ts,
        iconAngle: this.data.direction,
        icon_path: this.data.icon_path,
        lat: this.data.lat,
        lng: this.data.lng,
        prev_lat: this.data.prev_lat,
        prev_lng: this.data.prev_lng,
        area_nm: this.data.area_nm,
        loc_nm: this.data.loc_nm,
        odmtr_ct: this.data.odmtr_ct,
        onl_offl: this.data.onl_offl,
        title: this.data.asrt_nm + " | " + this.data.asrt_ctgry_nm + " | " + this.data.asrt_grp_nm + " | speed: " + this.data.spd_ct + " kmph",
        speed_ct: this.data.spd_ct,
        crw_ph: this.data.crw_ph,
        crw_nm: this.data.crw_nm,
        vehstat: this.data.vehstat,
        trp_rn_id: this.data.trp_rn_id,
        trp_ctrgy_id: this.data.trp_ctrgy_id,
        asrtglblId: this.data.asrtglblId,
        trp_actl_strt_ts: this.data.trp_actl_strt_ts,
        checked: true,
        id: i + 1,
        // group:"realworld",
        // group: 'stories',
        // layer: 'markercluster',
        // layer:'pointsLayer',
        focus: true,
        opacity: 1,
        // iconAngle: this.data.drcn_ct + 90
      };
      this.currentTimeStmp = moment(this.slctdDt).format('YYYY-MM-DD HH:mm:ss');
      this.recievedTime = moment(markerData.data_received_ts).format('YYYY-MM-DD HH:mm:ss');
      // console.log(this.recievedTime)
      // console.log( this.currentTimeStmp)
      this.markersList[i].diffTimeInMin = moment(this.currentTimeStmp).diff(this.recievedTime, 'minute');
      // this.markersList[i].diffTimeInHrs = moment(this.currentTimeStmp).diff(this.recievedTime, 'hours');
      // this.markersList[i].diffTimeInDys = moment(this.currentTimeStmp).diff(this.recievedTime, 'days');
      // console.log(markerData.speed_ct);
      // console.log(this.diffTimeInMin);
      // console.log(this.diffTimeInHrs);
      // console.log(this.diffTimeInDys);

      // if (this.data.asrt_sts_id == "1" || this.data.asrt_sts_id == "10") {
      if (this.data.asrtglblId == "1") {
        if (this.data.spd_ct <= '5') {
          var icon = "";
        } else {
          var icon = "";
        }
      } else {
        var icon = "";
      }
      markerData['icon'] = {
        iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.data.icon_path + icon,
        iconSize: [30, null],
        iconAnchor: [10, 25],
        popupAnchor: [5, -24],
        shaowSize: [35, 35]
      };

      /**All Vehicles... showing on map */
      var movIcon = new L.Icon({
        iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.data.icon_path + icon,
        iconSize: [30, null],
        iconAnchor: [10, 15],
        popupAnchor: [5, -24],
        shaowSize: [35, 35],
        asrt_id: this.data.asrt_id,
        latlang : [this.data.lat, this.data.lng],
        title:this.data.asrt_nm + " | " + this.data.asrt_ctgry_nm + " | " + this.data.asrt_grp_nm ,

      });
      // console.log('https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.data.icon_path + icon);
      if (this.data.lat !== null && this.data.lng !== null) {
        var parisKievLL = [[this.data.lat, this.data.lng], [this.data.lat, this.data.lng]];

        // this.animatedMarker = L.animatedMarker(parisKievLL, {
        //   autoStart: false,
        //   icon
        // });
        this.mov_marker[this.data.asrt_id] = L.animatedMarker(parisKievLL, { icon: movIcon, autoStart: false }).bindPopup(markerData.title).setRotationAngle(markerData.iconAngle + 270 )
          .addTo(this.livemap);
        // .addTo(this.mcg);

        // console.log(this.mov_marker[this.data.asrt_id]);
        this.deletemarkers.push(this.mov_marker[this.data.asrt_id]);
        // console.log(this.data.icon_path) //#change
        this.mov_marker[this.data.asrt_id].on('click', (e) => {
          console.log("IN MARKER CLICK");
          // chart1 = am4core.create("tripChart", am4charts.XYChart);

          for (var i = 0; i < this.markersList.length; i++) {
            if (e.target.options.icon.options.asrt_id == this.markersList[i].asrt_id) {
              this.movinmarkerClick(this.markersList[i]);
            }
          }
        });
        // ---- popup on mouseover a vehicle icon
        this.mov_marker[this.data.asrt_id].on('mouseover', (e) => {
          for (var i = 0; i < this.markersList.length; i++) {
            if (e.target.options.icon.options.asrt_id == this.markersList[i].asrt_id) {
              var popup = L.popup(e.target.options.icon.options.latlang, {content: e.target.options.icon.options.title}).openOn(this.livemap);
            }
          }
        });
      }

      // left side menu - vehicle list 
      if (this.data.asrtglblId == 1) { // Online  
        this.vehiclesList.push(this.data);

        this.masterVehicleMarkers.push(markerData);

      }
      if (this.data.asrtglblId != 1) { // Offline  for getting asrt_sts
        this.totalOfflineVehicleMarkers.push(markerData);

      }
      if (this.data.onl_offl === 0) { // Offline  based on data_rcvd_ts
        this.offlineVehicles.push(this.data);
      }

      // this.rawData_.push(markerData);
      // this.totalOfflineVehicleMarkers.push(markerData); // Offline --Binding Data
    }
    this.showOfflineVehicles();
    this.showRepairVehicles();
    this.showNotOprtedVehicles();
    this.showContractExpired();
    // console.log(this.deletemarkers);
    // });
    var duration = performance.now() - startTime;
    // });
    // }, (err) => {
    //   console.log("In errorr");
    // });
  };

  showOfflineVehicles() {

    this.offlineVehicles = [];
    if (this.offlineVehicles) {
      this.showOffline = this.totalOfflineVehicleMarkers;
    }

    for (var i = 0; i < this.totalOfflineVehicleMarkers.length; i++) {
      if (this.totalOfflineVehicleMarkers[i].asrtglblId == 2) {
        this.offlineVehicles.push({
          asrt_nm: this.totalOfflineVehicleMarkers[i].asrt_nm,
          asrt_type_nm: this.totalOfflineVehicleMarkers[i].asrt_type_nm,
          asrt_grp_nm: this.totalOfflineVehicleMarkers[i].asrt_grp_nm,
          asrt_ctgry_nm: this.totalOfflineVehicleMarkers[i].asrt_ctgry_nm,
          icon_path: this.totalOfflineVehicleMarkers[i].icon_path,
          onl_offl: this.totalOfflineVehicleMarkers[i].onl_offl,
          ast_sts_id: this.totalOfflineVehicleMarkers[i].ast_sts_id,
          crnt_Ast_sts_id: this.totalOfflineVehicleMarkers[i].crnt_Ast_sts_id,
          asrt_sts_nm: this.totalOfflineVehicleMarkers[i].asrt_sts_nm,
          prev_lat: this.totalOfflineVehicleMarkers[i].prev_lat,
          prev_lng: this.totalOfflineVehicleMarkers[i].prev_lng,
          lat: this.totalOfflineVehicleMarkers[i].lat,
          lng: this.totalOfflineVehicleMarkers[i].lng,
        });
      }
    }
  };

  showRepairVehicles() {
    this.repairVehicles = [];
    for (var i = 0; i < this.totalOfflineVehicleMarkers.length; i++) {
      if (this.totalOfflineVehicleMarkers[i].asrtglblId == 3) {
        this.repairVehicles.push({
          asrt_nm: this.totalOfflineVehicleMarkers[i].asrt_nm,
          asrt_type_nm: this.totalOfflineVehicleMarkers[i].asrt_type_nm,
          asrt_grp_nm: this.totalOfflineVehicleMarkers[i].asrt_grp_nm,
          asrt_ctgry_nm: this.totalOfflineVehicleMarkers[i].asrt_ctgry_nm,
          icon_path: this.totalOfflineVehicleMarkers[i].icon_path,
          ast_sts_id: this.totalOfflineVehicleMarkers[i].ast_sts_id,
          crnt_Ast_sts_id: this.totalOfflineVehicleMarkers[i].crnt_Ast_sts_id,
          asrt_sts_nm: this.totalOfflineVehicleMarkers[i].asrt_sts_nm
        });
      }
    }
  };
  showNotOprtedVehicles() {
    this.notOpratedVehicles = [];
    for (var i = 0; i < this.totalOfflineVehicleMarkers.length; i++) {
      if (this.totalOfflineVehicleMarkers[i].asrtglblId != 1 && this.totalOfflineVehicleMarkers[i].asrtglblId != 2 && this.totalOfflineVehicleMarkers[i].asrtglblId != 3) {
        console.log(this.notOpratedVehicles)
        this.notOpratedVehicles.push({
          asrt_nm: this.totalOfflineVehicleMarkers[i].asrt_nm,
          asrt_type_nm: this.totalOfflineVehicleMarkers[i].asrt_type_nm,
          asrt_grp_nm: this.totalOfflineVehicleMarkers[i].asrt_grp_nm,
          asrt_ctgry_nm: this.totalOfflineVehicleMarkers[i].asrt_ctgry_nm,
          icon_path: this.totalOfflineVehicleMarkers[i].icon_path,
          ast_sts_id: this.totalOfflineVehicleMarkers[i].ast_sts_id,
          crnt_Ast_sts_id: this.totalOfflineVehicleMarkers[i].crnt_Ast_sts_id,
          asrt_sts_nm: this.totalOfflineVehicleMarkers[i].asrt_sts_nm
        });
      }
    }
  }

  showContractExpired() {

    this.contractExpired = [];
    for (var i = 0; i < this.totalOfflineVehicleMarkers.length; i++) {
      if (this.totalOfflineVehicleMarkers[i].crnt_Ast_sts_id == 6) {
        this.contractExpired.push({
          asrt_nm: this.totalOfflineVehicleMarkers[i].asrt_nm,
          asrt_type_nm: this.totalOfflineVehicleMarkers[i].asrt_type_nm,
          asrt_grp_nm: this.totalOfflineVehicleMarkers[i].asrt_grp_nm,
          asrt_ctgry_nm: this.totalOfflineVehicleMarkers[i].asrt_ctgry_nm,
          icon_path: this.totalOfflineVehicleMarkers[i].icon_path,
          ast_sts_id: this.totalOfflineVehicleMarkers[i].ast_sts_id,
          crnt_Ast_sts_id: this.totalOfflineVehicleMarkers[i].crnt_Ast_sts_id,
          asrt_sts_nm: this.totalOfflineVehicleMarkers[i].asrt_sts_nm
        });
      }
    }

    this.tabs = [
      {
        id: 1,
        name: 'Online',
        icon: 'node-index',
        cnt: this.vehiclesList.length,
        clr: '#388e3c',
        bg_clr: '#e8f5e9',
        actv_bg_clr: '#4caf50',
        actv_in: 0
      },
      {
        id: 2,
        name: 'Offline',
        icon: 'warning',
        cnt: this.offlineVehicles.length,
        clr: '#f4511e',
        bg_clr: '#fbe9e7',
        actv_bg_clr: '#ff7043',
        actv_in: 0
      },
      {
        id: 3,
        name: 'Repair',
        icon: 'setting',
        cnt: this.repairVehicles.length,
        clr: '#5e35b1',
        bg_clr: '#ede7f6',
        actv_bg_clr: '#754fb8',
        actv_in: 0
      }
      ,
      {
        id: 4,
        name: 'Others',
        icon: 'question-circle',
        cnt: this.notOpratedVehicles.length,
        clr: '#546e7a',
        bg_clr: '#eceff1',
        actv_bg_clr: '#354f5b',
        actv_in: 0
      }
    ];
    this.event = {
      index: 0
    }
    console.log(this.event);
    this.tabchange(this.event);
  }
  refreshVehicleMarkers() {
    // this.mcg = new L.MarkerClusterGroup().addTo(this.livemap);

    // leafletData.getMap().then(function (map) {
    console.log("this http get call request refresh every 60 seconds...... (only in liveTracking)");
    this.refreshmarkersList = [];
    // $http.get($rootScope.BaseUrl + "/vehicles/master").success(function (data) {

    // this.apiS.get(`playback/vehicles/master/${this.selectedtenants}`)
    this.apiS.get(`playback/vehicles/master/vehicles/${this.selectedtenants}/vendor/${this.selectedvndr}`)
      .subscribe((res) => {
        if ((this.asrt_grp_id == undefined && this.asrt_ctgry_id == undefined) || (this.asrt_ctgry_id == 0 && this.asrt_grp_id == 0)) {
          this.refreshmarkersList = res['data'];
        }
        else if (this.asrt_ctgry_id == 0 && this.asrt_grp_id != 0) {
          for (var i = 0; i < res['data'].length; i++) {
            if (res['data'][i].asrt_grp_id == this.asrt_grp_id) {
              this.refreshmarkersList.push(res['data'][i]);
            }
          }
        }
        else {
          for (var i = 0; i < res['data'].length; i++) {
            if (res['data'][i].asrt_grp_id == this.asrt_grp_id && res['data'][i].asrt_ctgry_id == this.asrt_ctgry_id) {
              this.refreshmarkersList.push(res['data'][i]);
            }
          }
        }

        // gmc.removeLayer(refreshmarkersList);
        // console.log(gmc);

        for (i = 0; i < this.refreshmarkersList.length; i++) {
          this.data = this.refreshmarkersList[i];
          this.iconAngle = this.data.direction


          if (this.data.asrtglblId == "1") {
            if (this.data.spd_ct <= '5') {
              //var icon = "_p_m.png";
              var icon = "";
            } else {
              //var icon = "_r_m.png";
              var icon = "";
            }
          } else {
            //var icon = "_o_m.png";
            var icon = "";
          }
          var movIcon = new L.Icon({
            iconUrl: 'https://ent.wetrackon.com/assets/images/markers/mapIcons/m/' + this.data.icon_path + icon,
            iconSize: [31, 31],
            iconAnchor: [10, 25],
            popupAnchor: [5, -24],
            shaowSize: [35, 35],
            asrt_id: this.data.asrt_id
          });
          if (this.m_prv_lat[this.data.asrt_id] != this.data.lat) {

            if (this.data.lat !== null && this.data.lng !== null && this.m_prv_lat[this.data.asrt_id] != null && this.m_prv_lng[this.data.asrt_id] != null) {
              var parisKievLL = [[this.m_prv_lat[this.data.asrt_id], this.m_prv_lng[this.data.asrt_id]], [this.data.lat, this.data.lng]];
              // console.log(data.asrt_id);
              // gmc._needsRemoving();
              // gmc.addTo(null);
              // gmc.setMap(null);
              // gmc.clearLayers();
              this.livemap.removeLayer(this.mov_marker[this.data.asrt_id]);

              this.mov_marker[this.data.asrt_id] = L.animatedMarker(parisKievLL, { icon: movIcon, autoStart: false }).setRotationAngle(this.iconAngle + 270).bindPopup(this.data.asrt_nm + " | " + this.data.asrt_ctgry_nm + " | " + this.data.asrt_grp_nm + " | speed: " + this.data.spd_ct + " kmph").openPopup()
                // .addTo(this.mcg)
                .addTo(this.livemap);
              this.deletemarkers.push(this.mov_marker[this.data.asrt_id]);


              this.mov_marker[this.data.asrt_id].start();
              // console.log(mov_marker[data.asrt_id]);
              this.mov_marker[this.data.asrt_id].on('click', (e) => {
                console.log(e.target.options.icon.options.asrt_id);
                // console.log(markersList); 

                for (var i = 0; i < this.refreshmarkersList.length; i++) {
                  if (e.target.options.icon.options.asrt_id == this.refreshmarkersList[i].asrt_id) {
                    // console.log(markersList[i]);
                    this.movinmarkerClick(this.refreshmarkersList[i]);
                  }
                }

              });
              this.m_prv_lat[this.data.asrt_id] = this.data.lat;
              this.m_prv_lng[this.data.asrt_id] = this.data.lng;
            }
          }

          for (var j = 0; j < this.markers.length; j++) {
            if (this.markers[j].asrt_nm == this.data.asrt_nm) {
              this.markers[j].lat = this.data.lat;
              this.markers[j].lng = this.data.lng;
            }
          }

          for (j = 0; j < this.vehiclesList.length; j++) {
            if (this.vehiclesList[j].asrt_nm == this.data.asrt_nm) {
              this.vehiclesList[j].lat = this.data.lat;
              this.vehiclesList[j].lng = this.data.lng;
            }
          }

          for (j = 0; j < this.masterVehicleMarkers.length; j++) {
            if (this.masterVehicleMarkers[j].asrt_nm == this.data.asrt_nm) {
              this.masterVehicleMarkers[j].lat = this.data.lat;
              this.masterVehicleMarkers[j].lng = this.data.lng;
            }
          }

          for (j = 0; j < this.totalOfflineVehicleMarkers.length; j++) {
            if (this.totalOfflineVehicleMarkers[j].asrt_nm == this.data.asrt_nm) {
              this.totalOfflineVehicleMarkers[j].lat = this.data.lat;
              this.totalOfflineVehicleMarkers[j].lng = this.data.lng;
            }
          }

          for (j = 0; j < this.offlineVehicles.length; j++) {
            if (this.offlineVehicles[j].asrt_nm == this.data.asrt_nm) {
              this.offlineVehicles[j].lat = this.data.lat;
              this.offlineVehicles[j].lng = this.data.lng;
            }
          }
        }
      }, (err) => {
        console.log("In errorr");
      });
    // });
    // };

  }
  close(): void {
    this.visible = false;
  }

  movinmarkerClick(args) {
    // this.closeSidebar();
    var markerObj = args;
    this.gtdrctns = args;
    // this.closeSidebar();
    console.log(args)
    this.shwDta = true;
    this.opnSdebar = true;
    this.selectedVehicle = args;
    console.log(this.selectedVehicle);
    if (this.selectedVehicle) {
      this.visible = true;
    }
    // this.shwMrkerDta = true;
    // this.getdistanceChart();
    // this.getTripsChart();
    // this.shwDta=false;

    //     // $rootScope.closeLiveTrackFenceRgtBar();
    // if ((markerObj.trp_rn_id && markerObj.trp_ctrgy_id == 1) || (markerObj.trp_rn_id && markerObj.trp_ctrgy_id == 4 && markerObj.trp_actl_strt_ts !== null)) {
    //     /**## vehicle marker to get asrt_id, check in ltrack_dtl_t where trp_rn_id and trp_ctgry_id = 1 blah .. blah... to show schedule route timeline    */
    //     $http.post($rootScope.BaseUrl + 'schedule/route/timeline/' + markerObj.trp_rn_id)
    //         .success(function (resdata, status) {
    //             if (status == 200) {
    //                 $scope.routeTimeLineById = [];
    //                 for (var k = 0; k < resdata.data.length; k++) {
    //                     $scope.routeTimeLineById.push({
    //                         fnce_nm: resdata.data[k].fnce_nm,
    //                         actl_arvl_ts: resdata.data[k].actl_arvl_ts,
    //                         actl_dprte_ts: resdata.data[k].actl_dprte_ts,
    //                         lat: resdata.data[k].lat,
    //                         lng: resdata.data[k].lng,
    //                         stoppage_tm: resdata.data[k].stoppage_tm,
    //                     });
    //                 }
    //                 $scope.hide = true;
    //             }
    //         }).error(function (data, status, headers, config) { });
    // } else {
    //     $scope.hide = false;
    // }
    // if (markerObj.fnce_grp_id !== undefined) {
    //     if (markerObj.fnce_grp_id == 2 || markerObj.fnce_grp_id == 3) {
    //         return false;
    //     }
    // }

    //var leafEvent = args.leafletEvent;
    // var curBound = [args.lat, args.lng];
    // this.bounds.push(curBound);
    // // leafletData.getMap().then(function (map) {
    // this.livemap.fitBounds(this.bounds);
    // });
    // $rootScope.closeLiveTrackFilterRgtBar();

    // $rootScope.hideFilterBro = false;
    // var myEl = angular.element(document.querySelector('.m-quick-sidebar'));
    // myEl.css('right', '0px');
    // $scope.sidenavDtaHdr = "Vehicle Information";
    // $scope.targetDetails = "TIMELINE";
    // $scope.title = markerObj.asrt_nm;
    // $scope.asrt_ctgry_nm = markerObj.asrt_ctgry_nm;
    // $scope.crw_nm = markerObj.crw_nm;
    // $scope.crw_ph = markerObj.crw_ph;
    // $scope.asrt_grp_nm = markerObj.asrt_grp_nm;
    // $scope.asrt_type_nm = markerObj.asrt_type_nm;
    // $scope.area_nm = markerObj.area_nm;
    // $scope.loc_nm = markerObj.loc_nm;
    // $scope.lat = markerObj.lat;
    // $scope.lng = markerObj.lng;

    // var latlng = new L.LatLng($scope.lat, $scope.lng);
    // geocoder.geocode({ 'latLng': latlng }, function (results, status) {
    //     if (status == google.maps.GeocoderStatus.OK) {
    //         if (results[1]) {
    //             if (markerObj.loc_nm == null) {
    //                 $scope.loc_nm = results[1].formatted_address
    //             } else {
    //                 $scope.loc_nm
    //             }
    //         }
    //     }
    // });
    // geocode = L.geocode({'latlng':latlng}).on('success', function(e) {
    //     $scope.loc_nm= geocode.describeLocation(e.result.best);
    //   });

    // if (markerObj.onl_offl == "1") {
    //     if (markerObj.speed_ct <= 5) {
    //         $scope.status = "Parked";
    //     } else {
    //         $scope.status = "Running";
    //     }
    // } else {
    //     $scope.status = "Offline";
    // }

    // Todays trip details for a Vehicle
    // $http.get($rootScope.BaseUrl + "/assets/" + markerObj.asrt_id + "/trips/today")
    //     .success(function (resdata, status, headers, config) {
    //         if (status == 200) {
    //             $scope.today_trips = resdata.data;
    //         } else {
    //             console.log("Data = " + resdata);
    //         }
    //     }).error(function (data, status, headers, config) {
    //         $scope.message = "Unable retrive data from Server.";
    //     });

    //  Day Wise Distance Covered Status
    // $http.get($rootScope.BaseUrl + 'dywseDstnsCnt/' + markerObj.asrt_id)
    //     .success(function (resdata, status) {
    //         $scope.dstnsCvrd = resdata.data;

    //         $scope.chrtLvTrckngDistanceCovered();
    //         var string = "Distance in KM";
    //         $scope.showLoader = false;
    //     }).error(function (data, status, headers, config) { });
    //  Day Wise Trips Covered Status
    // $http.get($rootScope.BaseUrl + 'tripRptLst/' + markerObj.asrt_id)
    //     .success(function (resdata, status) {
    //         $scope.triplst = resdata.data;

    //         $scope.chrtLvTrckngTripsCovered();
    //         var string = "Total Trips Covered";
    //         $scope.showLoader = false;
    //     })
    //     .error(function (data, status, headers, config) {
    //         console.log(data);
    //     });

    //  Day Wise Landmark Covered Status    
    // $http.get($rootScope.BaseUrl + 'lndmrkCovChrtLst/' + markerObj.asrt_id)
    //     .success(function (resdata, status) {
    //         $scope.lndmrkcovchrtlst = resdata.data;
    //         var string = "Locations Covered";

    //         $scope.chrtLvTrckngLandmarksCovered();
    //         $scope.showLoader = false;
    //     }).error(function (data, status, headers, config) { });
  };
  closedrvrlst() {
    this.opnSdebar = false
  }
}
