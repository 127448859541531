<div class="page-header">
    <div class=" align-items-md-center">


        <div class="row hdrBar">
            <div class="col-lg-4 col-md-4">
                <h1>
                    <app-common-header [header_name]="header_label"></app-common-header>
                </h1>
            </div>
            <!-- <div class="col-lg-6 col-md-6 mg-a" style="text-align: right;" >
                                <span class="slctDteLbl">Select Date:</span>
                                <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat" [(ngModel)]="slctdDt"
                                    (ngModelChange)="onChange($event)" class="dte_picker"></nz-date-picker>
                
                
                            </div> -->
            <div class="col-lg-4 col-md-4 mg-a">
                <span class="slctDteLbl">Select District:</span>

                <nz-select class="slctBx" style="width: 200px;    text-align: left;" nzShowSearch
                    [(ngModel)]="slctd_dstrt" (ngModelChange)="getDstrwiseData($event)"
                    [ngModelOptions]="{standalone: true}" nzPlaceHolder="Select a district">

                    <nz-option *ngFor="let v of dstrtdata" [nzValue]="v.tnt_id" [nzLabel]="v.tnt_nm"></nz-option>
                </nz-select>
            </div>
            <!-- <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
                <span class="stdsbrdBtn" (click)="gotoDstrctDshbrd()"> Go to State Dashboard</span>
            </div> -->
            <div class="col-lg-4 col-md-4 mg-a" style="text-align: right;">
                <button nz-button nz-tooltip nzTooltipTitle="Go to State Dashboard" (click)="gotoDstrctDshbrd()"
                    class="back-btn">
                    <!-- <nz-icon nzType="arrow-left" nzTheme="outline"></nz-icon> -->
                    <span nz-icon nzType="arrow-left" nzTheme="outline"></span>

                </button>
            </div>

        </div>
    </div>
</div>

<div class="apsfl_dshbrd">



    <app-oms-dashbourd-cards [slctd_dstrt]="slctd_dstrt" [slctCrd]="slctdCrd"
        (slctdata)="selctedCatrd($event)"></app-oms-dashbourd-cards>


    <div class="row">
        <div class="col-md-12">
            <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;">
                <!-- <div class="row" style="margin-top:-10px;margin-bottom:5px">
                    <div class="col-md-8">
                        <div class="card_hdr_ttl" *ngIf="slctdCrd?.crd_hdr == 'WORKS'" style="position: relative;">
                            Activities</div>
                        <div class="card_hdr_ttl" *ngIf="slctdCrd?.crd_hdr == 'TRIPS'" style="position: relative;">
                            Travel Registry
                        </div>
                        <div class="card_hdr_ttl" *ngIf="slctdCrd?.crd_hdr == 'EXPENSES'" style="position: relative;">
                            Expenses</div>
                    </div> -->
                <div class="card_ttl">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="card_hdr_ttl" *ngIf="tb_nm == 'WORKS'" style="position: relative;">
                                Activities</div>
                            <div class="card_hdr_ttl" *ngIf="tb_nm == 'TRIPS'" style="position: relative;">
                                Travel Registry
                            </div>
                            <div class="card_hdr_ttl" *ngIf="tb_nm == 'EXPENSES'" style="position: relative;">
                                Expenses</div>
                        </div>
                        <div class="col-md-10">
                            <div class="row pstnCls ">

                                <div class="col-md-12" style="text-align:left; padding-left: 20px;">

                                    <nz-range-picker *ngIf="lvl_id!=3" [(ngModel)]="dateRnge" [nzFormat]="'dd-MM-yyyy'"
                                        (ngModelChange)="onChangeDate()"></nz-range-picker>


                                </div>

                            </div>
                        </div>
                        <div class="col-md-2">

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
                    <dx-data-grid class="p-8" id="gridContainer" [dataSource]="gridData" [remoteOperations]="false"
                        [allowColumnReordering]="true" [allowColumnResizing]="true" (onCellClick)="onCellClick($event)"
                        [showColumnLines]="true" [showRowLines]="true" [focusedRowEnabled]="true" [showBorders]="true"
                        style="width:100%" (onFocusedRowChanging)="onFocusedRowChanging($event)" keyExpr="sno"
                        [(focusedRowKey)]="focusedRowKey" [(autoNavigateToFocusedRow)]="autoNavigateToFocusedRow"
                        (onCellPrepared)="onCellPrepared($event)" (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onContentReady)="onContentReady($event)"  (onExporting)="onExporting($event)">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [visible]="tb_id!='3'" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager>
                        <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                        </dxo-search-panel>
                        <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                        <dxo-group-panel [visible]="false"></dxo-group-panel>
                        <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                        <dxo-column-chooser [enabled]="false" mode="select">
                        </dxo-column-chooser>
                        <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                        <dxo-export [enabled]="true" fileName=""></dxo-export>
                        <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                        <dxi-column *ngFor="let d of columnDefs" [dataField]="d.field" [visible]="d.visible"
                            [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                            [groupIndex]="d.groupIndex" [format]="d.format">
                        </dxi-column>
                        <!-- <dxi-column [showInColumnChooser]="false" [visible]="true" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoWorkProfile(data)">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>View Profile</p>
                        </div>
                    </div>
                </dxi-column> -->
                        <dxi-column [visible]="tb_id!='3'" [showInColumnChooser]="false" type="buttons" [width]="100"
                            caption="View" cellTemplate="editTemplate">
                            <div *dxTemplate="let data of 'editTemplate'">
                                <div *ngIf="data.rowType=='data'">


                                    <div *ngIf="tb_id=='1'" class="tblEdtBtn" (click)="gotoProfile(data,'work')">
                                        <i nz-icon nzType="eye" nzTheme="outline"></i>
                                        <p>View Profile</p>

                                    </div>
                                    <div *ngIf="tb_id=='2'" class="tblEdtBtn" 
                                        (click)="gotoTripTimeline(data)">
                                        <i nz-icon nzType="eye" nzTheme="outline"></i>

                                        <p>Trip Timeline</p>
                                    </div>
                                </div>
                            </div>
                        </dxi-column>
                        <!-- <dxi-column [visible]="tb_id=='2'" [showInColumnChooser]="false"   type="buttons" [width]="100"
                            caption="View" cellTemplate="editTemplate">
                            <div *dxTemplate="let data of 'editTemplate'">

                                <div class="tblEdtBtn" *ngIf="data.rowType=='data'" (click)="gotoTripTimeline(data)">{{tb_id}}ff
                                    <i nz-icon nzType="eye" nzTheme="outline"></i>
                                    <p>Trip Timeline</p>
                                </div>
                            </div>
                        </dxi-column> -->
                        <dxo-summary>
                            <dxi-total-item column="cstmr_efctd_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="no_trps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="asgn_emps" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="wrk_incld_expns" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="amt" summaryType="sum" [displayFormat]="sumcurrencyINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="ttl" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="inprg" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="cmpltd" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>
                            <dxi-total-item column="ttl_wrks" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>

                            <dxi-total-item column="emp_ct" summaryType="sum" [displayFormat]="SumINRFormater">
                            </dxi-total-item>



                            <!-- <dxi-group-item column="cstmr_efctd_ct" summaryType="sum" alignByColumn="true">
                            </dxi-group-item>
                            <dxi-group-item column="asgn_emps" summaryType="sum" alignByColumn="true">
                            </dxi-group-item> -->

                            <dxi-group-item column="cstmr_efctd_ct" summaryType="sum" alignByColumn="false"
                                [displayFormat]="SumINRFormater">
                            </dxi-group-item>

                            <dxi-group-item column="asgn_emps" summaryType="sum" alignByColumn="false"
                                [displayFormat]="SumINRFormater">
                            </dxi-group-item>
                            <!-- <dxi-group-item column="emp_ct" summaryType="sum" alignByColumn="false"  [displayFormat]="SumINRFormater">
                            </dxi-group-item> -->

                            <dxi-group-item *ngIf="tb_id=='2'" column="ttl_wrks" summaryType="sum" alignByColumn="false"
                                [displayFormat]="SumINRFormater">
                            </dxi-group-item>



                        </dxo-summary>
                    </dx-data-grid>
                </div>




            </div>
        </div>
        <div class="col-md-12">
            <div class="crd_style">
                <div class="card_ttl">
                    <div class="row">
                        <!-- <div class="col-md-10">
                            <div class="row pstnCls" style="position: relative;">
                                <div class="col-md-12">
                                    <div class="card_hdr_ttl" style="position: relative;left:0px">Activities</div>

                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-12 col-md-12">
                            <div class="row" style="    padding: 10px;
                            margin-bottom: 0px;
                            background: linear-gradient(45deg, #fff4e7, #ffeded);
                            border-radius: 10px 10px 0px 0px;">
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">Work Group</div>
                                    <nz-select nzShowSearch class="slctBx" [(ngModel)]="wrk_grp_id"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                                        <nz-option *ngFor="let t of wrkGrpLst" [nzValue]="t.wrk_grp_id"
                                            [nzLabel]="t.wrk_grp_nm">
                                        </nz-option>
                                    </nz-select>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">Work Status</div>
                                    <nz-select nzShowSearch class="slctBx" [(ngModel)]="oms_wrk_sts_id"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option [nzLabel]="'All'" [nzValue]="0"></nz-option>
                                        <nz-option *ngFor="let t of wrkStsLst" [nzValue]="t.oms_wrk_sts_id"
                                            [nzLabel]="t.wrk_sts_nm">
                                        </nz-option>
                                    </nz-select>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <!-- <div class="fltrLbls">From</div>
                                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat"
                                        [(ngModel)]="frmDt" (ngModelChange)="onChange($event)"
                                        style="    width: 100%; height: 32px; "></nz-date-picker>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="fltrLbls">To</div>
                                    <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="dateFormat"
                                        [(ngModel)]="toDt" (ngModelChange)="onDtChange($event)"
                                        style="    width: 100%; height: 32px; "></nz-date-picker> -->
                                    <div class="fltrLbls">Select Date</div>
                                    <nz-range-picker [(ngModel)]="dateRange" (ngModelChange)="onChange($event)"
                                        [nzFormat]="'dd-MM-yyyy'"
                                        style="height: 37px;border-radius: 4px;"></nz-range-picker>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <button (click)="getInPrgrsWrks()" style="    margin-top: 24px;
                                    background: #f15a22;
                                    color: #fff;
                                    border: currentColor;
                                    border-radius: 4px;
                                    border: 2px solid #ffb49f;
                                    cursor: pointer;">Get Data</button>
                                </div>
                            </div>



                        </div>
                        <div id="dstdbrdMap"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;" *ngIf="tb_id=='1'">
        <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
            <div class="card_ttl">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card_hdr_ttl" style="position: relative;">
                            Employees</div>

                    </div>
                </div>
            </div>
            <dx-data-grid class="p-8" id="gridContainer" [dataSource]="EmployData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="false" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [focusedRowEnabled]="true" [showBorders]="true"
                style="width:100%" (onFocusedRowChanging)="onFocusedRowChanging($event)" keyExpr="sno"
                [(focusedRowKey)]="focusedRowKey" [(autoNavigateToFocusedRow)]="autoNavigateToFocusedRow" (onFocusedRowChanged)="onFocusedRowChanged($event)"
                (onContentReady)="onContentReady($event)" (onExporting)="onExporting($event)" >
                <dxo-paging [pageSize]="10"></dxo-paging>
                <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager> -->
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select">
                </dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName=""></dxo-export>
                <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                <dxi-column *ngFor="let d of columnEmplyDefs" [dataField]="d.field" [visible]="d.visible"
                    [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                    [groupIndex]="d.groupIndex">
                </dxi-column>
                <dxi-column *ngIf="tb_id=='1'" [showInColumnChooser]="false" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoProfile(data,'employ')">
                            <i nz-icon nzType="eye" nzTheme="outline"></i>
                            <p>View Profile</p>
                        </div>
                    </div>
                </dxi-column>

            </dx-data-grid>
        </div>

    </div>


    <div class="   cstmTb px-4 pt-2 crd_style" style="margin-top: 0px;" *ngIf="tb_id=='2'">
        <div class="col-lg-12 col-md-12 mgnb-2 p-8 ">
            <div class="card_ttl">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card_hdr_ttl" style="position: relative;">
                            Vehicles</div>

                    </div>
                </div>
            </div>
            <dx-data-grid class="p-8" id="gridContainer" [dataSource]="vehicleData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" style="width:100%"  (onExporting)="onExporting($event)">
                <dxo-paging [pageSize]="10"></dxo-paging>
                <!-- <dxo-pager [visible]="true" [showPageSizeSelector]="true"
                            [allowedPageSizes]="[5, 10, 25, 50]" [showInfo]="true">
                        </dxo-pager> -->
                <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true">
                </dxo-search-panel>
                <!-- <dxo-filter-panel [visible]="true"></dxo-filter-panel> -->
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
                <dxo-column-chooser [enabled]="false" mode="select">
                </dxo-column-chooser>
                <dxo-scrolling columnRenderingMode="standard"></dxo-scrolling>
                <dxo-export [enabled]="true" fileName=""></dxo-export>
                <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
                <dxi-column *ngFor="let d of columnVhcleDefs" [dataField]="d.field" [visible]="d.visible"
                    [caption]="d.headerName" [alignment]="d.alignment" [width]="d.width" allowResizing="true"
                    [groupIndex]="d.groupIndex">
                </dxi-column>
                <dxi-column *ngIf="tb_id=='2'" [showInColumnChooser]="false" type="buttons" [width]="100" caption="View"
                    cellTemplate="editTemplate">
                    <div *dxTemplate="let data of 'editTemplate'">
                        <div class="tblEdtBtn" (click)="gotoProfile(data,'Vehicles')">
                            <i nz-icon nzType="node-index" nzTheme="outline"></i>
                            <p>View Profile</p>
                        </div>
                    </div>
                </dxi-column>

            </dx-data-grid>
        </div>

    </div>




    <nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzMaskClosable]="false" [nzWidth]="dwrWdth" [nzVisible]="sidenav"
        [nzTitle]="drwerTitle" (nzOnClose)="closeSideBar()" nzWrapClassName="wrkPrfleDrawer">
        <ng-container *nzDrawerContent>
            <div *ngIf="appCond==1">
                <app-wrok-profile [wrkData]="wrkData"></app-wrok-profile>
            </div>


            <div class="apsfl_dshbrd" *ngIf="appCond==2">
                <app-emplye-prfle [wrkData]="wrkData"></app-emplye-prfle>
            </div>

            <div class="apsfl_dshbrd" *ngIf="appCond==3">
                <app-vehicle-slider [vechile]="selectedVehicle"></app-vehicle-slider>
            </div>

            <div class="apsfl_dshbrd" *ngIf="appCond==4">
               <app-trip-timeline [tripId]="slctd_trp"></app-trip-timeline>
            </div>


        </ng-container>
    </nz-drawer>
</div>